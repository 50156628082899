import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Badge, Modal, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import UserProfile from "../GlobalTable/UserProfile";
import { createOrGetOneOnOneChat } from "../../service/chat.service";
import { useNavigate } from "react-router-dom";
import GlobalStateContext from "../../Contexts/GlobalStateContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const NewChatModal = ({
  handleUserClick,
  fetchContacts,
  openNewChat,
  handleCloseNewChat,
}) => {
  const {
    activeChatIndex,
    setActiveChatIndex,
    chatActiveId,
    setChatActiveId,
    setIsChatLoading,
    contacts,
  } = useContext(GlobalStateContext);
  // State variables
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  // Redux state
  const { isLoading, clients } = useSelector((state) => state?.globalClient);
  const { globalUsers } = useSelector((state) => state?.globalUser);
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);

  // Filtering logic
  const allContacts = [...clients, ...globalUsers];
  const filteredData = allContacts.filter(
    (item) =>
      item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredContacts = filteredData.filter(
    (item) => item.id !== authInfo?.id
  );

  // Functions
  const handleChatClick = async (id) => {
    try {
      const { data } = await createOrGetOneOnOneChat(id);
      alert("click");
      console.log(data.paylaod[0]);
      navigate(`/chats/${data?.paylaod[0]?.id}?index=${0}`);
      setActiveChatIndex(0);
      // handleUserClick(id, 0);
      setChatActiveId(data?.paylaod[0]?.id);
      fetchContacts();
    } catch (error) {
      // console.log(error);
    }
    handleCloseNewChat();
  };
  const _handleChatClick = (id) => async () => {
    setIsChatLoading(true);
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      const { data, status } = await createOrGetOneOnOneChat(id, signal);
      if (status == 200) {
        const index = contacts.findIndex((item) => {
          if (item.chatId === data?.payload[0]?.id) {
            return true;
          }
        });
        setChatActiveId(data?.payload[0]?.id);
        setActiveChatIndex(index);
        setIsChatLoading(false);
        navigate(`/chats/${data?.payload[0]?.id}?index=${index}`);
      }
      handleCloseNewChat();
      // console.log(index);
    } catch (error) {
      // console.log(error);
      setIsChatLoading(false);
      handleCloseNewChat();
    }
    return () => {
      setIsChatLoading(false);
      handleCloseNewChat();
      abortController.abort();
    };
  };

  return (
    <Modal
      open={openNewChat}
      onClose={handleCloseNewChat}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      <div
        className="rounded-2 "
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 480,
          height: "90%",
          backgroundColor: "#fff",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="d-flex justify-content-center">
          <h4 className="p-3 cad-fw-700">New Chat</h4>
        </div>
        <div className="w-100 mb-3 d-flex justify-content-center">
          <input
            type="text"
            className="form-control searchbox"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="w-100 d-flex flex-column overflow-auto h-75 pb-3">
          {filteredContacts?.map(
            ({ id, first_name, last_name, profile_photo }, index) => (
              <span
                onClick={_handleChatClick(id)}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow =
                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                  e.currentTarget.style.scale = 1;
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow = "none";
                }}
                style={{
                  cursor: "pointer",
                }}
                key={id}
                className="ms-3 me-3 border-bottom p-2  d-flex gap-2 align-items-center "
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <UserProfile proPic={profile_photo} />
                </StyledBadge>
                <span className="cad-text-medium ms-1 cad-fw-600">
                  {first_name} {last_name}
                </span>
              </span>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NewChatModal;
