import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import tagsSlice from "./Slice/tagsSlice";
import supportSlice from "./Slice/supportSlice";
import supportChatSlice from "./Slice/supportChatSlice";
import getAllUsers from "./Slice/getAllUsers";
import details from "./Slice/getUserDetails";
import faqSlice from "./Slice/faqSlice";
import helpAndGuidSlice from "./Slice/helpAndGuideSlice";
import clientSlice from "./Slice/contactClientSlice";
import getCompnay from "./Slice/company.service.slice";
import getArchiveClientSlice from "./Slice/contactArchiveClientSlice";
import thunk from "redux-thunk";
import taskSlice from "./Slice/taskSlice";
import kanbanSlice from "./Slice/kanbanSlice";
import taskType from "./Slice/taskTypeSlice";
import reminderSlice from "./Slice/reminderSlice";
import permissionSlice from "./Slice/permissionSlice";
import taskPrioritySlice from "./Slice/taskPrioritySlice";
import globalClientSlice from "./Slice/globalClientSlice";
import globalUserSlice from "./Slice/globalUserSlice";
import globalCompanySlice from "./Slice/globalCompanySlice";
import globlalTags from "./Slice/globlalTags";
import getRequestFeatures from "./Slice/getRequestFeatures";
import GlobalStatusSlice from "./Slice/GlobalStatusSlice";
import getSharedDocs from "./Slice/getSharedDocs";
import requestDocsSlice from "./Slice/requestDocsSlice";
import getClientById from "./Slice/getClientById";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { encryptTransform } from "redux-persist-transform-encrypt";
import globalModes from "./Slice/globalModes";
import getGlobalResource from "./Slice/getGlobalResource";
import authSlice from "./Slice/auth/auth.slice";
import getFormsSlice from "./Slice/getFormsSlice";
import fetchAuthMe from "./Slice/fetchAuthMe";
import getChatContacts from "./Slice/getChatContacts";
import getChatMessage from "./Slice/getChatMessage";
import themeSlice from "./Slice/theme.Slice";
import paymentSelice from "./Slice/paymentSelice";
import getGlobalForms from "./Slice/getGlobalForms";
import getCompanyById from "./Slice/getCompanyById";
import notificationData  from "./Slice/notification.service.slice";
import businessSlice from "./Slice/business.slice";

const rootReducer = combineReducers({
  tag: tagsSlice,
  support: supportSlice,
  supportChat: supportChatSlice,
  reqNewFeatures: getRequestFeatures,
  allUserData: getAllUsers,
  userDetails: details,
  faq: faqSlice,
  helpAndGuid: helpAndGuidSlice,
  client: clientSlice,
  company: getCompnay,
  archiveClient: getArchiveClientSlice,
  tasks: taskSlice,
  kanban: kanbanSlice,
  taskTypeData: taskType,
  reminders: reminderSlice,
  permission: permissionSlice,
  priority: taskPrioritySlice,
  globalClient: globalClientSlice,
  globalUser: globalUserSlice,
  globalCompany: globalCompanySlice,
  globalTags: globlalTags,
  globalStatus: GlobalStatusSlice,
  getSharedDocs: getSharedDocs,
  globalModes: globalModes,
  getRequestDocs: requestDocsSlice,
  getClientById: getClientById,
  getCompanyById: getCompanyById,
  getGlobalResource: getGlobalResource,
  authMe: authSlice,
  getForms: getFormsSlice,
  fetchAuthMe: fetchAuthMe,
  getChatContacts: getChatContacts,
  getChatMessage: getChatMessage,
  theme:themeSlice,
  paymentHistory: paymentSelice,
  getGlobalForms: getGlobalForms,
  notification: notificationData,
  business: businessSlice
});

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_SECRET_KEY,
  onError: function (error) {
    // console.log(error);
  },
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});
export const RootState = store.getState();
export const persistor = persistStore(store);


// import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import tagsSlice from "./Slice/tagsSlice";
// import supportSlice from "./Slice/supportSlice";
// import supportChatSlice from "./Slice/supportChatSlice";
// import getAllUsers from "./Slice/getAllUsers";
// import details from "./Slice/getUserDetails";
// import faqSlice from "./Slice/faqSlice";
// import helpAndGuidSlice from "./Slice/helpAndGuideSlice";
// import clientSlice from "./Slice/contactClientSlice";
// import getCompnay from "./Slice/company.service.slice";
// import getArchiveClientSlice from "./Slice/contactArchiveClientSlice";
// import thunk from "redux-thunk";
// import taskSlice from "./Slice/taskSlice";
// import kanbanSlice from "./Slice/kanbanSlice";
// import taskType from "./Slice/taskTypeSlice";
// import reminderSlice from "./Slice/reminderSlice";
// import permissionSlice from "./Slice/permissionSlice";
// import taskPrioritySlice from "./Slice/taskPrioritySlice";
// import globalClientSlice from "./Slice/globalClientSlice";
// import globalUserSlice from "./Slice/globalUserSlice";
// import globalCompanySlice from "./Slice/globalCompanySlice";
// import globlalTags from "./Slice/globlalTags";
// import getRequestFeatures from "./Slice/getRequestFeatures";
// import GlobalStatusSlice from "./Slice/GlobalStatusSlice";
// import getSharedDocs from "./Slice/getSharedDocs";
// import requestDocsSlice from "./Slice/requestDocsSlice";
// import getClientById from "./Slice/getClientById";
// import { combineReducers } from "redux";
// import globalModes from "./Slice/globalModes";
// import getGlobalResource from "./Slice/getGlobalResource";
// import authSlice from "./Slice/auth/auth.slice";
// import getFormsSlice from "./Slice/getFormsSlice";
// import fetchAuthMe from "./Slice/fetchAuthMe";
// import getChatContacts from "./Slice/getChatContacts";
// import getChatMessage from "./Slice/getChatMessage";
// import themeSlice from "./Slice/theme.Slice";
// import paymentSelice from "./Slice/paymentSelice";
// import getGlobalForms from "./Slice/getGlobalForms";

// const rootReducer = combineReducers({
//   tag: tagsSlice,
//   support: supportSlice,
//   supportChat: supportChatSlice,
//   reqNewFeatures: getRequestFeatures,
//   allUserData: getAllUsers,
//   userDetails: details,
//   faq: faqSlice,
//   helpAndGuid: helpAndGuidSlice,
//   client: clientSlice,
//   company: getCompnay,
//   archiveClient: getArchiveClientSlice,
//   tasks: taskSlice,
//   kanban: kanbanSlice,
//   taskTypeData: taskType,
//   reminders: reminderSlice,
//   permission: permissionSlice,
//   priority: taskPrioritySlice,
//   globalClient: globalClientSlice,
//   globalUser: globalUserSlice,
//   globalCompany: globalCompanySlice,
//   globalTags: globlalTags,
//   globalStatus: GlobalStatusSlice,
//   getSharedDocs: getSharedDocs,
//   globalModes: globalModes,
//   getRequestDocs: requestDocsSlice,
//   getClientById: getClientById,
//   getGlobalResource: getGlobalResource,
//   authMe: authSlice,
//   getForms: getFormsSlice,
//   fetchAuthMe: fetchAuthMe,
//   getChatContacts: getChatContacts,
//   getChatMessage: getChatMessage,
//   theme:themeSlice,
//   paymentHistory: paymentSelice,
//   getGlobalForms: getGlobalForms
// });

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }).concat(thunk),
// });
