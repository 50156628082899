import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BusinessDetails, BusinessStorage } from "../../service/auth.service";
const bytesToGB = (bytes, decimalPlaces = 6) => {
    const GB = bytes / (1024 ** 3);
    return GB.toFixed(decimalPlaces);
};
export const fetchBusinessStorage = createAsyncThunk("business/Storage", async (_, { rejectWithValue }) => {
    try {
        const { data } = await BusinessStorage()
        // console.log(data.data);
        if (data.data.length > 0) {
            return data
        }
        return rejectWithValue(data);
    } catch (error) {
        throw error
    }
})
export const fetchBusinessDetails = createAsyncThunk("business/Details", async (_, { rejectWithValue }) => {
    try {
        const { data } = await BusinessDetails()
        // console.log(data.data);
        if (data.data) {
            return data.data
        }
        return rejectWithValue(data);
    } catch (error) {
        throw error
    }
})

const businessSlice = createSlice({
    name: "business",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        deatils: undefined,
        bussiness_logo: undefined,
        storage: 0,
        document_count: 0,
        subscription: null,
        subscribed_user: true,
    },
    reducers: {
        setBusinessDetails: (state, action) => {
            state.deatils = action.payload
        },
        setBusinessLogo: (state, action) => {
            state.bussiness_logo = action.payload
        },
        setSubscription: (state, action) => {
            state.subscription = action.payload
        },
        setSubscription_user: (state, action) => {
            state.subscribed_user = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBusinessStorage.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(fetchBusinessStorage.fulfilled, (state, action) => {
                state.storage = action.payload?.data && bytesToGB(action.payload?.data[0]?.total_size, 6);
                state.document_count = action.payload?.data && action.payload?.data[0]?.document_count;
                state.isLoading = false;
            })
            .addCase(fetchBusinessStorage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.storage = 0
                state.error = action.error.message;
            });
        builder
            .addCase(fetchBusinessDetails.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(fetchBusinessDetails.fulfilled, (state, action) => {
                // console.log(action.payload);
                state.isLoading = false;
                state.deatils = action.payload
                state.bussiness_logo = action.payload.business_logo
            })
            .addCase(fetchBusinessDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.storage = 0
                state.error = action.error.message;
            });
    }
})

export default businessSlice.reducer;
export const { setBusinessDetails, setBusinessLogo, setSubscription, setSubscription_user } = businessSlice.actions