import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCompany, getCompanyDetails } from "../../service/company.service";

export const getCompanyData = createAsyncThunk("getCompanyData", async (params) => {
    try {
        const res = await getCompany(params);
        // console.log(res.data);
        return res.data;
    } catch (err) {
        throw new Error(`Error fetching data: ${err.message}`);
    }
});
export const getByIdCompany = createAsyncThunk("getByIdCompany", async (params) => {
    try {
        const res = await getCompanyDetails(params);
        console.log(res.data);
        return res.data;
    } catch (err) {
        throw new Error(`Error fetching data: ${err.message}`);
    }
});

const getCompanySlice = createSlice({
    name: "allUserData",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        companyById: undefined,
        users: [], // Initialize rows as an empty array
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyData.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(getCompanyData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.users = action.payload || [];
            })
            .addCase(getCompanyData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message; // Store the error message
            });
        builder
            .addCase(getByIdCompany.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(getByIdCompany.fulfilled, (state, action) => {
                state.isLoading = false;
                state.companyById = action.payload.data || undefined;
            })
            .addCase(getByIdCompany.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message; // Store the error message
            });
    },
})


export default getCompanySlice.reducer;

