import React, { useEffect, useState } from "react";
import { getPrivacyData } from "../../service/privacy.notice.service";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PrivacyNotice = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPrivacyData(setData); // Call the function and pass the setter function
      } catch (error) {
        console.error("Error fetching home card data:", error);
      }
    };
    fetchData(); // Call the function when the component mounts
  }, []);

  console.log(data);

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
      }}
      className="d-flex justify-content-start align-items-end flex-column p-4"
    >
      <div className="container mb-4">
        {data &&
          data?.map((htmlContent, index) => (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: htmlContent?.content }}
              className="text-dark"
            />
          ))}
      </div>
      <Link to="/registration" className="me-5 pe-3 ps-2 rounded-3 text-primary cad-text-small cad-fw-600 pointer action-hover"><ArrowBackIcon /> Back</Link>


    </div>
  );
};

export default PrivacyNotice;
