import React from "react";
import { Avatar, CircularProgress } from "@mui/material";
import PrimaryButton from "../../../Components/PrimaryButton";
import { useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import DateFormatter from "../../../Components/DateFormatter";
import UserProfile, { stringAvatar } from "../../../Components/GlobalTable/UserProfile";
import GroupAvatars from "../../../Components/Avatar/AvatarGroup";
import Tag from "../../../Components/Tag/Tag";

const CompanyDetails = ({
  closeModal,
  open,
  userId,
  handleOpenDpViewModal,
}) => {
  const { themeColorsObject } = useSelector((state) => state.theme);

  const fileURL = (path) => {
    return new URL(process.env.REACT_APP_DOWLOAD_URL + "/" + path).toString();
  };

  const { isLoading, companyById } = useSelector((state) => state?.company);

  console.log(companyById);

  const companyInformation = [
    {
      label: "Company name",
      value: companyById?.company_name,
    },
    // {
    //   label: "Phone Number",
    //   value: companyById?.phone_number ? companyById?.phone_number : "N/A",
    // },
    {
      label: "Industry",
      value: companyById?.industry ? companyById?.industry : "N/A",
    },
    {
      label: "Country",
      value: companyById?.country ? companyById?.country : "N/A",
    },
    {
      label: "City",
      value: companyById?.city ? companyById?.city : "N/A",
    },
    {
      label: "Pincode",
      value: companyById?.postal_code ? companyById?.postal_code : "N/A",
    },
    {
      label: "Web Address",
      value: companyById?.web_address ? companyById?.web_address : "N/A",
    },
    {
      label: "Email Address",
      value: companyById?.email_address,
    },
  ];

  const moreInformation = [
    {
      label: "Created Date",
      value: <DateFormatter inputDate={companyById?.createdAt} />,
    },
    {
      label: "Created By",
      value: companyById?.creator?.first_name ? (
        <UserProfile
          proPic={companyById?.creator?.profile_photo}
          companyName={`${companyById?.creator?.first_name}
    ${companyById?.creator?.last_name}`}
        />
      ) : (
        "N/A"
      ),
    },
    {
      label: "Tags",
      value: <div className="cad-text-medium d-flex gap-2 cad-text-dark flex-wrap cad-fw-600">
        {companyById?.tags?.map((tag, i) => (
          <Tag key={i} title={tag?.title} />
        ))}
      </div>,
    },
    // {
    //   label: "Tax ID",
    //   value: companyById?.tax_id ? companyById?.tax_id : "N/A",
    // },
    // {
    //   label: "Bussiness Registration No/ID",
    //   value: companyById?.tax_id ? companyById?.tax_id : "N/A",
    // }
  ];
  // useEffect(() => {
  // // console.log(arrayData);
  // }, [arrayData]);

  return (
    <MainModal
      title={"Company Details"}
      height={"90%"}
      width={"60%"}
      open={open}
      handleClose={closeModal}
    >
      {isLoading ? (
        <div className="d-flex w-100 p-5 justify-content-center align-items-center ">
          <CircularProgress className="m-5" />
        </div>
      ) : (
        <div className=" pb-4">
          {/* ===========[ border ]=============== */}
          <div className="col-12 border-bottom ps-3 pe-3 mb-3 pb-3 d-flex justify-content-between align-items-center">
            <span className="position-relative   d-flex justify-content-start gap-4 align-items-center">
              <Avatar
                onClick={() => handleOpenDpViewModal(companyById?.profile_photo)}
                style={{ width: 80, height: 80, cursor: "pointer" }}
                alt={companyById?.company_name}
                src={
                  companyById?.profile_photo === null
                    ? ""
                    : fileURL(companyById?.profile_photo)
                }


                {...stringAvatar(`${companyById?.company_name === undefined ? "N/A" : companyById?.company_name} `)}
              />

              <div className="d-flex flex-column gap-1 position-relative">
                <span className="cad-text-large cad-fw-600 ">
                  {companyById?.company_name}
                </span>
                <span className="cad-text-medium cad-fw-500">
                  Mob: {companyById?.phone_number ? companyById?.phone_number : "N/A"}
                </span>

                <span
                  style={{
                    right: "-36px",
                    bottom: "26px",
                  }}
                  className="position-absolute"
                ></span>
              </div>
            </span>
          </div>

          <div>
            <h6>Company Information</h6>
          </div>
          <div className="d-flex flex-wrap mt-3">
            {companyInformation.map((item, key) => {
              return (
                <div
                  key={key}
                  className="col-4 d-flx align-items-center mb-4 gap-2"
                >
                  <div className="col-12">
                    <label
                      style={{ color: themeColorsObject.titleText }}
                      className="cad-text-medium w-100  cad-fw-500"
                    >
                      {item.label}
                    </label>
                  </div>
                  <div className="col-12">
                    <label
                      style={{ color: themeColorsObject.textColor }}
                      className="cad-text-medium w-100 p-1   cad-fw-600"
                    >
                      {item.value}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>

          <hr />
          <div>
            <h6>More Information</h6>
          </div>
          <div className="d-flex flex-wrap mt-3">
            {moreInformation.map((item, key) => {
              return (
                <div
                  key={key}
                  className="col-4 d-flx align-items-center mb-4 gap-2"
                >
                  <div className="col-12">
                    <label
                      style={{ color: themeColorsObject.titleText }}
                      className="cad-text-medium w-100  cad-fw-500"
                    >
                      {item.label}
                    </label>
                  </div>
                  <div className="col-12">
                    <label
                      style={{ color: themeColorsObject.textColor }}
                      className="cad-text-medium w-100 p-1   cad-fw-600"
                    >
                      {item.value}
                    </label>
                  </div>
                </div>


              );
            })}

            <div
              className="col-12 d-flx align-items-center mb-4 gap-3"
            >
              <div className="col-12 mb-3">
                <label
                  style={{ color: themeColorsObject.titleText }}
                  className="cad-text-medium w-100  cad-fw-500"
                >
                  Clients
                </label>
              </div>
              <div className="col-12 d-flex justify-content-start">
                <GroupAvatars data={companyById?.clients} max={companyById?.clients?.length} />
              </div>
            </div>

          </div>


          <div className="col-12 d-flex justify-content-center">
            <PrimaryButton
              variant="contained"
              title="Close"
              padding="0.2rem 1.2rem"
              fontSize="14px"
              onClick={closeModal}
              width="160px"
            />
          </div>
        </div>
      )}




    </MainModal>
  );
};

export default CompanyDetails;
