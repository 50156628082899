import React, { useEffect, useState } from "react";
import { Avatar, Paper, Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import PrimaryButton from "../../../Components/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { BsCaretLeft } from "react-icons/bs";
import { AiOutlineLeft } from "react-icons/ai";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addClient } from "../../../service/client.service";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCompanyData } from "../../../Redux/Slice/company.service.slice";
import { getAllUsers } from "../../../Redux/Slice/getAllUsers";
// import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { getTags } from "../../../service/tags.service";
import Select from "../../../Components/Select";
import { toast, ToastContainer } from "react-toastify";
import { fetchClient } from "../../../Redux/Slice/contactClientSlice";
import { fetchGlobalClient } from "../../../Redux/Slice/globalClientSlice";
import { notify } from "../../../App";

const AddNewClient = () => {
  const navigate = useNavigate();

  const [tagsData, setTagsData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyData());
    dispatch(getAllUsers());

    async function fetchPermissionData() {
      try {
        const response = await getTags();
        // // console.log(response.data);
        if (response.status === 200) {
          // If the API call is successful (status code 200), set the data
          setTagsData(response.data.data.rows);
        //   // console.log(response.data.data.rows);
        } else {
          // Handle other cases, e.g., error or non-200 response
          console.error("API request failed");
        }
      } catch (error) {
        // Handle network or other errors
        console.error("API request error", error);
      }
    }

    fetchPermissionData();
  }, []);

  const companyData = useSelector((state) => state?.company?.users?.data?.rows);
  const {globalUsers} = useSelector(
    (state) => state?.globalUser
  );

  // console.log(globalUsers);

  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const contentData = [
    {
      title: "Personal Information",
      fields: [
        {
          label: "First Name",
          type: "text",
          required: true,
          name: "first_name",
          error_msg: true,
        },
        {
          label: "Last Name",
          type: "text",
          name: "last_name",
          error_msg: true,
        },
        {
          label: "Phone Number",
          type: "text",
          name: "phone_number",
          error_msg: true,
        },
        {
          label: "Gender",
          type: "text",
          name: "gender",
          error_msg: true,
        },
        {
          label: "Email Address",
          type: "email",
          required: true,
          name: "email_address",
          error_msg: true,
        },
      ],
    },
    {
      title: "Address Details",
      fields: [
        {
          label: "Country",
          type: "text",
          name: "country",
          error_msg: true,
        },
        {
          label: "City",
          type: "text",
          name: "city",
          error_msg: true,
        },
        {
          label: "State",
          type: "text",
          name: "state",
          error_msg: true,
        },
        {
          label: "Pincode",
          type: "text",
          name: "postal_code",
          error_msg: true,
        },
      ],
    },
    {
      title: "Other Information",
      fields: [
        {
          label: "Company/Organization",
          name: "company",
          type: "select",
          options: companyData?.map((ele) => ({
            label: ele.company_name,
            value: ele.id,
          })),
        },
        {
          label: "Assign to",
          name: "assign",
          type: "select",
          options: globalUsers?.map((ele) => ({
            label: ele.first_name,
            value: ele.id,
          })),
        },
        {
          label: "Tag",
          name: "tag",
          type: "multiple-select",
          options: tagsData?.map((ele) => ({
            label: ele.title,
            value: ele.id,
            color: "red",
          })),
        },
      ],
    },
  ];

  const [previewImage, setPreviewImage] = useState("");

  const userSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/^\S*$/, "First Name cannot contain spaces"),

    email_address: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required").matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 
        "Email address is required"
      ),

    last_name: Yup.string()
      .matches(/^\S*$/, "Last Name cannot contain spaces")
      .notRequired(), // Mark the field as optional
    state: Yup.string()
      .matches(/^\S*$/, "Last Name cannot contain spaces")
      .notRequired(),
    gender: Yup.string()
      .oneOf(["male", "female", "others"], "Invalid Input")
      .notRequired(),
    country: Yup.string()
      .matches(/^\S*$/, "Last Name cannot contain spaces")
      .notRequired(),
    phone_number: Yup.string()
      .notRequired() // Mark the field as optional
      .matches(/^[0-9]*$/, "Phone Number must contain only numbers")
      .test(
        "is-min-10-characters",
        "Phone Number must be at least 10 characters",
        (value) => {
          if (!value) {
            return true; // Pass the validation if the field is empty
          }
          return value.length >= 10;
        }
      ),

    postal_code: Yup.string().matches(
      /^[0-9]*$/,
      "Pincode must contain only numbers"
    ),

    profile_photo: Yup.mixed()
      .test("required", "You need to provide a file", (files) => {
        // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
        if (files) return true;
        return false;
      })
      .test("fileSize", "The file is too large", (files) => {
        //if u want to allow only certain file sizes
        try {
          if (files.length !== 0) {
            return files[0].size <= 2000000;
          }
          return true;
        } catch (error) {
          return false;
        }
      })
      .test("file_formate", "Image file has unsupported format.", (files) => {
        // // console.log(files[0].type)

        const SUPPORTED_FORMATS = [
          "image/jpeg",
          "image/png",
          "image/tiff",
          "image/svg+xml",
        ];
        try {
          if (files.length !== 0) {
            setPreviewImage(URL.createObjectURL(files[0]));
            return files && SUPPORTED_FORMATS.includes(files[0].type);
          }
          return true;
        } catch (error) {
          return false;
        }
      })
      .optional(),

    tag: Yup.array(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    getFieldState,
    control,
  } = useForm({ mode: "all", resolver: yupResolver(userSchema) });

  // console.log(errors);

  const onSubmit = async (values) => {
    setIsLoading(true);

    // Create a FormData object
    const formDataToSend = new FormData();

    // Append the data from each field
    values.profile_photo[0] &&
      formDataToSend.append("profile_photo", values.profile_photo[0]);
    formDataToSend.append("first_name", values.first_name);
    formDataToSend.append("email_address", values.email_address);
    values.last_name && formDataToSend.append("last_name", values.last_name);
    values.phone_number &&
      formDataToSend.append("phone_number", values.phone_number);
    values.country && formDataToSend.append("country", values.country);
    values.city && formDataToSend.append("city", values.city);
    values.country && formDataToSend.append("state", values.state);
    values.city && formDataToSend.append("gender", values.gender);
    values.postal_code &&
      formDataToSend.append("postal_code", values.postal_code);
    values.company && formDataToSend.append("company_id", values.company);
    values.assign && formDataToSend.append("employee_id", values.assign);

    if (selectedTags.length > 0) {
      selectedTags.forEach((tag, index) => {
        formDataToSend.append(`tags[${index}]`, tag);
      });
    }

 

    try {
      const response = await addClient(formDataToSend);
      if (response.status == 400) {
        setIsLoading(false);
        // console.log(response);
        notify();
      } else if (response.status == 201) {
        dispatch(fetchGlobalClient())
        dispatch(fetchClient({
          size: 10,
          page: 1,
        }));
        setIsLoading(false);
        notify();
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
    reset();
  };

  const animatedComponents = makeAnimated();

  const selectOption = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedTags(selectedValues);
  };



  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "#f1f2f6",
      }}
      className=" h-100 w-100 p-3 d-flex overflow-hidden flex-column justify-content-start align-items-start "
    >
      <Box
        className="border-bottom rounded-top-3"
        sx={{
          borderBottom: 0,
          padding: 0,
          backgroundColor: "#fff",
          width: "100%",
          overflow: "scroll",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-3">
            <Link
              to="/contacts"
              className="mb-4 d-flex align-items-center gap-1 text-decoration-none"
            >
              <AiOutlineLeft className="pointer" size={18} />
              <label className="pointer">back to contacts</label>
            </Link>
            <h4 className="cad-text-dark cad-fw-600">Add Client</h4>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <div
                style={{
                  position: "relative",
                }}
              >
                <Avatar
                  className="m-1"
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "4px solid #E9E9E9",
                  }}
                  alt="Remy Sharp"
                  src={previewImage}
                />

                <input
                  type="file"
                  id="profile-image-input"
                  accept="image/*"
                  style={{
                    display: "none",
                  }}
                  {...register("profile_photo")}
                />

                <label
                  style={{
                    position: "absolute",
                    bottom: "0.9rem",
                    left: "4.9rem",
                    height: "30px",
                    width: "30px",
                    backgroundColor: "#E9E9E9",
                    padding: "4px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  htmlFor="profile-image-input"
                >
                  <CameraAltTwoToneIcon
                    sx={{
                      position: "absolute",
                      bottom: "0rem",
                      right: "0rem",
                      height: "30px",
                      width: "30px",
                      backgroundColor: "#E9E9E9",
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </label>
              </div>
              <label className="cad-text-medium mb-1 cad-text-light-grey cad-fw-500">
                The maximum size of profile picture is 2MB.
              </label>
            </div>
          </div>

          {contentData.map((section, index) => (
            <div key={index}>
              <hr className="m-0 hr-border" />
              <div className="p-3">
                <h5 className="cad-text-grey mb-3 cad-text-large">
                  {section.title}
                </h5>
                <div className="row">
                  {section.fields.map((field, fieldIndex) => (
                    <div
                      className={
                        field.type === "checkboxGroup"
                          ? "col-4 mb-2"
                          : "col-4 mb-2"
                      }
                      key={fieldIndex}
                    >
                      <label
                        className={`cad-text-medium mb-1 cad-text-light-grey cad-fw-500 ${
                          field.required ? "text-danger" : ""
                        }`}
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-danger"> *</span>
                        )}
                      </label>
                      {field.type === "select" ? (
                        <select
                          defaultValue={"none"}
                          className={`form-control w-100  ${
                            field.required ? "required" : ""
                          }`}
                          {...register(field.name)}
                        >
                          <option value="">none</option>
                          {field.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "multiple-select" ? (
                        <Select
                          isMulti={true}
                          options={field.options}
                          setSelected={selectOption}
                        />
                      ) : (
                        <input
                          className={`form-control w-100 ${
                            field.required ? "required" : ""
                          }`}
                          type={field.type}
                          {...register(field.name)}
                        />
                      )}
                      {field.error_msg && (
                        <div
                          htmlFor={field.name}
                          className="form-label text-primary form-text text-danger"
                        >
                          {errors[field.name]?.message}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-center py-3 m-auto w-25">
            <Button className="button-contained" type="submit">
              
            {isLoading ? (
                <CircularProgress
                  style={{ height: 24, width: 24 }}
                  color="inherit"
                />
              ) : (
                "Add"
              )}
            </Button>
          </div>
        </form>
      </Box>
    </Paper>
  );
};

export default AddNewClient;
