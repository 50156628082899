import React, {
  useState,
  useCallback,
  useEffect,
} from "react";
import "./User.css";
import PrimaryButton from "../../Components/PrimaryButton";
import {
  AiOutlineDelete,
  AiOutlinePlus,
} from "react-icons/ai";
import downloadCSV from "./DownloadCSV";
import {
  Chip,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ViewUserDetails from "./ViewUserDetails";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../Redux/Slice/getAllUsers";
import { useSelector } from "react-redux";
import UserProfile from "../../Components/GlobalTable/UserProfile";
import { formatDate } from "../../Components/DateFormatter";
import AddNewUserModal from "./AddNewUserModal";
import DpViewModal from "../../Components/DpViewModal";
import TableData from "../../Components/TableData";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Refresher from "../../Components/Refresher";
import { notify } from "../../App";
import AlertModal from "../../Components/AlertModal";
import { deleteUser } from "../../service/user.service";
import { fetchGlobalUser } from "../../Redux/Slice/globalUserSlice";
import AdvanceFilter from "../../Components/AdvanceFilter/AdvanceFilter";
import GroupAvatars from "../../Components/Avatar/AvatarGroup";

const User = () => {
  const navigate = useNavigate();
  const { themeColorsObject } = useSelector((state) => state.theme);
  const [tableData, setTableData] = useState([]);
  const [openView, setOpenView] = useState(false);

  const [DpViewModalOpen, setDpViewModalOpen] = useState(false);

  const [DpUrl, setDpUrl] = useState("");

  const handleOpenDpViewModal = (url) => {
    // console.log(DpViewModalOpen);
    setDpUrl(url);
    setDpViewModalOpen(true);
  };

  const handleCloseDpViewModal = () => setDpViewModalOpen(false);

  const [openAddNewUserModal, setOpenAddNewUserModal] = useState(false);
  const handleAddNewUserModalOpen = () => setOpenAddNewUserModal(true);

  const dispatch = useDispatch();
  const handleViewOpen = (id) => {
    if (profileData?.id === id) {
      return navigate("/profile");
    }
    setOpenView(true);
  };

  const { rows, totalPages } = useSelector(
    (state) => state?.allUserData?.users?.data
  );
  const { role, profileData } = useSelector((state) => state?.authMe);
  const { users, isLoading } = useSelector((state) => state?.allUserData);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (rows) {
      setTableData(rows);
    }
  }, [rows]);

  // State to keep track of selected rows
  const [selectedRows, setSelectedRows] = useState([]);

  const [userId, setUserId] = useState("");

  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);

  const [filteredCellConfig, setFilteredCellConfigg] = useState([]);
  const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };


  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setUserId(id);
  };

  // Handle click on export button
  const handleExportClick = () => {
    if (selectedRows.length === 0) {
      // alert('No rows selected for export');
      notify("No rows selected for export", "error");
      return;
    }

    // Call downloadCSV function with selectedRows
    downloadCSV(selectedRows, "selected_data.csv");
  };

  // Callback function to handle changes in selected rows
  const handleChange = useCallback((state) => {
    setSelectedRows(state?.selectedRows);
  }, []);

  useEffect(() => {
    // // console.log(userId);
  }, [userId]);


  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Function to open the modal
  const openModalDelete = () => {
    setDeleteModalOpen(true);
    closeMenu();
  };

  // Function to close the modal
  const closeModalDelete = () => {
    setDeleteModalOpen(false);
  };

  const objectToMove = users?.data?.rows?.find(
    (obj) => obj.id === profileData?.id
  );

  const filteredArray = users?.data?.rows?.filter(
    (obj) => obj.id !== profileData?.id
  );

  const topOrderUser = [objectToMove, ...filteredArray];

  // const filteredTaskArray = topOrderUser.filter((row) => {
  //   return (
  //     row?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     row?.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     row?.managers[0]?.first_name
  //       .toLowerCase()
  //       .includes(searchTerm.toLowerCase()) ||
  //     row?.managers[0]?.last_name
  //       .toLowerCase()
  //       .includes(searchTerm.toLowerCase()) ||
  //     row?.email_address.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });

  const extractedDataArray = topOrderUser.map((user) => {
    const extractedData = {
      id: user?.id,
      input: (
        <input
          style={{ width: 16 }}
          className="form-check-input pointer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
      ),
      name: (
        <span
          className={`${user?.id === profileData?.id ? "text-primary fw-bold" : ""
            }`}
        >
          <UserProfile
            proPic={user?.profile_photo}
            companyName={`${user?.first_name} ${user?.last_name}`}
          />
        </span>
      ),
      email: (
        <span
          className={`${user?.id === profileData?.id ? "text-primary fw-bold" : ""
            }`}
        >
          {user?.email_address}
        </span>
      ),
      reporting: (
        <span
          className={`${user?.id === profileData?.id ? "text-primary fw-bold" : ""
            }`}
        >
          {user?.managers.length === 0 ? (
            "N/A"
          ) : (
            <UserProfile
              proPic={user?.managers[0]?.profile_photo}
              companyName={`${user?.managers[0]?.first_name} ${user?.managers[0]?.last_name}`}
            />
          )}
        </span>
      ),

      assignedClient: (
        <span className="d-flex justify-content-center">
          {user?.clients?.length === 0 ? (
            <span className="cad-text-small cad-fw-600 border border-primary rounded-2 ps-2 pe-2 text-primary">
              No clients
            </span>
          ) : (
            <GroupAvatars data={user?.clients} />
          )}
        </span>
      ),
      createdAt:
        mouseEntered && mouseEnteredId === user?.id ? (
          profileData?.id === user?.id ? (
            <Chip
              className="chip-onhold ps-2 pe-2 mt-2 mb-2 cad-fw-700 rounded-2"
              label="Self"
              size="small"
            />
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              {/* <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover text-primary"
              >
                <RemoveRedEyeOutlinedIcon
                  onClick={() => handleViewOpen(user?.id)}
                  style={{
                    fontSize: "20px",
                  }}
                />
              </span> */}

              {role === "Admin" ? (
                ""
              ) : (
                <span
                  style={{ borderRadius: "18%" }}
                  className="p-2 action-hover text-primary"
                >
                  <AiOutlineDelete
                    onClick={() => openModalDelete(user?.id)}
                    style={{
                      fontSize: "20px",
                    }}
                  />
                </span>
              )}
            </div>
          )
        ) : (
          <span>{formatDate(user?.createdAt)}</span>
        ),

      designation: (
        <span
          className={`${user?.id === profileData?.id ? "text-primary fw-bold" : ""
            }`}
        >
          {user?.metadata?.designation || "N/A"}
        </span>
      ),
    };

    return extractedData;
  });

  const [checkboxValues, setCheckboxValues] = useState({
    Checkbox: false,
    Name: true,
    "Email Address": true,
    Designation: true,
    "Repoting Manager": true,
    "Created At": true,
    // "Assign Date": true,
  });

  const cellConfig = [
    { dataKey: "input", align: "center", width: "5%" },
    { dataKey: "name", align: "center", width: "23%" },
    { dataKey: "email", align: "left", width: 160 },
    { dataKey: "designation", align: "left", width: 200 },
    { dataKey: "reporting", align: "left", width: 200 },
    { dataKey: "assignedClient", align: "center", width: 230 },
    { dataKey: "createdAt", align: "center", width: 230 },
  ];

  const tableHeadRowCell = [
    {
      title: (
        <input
          style={{ width: 16 }}
          className="form-check-input pointer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
      ),
      width: "5%",
      align: "center",
    },
    {
      title: "Name",
      width: "23%",
      align: "left",
    },
    {
      title: "Email Address",
      width: 160,
      align: "left",
    },
    {
      title: "Designation",
      width: 200,
      align: "left",
    },
    {
      title: "Reporting",
      width: 200,
      align: "left",
    },
    {
      title: "Assigned client",
      width: 230,
      align: "center",
    },
    {
      title: "Created At",
      width: 230,
      align: "center",
    },
  ];

  const handlePagination = (value) => {
    dispatch(
      getAllUsers({
        size: 10,
        page: value,
      })
    );
  };

  const handleDeleteUser = async () => {
    try {
      // Call the deleteUser function and pass the user ID
      const response = await deleteUser(mouseEnteredId);
      if (response.status === 200) {
        // The user was successfully deleted, handle the success
        // console.log('User deleted successfully');
        dispatch(getAllUsers());
        dispatch(fetchGlobalUser());
        closeModalDelete();
        notify("User deleted successfully");
      } else {
        // Handle errors or show a message to the user
        console.error("Failed to delete user");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error deleting user", error);
    }
  };

  // Render the component
  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        // transition:"0.3s"
      }}
      className="w-100   overflow-auto mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
    >
      <Box
        className=" rounded-top-3"
        sx={{
          borderBottom: 0,
          padding: 0,
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center justify-content-between p-3 col-md-12">
          <div className="col-3">
            <div className="w-100">
              <SearchInput
                searchValue={searchTerm}
                searchFucntion={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex gap-2">
            <Refresher
              isLoading={isLoading}
              handleRefresh={() => dispatch(getAllUsers())}
            />
            {role === "Admin" ? (
              ""
            ) : (
              <>
                <PrimaryButton
                  variant="contained"
                  title="Add New User"
                  padding="0.3rem 0.6rem"
                  icon={<AiOutlinePlus size={13} />}
                  fontSize="14px"
                  width="200px"
                  // path={"/add-new-user"}
                  onClick={() => handleAddNewUserModalOpen()}
                />
                {/* <SecondaryButton
                  title="Export"
                  padding="0.2rem 2rem"
                  icon={<AiOutlineUpload size={16} />}
                  fontSize="13px"
                  onClick={handleExportClick}
                /> */}
                <AdvanceFilter
                  bottom="-170px"
                  left="-90px"
                  cellConfig={cellConfig}
                  tableHeadRowCell={tableHeadRowCell}
                  setFilteredCellConfig={setFilteredCellConfigg}
                  setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
                  checkboxValues={checkboxValues}
                  setCheckboxValues={setCheckboxValues}
                />
              </>
            )}
          </div>
        </div>

        <TableData
          tableHeadRowCell={filteredTableHeadRowCell}
          extractedDataArray={extractedDataArray}
          cellConfig={filteredCellConfig}
          setMouseEnteredId={setMouseEnteredId}
          setMouseEntered={setMouseEntered}
          handleViewOpen={handleViewOpen}
          totalPages={totalPages}
          handlePagination={handlePagination}
          isLoading={isLoading}
        />
      </Box>
      <AddNewUserModal
        setOpenAddNewUserModal={setOpenAddNewUserModal}
        openAddNewUserModal={openAddNewUserModal}
      />

      <DpViewModal
        DpViewModalOpen={DpViewModalOpen}
        handleCloseDpViewModal={handleCloseDpViewModal}
        DpUrl={DpUrl}
      />

      {openView && (
        <ViewUserDetails
          handleClose={() => setOpenView(false)}
          open={openView}
          userId={mouseEnteredId}
          handleOpenDpViewModal={handleOpenDpViewModal}
        />
      )}

      {deleteModalOpen && (
        <AlertModal
          message={"Are you sure you want to delete user?"}
          closeModal={closeModalDelete}
          handler={handleDeleteUser}
          open={deleteModalOpen}
        />
      )}
    </div>
  );
};

export default User;
