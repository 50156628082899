import React from 'react'

const OTPScreen = () => {
  return (
    <div 
    style={{
      width:"100vw",
      height:"100vh",
      background:
        "linear-gradient(90deg, hsla(203, 69%, 84%, 0.5) 0%, hsla(18, 76%, 85%, 0.5) 100%)",
    }}
    className='d-flex justify-content-center align-items-center'>
      <div className='col-4 bg-white rounded-3 d-flex flex-column align-items-center p-4 cad-shadow'>
        <h4 className='cad-fw-800 text-dark'>OTP Screen</h4>
        <p className='text-center'>Please enter the security code sent to your registered email</p>

        <div className='d-flex flex-column gap-4 align-items-center w-100'>
        <h6>Verification code</h6>
        <input type='number' className='form-control p-2 text-center'></input>
        <button className='btn button-contained p-2'>Verify</button>
        </div>
      </div>
    </div>
  )
}

export default OTPScreen