import api from "./api.service";

export const getTags = async () => {
    try {
        return await api.get(`/tag/agent/`);
      } catch (error) {
        return error.response;
      }
}

export const createTags = async (data) => {
  try {
    const user = await api.post(`/tag/agent/`, data);
    return user;
  } catch (error) {
    return error.response;
  }
};

export const updateTag = async (id, data) => {
  try {
    const response = await api.put(`/tag/agent/${id}`, data);
    return response.data; 
  } catch (error) {
    return error.response;
  }
};

export const deleteTag = async (id) => {
  try {
      const response = await api.delete(`/tag/agent/delete/${id}`);
      return response.data; // Assuming the deleted data is returned from the server
  } catch (error) {
      return error.response;
  }
};