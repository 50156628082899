import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BusinessStorage, authMe, authMeService } from "../../service/auth.service";

export const fetchAuthMe = createAsyncThunk("fetchAuthMe", async () => {
  try {
    const res = await authMeService(); // Replace with your authentication service function
    // // console.log(res.data);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching authentication data: ${err.message}`);
  }
});

export const fetchStorage = createAsyncThunk("fetchStorage", async () => {
  try {
    const { data } = await BusinessStorage()
    const { total_size, document_count } = data[0]
    console.log(data);
    return data
  } catch (error) {
    throw error
  }
})

const authMeSlice = createSlice({
  name: "auth-me",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    authInfo: null,
    isAuthenticate: false,
    group: null,
    role: null,
    resources: [],
    actions: [],
    accessToken: null,
    refreshToken: null,
    storage: 0,
  },
  reducers: {
    // You can add additional reducers if needed
    logoutAgent: (state, action) => {
      state.isAuthenticate = false;
      // console.log(state);
    },
    useAuthenticate: (state, action) => {
      // state.isAuthenticate;
      // console.log(state);
    },
    fetchAuthLogin: (state, action) => {
      state.isAuthenticate = true;
      state.role = action.payload.role[0].title;
      state.group = action.payload.group[0].title;
      state.actions = action.payload.role[0].actions;
      state.resources = action.payload.resources;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    fetcIisAuthenticateFailed: (state) => {
      state.isAuthenticate = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthMe.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchAuthMe.fulfilled, (state, action) => {
        state.authInfo = action.payload?.data || null;
        state.isLoading = false;
      })
      .addCase(fetchAuthMe.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchStorage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchStorage.fulfilled, (state, action) => {
        state.storage = action.payload?.data || null;
        state.isLoading = false;
      })
      .addCase(fetchStorage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });

  },
});

export default authMeSlice.reducer;
export const {
  useAuthenticate,
  logoutAgent,
  fetchAuthLogin,
  fetcIisAuthenticateFailed,
} = authMeSlice.actions;
