// Function to download CSV data
export const  downloadCSV = (csvData, filename) => {
    // Create a Blob object
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // Trigger a click event on the link
    link.click();

    // Clean up
    window.URL.revokeObjectURL(link.href);
}

