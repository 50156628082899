import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa6";
import SecondaryButton from "../../Components/SecondaryButton";
import { Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchForms } from "../../Redux/Slice/getFormsSlice";
import SearchInput from "../../Components/SearchInput/SearchInput";
import {
  fetchGlobalForms,
} from "../../Redux/Slice/getGlobalForms";
import GlobalFormViewModal from "../MyForms/GlobalFormViewModal";
import Refresher from "../../Components/Refresher";
import { AuthMe } from "../../Redux/Slice/auth/auth.slice";

const Form = () => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const [openFormView, setOpenFormView] = useState(false);
  const [mouseEnterId, setMouseEnterId] = useState("");


  // console.log(themeColorsObject);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchGlobalForms());
    dispatch(AuthMe());
  }, [dispatch]);

  const { forms, isLoading } = useSelector((state) => state?.getGlobalForms);
  // console.log(forms, isLoading);

  const [searchText, setSearchText] = useState("");

  const handleOpenFormView = (id) => {
    setMouseEnterId(id);
    setOpenFormView(true);
  };
  const handleCloseFormView = () => setOpenFormView(false);

  const filtered = forms.filter((template) =>
    template.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
  };

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.titleText,
      }}
      className="form mt-1 cad-shadow overflow-auto rounded-3 mb-4"
    >
      {/* =======[ Top Section ]====== */}
      <div className="d-flex align-items-center justify-content-between  p-3 col-md-12">
        <div className="col-3">
          <h2 className="cad-fw-500 cad-text-xlarge ps-0 p-2">
            Pick your template
          </h2>

          <SearchInput
            searchValue={searchText}
            searchFucntion={handleSearchInputChange}
          />
        </div>
        <div className="d-flex justify-content-end col-3 gap-2">
          <Refresher
            handleRefresh={() => dispatch(fetchGlobalForms())}
            isLoading={isLoading}
          />
          <Link to="/my-forms">
            <SecondaryButton
              variant="contained"
              title="My form"
              padding="0.4rem 0.6rem"
              icon={<FaWpforms size={13} />}
              fontSize="14px"
              width="250px"
            />
          </Link>
        </div>
      </div>

      {/* =========[ Med section ]======= */}
      <div className="w-100 cad-text-large cad-fw-600 p-3">Template</div>

      <div className="d-flex gap-4 flex-wrap p-3 w-100">
        <Link
          to="/template-forms/new-form/"
          style={{
            backgroundColor: "#DFFFEF",
            height: "320px",
            width: "23%",
          }}
          className="col-3  rounded-3  create-form-card p-5 d-flex justify-content-center align-items-center flex-column"
        >
          <AiOutlinePlus color="#4CCD8D" size={40} />
          <h4 style={{ color: "#4CCD8D" }} className="cad-text-large">
            Create Form
          </h4>
        </Link>

        {filtered?.map(({ id, title }) => (
          <div
            key={id}
            style={{
              height: "320px",
              width: "23%",
            }}
            className=" create-form-card d-flex justify-content-between align-items-between flex-column"
          >
            <div className="form-hover h-75 form-png rounded-3 w-100">
              <span
                onClick={() => handleOpenFormView(id)}
                className="icon-center rouded-3 d-flex gap-2 ps-3 pe-3 p-2 cad-fw-700 text-white"
              >
                <RemoveRedEyeIcon style={{ color: "#ffffff" }} />
                Preview
              </span>
            </div>

            <div className="d-flex flex-column gap-1">
              <span className="cad-text-medium cad-fw-600">{title}</span>
              <Link to={`/template-forms/${id}`}>
                <Button variant="outlined" className="button-outlined ">
                  Use Template
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <GlobalFormViewModal
        id={mouseEnterId}
        open={openFormView}
        handleOpen={handleOpenFormView}
        handleClose={handleCloseFormView}
      />
    </div>
  );
};

export default Form;
