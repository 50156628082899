import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import dummyLogo from "../Images/dummyLogo.png"
import dummyLogoFemale from "../Images/dummyLogoFemale.png"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  //   p: 1,
    borderRadius: 8,
};
const DpViewModal = ({ DpViewModalOpen, handleCloseDpViewModal, DpUrl }) => {

  let picUrl = DpUrl === null ? "" : process.env.REACT_APP_DOWLOAD_URL+"/"+DpUrl


  // // console.log(DpUrl);

  return (
    <div>
      <Modal
        open={DpViewModalOpen}
        onClose={handleCloseDpViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="cad-modal-blur"
      >
        <Box sx={style}>
          <img
            style={{ borderRadius: 8, }}
            src={picUrl}
            alt="DP"
            srcset=""
            width={500}
            height={500}
          />
        </Box>
      </Modal>
    </div>
  );
};
export default DpViewModal;
