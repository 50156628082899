import React, { Fragment, forwardRef, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import EmailField from "./Components/EmailField";
import { useLocation, useParams } from "react-router-dom";
import VerifyMail from "./Components/VerifyMail";
import VerifiedMail from "./Components/VerifiedMail";
import CompanyDetails from "./Components/CompanyDetails";
import StepperComp from "./Components/StepperComp";
import { jwtDecode } from "jwt-decode";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Registration = () => {
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [emailEntered, setEmailEntered] = useState("");
  const [companyDetails, setCompanyDetails] = useState(false);
  const [stepper, setStepper] = useState(false);
  const [registrationData, setRegistrationData] = useState({});
  const [regData, setRegData] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [token, setToken] = useState(searchParams.get("token"));
  const [email, setEmail] = useState(searchParams.get("email_address"));
  let { id } = useParams();

  let decoded;
  useEffect(() => {
    if (token) {
      decoded = jwtDecode(token);
      console.log(decoded);
    }
  }, [token]);

  return (
    <div
      style={{
        background:
          "linear-gradient(90deg, hsla(203, 69%, 84%, 0.5) 0%, hsla(18, 76%, 85%, 0.5) 100%)",
        minHeight: "100vh",
      }}
      className=" p-3 container-fluid  d-flex justify-content-center align-items-center"
    >
      <div className="container  d-flex  align-items-center w-100  h-auto">
        <div
          className={` ${
            (email && token && companyDetails === false) ||
            companyDetails === true
              ? "flex-column"
              : ""
          } d-flex w-100  justify-content-center  align-items-center  h-75 p-0`}
        >
          <div
            className={` ${
              (email && token && companyDetails === false) ||
              companyDetails === true
                ? "d-none"
                : "d-flex"
            }  col-left  pe-3 d-flex flex-column  col-7 h-50 d-flex align-items-start justify-content-center`}
          >
            {/* <span style={{ fontSize: "50px", color:"#4CCD8D" }} className="cad-fw-800">
              CAD <span className="cad-fw-500 text-primary">IT Solution</span>
            </span> */}

            <h1
              style={{ fontSize: "50px", color: "#4CCD8D" }}
              className="cad-fw-800"
            >
              Cloudify<span className="cad-fw-500 text-primary">Docs</span>
            </h1>

            <h3 style={{ color: "#3c3c3c" }}>Get Started</h3>
            <p style={{ color: "#3c3c3c" }} className=" fs-4 cad-fw-600">
              "Welcome to <b>Cloudify</b>
            </p>
            <p
              style={{ color: "#534D73" }}
              className="cad-text-large cad-fw-600"
            >
              Get started, it is free for 14 days. No credit card required.
            </p>
          </div>
          <h1
            style={{ fontSize: "30px", color: "#4CCD8D" }}
            className={` ${
              (email && token && companyDetails === false) ||
              companyDetails === true
                ? "d-block"
                : "d-none"
            } cad-fw-800 mb-3`}
          >
            Cloudify<span className="cad-fw-500 text-primary">Docs</span>
          </h1>
          <div
            style={{
              height:
                (email && token && companyDetails === false) ||
                companyDetails === true
                  ? "auto"
                  : "auto",
            }}
            className={`${
              (email && token && companyDetails === false) ||
              companyDetails === true
                ? "col-5"
                : "col-5"
            } col-right  p-3`}
          >
            {verifyEmail ? (
              <VerifyMail emailEntered={emailEntered} />
            ) : email && token && companyDetails === false ? (
              <VerifiedMail
                setRegistrationData={setRegistrationData}
                setCompanyDetails={setCompanyDetails}
                emailEntered={email}
              />
            ) : email &&
              token &&
              companyDetails === true &&
              stepper === false ? (
              <CompanyDetails
                setRegData={setRegData}
                setStepper={setStepper}
                setRegistrationData={setRegistrationData}
                registrationData={registrationData}
              />
            ) : email &&
              token &&
              companyDetails === true &&
              stepper === true ? (
              <StepperComp
                setCompanyDetails={setCompanyDetails}
                setToken={setToken}
                setEmail={setEmail}
                email={email}
                token={token}
                setRegData={setRegData}
                regData={regData}
                googleLogin={false}
              />
            ) : (
              <EmailField
                id={id}
                setEmailEntered={setEmailEntered}
                setVerifyEmail={setVerifyEmail}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
