import React, { useEffect, useRef, useState } from "react";
import "./GenerateNewTickets.css";
import { Chip, CircularProgress, Modal, Skeleton } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { createSupportChat } from "../../service/support.service";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useDispatch } from "react-redux";
import { fetchSupportChats } from "../../Redux/Slice/supportChatSlice";
import { useSelector } from "react-redux";
import { fetchSupportTicket } from "../../Redux/Slice/supportSlice";
import { FilePresentSharp } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { formatDate } from "../DateFormatter";

const GenerateNewTickets = () => {
  const chatContainerRef = useRef(null);
  const [text, setText] = useState("");
  const [sendingText, setSendingText] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDbFile, setSelectedDbFile] = useState(null);
  const [imagePrevopen, setImagePrevOpen] = useState(false);
  const handleImagePrevOpen = () => setImagePrevOpen(true);
  const handleImagePrevClose = () => setImagePrevOpen(false);
  const [imagePrevArray, setImagePrevArray] = useState([]);

  const supportChatData = useSelector((state) => state?.supportChat.chats);
  const isLoadingChat = useSelector((state) => state.supportChat.isLoading);
  const supportTicketsData = useSelector((state) => state.support?.rows);
  const isTicketDataLoading = useSelector((state) => state.support?.isLoading);
  const {theme, themeColorsObject} = useSelector((state) => state.theme);


  const chatWindowRef = useRef(null);
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  // // console.log("===================>",supportTicketsData);

  const sortedChatMessages = [...supportChatData].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  });

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(fetchSupportChats(id));
    dispatch(fetchSupportTicket());
    scrollToBottom();
  }, [dispatch, id]); // Only run this effect when generateTicketTable or id changes

  const fileURL = (path) => {
    return new URL(path, process.env.REACT_APP_DOWLOAD_URL).toString();
  };

  const handleSend = async (attachedFile) => {
    setSendingText(true)
    // Prepare the form data for sending files
    const formData = new FormData();
    formData.append("message", text);
    // Append the file to the form data if there's a selected file
    if (attachedFile) {
      formData.append("attachment", attachedFile);
      for (var i = 0; i < attachedFile.length; i++) {
        formData.append("attachment", attachedFile[i]);
      }
    }

    try {
      const response = await createSupportChat(formData, id);
      setText("");
    } catch (error) {
      console.error("API request error", error);
    }
    setSendingText(false)
    setSelectedFile(null);
    setSelectedDbFile(null)
    dispatch(fetchSupportChats(id));
  };

  const filteredArray = supportTicketsData.filter((item) => item?.id === id);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if(text === "" && selectedDbFile === null) return
      handleSend(selectedDbFile);
      
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setSelectedDbFile(e.target.files);

    const fileDataArray = [];
    // Loop through the selected files and read their content using FileReader
    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // When the file reading is done, store the result in fileDataArray
        fileDataArray.push({ name: file.name, dataURL: reader.result });
        // Check if all files are processed
        if (fileDataArray.length === selectedFiles.length) {
          // When all files are processed, you can use fileDataArray as needed
          setSelectedFile(fileDataArray);
          // Update state or perform further actions with fileDataArray
          // setSelectedFiles(fileDataArray);
        }
      };
      reader.readAsDataURL(file); // Read file as data URL
    });
  };

  const convertUTCToLocalTime = (utcTimestamp) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const localTime = new Date(utcTimestamp).toLocaleString(undefined, options);
    return localTime;
  };

  const handleImageClick = (id) => {
    handleImagePrevOpen();

    const filteredObject = supportChatData.filter((item) => item.id === id);
    setImagePrevArray(filteredObject[0].attachments);
    // // console.log(imagePrevArray[0]?.path);
  };

  const handleDownload = async (imageUrl, imgName) => {
    try {
      const response = await fetch(fileURL(imageUrl));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob({ type: "image/jpeg" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = imgName; // Desired name for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handlePrint = (imageUrl) => {
    const printWindow = window.open("", "", "width=600,height=400");
    const img = new Image();
    img.onload = function () {
      printWindow.document.write(
        '<img src="' + fileURL(imageUrl) + '" style="width:100%;"/>'
      );
      printWindow.print();
      printWindow.document.close();
    };

    img.onerror = function () {
      console.error("Error loading the image for printing.");
    };

    img.src = fileURL(imageUrl);
  };

  return (
    <div 
    style={{
      height: "87vh",
      backgroundColor: themeColorsObject.megaCardBackgroundColor,
      color: themeColorsObject.textColor,
      // transition:"0.3s"
    }}
    className="w-100 mt-1 cad-shadow rounded-3  mb-4 d-flex flex-column ">
      <Modal
        className="cad-modal-blur"
        open={imagePrevopen}
        onClose={handleImagePrevClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="img-prev-modal"
          onClick={handleImagePrevClose}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // overflowX:"scroll",
            outline: "none",
          }}
        >
          <div className="img-prev-cnt h-100 d-flex overflow-auto">
            {imagePrevArray.map((image) => (
              <div key={image.id} className="image-prev-control-panel ">
                <img
                  style={{ maxHeight: "400px" }}
                  className=" rounded-3 m-2"
                  src={fileURL(image?.path)}
                  alt=""
                />

                <div className="d-flex gap-4 align-items-center justify-content-between ps-3">
                  {/* <ArrowBackOutlinedIcon className="text-white " /> */}

                  <div className="d-flex gap-3 align-items-center">
                    <CollectionsOutlinedIcon className="text-danger cad-text-large" />
                    <span className="text-white cad-text-medium cad-fw-500 d-flex align-items-center">
                      {image?.originalname}
                    </span>
                  </div>

                  <div className="d-flex gap-3  align-items-center text-white fw-bold">
                    <span
                      onClick={() => handlePrint(image?.path)}
                      className="cad-text-small image-prev-control-panel-icon p-2"
                    >
                      <LocalPrintshopOutlinedIcon />
                    </span>

                    <span
                      onClick={() =>
                        handleDownload(image?.path, image?.originalname)
                      }
                      className="image-prev-control-panel-icon p-2 cad-text-small"
                    >
                      <VerticalAlignBottomOutlinedIcon />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>


      <div className="rounded-3 cad-shadow position-relative  d-flex justify-content-center p-3 flex-lg-nowrap gap-0 h-100">

      <Link to={"/support"}  style={{
            position:"absolute",
            right: 10,
            top:10
          }} className="action-hover rounded-3 pointer text-primary p-1">
            <CloseIcon />
            </Link >

        <div className="position-relative col-lg-8 col-12">
          <div  className="w-100 h-100  p-3 pt-1 overflow-auto">
            <div
              className="generate-tickets-chat-screen  pb-5  w-100 d-flex flex-column "
            >
              {isLoadingChat ? (
                <div className=" d-flex h-100  align-items-center justify-content-center">
                  <CircularProgress className=" cad-fw-800" />
                </div>
              ) : (
                sortedChatMessages?.map((chat) => {
                  return (
                    <div
                    
      ref={chatWindowRef}
                      style={{
                        paddingBottom:
                          chat.attachments.length === 0 ? "1.5rem" : "0.5rem",
                      }}
                      className={
                        chat?.type === "query"
                          ? "sending-msg-bubble mb-3"
                          : "receiving-msg-bubble mb-3"
                      }
                      key={chat.id}
                    >
                      {chat.attachments.length === 0 ? (
                        <div>
                          <span className="msg">{chat?.message}</span>
                          <span className="msg-time">
                            {convertUTCToLocalTime(chat.createdAt)}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex gap-2 flex-wrap mb-2">
                            {chat.attachments.map((eachFile) => {
                              let imgSrc = fileURL(eachFile.path)
                              return(
                              
                              <img
                                onClick={() => handleImageClick(chat.id)}
                                key={eachFile.id}
                                className="rounded-3 pointer"
                                src={imgSrc}
                                width={120}
                                height={120}
                                alt="file-img"
                              />
                            )})}
                          </div>

                          <span className="msg">{chat?.message}</span>
                          <span className="msg-time">
                            {convertUTCToLocalTime(chat.createdAt)}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              )}
            </div>

            <div className="file-attachment-cnt  d-flex gap-3 align-items-center">
              {selectedFile
                ? selectedFile.map((eachFile, i) => (
                    <img
                      key={i}
                      className="file-attachment"
                      src={eachFile?.dataURL}
                      width={60}
                      alt=""
                    />
                  ))
                : ""}
              {selectedFile ? (
                <span
                  style={{ backgroundColor: "#ecf0f1" }}
                  className=" p-3 rounded-2"
                >
                  <label htmlFor="file-upload" className=" cad-fw-700 pointer">
                    <AddIcon className="color-primary" />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple
                  />
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="chat-input   p-3 pt-2">
            <div className="rounded-5 border w-100">
              <input
                type="text"
                style={{
                  width:"85%"
                }}
                className={`form-control cad-text-medium   ${themeColorsObject.themed_input_class}`}
                placeholder="Message..."
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              
              </div>

             
              <label htmlFor="file-upload" className="attchBtn  cad-fw-700">
                <InsertLinkOutlinedIcon />
              </label>
              <input
                onPaste={() =>console.log("paste")}
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />
 
              <button
              disabled={sendingText ? true : false}
                onClick={() => (text === "" && selectedDbFile === null ? "" : handleSend(selectedDbFile))}
                className="sentBtn border-none cad-fw-700 btn btn-primary bg-warning  rounded-5 text-white"
              >
                send
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-4 p-3 border-start col-12">
          <table 
          className=" table-borderless">
            <thead>
              <tr>
                <th className="col-4 pb-3 cad-text-small">Ticket Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pb-3 cad-text-small">Issue / Query Type</td>
                <td className="pb-3 cad-text-small">- Claim</td>
              </tr>
              <tr>
                <td className="pb-3 cad-text-small">Subject</td>
                {isTicketDataLoading ? (
                  <td className="pb-3 cad-text-small">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1.5rem", width: 100 }}
                    />
                  </td>
                ) : (
                  <td className="pb-3 cad-text-small">
                    {filteredArray && filteredArray.length > 0
                      ? filteredArray[0].title
                      : ""}
                  </td>
                )}
              </tr>
              <tr>
                <td className="pb-3 cad-text-small">Created At</td>
                {isTicketDataLoading ? (
                  <td className="pb-3 cad-text-small">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1.5rem", width: 100 }}
                    />
                  </td>
                ) : (
                  <td className="pb-3 cad-text-small">
                    {filteredArray && filteredArray.length > 0
                      ? formatDate(filteredArray[0].createdAt)
                      : ""}
                  </td>
                )}
              </tr>

              <tr>
                <td className="pb-3 cad-text-small">Ticket Id</td>
                {isTicketDataLoading ? (
                  <td className="pb-1 cad-text-small">
                    <Skeleton variant="rounded" width={200} height={60} />
                  </td>
                ) : (
                  <td className="pb-3 cad-text-small">
                    {filteredArray && filteredArray.length > 0
                      ? filteredArray[0].id
                      : ""}
                  </td>
                )}
              </tr>
              <tr>
                <td className="pb-3 cad-text-small">Status</td>
                {isTicketDataLoading ? (
                  <td className="pb-1 cad-text-small">
                    <Skeleton variant="rounded" width={100} height={30} />
                  </td>
                ) : (
                  <td className="pb-3 cad-text-small">
                    <Chip
                      style={{ fontWeight: "500" }}
                      size="small"
                      className={`${
                        filteredArray[0].status.title === "On Hold"
                          ? "chip-onhold"
                          : filteredArray[0].status.title === "Open"
                          ? "chip-high"
                          : filteredArray[0].status.title === "In Progress"
                          ? "chip-med"
                          : "chip-low"
                      }`}
                      label={
                        filteredArray[0].status.title === "Open"
                          ? "Open"
                          : filteredArray[0].status.title === "In Progress"
                          ? "In Progress"
                          : filteredArray[0].status.title === "On Hold"
                          ? "On Hold"
                          : "Completed"
                      }
                    />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GenerateNewTickets;
