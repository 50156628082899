import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};



export const comapanyBranding = async (data) => {
    try {
        return await api.put(`/agent/business/`,data, formDataConfig)
    } catch (error) {
        throw error
    }
}
