import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userDetails } from "../../service/user.service";



export const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
    try {
        const res = await userDetails(id);
        // console.log(res.data, id);
        return res.data;
    } catch (err) {
        throw new Error(`Error fetching data: ${err.message}`);
    }
});

const getUserDetailsSlice = createSlice({
    name: "user-data",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        userInfo: [], // Initialize rows as an empty array
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetails.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.userInfo = action.payload?.data || 'hi';
                state.isLoading = false;
            })
            .addCase(getUserDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message; // Store the error message
            });
    },
})


export default getUserDetailsSlice.reducer;