import React from "react";
import { useSelector } from "react-redux";
import * as themeColors from "../../Utils/Theme/Colors";

const SearchInput = ({searchFucntion, searchValue}) => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  return (
    <input
      value={searchValue}
      onChange={searchFucntion}
      type="search"
      placeholder="Search..."
      // className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
      className={`form-control cad-text-medium border-primary w-100`}
      style={{
        backgroundColor: themeColorsObject.backgroundColor,
        color: themeColorsObject.textColor,
        "::placeholder": {
          color: themeColorsObject.placeholder, // Use the provided placeholder color or a default color
          fontStyle: "italic", // Customize other placeholder styles if needed
        },
        WebkitTextFillColor: themeColorsObject.placeholder, // For Safari compatibility
      }}
    />
  );
};

export default SearchInput;
