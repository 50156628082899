import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  FormControlLabel,
  Checkbox,
  Paper,
  Avatar,
  Button,
} from "@mui/material";
import "./User.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { createUser, resource } from "../../service/user.service";
import { userReporting } from "../../service/user.service";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Define the AddUser component
const AddNewUser = () => {
  const navigate = useNavigate();

  const [userResource, setUserResource] = useState([]);
  const [reporting, setReporting] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchPermissionData() {
      try {
        const response = await resource();
        // // console.log(response.data)
        if (response.status === 204) {
          // Handle the case when the API returns a 204 status code (no content).
          // // console.log('No data available');
        } else if (response.status === 200) {
          // If the API call is successful (status code 200), set the data
          setUserResource(response.data.data);
          // // console.log(response.data.data);
        } else {
          // Handle other cases, e.g., error or non-200 response
          // console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        // console.error('API request error', error);
      }
    }

    async function fetchReportingData() {
      try {
        const response = await userReporting();
        // // console.log(response.data)
        if (response.status === 204) {
          // Handle the case when the API returns a 204 status code (no content).
          // // console.log('No data available');
        } else if (response.status === 200) {
          // If the API call is successful (status code 200), set the data
          setReporting(response.data.data);
        } else {
          // Handle other cases, e.g., error or non-200 response
          // console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        // console.error('API request error', error);
      }
    }

    // Fetch data for both permissions and reporting options
    Promise.all([fetchPermissionData(), fetchReportingData()])
      .then(() => {
        setLoading(true);
      })
      .catch((error) => {
        // console.error('Error in fetching data', error);
        setLoading(false);
      });
  }, []);

  const contentData = [
    {
      title: "Personal Information",
      fields: [
        {
          label: "First Name",
          type: "text",
          required: true,
          name: "first_name",
          error_msg: true,
        },
        {
          label: "Last Name",
          type: "text",
          name: "last_name",
          error_msg: true,
        },
        {
          label: "Phone Number",
          type: "text",
          name: "phone_number",
          error_msg: true,
        },
        {
          label: "Email Address",
          type: "email",
          required: true,
          name: "email_address",
          error_msg: true,
        },
        {
          label: "Designation",
          type: "text",
          name: "designation",
          error_msg: true,
        },
      ],
    },
    {
      title: "Other Information",
      fields: [
        {
          label: "Reporting Manager",
          name: "manager_id",
          type: "select",
          options: reporting.map((ele) => ({
            label: ele.first_name,
            value: ele.id,
          })),
        },
        {
          label: "Permission",
          type: "checkboxGroup",
          fields: userResource.map((item) => ({
            label: item.title,
            value: item.id,
            type: "checkbox",
            name: "resources",
          })),
          error_msg: true,
        },
      ],
    },
  ];

  // useEffect(() => {
  //     // console.log(contentData);
  // }, [contentData])

  const [previewImage, setPreviewImage] = useState("");

  const userSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/^\S*$/, "First Name cannot contain spaces"),

    email_address: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required").matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 
        "Email address is required"
      ),
    last_name: Yup.string()
      .matches(/^\S*$/, "Last Name cannot contain spaces")
      .notRequired(), // Mark the field as optional
    phone_number: Yup.string()
      .notRequired() // Mark the field as optional
      .matches(/^[0-9]*$/, "Phone Number must contain only numbers")
      .test(
        "is-min-10-characters",
        "Phone Number must be at least 10 characters",
        (value) => {
          if (!value) {
            return true; // Pass the validation if the field is empty
          }
          return value.length >= 10;
        }
      ),

    designation: Yup.string()
      .matches(/^[^\d]*$/, "Designation cannot contain numbers")
      .min(4,"Designation must be at least 4 characters")
      .required(), // Allow null values (non-required field)
    profile_photo: Yup.mixed().test(
      "fileType",
      "Invalid file type",
      (value) => {
        if (!value || value.length === 0) {
          setPreviewImage(""); // Clear the preview when no file is selected
          return true; // Allow empty value (no file selected)
        }

        if (value[0].type.startsWith("image/")) {
          // Set the previewImage state with the selected image
          setPreviewImage(URL.createObjectURL(value[0]));
          return true; // Valid image file
        } else {
          setPreviewImage(""); // Clear the preview when an invalid file is selected
          return false; // Invalid file type
        }
      }
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
    // getValues,
    // getFieldState,
  } = useForm({ mode: "all", resolver: yupResolver(userSchema) });

  // // console.log(errors);

  const onSubmit = async (values) => {
    // reset()
    // return // console.log(formData);

    // Create a FormData object
    const formDataToSend = new FormData();

    // Append the data from each field
    formDataToSend.append("first_name", values.first_name);
    formDataToSend.append("email_address", values.email_address);
    // formDataToSend.append('resources', formData.resources);
    values.last_name && formDataToSend.append("last_name", values.last_name);
    values.phone_number &&
      formDataToSend.append("phone_number", values.phone_number);
    values.manager_id && formDataToSend.append("manager_id", values.manager_id);
    values.profile_photo[0] &&
      formDataToSend.append("profile_photo", values.profile_photo[0]);
    values.designation &&
      formDataToSend.append("designation", values.designation);

    // Append the selected resources (checkbox values)
    if (values.resources.length > 0) {
      values.resources.forEach((resource) => {
        formDataToSend.append("resources[]", resource);
      });
    }

    try {
      const response = await createUser(formDataToSend);
      // console.log(response);
      navigate("/users");
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "#f1f2f6",
      }}
      className=" h-100 w-100 p-3 d-flex overflow-hidden flex-column justify-content-start align-items-start "
    >
      <Box
        className="border-bottom rounded-top-3"
        sx={{
          borderBottom: 0,
          padding: 0,
          backgroundColor: "#fff",
          width: "100%",
          overflow: "scroll",
        }}
      >
        {loading ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-3">
              <Link
                to="/users"
                className="mb-4 d-flex align-items-center gap-1 text-decoration-none"
              >
                <AiOutlineLeft className="pointer" size={18} />
                <label className="pointer">back to users</label>
              </Link>
              <h4 className="cad-text-dark cad-fw-600">Add User</h4>
              <div className="d-flex justify-content-start align-items-center gap-2">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <Avatar
                    className="m-1"
                    style={{
                      width: "100px",
                      height: "100px",
                      border: "4px solid #E9E9E9",
                    }}
                    alt="Remy Sharp"
                    src={previewImage}
                  />

                  <input
                    type="file"
                    id="profile-image-input"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    {...register("profile_photo")}
                  />

                  <label
                    style={{
                      position: "absolute",
                      bottom: "0.9rem",
                      left: "4.9rem",
                      height: "30px",
                      width: "30px",
                      backgroundColor: "#E9E9E9",
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    htmlFor="profile-image-input"
                  >
                    <CameraAltTwoToneIcon
                      sx={{
                        position: "absolute",
                        bottom: "0rem",
                        right: "0rem",
                        height: "30px",
                        width: "30px",
                        backgroundColor: "#E9E9E9",
                        padding: "4px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </div>
                <label className="cad-text-medium mb-1 cad-text-light-grey cad-fw-500">
                  Upload Profile Picture
                </label>
              </div>
            </div>
            {contentData.map((section, index) => (
              <div key={index}>
                <hr className="m-0 hr-border" />
                <div className="p-3">
                  <h5 className="cad-text-grey mb-3 cad-text-large">
                    {section.title}
                  </h5>
                  <div className="row">
                    {section.fields.map((field, fieldIndex) => (
                      <div
                        className={
                          field.type === "checkboxGroup"
                            ? "col-4 mb-2"
                            : "col-4 mb-2"
                        }
                        key={fieldIndex}
                      >
                        <label
                          className={`cad-text-medium mb-1 cad-text-light-grey cad-fw-500 ${
                            field.required ? "text-danger" : ""
                          }`}
                        >
                          {field.label}
                          {field.required && (
                            <span className="text-danger"> *</span>
                          )}
                        </label>
                        {field.type === "select" ? (
                          <select
                            defaultValue={"none"}
                            className={`form-control w-100  ${
                              field.required ? "required" : ""
                            }`}
                            {...register(field.name)}
                          >
                            <option value="">none</option>
                            {field.options.map((option, optionIndex) => (
                              <option key={optionIndex} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        ) : field.type === "checkboxGroup" ? (
                          <div id="permission-block">
                            {field.fields.map(
                              (checkboxField, checkboxIndex) => (
                                <FormControlLabel
                                  key={checkboxIndex}
                                  value={checkboxField.value}
                                  control={
                                    <Checkbox
                                      sx={{
                                        color: "#4CCD8D",
                                        "&.Mui-checked": {
                                          color: "#4CCD8D",
                                        },
                                        "& .MuiSvgIcon-root": { fontSize: 18 },
                                      }}
                                      {...register(checkboxField.name)}
                                    />
                                  }
                                  // {...register(checkboxField.value)}
                                  label={checkboxField.label}
                                  labelPlacement="end"
                                  sx={{ fontSize: 10 }}
                                />
                              )
                            )}
                          </div>
                        ) : (
                          <input
                            className={`form-control w-100 ${
                              field.required ? "required" : ""
                            }`}
                            type={field.type}
                            {...register(field.name)}
                            // {...(field.error_msg ? register(field.name) : {})}
                            // onChange={handleInputChange}
                          />
                        )}
                        {field.error_msg && (
                          <div
                            htmlFor={field.name}
                            className="form-label text-primary form-text text-danger"
                          >
                            {errors[field.name]?.message}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-center py-3 m-auto w-25">
              <Button className="button-contained" type="submit">
                Add
              </Button>
            </div>
          </form>
        ) : (
          <p>Loading...</p>
        )}
      </Box>
    </Paper>
  );
};

export default AddNewUser; // Export the AddUser component
