import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import {
  CircularProgress,
  Switch,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  updateTask,
} from "../../service/task.service";
import { fetchTaskData, fetchTaskDataByID } from "../../Redux/Slice/taskSlice";
import { fetchKanbanData } from "../../Redux/Slice/kanbanSlice";
import { fetchSharedDocs } from "../../Redux/Slice/getSharedDocs";
import MainModal from "../../Components/MainModal";
import MuiSelector from "../../Components/MuiSelector";
import { notify } from "../../App";
import AlertModal from "../../Components/AlertModal";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const formatDueDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const EditModal = ({
  open,
  handleOpen,
  handleClose,
  id,
  themeColorsObject,
  setOpenShareDocumentModal,
  setOperationTaskId,
  setOpenReciveDocumentModal,
}) => {
  const [switchValue, setSwitchValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const priorityData = useSelector((state) => state?.priority);
  const { statusData } = useSelector((state) => state?.globalStatus);
  const { modes } = useSelector((state) => state?.globalModes);
  const [switchMode, setSwitchMode] = useState(modes[2]?.id);
  const { taskById, isTaskByIdLoading } = useSelector((state) => state?.tasks);
  const employee = useSelector((state) => state?.globalUser.globalUsers);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const empOption = employee?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name + " " + item.last_name,
      color: "#0d6efd",
    };
  });

  const empDefault = taskById?.employees?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name + " " + item.last_name,
      color: "#0d6efd",
    };
  });

  const { clients } = useSelector((state) => state?.globalClient);

  const clientsOption = clients?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name + " " + item.last_name,
      color: "#0d6efd",
    };
  });

  const clientsDefault = taskById?.clients?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name + " " + item.last_name,
      color: "#0d6efd",
    };
  });

  const tags = useSelector((state) => state.globalTags);

  const tagsOptions = tags?.rows.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.title,
      color: "#0d6efd",
    };
  });

  const tagsDefault = taskById?.tags?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.title,
      color: "#0d6efd",
    };
  });

  const [taskName, setTaskName] = useState(undefined);
  useEffect(() => {
    setTaskName(taskById?.title)
    return () => {
      setTaskName(undefined)
    }
  }, [taskById])

  const [selectedDueDate, setSelectedDueDate] = useState(null);
  const dueDatePickerRef = useRef(null);
  const [priority, setPriority] = useState(null);
  const [taskType, setTaskType] = useState(null);
  const [status, setStatus] = useState(null);

  const dispatch = useDispatch();

  const handleDueDateChange = () => {
    // Access the selected date using datePickerRef.current.value
    const selectedDateValue = dueDatePickerRef.current.value;
    setSelectedDueDate(selectedDateValue);
  };

  function getCurrentDate() {
    const today = new Date();
    let month = "" + (today.getMonth() + 1);
    let day = "" + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();

    const updatedTask = {
      title: taskName  ? taskById?.title : taskName,
      due_date: selectedDueDate === null ? taskById?.due_date : selectedDueDate,
      // description: "2023-11-30",
      // note: "2023-11-30",
      mode_xid: priority === null ? taskById?.mode?.id : switchMode,
      // permission_xid: "02215b8b-87e1-4275-a8a4-4aa8e786a14f",
      priority_xid: priority === null ? taskById?.priority?.id : priority,
      // reminder_set_xid: "02215b8b-87e1-4275-a8a4-4aa8e786a14f",
      status_xid: status === null ? taskById?.status?.id : status,
      // company_xid: "02215b8b-87e1-4275-a8a4-4aa8e786a14f",
      // client_xid: "02215b8b-87e1-4275-a8a4-4aa8e786a14f",
      clients: selectedClients.map((item) => item.id),
      employee: selectedEmp.map((item) => item.id),
      tags: selectedTags.map((item) => item.id),
      type_xid: taskType === null ? taskById?.type?.id : taskType,
    };

    try {
      await updateTask(taskById?.id, updatedTask);
      setIsLoading(false)
      notify("Task updated");
      dispatch(fetchKanbanData());
      setTaskName(undefined);
      dispatch(fetchTaskData());
      handleClose();
      setOpenAlert(false)
    } catch (error) {
      setIsLoading(false)
      notify(error?.data.error.message, "error");
      console.error("Error Updateing ta:", error?.data?.error?.message);
    }

  };

  const handleCheck = () => {
    dispatch(fetchTaskDataByID(id));
    dispatch(fetchSharedDocs(id));
    setOperationTaskId(id);
    // console.log(taskById?.type?.title);
    if (taskById?.type?.title === "Recevied Document") {
      setOpenReciveDocumentModal(true);
    } else if (taskById?.type?.title === "Shared Document") {
      setOpenShareDocumentModal(true);
    }
  };

  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked);
    setSwitchMode(
      event.target.checked
        ? modes[0].id // private mode
        : modes[2].id // public mode
    );
  };
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <MainModal
      open={open}
      handleClose={handleClose}
      title="Edit Task"
      width={"60%"}
    >

      <AlertModal
        message="Are you sure you want to update this data?"
        handler={handleSubmit}
        open={openAlert}
        closeModal={() => setOpenAlert(false)}
      />
      {isTaskByIdLoading ? (
        <CircularProgress className="m-auto" />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setOpenAlert(true)
          }}
          className="d-flex pb-3 justify-content-between flex-wrap position-relative"
        >
          <div className="d-flex col-12 justify-content-end align-items-center position-relative">
            <Switch
              size="small"
              checked={switchValue}
              onChange={handleSwitchChange}
            />
            <span
              style={{
                right: -24,
                backgroundColor: switchValue ? "#FD394A" : "#34A853",
                width: "fit-content",
                height: "100%",
                width: "90px",
              }}
              className="text-white rounded-2 ms-3 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
            >
              {switchValue ? "Private" : "Public"}
              <LocalOfferOutlinedIcon className="cad-text-large" />
            </span>
          </div>
          <div className="col-6 mb-4 d-flex pe-1 flex-column gap-2">
            <div className="cad-text-medium cad-fw-500 ">Task Name</div>
            <input
              type="text"
              className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              placeholder={taskById?.title}
            />
          </div>

          <div className="col-6 mb-4 d-flex ps-1 flex-column gap-2">
            <div className="cad-text-medium  cad-fw-500">Task type</div>
            <input
              type="text"
              className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
              value={taskById?.type?.title}
              readOnly={true}
            />
          </div>

          <div className="col-6 mb-4 d-flex pe-1 d-flex flex-column gap-2">
            <div className="cad-text-medium cad-fw-500 ">Due Date</div>

            <div
              className="input-group date col-3 pointer"
              data-provide="datepicker"
            >
              <input
                type="date"
                className={`form-control rounded-2 cad-text-medium ${themeColorsObject.themed_input_class}`}
                ref={dueDatePickerRef} // Add a ref to the date picker input element
                onChange={handleDueDateChange} // Call the handleDateChange function when the date changes
                min={getCurrentDate()}
                defaultValue={formatDueDate(taskById?.due_date)}
              />

              <div className="input-group-addon">
                <span className="glyphicon glyphicon-th"></span>
              </div>
            </div>
          </div>

          <div className="col-6 mb-4 d-flex ps-1 flex-column gap-2">
            <div className="cad-text-medium cad-fw-500 ">Assigned Date</div>

            <div className="cad-text-medium cad-fw-600 ">
              <input
                type="text"
                className={`form-control w-100 cad-text-medium ${themeColorsObject.themed_input_class}`}
                value={formatDueDate(taskById?.createdAt)}
                readOnly={true}
              />
            </div>
          </div>

          {/* ===[ Status ]=== */}

          <div className="col-6 mb-4 d-flex pe-1 flex-column gap-2">
            <div className="cad-text-medium  cad-fw-500 col-4">Priority </div>

            <select
              className={`form-select pointer cad-text-medium ${themeColorsObject.themed_input_class}`}
              aria-label="Default select example"
              defaultValue={taskById?.priority?.id}
              onChange={(e) => setPriority(e.target.value)}
            >
              {priorityData?.taskPriorities.map((item) => (
                <option
                  key={item.id}
                  className="text-success pointer"
                  value={item.id}
                >
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          <div className="col-6 mb-4 d-flex ps-1 flex-column gap-2 ">
            <div className="cad-text-medium  cad-fw-500">Status</div>

            <select
              className={`form-select pointer cad-text-medium ${themeColorsObject.themed_input_class}`}
              aria-label="Default select example"
              defaultValue={taskById?.status?.id}
              onChange={(e) => setStatus(e.target.value)}
            >
              {statusData?.map((item) => (
                <option
                  key={item.id}
                  className="text-success pointer"
                  value={item.id}
                >
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="col-12 mb-4">
          <p className="cad-text-medium cad-fw-600">Tags</p>

          <Selector
            defaultValue={tagsDefault}
            isMulti={true}
            options={tagsOptions}
            setSelected={setSelectedTags}
          />
        </div> */}
          <div className="col-12 mb-4">
            <p className="cad-text-medium cad-fw-600">Owner</p>

            <MuiSelector
              defaultValue={empDefault}
              options={empOption}
              isMulti={true}
              setSelected={setSelectedEmp}
            />
          </div>


          <div className="col-12 mb-4">
            <p className="cad-text-medium cad-fw-600">Tags</p>

            <MuiSelector
              defaultValue={tagsDefault}
              options={tagsOptions}
              isMulti={true}
              setSelected={setSelectedTags}
            />
          </div>


          <div className="col-12 mb-4">
            <p className="cad-text-medium cad-fw-600">Clients</p>

            <MuiSelector
              defaultValue={clientsDefault}
              options={clientsOption}
              isMulti={true}
              setSelected={setSelectedClients}
            />
          </div>






          {/* ==========[ Pending ]=========== */}


          {/* <div className="col-6 mb-4 d-flex flex-column gap-2 flex-wrap">
          <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
            Task Mode :
          </div>

          <span
            style={{
              // top:10,
              backgroundColor:
                taskById?.mode?.title === "public" ? "#34A853" : "#FD394A",
              width: "90px",
            }}
            className="text-white rounded-2 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
          >
            {taskById?.mode?.title}
            <LocalOfferOutlinedIcon className="cad-text-large" />
          </span>
        </div> */}

          <div style={{
            display: taskById?.type?.title === "Fill Form" ? "none" : "none"
          }} className="col-6 mb-4  flex-column gap-2 flex-wrap">
            <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
              {taskById?.type?.title === "Fill Form"
                ? "Form you shared :"
                : taskById?.type?.title === "Shared Document"
                  ? "Document you shared"
                  : "Requested Document"}
            </div>

            <Button
              variant="outlined"
              color="primary"
              style={{
                width: "200px",
                textTransform: "none",
                display: taskById?.type?.title === "Fill Form" ? "none" : "none"
              }}
              onClick={handleCheck}
              className=" p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
            >
              {taskById?.type?.title === "Fill Form"
                ? "Form check"
                : taskById?.type?.title === "Shared Document"
                  ? "Document check"
                  : "Document check"}
            </Button>
          </div>

          <div className="col-12 mt-4 d-flex justify-content-center">
            <div className="col-4">
              <Button
                type="submit"
                className="button-contained"
                variant="contained"
              >
                {isLoading ? (
                  <CircularProgress
                    style={{ height: 24, width: 24 }}
                    color="inherit"
                  />
                ) : "Update"}
              </Button>
            </div>
          </div>
        </form>
      )}

      {/* <div className="w-100">
        <TableData
        message={"message"}
        tableHeadRowCell={tableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={cellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        padding="p-2"
        // isLoading={isLoading}
      /></div> */}
    </MainModal>
  );
};

export default EditModal;
