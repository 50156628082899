import { Avatar, Badge, Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import UserProfile from "../GlobalTable/UserProfile";
import {
  createNewGroup,
  createOrGetOneOnOneChat,
  getContacts,
} from "../../service/chat.service";
import NoProfileLogo from "../../Images/profile_img.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckBox from "../CheckBox";
import { notify } from "../../App";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const GroupList = ({
  openNewChat,
  setContacts,
  contacts,
  themeColorsObject,
  setOpenGroupList,

  newGroupDetails,
  setNewGroupDetails,
  profileSrc,
  setProfileSrc,
}) => {
  const [mouseEntered, setMouseEntered] = useState(false);
  const { isLoading, clients } = useSelector((state) => state?.globalClient);
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);

  const { globalUsers } = useSelector((state) => state?.globalUser);

  const allContacts = [...clients, ...globalUsers];

  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = allContacts.filter(
    (item) =>
      item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredContacts = filteredData.filter(
    (item) => item.id !== authInfo?.id
  );

  // const [selectedContacts, setSelectedContacts] = useState([]);

  const handleCheckboxChange = (contactId) => {
    setNewGroupDetails((prevGroupDetails) => {
      if (prevGroupDetails.participants.includes(contactId)) {
        // If the contact is already selected, remove it
        return {
          ...prevGroupDetails,
          participants: prevGroupDetails.participants.filter(
            (id) => id !== contactId
          ),
        };
      } else {
        // If the contact is not selected, add it
        return {
          ...prevGroupDetails,
          participants: [...prevGroupDetails.participants, contactId],
        };
      }
    });
  };

  const handleDone = async () => {
    if (newGroupDetails?.participants?.length === 0) {
      return notify("Chats not found", "warn");
    }
    const formData = new FormData();

    formData.append("name", newGroupDetails.name);
    formData.append("profile", newGroupDetails.profile);
    formData.append("description", newGroupDetails.description);
    newGroupDetails?.participants?.map((item, index) => {
      formData.append(`participants[${index}]`, item);
    });

    // // console.log(formData);

    for (const pair of formData.entries()) {
      // console.log(pair[0] + ": " + pair[1]);
    }
    try {
      const response = await createNewGroup(formData);
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }

    setOpenGroupList(false);
    setNewGroupDetails({
      name: "",
      profile: "",
      description: "",
      id: "",
      chatId: "",
      receiverId: "",
      participants: [],
    });
    setProfileSrc("");
  };

  return (
    <Modal
      open={openNewChat}
      onClose={() => {
        setOpenGroupList(false);
        setNewGroupDetails({
          name: "",
          profile: "",
          description: "",
          id: "",
          chatId: "",
          receiverId: "",
          participants: [],
        });
        setProfileSrc("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      <div
        className="rounded-2 overflow-auto"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 480,
          height: "90%",
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="d-flex  p-4 pb-0 justify-content-center align-items-center flex-column">
          <Avatar
            className="m-1"
            style={{
              width: "100px",
              height: "100px",
              border: "4px solid #E9E9E9",
            }}
            alt={newGroupDetails.name}
            src={profileSrc}
          />
          <h4 className=" cad-fw-700">{newGroupDetails.name}</h4>
          <p className="cad-text-medium">{newGroupDetails.description}</p>
        </div>

        <div className="w-100 mb-3 d-flex justify-content-center">
          <input
            type="text"
            className={`form-control p-3 cad-fw-600 cad-text-medium rounded-0 text-center  ${themeColorsObject.themed_input_class}`}
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="w-100 d-flex flex-column  pb-3">
          {filteredContacts?.map(
            ({ id, first_name, last_name, profile_photo }, index) => (
              <span
                //   onClick={() => handleChatClick(id)}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow =
                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                  e.currentTarget.style.scale = 1;
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow = "none";
                }}
                style={{
                  cursor: "pointer",
                }}
                key={id}
                className=" w-100 d-flex gap-2  border-bottom align-items-center justify-content-between w-100"
              >
                <div className=" d-flex align-items-center gap-2 ps-3 p-2   ">
                  {/* <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                > */}
                  <UserProfile proPic={profile_photo} />
                  {/* </StyledBadge> */}
                  <span className="cad-text-medium ms-1 cad-fw-600">
                    {first_name} {last_name}
                  </span>
                </div>

                <span className="p-2 pe-3">
                  <CheckBox
                    isChecked={newGroupDetails?.participants?.includes(id)}
                    handleHandleChange={() => handleCheckboxChange(id)}
                  />
                </span>
              </span>
            )
          )}
        </div>

        <span
          className=" d-flex align-items-center justify-content-center p-3"
          style={{
            height: 70,
            width: "100%",
            position: "absolute",
            left: 0,
            bottom: 0,
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
          }}
        >
          <Button
            onClick={handleDone}
            variant="contained"
            className="bg-primary rounded-5 p-2 ps-3 pe-3"
          >
            Done
          </Button>
        </span>
      </div>
    </Modal>
  );
};

export default GroupList;
