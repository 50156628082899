import React from "react";
import "./LineChart.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const LineChart = ({ themeColorsObject }) => {
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);

  const { globalUsers, isLoading } = useSelector((state) => state?.globalUser);
  const { globalCompany } = useSelector((state) => state?.globalCompany);

  console.log(globalUsers);


  const { clients } = useSelector((state) => state?.globalClient);

  const globalCompanyCreatedAtArray = globalCompany?.map(
    (item) => item.createdAt
  );

  const globalUsersCreatedAtArray = globalUsers?.map((item) => item.createdAt);
  console.log(globalUsersCreatedAtArray);

  const globalClientsCreatedAtArray = clients?.map((item) => item.createdAt);

  

  // const getDataSetLabel = (createdAtArray) => {
  //   // Convert user creation dates to Date objects
  //   const globalCompanyCreatedAtArrayDates = createdAtArray.map(
  //     (dateString) => new Date(dateString)
  //   );
  //   // Define the intervals
  //   const intervals = [0, 5, 15, 20, 30, 40, 50];
  //   // Initialize counts for each interval
  //   const counts = Array(intervals.length).fill(0);
  
  //   // Iterate through each user creation date and count the users falling within each interval
  //   globalCompanyCreatedAtArrayDates.forEach((date) => {
  //     const diffInDays = Math.floor((date - startDate) / (1000 * 60 * 60 * 24));
  //     for (let i = 0; i < intervals.length - 1; i++) {
  //       if (diffInDays >= intervals[i] && diffInDays < intervals[i + 1]) {
  //         counts[i]++;
  //         break;
  //       }
  //     }
  //   });
  
  //   // Store the counts in an array
  //   const countsArray = [];
  //   for (let i = 0; i < intervals.length - 1; i++) {
  //     countsArray.push(counts[i]);
  //   }
  
  //   return countsArray;
  // };

  // Function to get dynamic labels based on current day interval from start date
function getDynamicLabels(startDate) {
  const currentDate = new Date(); // Get the current date
  const daysDifference = Math.ceil((currentDate - startDate) / (1000 * 60 * 60 * 24)); // Calculate difference in days

  // Generate dynamic labels based on current day interval
  const labels = [];
  for (let i = 0; i <= daysDifference; i += 5) {
    labels.push(i);
  }
  return labels;
}

const startDate = new Date(authInfo?.createdAt);
const dynamicLabels = getDynamicLabels(startDate);


  const getDataSetLabel = (createdAtArray) => {
    const intervals = dynamicLabels;
    
    // Initialize counts for each interval
    const counts = Array(intervals.length).fill(0);
    
    // Function to calculate difference in days between two dates
    function getDaysDifference(date1, date2) {
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
    
    // Calculate counts for each interval
    createdAtArray.forEach(timestamp => {
      const currentDate = new Date(timestamp);
      const daysDifference = getDaysDifference(startDate, currentDate);
      
      // Find the interval where the difference falls
      for (let i = 0; i < intervals.length; i++) {
        if (daysDifference <= intervals[i]) {
          counts[i]++;
          break;
        }
      }
    });
  
    return counts;
  };


console.log(getDataSetLabel(globalUsersCreatedAtArray));







  const data = {
    labels: dynamicLabels,
    datasets: [
      {
        label: "User",
        data: getDataSetLabel(globalUsersCreatedAtArray),
        backgroundColor: "#00b894",
        borderColor: "#00b894",
        borderWidth: 1.5,
        // pointBorderColor: 'aqua',
        fill: false,
        tension: 0,
        pointStyle: "rectRot",
        pointLabel: {
          display: true, // Set to true to display data labels
          font: {
            size: 12, // You can customize the font size here
          },
        },
      },
      {
        label: "Contacts",
        data: getDataSetLabel(globalClientsCreatedAtArray),
        backgroundColor: "#548CFF",
        borderColor: "#548CFF",
        borderWidth: 1.5,
        // pointBorderColor: 'aqua',
        fill: false,
        tension: 0,
        pointStyle: "rectRot",
        pointLabel: {
          display: true, // Set to true to display data labels
          font: {
            size: 12, // You can customize the font size here
          },
        },
      },
      {
        label: "Company",
        data: getDataSetLabel(globalCompanyCreatedAtArray),
        backgroundColor: "#e17055",
        borderColor: "#e17055",
        borderWidth: 1.5,
        // pointBorderColor: 'aqua',
        fill: false,
        tension: 0,
        pointStyle: "rectRot",
        pointLabel: {
          display: true, // Set to true to display data labels
          font: {
            size: 12, // You can customize the font size here
            color:"red"
          },
        },
      },
    ],
  };


  console.log(data);

  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 12, // Decrease the font size as needed
          },
        },
      },
    },

    animations: {
      tension: {
        duration: 1500,
        easing: "linear",
        from: 0.5,
        to: 0,
        loop: true,
      },
    },
  };

  return (
    <Paper
      elevation={1}
      style={{
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        // color: themeColorsObject.textColor,
      }}
      className="lineChart  w-100 d-flex justify-content-center align-items-center p-2 "
    >
      <div 
      style={{
        color: themeColorsObject.titleText,
      }} className=" chart-cnt  d-flex justify-content-center">
        <span className="clients">Clients</span>
        <Line data={data} options={options} />
        <span className="days">Days</span>
      </div>
    </Paper>
  );
};

export default LineChart;
