import React, { useEffect, useState } from "react";
import { emailCheck } from "../../../service/auth.service";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import google from "../../../Images/google-icon.png";
import windows from "../../../Images/wondows-icon.png";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../App";
import { GoogleLogin } from "@react-oauth/google";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority:
      `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_APP_ID}`,
    redirectUri: process.env.REACT_APP_BACKEND_BASEURL, // Update with your redirect URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, // Set to true for IE 11 support
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const EmailField = ({ setVerifyEmail, setEmailEntered, id }) => {
  const [TandC, setTandC] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  useEffect(() => {
    const initializeMSAL = async () => {
      try {
        await msalInstance.initialize(); // Make sure the MSAL instance is initialized
        await msalInstance.handleRedirectPromise();
        const account = msalInstance.getAllAccounts()[0];
        if (account) {
          msalInstance.setActiveAccount(account);
        }
      } catch (error) {
        console.error("MSAL initialization failed:", error);
      } finally {
        setIsMsalInitialized(true); // Ensure this is always set to true after the attempt
      }
    };

    initializeMSAL();
  }, []);

  const handleMSLogin = async (event) => {
    event.preventDefault();
    if (!isMsalInitialized) {
      console.error("MSAL instance not initialized yet");
      return;
    }

    try {
      const loginRequest = {
        scopes: ["user.read"], // Add additional scopes as needed
      };
      await msalInstance.loginRedirect(loginRequest);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const onSuccess = (credentialResponse) => {
    navigate(`/google-login/${credentialResponse?.credential}`);
  };

  const onError = (error) => {
    console.error("Login failed:", error);
  };

  const onSubmit = async (value) => {
    setIsLoading(true);
    if (!TandC) {
      setIsLoading(false);
      return notify("Please accept terms and condition", "warn");
    }
    setEmailEntered(value.email_address);
    try {
      const res = await emailCheck(id ? { ...value, plan_xid: id } : value);
      if (res?.status === 201) {
        setIsLoading(false);
        notify("Please check your email");
        setVerifyEmail(true);
      }
    } catch (err) {
      setVerifyEmail(false);
      setIsLoading(false);
      notify(`Email id ${value.email_address} already exist`, "warn");
    }
    reset();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const emailSchema = Yup.object().shape({
    email_address: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(emailSchema) });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column  justify-content-start h-100 "
    >
      <LoadingButton
        onClick={handleMSLogin}
        className=" mt-2 mb-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd"
      >
        <img className="me-2" width={28} src={windows} alt="" />
        <span>Sign Up With Microsoft</span>
      </LoadingButton>

      <div
        style={{
          position: "relative",
          height: 46,
        }}
        className="w-100 d-flex mb-2"
      >
        <div
          style={{
            opacity: 0,
            position: "absolute",
            top: 12,
            zIndex: 999,
          }}
          className="w-100 d-flex  justify-content-center"
        >
          <GoogleLogin
            clientId={process.env.REACT_APP_GG_APP_ID}
            buttonText="Login with Google"
            onSuccess={onSuccess}
            onError={onError}
            width={440}
            GsiButtonConfiguration={{
              shape: "pill",
              type: "icon",
            }}
          />
        </div>

        <LoadingButton
          style={{
            opacity: 1,
            position: "absolute",
            top: 0,
          }}
          className=" mt-2 mb-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd rounded-1 "
        >
          <img className="me-2" width={24} src={google} alt="" />
          <span>Sign Up With With Google</span>
        </LoadingButton>
      </div>

      <hr className="mt-4 mb-4"></hr>

      <div>
        <label htmlFor="exampleInputEmail1" className="form-label text-primary">
          Email address{" "}
          <span className="text-danger cad-text-medium0">*</span>
        </label>
        <input
          type="text"
          className="form-control p-4 ps-3 border-primary"
          id="exampleInputEmail1"
          placeholder="Email address"
          aria-describedby="emailHelp"
          {...register("email_address")}
        />

        <div
          htmlFor="exampleInputEmail1"
          className="form-label text-primary form-text text-danger"
        >
          {errors.email_address?.message}
        </div>
      </div>

      <div className=" d-flex justify-content-between">
        <span className="form-check d-flex justify-content-start gap-3">
          <input
            className="form-check-input w-auto pointer border-primary"
            type="checkbox"
            value={TandC}
            onChange={() => setTandC(!TandC)}
            id="flexCheckDefault"
          />
          <span className="cad-text-small">
            I accept the{" "}
            <Link
              target="_blank"
              to="https://staging.cloudifydocs.com/privacypolicy/#banner-top"
              className="cad-fw-600 underline"
            >
              <u> Privacy Policy</u>
            </Link>{" "}
            and{" "}
            <Link
              target="_blank"
              to="https://staging.cloudifydocs.com/termcondition"
              className="cad-fw-600 underline"
            >
              <u> Terms & Conditions</u>
            </Link>
          </span>
        </span>
      </div>

      <div className="col-8 mb-3 d-flex flex-column gap-2 align-self-center mt-2">
        <Button
          type="submit"
          className="btn mt-2 pt-1 fs-5 cad-fw-700 pb-1 button-contained"
        >
          {isLoading ? <CircularProgress className="text-white" /> : "Register"}
        </Button>
      </div>
      <Link
        to="/"
        className="cad-text-small cad-fw-600 text-primary text-center mt-2"
      >
        Back to login
      </Link>
    </form>
  );
};

export default EmailField;
