import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SplashScreen.css";
import { fetchTaskData } from "../../Redux/Slice/taskSlice";
import { useDispatch } from "react-redux";
import { fetchKanbanData } from "../../Redux/Slice/kanbanSlice";
import { fetchTag } from "../../Redux/Slice/tagsSlice";
import { getAllUsers } from "../../Redux/Slice/getAllUsers";
import { fetchTaskType } from "../../Redux/Slice/taskTypeSlice";
import { fetchReminderData } from "../../Redux/Slice/reminderSlice";
import { fetchPermissionData } from "../../Redux/Slice/permissionSlice";
import { fetchTaskPriorityData } from "../../Redux/Slice/taskPrioritySlice";
import { fetchGlobalUser } from "../../Redux/Slice/globalUserSlice";
import { fetchGlobalClient } from "../../Redux/Slice/globalClientSlice";
import { fetchGlobalCompany } from "../../Redux/Slice/globalCompanySlice";
import { fetchGlobalTags } from "../../Redux/Slice/globlalTags";
import { fetchGlobalModes } from "../../Redux/Slice/globalModes";
import { fetchClient } from "../../Redux/Slice/contactClientSlice";
import { getArchiveClient } from "../../Redux/Slice/contactArchiveClientSlice";
import { getCompanyData } from "../../Redux/Slice/company.service.slice";
import { pagination } from "../../Constants/Constants";
import { fetchGlobalResources } from "../../Redux/Slice/getGlobalResource";
import { fetchSupportTicket } from "../../Redux/Slice/supportSlice";
import { fetchFaqData } from "../../Redux/Slice/faqSlice";
import { fetchRequestFeatures } from "../../Redux/Slice/getRequestFeatures";
import { fetchHelpAndGuide } from "../../Redux/Slice/helpAndGuideSlice";
import { AuthMe } from "../../Redux/Slice/auth/auth.slice";
import { fetchAuthMe } from "../../Redux/Slice/fetchAuthMe";
import { useSelector } from 'react-redux';
import { fetchGlobalStatus } from "../../Redux/Slice/GlobalStatusSlice";
import { fetchPaymentHistory } from "../../Redux/Slice/paymentSelice";
import { getNotificationData } from "../../Redux/Slice/notification.service.slice";
import { fetchForms, fetchFormsActive } from "../../Redux/Slice/getFormsSlice";
import { fetchGlobalForms } from "../../Redux/Slice/getGlobalForms";
import CircularProgress from '@mui/material/CircularProgress';
// import { styled } from '@mui/material/styles';
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const SplashScreen = () => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const redirectToDashboard = () => {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    };

    setTimeout(() => {
      dispatch(AuthMe());
      dispatch(fetchAuthMe());
      dispatch(fetchTaskData(pagination));
      dispatch(fetchGlobalResources());
      dispatch(fetchKanbanData());
      dispatch(fetchTag());
      dispatch(getAllUsers());
      dispatch(fetchHelpAndGuide());
      dispatch(fetchSupportTicket());
      dispatch(fetchFaqData());
      dispatch(fetchForms())
      dispatch(fetchGlobalForms())
      dispatch(fetchFormsActive());
      dispatch(fetchRequestFeatures());
      dispatch(fetchClient(pagination));
      dispatch(getArchiveClient(pagination));
      dispatch(getCompanyData(pagination));
      dispatch(fetchTaskType());
      dispatch(fetchReminderData());
      dispatch(fetchPermissionData());
      dispatch(fetchTaskPriorityData());
      dispatch(fetchGlobalUser());
      dispatch(fetchGlobalClient());
      dispatch(fetchGlobalCompany());
      dispatch(fetchGlobalStatus());
      dispatch(fetchGlobalTags());
      dispatch(fetchGlobalModes());
      redirectToDashboard();
      dispatch(fetchPaymentHistory());
      dispatch(getNotificationData());
    }, 1000);
  }, [navigate, dispatch]);

  // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 10,
  //   borderRadius: 5,
  //   [`&.${linearProgressClasses.colorPrimary}`]: {
  //     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  //   },
  //   [`& .${linearProgressClasses.bar}`]: {
  //     borderRadius: 5,
  //     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  //   },
  // }));

  function GradientCircularProgress() {
    return (
      <Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#4CCD8D" />
              <stop offset="100%" stopColor="#0D6EFD" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={40} // Increase the size
          thickness={4.5} // Increase the thickness
          sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
      </Fragment>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.titleText,
      }}
      className="d-flex justify-content-center gap-3 flex-column align-items-center">
      <h1
        style={{ fontSize: "40px", color: "#4CCD8D" }}
        className="cad-fw-800 "
      >
        Cloudify
        <span className="cad-fw-500 text-primary">
          Docs
        </span>
      </h1>
      {/* <Loader/> */}
      <GradientCircularProgress />

      {/* <div className="Loading">
        </div> */}
    </div>
  );
};

export default SplashScreen;
