import React, { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./Chats.css";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import SearchIcon from "@mui/icons-material/Search";
import GlobalStateContext from "../../Contexts/GlobalStateContext";
import { Avatar, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import PeopleTwoToneIcon from "@mui/icons-material/PeopleTwoTone";
import SecondaryButton from "../SecondaryButton";
import AddIcon from "@mui/icons-material/Add";
import PrimaryButton from "../PrimaryButton";
import NewChatModal from "./NewChatModal";
import NewGroupChatModal from "./NewGroupChatModal";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import UserProfile from "../GlobalTable/UserProfile";
import {
  createOrGetOneOnOneChat,
  getContacts,
  getContactsWithoutSort,
  getMessage,
} from "../../service/chat.service";
import GroupList from "./GroupList";
import SearchInput from "../SearchInput/SearchInput";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const convertTimestampToTime = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Add leading zero to minutes if less than 10
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Formatted time string
  const formattedTime = `${hours}:${formattedMinutes} ${meridiem}`;

  return formattedTime;
};

const ChatsLeft = ({
  openNewGroupChat,
  handleOpenNewGroupChat,
  handleCloseNewGroupChat,
  openNewChat,
  handleOpenNewChat,
  handleCloseNewChat,
  themeColorsObject,
  newGroupDetails,
  setNewGroupDetails,
  handleNewGroupDetails,
  profileSrc,
  setProfileSrc,
  isChatLoading,
}) => {
  const {
    socket,
    message,
    setMessage,
    contacts,
    setContacts,
    setIsChatLoading,
    activeChatIndex,
    setActiveChatIndex,
    chatActiveId,
    setChatActiveId,
  } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { individualArray, chatActiveIndex } = useContext(GlobalStateContext);
  const [openGroupList, setOpenGroupList] = useState(false);
  const [lastMessage, setLastMessage] = useState({});

  const { role, actions } = useSelector((state) => state?.authMe);

  const [searchTerm, setSearchTerm] = useState("");

  const handleUserClick = (chatId, index) => {
    setChatActiveId(chatId);
    setActiveChatIndex(index);
    const fetchMessage = async (id) => {
      try {
        const response = await getMessage(id);
        setMessage(response?.data?.payload);
        const lastMsg = message?.pop();
        setLastMessage(lastMsg);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchMessage(chatId);
  };

  const handleViewChatMessage = (id, index) => async () => {
    setIsChatLoading(true);
    setChatActiveId(id);
    navigate(`/chats/${id}?index=${index}`);
    setActiveChatIndex(index);
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const response = await getMessage(id, signal);
      setMessage(response?.data?.payload);
      setIsChatLoading(false);
    } catch (error) {
      console.log(error);
      setIsChatLoading(false);
    }
    return () => {
      setIsChatLoading(false);
      abortController.abort();
    };
  };

  const fetchContacts = async () => {
    try {
      const response = await getContacts();
      // const response = await getContactsWithoutSort();
      setContacts(
        response?.data?.payload.map((chats) => {
          let profileName;
          if (!chats?.isGroupChat) {
            const { first_name, last_name, profile_photo, id } =
              chats?.participants[0];
            profileName = {
              name: first_name + " " + last_name,
              profile: profile_photo,
              chatId: chats?.id,
              first_name,
              last_name,
              id: id,
              last_message: chats?.messages[0] || null,
            };
          } else {
            profileName = {
              name: chats.name,
              profile: chats.profile,
              chatId: chats?.id,
              id: id,
              last_message: chats?.messages[0] || null,
            };
          }
          return profileName;
        }) || []
      );
      // console.log("contacts====", response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMessages = async () => {
    if (id) {
      setChatActiveId(id);
      const index = contacts.filter((item, index) => {
        if (item.id === id) {
          return index;
        }
      });
      setActiveChatIndex(index + 1);
      try {
        const response = await getMessage(id);
        setMessage(response?.data?.payload);
        const lastMsg = message?.pop();
        // setLastMessage(lastMsg);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // fetchContacts();
    fetchMessages();
  }, []);



  return (
    <div className="h-100  overflow-hidden pb-5">
      <div
        style={{
          display: role === "Admin" ? "none" : "flex",
        }}
        className="w-100 p-3 
       justify-content-between text-small"
      >
        <Button
          onClick={handleOpenNewChat}
          variant="contained"
          style={{
            width: "48%",
            backgroundColor: "#548CFF",
            textTransform: "none",
          }}
        >
          <AddIcon /> Chat
        </Button>
        <Button
          onClick={handleOpenNewGroupChat}
          variant="contained"
          style={{
            width: "48%",
            backgroundColor: "#548CFF",
            textTransform: "none",
          }}
        >
          <AddIcon /> Group Chat
        </Button>
      </div>

      <div className="p-2">
        <SearchInput
          searchFucntion={(e) => setSearchTerm(e.target.value)}
          searchValue={searchTerm}
        />
        {/* <p style={{ fontSize: "14px", fontWeight: "600", marginTop:"18px" }}>Frequent Contact</p>
        <div
          style={{
            height: "6%",
          }}
          className="active-profile ps-2 pointer"
        >
          {individualArray.map((message, i) => (
            <div
              key={i}
              className=""
              style={{
                display: "flex",
                position: "relative",
                marginRight: "15px",
              }}
            >
              <div className="contact-profile" style={{ position: "relative" }}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar alt="Remy Sharp" src={message.image} />
                </StyledBadge>
              </div>
            </div>
          ))}
        </div> */}
      </div>

      {isChatLoading ? (
        <div
          style={{
            height: "85%",
          }}
          className="mess pointer p-3 pb-5 overflow-auto d-flex justify-content-center align-items-center"
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            height: "85%",
          }}
          className="mess pointer p-3 pb-5 overflow-auto"
        >
          {contacts?.length === 0 ? (
            <div className="h-50 w-100 d-flex gap-2 flex-column justify-content-center align-items-center">
              <SpeakerNotesOutlinedIcon className="fs-1 text-primary" />
              <span className="text-primary cad-fw-500">You have no chats</span>
            </div>
          ) : (
            contacts.map(({ name, profile, chatId, last_message }, i) => (
              <div
                key={i}
                onClick={handleViewChatMessage(chatId, i)}
                className={
                  chatActiveId === chatId
                    ? "chat-active message position-relative mb-3 mt-1 pt-3 pb-3 gap-2 p-2 rounded-2 d-flex  align-items-center"
                    : "message position-relative mb-3 mt-1 gap-2 pt-3 pb-3 p-2 rounded-2 d-flex  align-items-center"
                }
              >
                <div className="contact-profile">
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    {/* <Avatar alt={name} src={profile} /> */}
                    <UserProfile proPic={profile} />
                  </StyledBadge>
                </div>

                <div className="d-flex flex-column pe-2">
                  <span style={{ fontSize: "14px", fontWeight: "500" }}>
                    {name}
                  </span>
                  <span
                    className="text-nowrap"
                    style={{
                      fontSize: "12px",
                      width: "240px",
                      overflow: "hidden",
                    }}
                  >
                    {last_message?.content}
                  </span>
                </div>
                <div className="d-flex pe-1 h-100 position-absolute end-0 flex-column justify-content-around align-items-end">
                  <p
                    className="m-1"
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      position: "relative",
                    }}
                  >
                    {last_message?.createdAt &&
                      convertTimestampToTime(last_message?.createdAt)}
                  </p>
                  {/* //TODO set a counter to unread message */}
                  {/* <span
                  className="m-2"
                  style={{
                    position: "relative",
                    fontSize: "8px",
                    background: "#548CFF",
                    width: "18px",
                    height: "18px",
                    padding: "3px",
                    borderRadius: "10px",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  1
                </span> */}
                </div>
              </div>
            ))
          )}
        </div>
      )}

      <NewChatModal
        fetchContacts={fetchContacts}
        handleUserClick={handleUserClick}
        contacts={contacts}
        setContacts={setContacts}
        openNewChat={openNewChat}
        handleOpenNewChat={handleOpenNewChat}
        handleCloseNewChat={handleCloseNewChat}
      />

      <NewGroupChatModal
        contacts={contacts}
        setContacts={setContacts}
        openNewChat={openNewGroupChat}
        themeColorsObject={themeColorsObject}
        handleOpenNewChat={handleOpenNewGroupChat}
        handleCloseNewChat={handleCloseNewGroupChat}
        setOpenGroupList={setOpenGroupList}
        newGroupDetails={newGroupDetails}
        setNewGroupDetails={setNewGroupDetails}
        handleNewGroupDetails={handleNewGroupDetails}
        profileSrc={profileSrc}
        setProfileSrc={setProfileSrc}
      />

      <GroupList
        contacts={contacts}
        setContacts={setContacts}
        openNewChat={openGroupList}
        setOpenGroupList={setOpenGroupList}
        themeColorsObject={themeColorsObject}
        handleOpenNewChat={handleOpenNewGroupChat}
        handleCloseNewChat={handleCloseNewGroupChat}
        newGroupDetails={newGroupDetails}
        setNewGroupDetails={setNewGroupDetails}
        handleNewGroupDetails={handleNewGroupDetails}
        profileSrc={profileSrc}
        setProfileSrc={setProfileSrc}
      />
    </div>
  );
};

export default ChatsLeft;
