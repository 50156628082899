
import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserProfile from "../../../Components/GlobalTable/UserProfile";
import {
  AiOutlineDelete,
  AiOutlinePlus,
} from "react-icons/ai";
import DateFormatter from "../../../Components/DateFormatter";
import PrimaryButton from "../../../Components/PrimaryButton";
import downloadCSV from "../../User/DownloadCSV";
import CompanyDetails from "./CompanyDetails";
import { useDispatch } from "react-redux";
import { getByIdCompany, getCompanyData } from "../../../Redux/Slice/company.service.slice";
import DpViewModal from "../../../Components/DpViewModal";
import TableData from "../../../Components/TableData";
import Refresher from "../../../Components/Refresher";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import AlertModal from "../../../Components/AlertModal";
import { deleteCompany } from "../../../service/company.service";
import { notify } from "../../../App";
import AddCompanyModal from "./AddCompanyModal";
import AdvanceFilter from "../../../Components/AdvanceFilter/AdvanceFilter";
import CompanyDetailsEditModal from "./CompanyDetailsEditModal";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { fetchGlobalCompanyByID } from "../../../Redux/Slice/getCompanyById";

const CompanyNew = () => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const [, setAnchorEl] = useState(null);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [checkedRows, setCheckedRows] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddNewCompanyModal, setOpenAddNewCompanyModal] = useState(false);
  const { role, actions } = useSelector((state) => state?.authMe);
  const [DpViewModalOpen, setDpViewModalOpen] = useState(false);
  const [DpUrl, setDpUrl] = useState("");
  const handleOpenDpViewModal = (url) => {
    setDpUrl(url);
    setDpViewModalOpen(true);
  };
  const handleCloseDpViewModal = () => setDpViewModalOpen(false);
  const [filteredCellConfig, setFilteredCellConfigg] = useState([])
  const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([])

  const companyData = useSelector((state) => state?.company?.users?.data?.rows);

  const { isLoading } = useSelector((state) => state?.company);
  // console.log(isLoading);

  const totalPages = useSelector(
    (state) => state?.company?.users?.data?.totalPages
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleCheckboxChange = (id) => {
  //   setCheckedRows((prevCheckedRows) => ({
  //     ...prevCheckedRows,
  //     [id]: !prevCheckedRows[id],
  //   }));
  // };



  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };



  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Function to open the modal
  const openModalDelete = () => {
    setDeleteModalOpen(true);
    handleClose();
  };

  // Function to close the modal
  const closeModalDelete = () => {
    setDeleteModalOpen(false);
  };

  const [companyDetailsModal, setCompanyDetailsModal] = useState(false);
  const [companyEditModal, setCompanyEditModal] = useState(false);

  // Function to close the modal
  const closeCompanyDetails = () => {
    setCompanyDetailsModal(false);
  };

  const closeCompanyDetailsEdit = () => {
    setCompanyEditModal(false)
  }

  // Function to open the modal
  const openCompnayDetails = (id) => {
    dispatch(getByIdCompany(id))
    setMouseEntered(id);
    setCompanyDetailsModal(true);
    handleClose();
  };

  // Function to open the modal
  const openCompnayDetailsEdit = (id) => {
    dispatch(getByIdCompany(id))
    dispatch(fetchGlobalCompanyByID(id));
    setMouseEntered(id);
    setCompanyEditModal(true);
    handleClose();
  };

  const handleExportClick = () => {
    const selectedRowsData = companyData.filter((row) => checkedRows[row.id]);

    if (selectedRowsData.length === 0) {
      return;
    }

    // Call downloadCSV function with selectedRowsData
    downloadCSV(selectedRowsData, "selected_data.csv");
  };

  const dispatch = useDispatch();

  const handlePagination = (value) => {
    // console.log(value);
    dispatch(
      getCompanyData({
        size: 10,
        page: value,
      })
    );
  };

  // console.log(companyData);

  const filteredTaskArray = companyData?.filter((row) => {
    return (
      row.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.email_address?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const extractedDataArray = filteredTaskArray?.map((user) => {
    const extractedData = {
      id: user.id,
      checkBox: (
        <input
          style={{ width: 16 }}
          className="form-check-input pointer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          checked={!!checkedRows[user.id]}
          onChange={() => handleCheckboxChange(user.id)}
        />
      ),
      name: (
        <UserProfile
          proPic={user?.profile_photo}
          companyName={user?.company_name}
        />
      ),
      emailAddress: user?.email_address === null ? "N/A" : user?.email_address,
      phone: user?.phone_number === null ? "N/A" : user?.phone_number,
      industry: user?.industry === null ? "N/A" : user?.industry,
      createdDate:
        mouseEntered && mouseEnteredId === user?.id ? (
          <div className="d-flex justify-content-center">
            {/* <Link to={`/view-task/${id}`}> */}
            <span style={{ borderRadius: "18%" }} className="p-2 action-hover text-primary">
              <BorderColorOutlinedIcon
                onClick={() => openCompnayDetailsEdit(user.id)}
                style={{ fontSize: "20px" }}
              />
            </span>
            {/* </Link> */}
            {role === "Admin" ? (
              ""
            ) : (
              <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover text-primary"
              >
                <AiOutlineDelete
                  onClick={openModalDelete}
                  style={{ fontSize: "20px" }}
                />
              </span>
            )}
          </div>
        ) : (
          <DateFormatter inputDate={user?.createdAt} />
        ),
    };

    return extractedData;
  });

  const [checkboxValues, setCheckboxValues] = useState({
    Checkbox: false,
    Name: true,
    "Passport Name": true,
    "National ID": true,
    "Created Date": true,
  });

  const cellConfig = [
    {
      dataKey: "checkBox",
      width: "1%",
      align: "center",
    },
    {
      dataKey: "name",
      width: "20%",
      align: "left",
    },
    {
      dataKey: "emailAddress",
      width: "20%",
      align: "center",
    },
    {
      dataKey: "phone",
      width: "20%",
      align: "center",
    },
    {
      dataKey: "industry",
      width: "19%",
      align: "center",
    },
    {
      dataKey: "createdDate",
      width: "19%",
      align: "center",
    },
  ];



  const tableHeadRowCell = [
    {
      title: (
        <input
          style={{ width: 16 }}
          className="form-check-input pointer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        // checked={!!checkedRows[user?.id]}
        // onChange={() => handleCheckboxChange(user?.id)}
        />
      ),
      width: "1%",
      align: "center",
    },
    {
      title: "Name",
      width: "20%",
      align: "left",
    },
    {
      title: "Email Address",
      width: "20%",
      align: "center",
    },
    {
      title: "Phone",
      width: "20%",
      align: "center",
    },
    {
      title: "Industry",
      width: "20%",
      align: "center",
    },
    {
      title: "Created Date",
      width: "19%",
      align: "center",
    },
  ];


  const handleDeleteCompany = async () => {
    try {
      // Call the deleteUser function and pass the user ID
      const response = await deleteCompany(mouseEnteredId);

      if (response.status === 200) {
        // The user was successfully deleted, handle the success
        // console.log("User deleted successfully");
        dispatch(getCompanyData());
        closeModalDelete();
        notify("Company deleted succesfully");
      } else {
        // Handle errors or show a message to the user
        console.error("Failed to delete user");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error deleting user", error);
    }
  };


  return (
    <div className="h-100">
      <div className="d-flex align-items-center justify-content-between p-3 col-md-12">
        <div className="col-3">
          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="d-flex gap-2">
          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(getCompanyData())}
          />
          <PrimaryButton
            variant="contained"
            title="Add New Company"
            padding="0.3rem 0.6rem"
            icon={<AiOutlinePlus size={13} />}
            fontSize="14px"
            width="200px"
            onClick={() => setOpenAddNewCompanyModal(true)}
          />
          {/* <SecondaryButton
            title="Export"
            padding="0.3rem 1.2rem"
            icon={<AiOutlineUpload size={16} />}
            fontSize="13px"
            // onClick={handleExportClick}
          /> */}

          <AdvanceFilter
            bottom="-169px"
            left="-73px"

            cellConfig={cellConfig}
            tableHeadRowCell={tableHeadRowCell}

            setFilteredCellConfig={setFilteredCellConfigg}
            setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}

            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
          />


        </div>
      </div>

      <TableData
        tableHeadRowCell={filteredTableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={filteredCellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        totalPages={totalPages}
        handlePagination={handlePagination}
        handleViewOpen={openCompnayDetails}
        isLoading={isLoading}
      />

      {deleteModalOpen && (
        <AlertModal
          message={"Are you sure you want to delete this company?"}
          closeModal={closeModalDelete}
          open={openModalDelete}
          handler={handleDeleteCompany}
        />
      )}
      <CompanyDetails
        closeModal={closeCompanyDetails}
        open={companyDetailsModal}
        userId={mouseEnteredId}
        handleOpenDpViewModal={handleOpenDpViewModal}
      />
      <CompanyDetailsEditModal
        closeModal={closeCompanyDetailsEdit}
        open={companyEditModal}
        userId={mouseEnteredId}
        handleOpenDpViewModal={closeCompanyDetailsEdit}
      />
      <DpViewModal
        DpViewModalOpen={DpViewModalOpen}
        handleCloseDpViewModal={handleCloseDpViewModal}
        DpUrl={DpUrl}
      />

      <AddCompanyModal
        theme={theme}
        themeColorsObject={themeColorsObject}
        openAddNewCompanyModal={openAddNewCompanyModal}
        setOpenAddNewCompanyModal={setOpenAddNewCompanyModal}
      />

    </div>
  );
};

export default CompanyNew;
