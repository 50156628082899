import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getForms, getFormsAll } from "../../service/form.service";
// import { getForms, createForm } from "../../service/form.service";

export const fetchForms = createAsyncThunk("getForms", async () => {
  try {
    const res = await getFormsAll();
    // console.log(res.data);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching forms: ${err.message}`);
  }
});
export const fetchFormsActive = createAsyncThunk("getFormsActive", async () => {
  try {
    const res = await getForms();
    // console.log(res.data);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching forms: ${err.message}`);
  }
});

// export const addForm = createAsyncThunk(
//   "addForm",
//   async (formData) => {
//     try {
//       const res = await createForm(formData);
//       // console.log(res.data);
//       return res.data;
//     } catch (err) {
//       throw new Error(`Error creating form: ${err.message}`);
//     }
//   }
// );

const getFormsSlice = createSlice({
  name: "formArray",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    formArray: [],
    activeForms: [],
  },
  reducers: {
    updateFormData: (state, action) => {
      const { id, formData } = action.payload;
      // console.log(formData);

      // Find the index of the form to update
      const formIndex = state.formArray.findIndex((form) => form.id === id);

      if (formIndex !== -1) {
        // Create a copy of the form object to update
        const updatedForm = {
          ...state.formArray[formIndex],
          formData: formData,
        };

        // Create a new array with the updated form
        const updatedFormArray = [
          ...state.formArray.slice(0, formIndex),
          updatedForm,
          ...state.formArray.slice(formIndex + 1),
        ];

        // Update the state with the new form array
        state.formArray = updatedFormArray;
      }

      // console.log(state.formArray);
    },

    saveFormData: (state, action) => {
      // console.log(action.payload);
      state.formArray.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchForms.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchForms.fulfilled, (state, action) => {
        state.formArray = action.payload?.payload || [];
        state.isLoading = false;
      })
      .addCase(fetchForms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchFormsActive.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchFormsActive.fulfilled, (state, action) => {
        state.activeForms = action.payload?.payload || [];
        state.isLoading = false;
      })
      .addCase(fetchFormsActive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export const { updateFormData, saveFormData } = getFormsSlice.actions;
export default getFormsSlice.reducer;
