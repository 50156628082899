import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

import { HiOutlineViewGrid } from "react-icons/hi";
import { HiOutlineNewspaper } from "react-icons/hi";
import { FaTasks } from "react-icons/fa";
import { HiOutlineAnnotation } from "react-icons/hi";
import { HiOutlineCog } from "react-icons/hi";





export const nav = [
  {
    title: "Dashboard",
    path: "/",
    // icon: <GridViewOutlinedIcon />,
    icon: <HiOutlineViewGrid size={24} className="cad-fw-600" />,
  },
  {
    title: "Tasks",
    path: "/task",
    // icon: <ListAltOutlinedIcon />,
    icon: <HiOutlineNewspaper size={24} className="cad-fw-600" />,
  },
  {
    title: "Contacts",
    path: "/contacts",
    icon: <PermContactCalendarOutlinedIcon />,
  },
  {
    title: "Forms",
    path: "/form",
    icon: <SummarizeOutlinedIcon />,
  },
  {
    title: "Chats",
    path: "/chats",
    // icon: <MessageOutlinedIcon />,
    icon: <HiOutlineAnnotation size={24} className="cad-fw-600" />,
    
  },
  {
    title: "Settings",
    path: "/settings",
    // icon: <SettingsOutlinedIcon />,
    icon: <HiOutlineCog size={24} className="cad-fw-600" />,
    items: [
      {
        subTitle: "Support",
        path: "/support",
        icon: "SupportAgentIcon",
      },
      {
        subTitle: "Users",
        path: "/users",
        icon: "PeopleOutlineRoundedIcon" ,
      },
      {
        subTitle: "Tags",
        path: "/tags",
        icon: "SupportAgentIcon",
      },
      {
        subTitle: "Company Branding",
        path: "/company-branding",
        icon: "SupportAgentIcon",
      },
      {
        subTitle: "Notification",
        path: "/notification",
        icon: "SupportAgentIcon",
      },
      {
        subTitle: "Subscription",
        path: "/subscription",
        icon: "SupportAgentIcon",
      },
      {
        subTitle: "Account & Security",
        path: "/accounts-security",
        icon: "SupportAgentIcon",
      },
    ],
  },
];



