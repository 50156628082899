import React from "react";
import { Typography } from "@mui/material";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Tag from "../../../Components/Tag/Tag";
import { fetchRequestDocs } from "../../../Redux/Slice/requestDocsSlice";
import { fetchSharedDocs } from "../../../Redux/Slice/getSharedDocs";
import EditModal from "../EditModal";
import ViewModal from "../ViewModal";
import { fetchTaskDataByID } from "../../../Redux/Slice/taskSlice";
import { useState } from "react";
import GroupAvatars from "../../../Components/Avatar/AvatarGroup";

const GridCard = ({
  id,
  title,
  disc,
  tags,
  assignee,
  themeColorsObject,
  theme,
  setOperationTaskId
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ===============================================================

  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => state?.tasks);

  const [openEdit, setOpenEdit] = useState(false);
  // ===============================================================

  const [openView, setOpenView] = useState(false);

  const handleViewOpen = (id) => {
    const foundObject = tasks.find((obj) => obj.id === id);
    // console.log(foundObject);
    foundObject?.type?.title === "Recevied Document"
      ? dispatch(fetchRequestDocs(id))
      : dispatch(fetchSharedDocs(id));
    setOpenView(true);
  };


  const handleEditOpen = (id) => {
    setOperationTaskId(id)
    setOpenEdit(true);
    dispatch(fetchTaskDataByID(id));
  };
  const handleEditClose = () => {
    // dispatch(fetchKanbanData());
    setOpenEdit(false);
  };

  const handleViewClose = () => setOpenView(false);

  // ================================================================

  return (
    <div
      style={{
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        // color: themeColorsObject.textColor,
        color: theme === "light" ? "#000" : "#fff",
      }}
      className="p-3 grid-card rounded-1 d-flex flex-column gap-2  "
    >
      <div className="d-flex justify-content-between p-1">
        <span className="cad-fw-700 cad-tex-large text-truncate">{title}</span>
        <MoreHorizTwoToneIcon
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="pointer"
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Link onClick={() => handleEditOpen(id)}>
            <MenuItem style={{ fontSize: "14px" }}>
              <DriveFileRenameOutlineTwoToneIcon
                style={{ fontSize: "16px", marginRight: "6px" }}
              />
              Edit
            </MenuItem>
          </Link>

          {id ? (
            <Link onClick={() => handleViewOpen(id)}>
              <MenuItem style={{ fontSize: "14px" }} onClick={handleClose}>
                <RemoveRedEyeTwoToneIcon
                  style={{ fontSize: "16px", marginRight: "6px" }}
                />
                View Task
              </MenuItem>
            </Link>
          ) : (
            ""
          )}
        </Menu>
      </div>
      <Typography variant="subtitle2" style={{ fontSize: "12px" }}>
        {disc}
      </Typography>

      <div className="d-flex flex-wrap gap-2  mb-2 mt-2">
        {tags?.map((tag, i) => (
          <Tag key={i} title={tag.title} />
        ))}
      </div>
      <div className="d-flex justify-content-end flex-wrap gap-2 pointer  mb-2 mt-2">
        <GroupAvatars data={assignee} />
      </div>

      <ViewModal
        themeColorsObject={themeColorsObject}
        open={openView}
        handleOpen={handleViewOpen}
        handleClose={handleViewClose}
        id={id}
      />

      <EditModal
        themeColorsObject={themeColorsObject}
        open={openEdit}
        handleOpen={handleEditOpen}
        handleClose={handleEditClose}
        id={id}
      />
    </div>
  );
};

export default GridCard;
