import {
  Button,
  Chip,
  CircularProgress,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchTaskData } from "../../Redux/Slice/taskSlice";
import { fetchKanbanData } from "../../Redux/Slice/kanbanSlice";
import { useDispatch } from "react-redux";
import { createFillFormTask, createTask } from "../../service/task.service";
import { useNavigate } from "react-router-dom";
import { fetchSharedDocs } from "../../Redux/Slice/getSharedDocs";
import { fetchRequestDocs } from "../../Redux/Slice/requestDocsSlice";
import MuiSelector from "../../Components/MuiSelector";
import { notify } from "../../App";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";



const AddTaskModal = ({
  open,
  setOpen,
  toast,
  setOperationTaskId,
  setOpenShareDocumentModal,
  setOpenReciveDocumentModal,
  themeColorsObject,
}) => {
  const navigate = useNavigate();
  const [switchValue, setSwitchValue] = useState(false);
  const [selectedChip, setSelectedChip] = useState(null);
  const [selectedDueDate, setSelectedDueDate] = useState("");
  const datePickerRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [taskDiscription, setTaskDiscription] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedForm, setSelectedForm] = useState();
  const [assignCompany, setAssignCompany] = useState(null);

  // =======[ Redux States ]=======
  const { modes } = useSelector((state) => state?.globalModes);
  const taskType = useSelector((state) => state?.taskTypeData);
  const priorityData = useSelector((state) => state?.priority);
  const permission = useSelector((state) => state?.permission);
  const reminderArray = useSelector((state) => state?.reminders);
  const tags = useSelector((state) => state.globalTags);
  const company = useSelector((state) => state?.globalCompany?.globalCompany);
  const client = useSelector((state) => state.globalClient);
  const { activeForms } = useSelector((state) => state?.getForms);
  const employee = useSelector((state) => state?.globalUser.globalUsers);
  const [switchMode, setSwitchMode] = useState(modes[2]?.id);
  const [selectedOwners, setSelectedOwner] = useState([
    {
      id: employee[0]?.id,
      value: employee[0]?.id,
      label: employee[0]?.first_name,
      color: "#0d6efd",
      isFixed: false,
    },
  ]);

  const [openModal, setOpenModal] = useState(false);
  // const handleOpen = () => setOpenModal(true);
  // const handleClose = () => setOpenModal(false);

  function openInNewTab(url, delay) {
    setOpenModal(true)
    setTimeout(() => {
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.opener = null;
      }
    }, delay);
  }

  //   ===========[ Valued state ]==========
  const [priority, setPriority] = useState(priorityData?.taskPriorities[0]?.id);
  const [permissionInput, setPermissionInput] = useState(
    permission.permissions[2]?.id
  );
  const [reminder, setReminder] = useState(reminderArray?.remindersData[1]?.id);

  //   =======[ Functionc ]=========
  function getCurrentDate() {
    const today = new Date();
    let month = "" + (today.getMonth() + 1);
    let day = "" + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const clientFilter = () => {
    // dispatch(updateClients(client));
  };

  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked);
    setSwitchMode(
      event.target.checked
        ? modes[0].id // private mode
        : modes[2].id // public mode
    );
  };

  const handleChipClick = (chipLabel) => {
    setSelectedChip(chipLabel);
  };

  const handleDueDateChange = () => {
    // Access the selected date using datePickerRef.current.value
    const selectedDateValue = datePickerRef.current.value;
    setSelectedDueDate(selectedDateValue);
  };

  const onCompanyChange = (select) => {
    setAssignCompany(select);
    clientFilter();
  };

  //   ======[ Refactorinf array ]======
  const reminderArrayy = reminderArray?.remindersData.map((item) => {
    return {
      id: item.id,
      title: item.title,
    };
  });

  const tagsOptions = tags?.rows.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.title,
      color: "#0d6efd",
    };
  });

  const comapnyOptions = company?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.company_name,
      color: "#fa983a",
      isFixed: false,
      clients: item.clients,
    };
  });
  //   ======[ Refactorinf array ]======
  const filteredClients = client?.clients?.filter(item => item?.company?.id === assignCompany);

  const filteredClientOptions = filteredClients.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name,
      color: "#0d6efd",
      isFixed: false,
    };
  });

  const clientOptions = client?.clients?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name,
      color: "#0d6efd",
      isFixed: false,
    };
  });

  const empOptions = employee?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name,
      color: "#0d6efd",
      isFixed: false,
    };
  });

  // const selectForm = formArray?.map((item) => {
  //   return {
  //     id: item.id,
  //     value: item.id,
  //     label: item.title,
  //     color: "#0d6efd",
  //     isFixed: false,
  //   };
  // });
  const selectForm = activeForms?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.title,
      color: "#0d6efd",
      isFixed: false,
    };
  });

  const dispatch = useDispatch();

  // this will be shown in error element
  const taskValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Task name is required")
      .min(5, "Task name must be atleast 5 characters"),
    due_date: Yup.string()
      .required("Due date is required")
      .min(5, "Description must be at least 5 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters")
      .max(200, "Description must not exceed 200 characters"),
  });

  const onSubmit = async (value) => {
    // e.preventDefault();
    setSubmitLoading(true);
    // console.log(value.title);

    const formValues = {
      title: value.title,
      due_date: value.due_date,
      description: value.description,
      priority_xid: priority,
      permission_xid: permissionInput,
      reminder_set_xid: reminder,
      tags: selectedTags.map((item) => item.id),
      type_xid: selectedChip,
      // employee_xid: selectedOwners[0]?.id, //user
      mode_xid: switchMode,
      employee: selectedOwners.map((item) => item.id),
      clients: selectedClients.map((item) => item.id),
    };

    const fillFormValues = {
      title: value.title,
      due_date: value.due_date,
      description: value.description,
      priority_xid: priority,
      permission_xid: permissionInput,
      reminder_set_xid: reminder,
      tags: selectedTags.map((item) => item.id),
      type_xid: selectedChip,
      // employee_xid: selectedOwners[0]?.id, //user
      mode_xid: switchMode,
      employee: selectedOwners.map((item) => item.id),
      clients: selectedClients.map((item) => item?.id),
      form_xid: selectedForm,
    };

    // const response = await createTask(formValues);
    if (selectedChip === null) {
      setSubmitLoading(false);
      return notify("Please select Task Type", "warn");
    }

    if (selectedChip === taskType?.tasksType[0].id) {
      if (selectedForm?.length === 0) {
        setSubmitLoading(false);
        return notify("Please select Form", "warn");
      } else if (selectedClients?.length === 0) {
        setSubmitLoading(false);
        return notify("Please select Client", "warn");
      }
      try {
        const response = await createFillFormTask(fillFormValues);
        if (response.status === 400) {
          setSubmitLoading(false);
          // console.log(response?.data?.message);
          notify(response?.data?.message, "error");
        } else if (response.status === 201) {
          setSubmitLoading(false);
          notify("Fill a form task is created");
          setOpen(false);
        } else if (response.status === 500) {
          setSubmitLoading(false);
          // console.log(response.status);
          notify("Please select form");
        }
      } catch (error) {
        // console.log(error);
        setOpen(false);
      }
    } else {
      try {
        if (selectedClients?.length === 0) {
          setSubmitLoading(false);
          return notify("Please select Client", "warn");
        }
        const response = await createTask(formValues);
        // console.log(response);
        if (response.status === 400) {
          setSubmitLoading(false);
          // console.log(response?.data?.message);
        } else if (response.status === 201) {
          // console.log(response);
          switch (selectedChip) {
            case taskType?.tasksType[0].id:
              // console.log(response?.data?.message);
              setOperationTaskId(response?.data?.id);
              break;
            case taskType?.tasksType[1].id:
              // console.log(response?.data?.message);
              setOperationTaskId(response?.data?.id);
              dispatch(fetchRequestDocs(response?.data?.id));
              setOpenReciveDocumentModal(true);
              break;
            case taskType?.tasksType[2].id:
              // SgnDoc
              setOperationTaskId(response?.data?.id);
              setOpenModal(true);
              openInNewTab("https://www.adobe.com/sign/online-signature.html", 4000);
              break;
            case taskType?.tasksType[3].id:
              // console.log(response?.data?.message);
              setOperationTaskId(response?.data?.id);
              dispatch(fetchSharedDocs(response?.data?.id));
              setOpenShareDocumentModal(true);
              break;
            default:
              notify("Please select task type !");
              break;
          }
          setOpen(false);
        }
      } catch (error) {
        if (error?.status === 500) {
          setSubmitLoading(false);

          return notify(error?.data?.error?.message, "error");
        }
      }
    }
    setTaskDiscription("");
    setOpen(false);
    dispatch(fetchTaskData());
    dispatch(fetchKanbanData());
    setSubmitLoading(false);
    reset();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(taskValidationSchema) });

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);

          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="cad-modal-blur"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: "95%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.titleText,
            boxShadow: 24,
            // p: 4,
          }}
          className="d-flex m-2 rounded-3 overflow-auto p-4  cad-shadow pb-5 flex-column gap-4 scroll-bar"
        >
          <div className="d-flex justify-content-between">
            <span className="cad-text-large text-primary cad-fw-600">
              Add task
            </span>
            <div className="d-flex justify-content-end align-items-center position-relative">
              <Switch
                size="small"
                checked={switchValue}
                onChange={handleSwitchChange}
              />
              <span
                style={{
                  right: -24,
                  backgroundColor: switchValue ? "#FD394A" : "#34A853",
                  width: "fit-content",
                  height: "100%",
                  // width: "90px",
                }}
                className="text-white rounded-2 ms-3 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
              >
                {switchValue ? "Private" : "Public"}
                <LocalOfferOutlinedIcon className="cad-text-large" />
              </span>
              <span
                onClick={() => setOpen(false)}
                className="action-hover ms-3 rounded-2 pointer text-primary"
              >
                <CloseIcon />
              </span>
            </div>
          </div>

          <div className="col-12">
            <p className="cad-text-medium cad-fw-600">
              Task Name <span className="text-danger">*</span>
            </p>

            <input
              type="text"
              style={{
                backgroundColor: "transparent",
                color: themeColorsObject.titleText,
              }}
              className={`form-control pointer cad-text-medium rounded-2 ${themeColorsObject.themed_input_class}`}
              id="exampleFormControlInput1"
              placeholder="Enter task name"
              // onChange={(e) => setTaskName(e.target.value)}
              {...register("title")}
            />
            {errors.title && (
              <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                {errors.title.message}
              </div>
            )}
          </div>

          <div>
            <div className="d-flex flex-column">
              <p className="cad-text-medium cad-fw-600">
                Task Type <span className="text-danger">*</span>
              </p>
              <Stack useFlexGap flexWrap="wrap" direction="row" spacing={1}>
                {taskType?.tasksType.map((item) => {
                  return (
                    <Chip
                      disabled={
                        // item.id === taskType?.tasksType[2].id ||
                        item.id === taskType?.tasksType[2].id ? true : false
                      }
                      key={item.id}
                      onClick={() => handleChipClick(item.id)}
                      style={{ width: "32%", display: item.id === taskType?.tasksType[2].id? "none" : ""}}
                      className={`m-0 p-3  cad-text-small border-primary text-secondary cad-fw-500 ${selectedChip === item.id
                        ? "chip-active border-primary cad-fw-500 text-white"
                        : ""
                        }`}
                      variant="outlined"
                      label={item.title}
                    />
                  );
                })}
              </Stack>
            </div>
          </div>

          <div className="row">
            <div className="col-4 mb-3">
              <p className="cad-text-medium cad-fw-600">
                Due Date <span className="text-danger">*</span>
              </p>

              <div
                className="input-group date col-3 pointer"
                data-provide="datepicker"
              >
                <input
                  type="date"
                  className={`form-control cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                  ref={datePickerRef} // Add a ref to the date picker input element
                  onChange={handleDueDateChange} // Call the handleDateChange function when the date changes
                  min={getCurrentDate()}
                  {...register("due_date")}
                />
                <div className="input-group-addon">
                  <span className="glyphicon glyphicon-th"></span>
                </div>
              </div>
              {errors.due_date && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.due_date.message}
                </div>
              )}
            </div>

            <div className="col-4 mb-3">
              <p className="cad-text-medium cad-fw-600">
                Priority <span className="text-danger">*</span>
              </p>
              <select
                className={`form-select pointer cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                aria-label="Default select example"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              >
                {priorityData?.taskPriorities.map((item) => (
                  <option
                    key={item.id}
                    className=" m-2 pointer"
                    value={item.id}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-4 mb-3">
              <p className="cad-text-medium cad-fw-600">
                Reminder <span className="text-danger">*</span>
              </p>

              <select
                className={`form-select pointer cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                aria-label="Default select example"
                value={reminder}
                onChange={(event) => setReminder(event.target.value)}
              >
                {reminderArrayy.map((item) => (
                  <option
                    key={item.id}
                    className="text-success pointer"
                    value={item.id}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 mb-3">
              <p className="cad-text-medium cad-fw-600">
                Owner <span className="text-danger">*</span>
              </p>

              {/* ====================[ HighLighted ]=================== */}

              <MuiSelector
                defaultValue={[]}
                options={empOptions}
                isMulti={true}
                setSelected={setSelectedOwner}
              />
            </div>

            <div className="col-12 mb-3">
              <p className="cad-text-medium cad-fw-600">Tags</p>

              <MuiSelector
                defaultValue={[]}
                options={tagsOptions}
                isMulti={true}
                setSelected={setSelectedTags}
              />
            </div>

            <div className="col-12 mb-3">
              <p className="cad-text-medium cad-fw-600">
                Assign Company <span className="text-danger">*</span>
              </p>
              <select
                className={`form-select pointer cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                aria-label="Default select example"
                value={assignCompany}
                onChange={(event) => setAssignCompany(event.target.value)}
              ><option
                key="none"
                className="text-success pointer text-primary"
                value={null}
              >
                  None
                </option>
                {comapnyOptions.map((item) => (
                  <option
                    key={item.id}
                    className="text-success pointer text-primary"
                    value={item.id}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 mb-3">
              <p className="cad-text-medium cad-fw-600">
                Assign Clients <span className="text-danger">*</span>
              </p>

              <MuiSelector
                defaultValue={[]}
                isMulti={true}
                options={assignCompany === null || assignCompany === "None" ? clientOptions : filteredClientOptions}
                setSelected={setSelectedClients}
              />
            </div>

            {selectedChip === taskType?.tasksType[0]?.id ? (
              <div className="col-12 mb-3">
                <p className="cad-text-medium cad-fw-600">
                  Select {taskType?.tasksType[0].title}{" "}
                  <span className="text-danger">*</span>
                </p>

                {/* <MuiSelector
                  defaultValue={[]}
                  isMulti={true}
                  options={selectForm}
                  setSelected={setSelectedForm}
                /> */}



                <select
                  className={`form-select pointer cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                  aria-label="Default select example"
                  value={null}
                  onChange={(event) => setSelectedForm(event.target.value)}
                ><option
                  key="none"
                  className="text-success pointer text-primary"
                  value={null}
                >
                    None
                  </option>
                  {selectForm.map((item) => (
                    <option
                      key={item.id}
                      className="text-success pointer text-primary"
                      value={item.id}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ""
            )}

            <div className="col-12">
              <p className="cad-text-medium cad-fw-600">
                Description <span className="text-danger">*</span>
              </p>
              <textarea
                className={`form-control pointer cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                id="exampleFormControlTextarea1"
                rows="3"
                // onChange={(e) => setTaskDiscription(e.target.value)}
                {...register("description")}
              />
              {errors.description && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.description.message}
                </div>
              )}
            </div>

            <div className=" justify-content-center row">
              <div className="col-3 mt-4">
                <Button
                  type="submit"
                  className="button-contained"
                  variant="contained"
                >
                  {submitLoading ? (
                    <CircularProgress
                      style={{ height: 24, width: 24 }}
                      color="inherit"
                    />
                  ) : selectedChip === taskType?.tasksType[0]?.id ? (
                    "Submit"
                  ) : (
                    "Next"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>

        {/* <Button onClick={handleOpen}>Open modal</Button> */}
      </Modal>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="cad-modal-blur"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            backgroundColor: "#fff",
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Dialogue box Content
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default AddTaskModal;
