
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AiOutlineDelete,
} from "react-icons/ai";
import { useDispatch } from "react-redux";
import { PiArchive } from "react-icons/pi";
import downloadCSV from "../../User/DownloadCSV";
import DateFormatter from "../../../Components/DateFormatter";
import UserProfile from "../../../Components/GlobalTable/UserProfile";
import { deleteClient, unarchiveClient } from "../../../service/client.service";
import ArchuvedClientsViewModal from "./ArchivedClientsViewModal";
import { fetchClient } from "../../../Redux/Slice/contactClientSlice";
import DpViewModal from "../../../Components/DpViewModal";
import TableData from "../../../Components/TableData";
import Refresher from "../../../Components/Refresher";
import { getArchiveClient } from "../../../Redux/Slice/contactArchiveClientSlice";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import AlertModal from "../../../Components/AlertModal";
import { fetchGlobalClient } from "../../../Redux/Slice/globalClientSlice";
import { pagination } from "../../../Constants/Constants";
import { notify } from "../../../App";
import ClientViewModal from "../ClientViewModal";

const Client = ({ themeColorsObject, theme }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [checkedRows, setCheckedRows] = useState({});
  const [openView, setOpenView] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [DpViewModalOpen, setDpViewModalOpen] = useState(false);
  const [DpUrl, setDpUrl] = useState("");
  const handleOpenDpViewModal = (url) => {
    setDpUrl(url);
    setDpViewModalOpen(true);
  };
  const handleCloseDpViewModal = () => setDpViewModalOpen(false);

  const dispatch = useDispatch();
  const handleViewOpen = (id) => {
    setOpenView(true);
  };

  useEffect(() => {
    // dispatch(getArchiveClientData())
  }, []);

  const handleViewClose = () => setOpenView(false);

  const { isLoading, clients } = useSelector((state) => state?.archiveClient);

  const row = clients?.data?.rows;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCheckboxChange = (id) => {
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [id]: !prevCheckedRows[id],
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to open the modal
  const openModalDelete = () => {
    setDeleteModalOpen(true);
    handleClose();
  };

  // Function to close the modal
  const closeModalDelete = () => {
    setDeleteModalOpen(false);
  };

  // Function to close the modal
  const closeModalArchive = () => {
    setArchiveModalOpen(false);
  };

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);


  const handleExportClick = () => {
    const selectedRowsData = clients?.rows?.filter(
      (row) => checkedRows[row.id]
    );

    if (selectedRowsData.length === 0) {
      notify();
      return;
    }

    // Call downloadCSV function with selectedRowsData
    downloadCSV(selectedRowsData, "selected_data.csv");
  };

  const openModalArchive = () => {
    setArchiveModalOpen(true);
    handleClose();
  };

  const handlePagination = (value) => {
    dispatch(
      fetchClient(pagination)
    );
  };

  const filteredTaskArray = row.filter((row) => {
    return (
      row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.email_address.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const extractedDataArray = filteredTaskArray.map((user) => {
    const extractedData = {
      id: user.id,
      checkBox: (
        <input
          style={{ width: 16 }}
          className="form-check-input pointer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          checked={!!checkedRows[user?.id]}
          onChange={() => handleCheckboxChange(user?.id)}
        />
      ),
      name: (
        <UserProfile
          proPic={user?.profile_photo}
          companyName={`${user?.first_name}
        ${user?.last_name}`}
        />
      ),
      email:
        user?.details?.primary_email_address === null
          ? "N/A"
          : user?.details?.primary_email_address,
      mobileNumber:
        user?.details?.mobile_number === null
          ? "N/A"
          : user?.details?.mobile_number,
      country:
        user?.details?.permanent_country === null
          ? "N/A"
          : user?.details?.permanent_country,
      city:
        user?.details?.permanent_city === null
          ? "N/A"
          : user?.details?.permanent_city,
      createdDate:
        mouseEntered && mouseEnteredId === user?.id ? (
          <div className="d-flex align-items-center justify-content-center">
            {/* <Link to={`/edit-task/${id}`}> */}
            {/* <AiOutlineEdit
          // onClick={handleEditOpen}
          style={{ fontSize: "20px", color: "#636e72" }}
        /> */}
            {/* </Link> */}

            {/* <Link to={`/view-task/${id}`}> */}
            {/* <span style={{ borderRadius: "18%" }} className="p-2 action-hover">
              <AiOutlineEye
                onClick={() => handleViewOpen(row?.id)}
                style={{ fontSize: "20px" }}
              />
            </span> */}
            {/* </Link> */}
            <span style={{ borderRadius: "18%" }} className="p-2 action-hover">
              <AiOutlineDelete
                onClick={() => openModalDelete(row?.id)}
                style={{ fontSize: "20px" }}
              />
            </span>

            <span style={{ borderRadius: "18%" }} className="p-2 action-hover">
              <PiArchive
                onClick={openModalArchive}
                style={{ fontSize: "20px" }}
              />
            </span>
          </div>
        ) : (
          <span className=" rounded-2 m-auto p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small">
            <DateFormatter inputDate={user?.createdAt} />
          </span>
        ),
    };

    return extractedData;
  });

  const cellConfig = [
    // {
    //   dataKey: "checkBox",
    //   width: "1%",
    //   align: "center",
    // },
    {
      dataKey: "name",
      width: "20%",
      align: "left",
    },
    {
      dataKey: "email",
      width: "20%",
      align: "center",
    },
    {
      dataKey: "mobileNumber",
      width: "15%",
      align: "center",
    },
    {
      dataKey: "country",
      width: "10%",
      align: "center",
    },
    {
      dataKey: "city",
      width: "10%",
      align: "center",
    },
    {
      dataKey: "createdDate",
      width: "24%",
      align: "center",
    },
  ];

  const tableHeadRowCell = [
    // {
    //   title: (
    //     <input
    // style={{ width: 16 }}
    // className="form-check-input pointer"
    // type="checkbox"
    // value=""
    // id="flexCheckDefault"
    // checked={!!checkedRows[user?.id]}
    // onChange={() => handleCheckboxChange(user?.id)}
    //     />
    //   ),
    //   width: "1%",
    //   align: "center",
    // },
    {
      title: "Name",
      width: "20%",
      align: "left",
    },
    {
      title: "Primary Email",
      width: "25%",
      align: "center",
    },
    {
      title: "Mobile Number",
      width: "15%",
      align: "center",
    },
    {
      title: "City",
      width: "10%",
      align: "center",
    },
    {
      title: "Country",
      width: "10%",
      align: "center",
    },
    {
      title: "Created Date",
      width: "24%",
      align: "center",
    },
  ];

  const handleDeleteClient = async () => {
    // console.log("hit deleted");
    try {
      // Call the deleteUser function and pass the user ID
      const response = await deleteClient(mouseEnteredId);

      if (response.status === 200) {
        // The user was successfully deleted, handle the success
        // console.log('User deleted successfully');
        dispatch(fetchClient())
        dispatch(getArchiveClient())
        closeModalDelete()
        notify("Client deleted succesfully")
      } else {
        // Handle errors or show a message to the user
        console.error('Failed to delete user');
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error deleting user', error);
    }

    dispatch(fetchClient());
    dispatch(fetchGlobalClient())

  };

  const handleUnArchiveClient = async () => {
    try {
      // Call the deleteUser function and pass the user ID
      const response = await unarchiveClient(mouseEnteredId);

      if (response.status === 200) {
        // The user was successfully deleted, handle the success
        // console.log("User deleted successfully");
        dispatch(fetchClient(pagination));
        dispatch(getArchiveClient(pagination));
        closeModalArchive();
        notify("Client unarchived succesfully")
      } else {
        // Handle errors or show a message to the user
        console.error("Failed to delete user");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error deleting user", error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between p-3 col-md-12">
        <div className="col-3">

          <SearchInput searchValue={searchTerm} searchFucntion={(e) => setSearchTerm(e.target.value)} />
        </div>
        <div className="d-flex gap-2">
          <Refresher isLoading={isLoading} handleRefresh={() => dispatch(getArchiveClient())} />
          {/* <SecondaryButton
            title="Export"
            padding="0.4rem 5rem"
            icon={<AiOutlineUpload size={16} />}
            fontSize="13px"
            onClick={handleExportClick}
          /> */}
          {/* <SecondaryButton
            title="Archive"
            padding="0.2rem 1.2rem"
            icon={<BsArchive size={14} />}
            fontSize="13px"
          /> */}
        </div>
      </div>

      <TableData
        tableHeadRowCell={tableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={cellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        handleViewOpen={handleViewOpen}
        totalPages={clients?.totalPages}
        handlePagination={handlePagination}
        isLoading={isLoading}
      />


      <ClientViewModal
        theme={theme}
        themeColorsObject={themeColorsObject}
        idu={mouseEnteredId}
        open={openView}
        handleClose={handleViewClose}
        handleOpenDpViewModal={handleOpenDpViewModal}
      />
      {/* <ArchuvedClientsViewModal
        themeColorsObject={themeColorsObject}
        idu={mouseEnteredId}
        open={openView}
        handleClose={handleViewClose}
        handleOpenDpViewModal={handleOpenDpViewModal}
      /> */}


      <DpViewModal
        DpViewModalOpen={DpViewModalOpen}
        handleCloseDpViewModal={handleCloseDpViewModal}
        DpUrl={DpUrl}
      />


      <AlertModal
        message="Are you sure you want to delete client?"
        closeModal={closeModalDelete}
        open={deleteModalOpen}
        handler={handleDeleteClient}
      />

      <AlertModal
        message="Are you sure you want to unarchive client?"
        closeModal={closeModalArchive}
        open={archiveModalOpen}
        handler={handleUnArchiveClient}
      />


    </div>
  );
};

export default Client;
