import React, { useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  CircularProgress,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import wordLogo from "../../Images/wordLogo.png";
import pdfLogo from "../../Images/pdfLogo.png";
import gallery from "../../Images/gallery.png";
import LastAcces from "../../Components/LastAcces";
import DateFormatter from "../../Components/DateFormatter";
import Tag from "../../Components/Tag/Tag";
import MainModal from "../../Components/MainModal";
import UserProfile from "../../Components/GlobalTable/UserProfile";
import { fetchTaskDataByID } from "../../Redux/Slice/taskSlice";
import { fetchSharedDocs } from "../../Redux/Slice/getSharedDocs";
import { useDispatch } from "react-redux";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { notify } from "../../App";
import GroupAvatars from "../../Components/Avatar/AvatarGroup";

export const formatDateStringMonth = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString);
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}`;
};

export function formatTime12Hour(dateString) {
  const date = new Date(dateString);

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, themeColorsObject, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewModal = ({
  open,
  handleClose,
  id,
  themeColorsObject,
  operationTaskId,
  setOpenShareDocumentModal,
  setOpenReciveDocumentModal,
  setOperationTaskId,
  setOpenFillFormModal,
}) => {
  const dispatch = useDispatch();
  const { taskById, isTaskByIdLoading } = useSelector((state) => state?.tasks);
  // console.log(taskById);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openFile = (filePath, fileType) => {
    if (fileType === "pdf") {
      // Open PDF directly
      // window.open(filePath, '_blank');
      return;
    } else if (fileType === "docx") {
      // Open Word document using Office Online Viewer
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        filePath
      )}`;
      window.open(officeViewerUrl, "_blank");
    } else {
      // Download other file types
      fetch(filePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filePath.split("/").pop()); // Use the file name from the URL
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error("There was a problem with the file download:", error);
        });
    }
  };

  const downloadFile = (filePath, fileName) => {
    // console.log(filePath, fileName);

    fetch(process.env.REACT_APP_DOWLOAD_URL + "/" + filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notify("Document downloaded");
        setTimeout(() => {
          openFile(
            process.env.REACT_APP_DOWLOAD_URL + "/" + filePath,
            filePath.split(".").pop()
          );
        }, 3000);
      })
      .catch((error) => {
        console.error("There was a problem with the file download:", error);
      });
  };

  const handleCheck = () => {
    dispatch(fetchTaskDataByID(id));
    dispatch(fetchSharedDocs(id));
    setOperationTaskId(id);
    // console.log(taskById?.type?.title);
    if (taskById?.type?.title === "Recevied Document") {
      setOpenReciveDocumentModal(true);
    } else if (taskById?.type?.title === "Shared Document") {
      setOpenShareDocumentModal(true);
    } else {
      setOpenFillFormModal(true);
    }
  };

  // const sortedTasks = [...taskById?.task_activities]?.sort((a, b) => {
  //   // Assuming createdAt is the property you want to sort by
  //   return new Date(b?.createdAt) - new Date(a?.createdAt);
  // });
  // // console.log(sortedTasks);

  return (
    <MainModal
      width={"60%"}
      open={open}
      handleClose={handleClose}
      title="View Task"
    >
      {/* <div className="d-flex align-items-center justify-content-end">
        <span
          style={{
            // top:10,
            right: -24,
            backgroundColor:
              taskById?.mode?.title === "public" ? "#34A853" : "#FD394A",
            height: "100%",
            width: "90px",
          }}
          className="text-white rounded-2 ms-5 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
        >
          {taskById?.mode?.title}
          <LocalOfferOutlinedIcon className="cad-text-large" />
        </span>
      </div> */}
      {isTaskByIdLoading ? (
        <CircularProgress className="m-auto" />
      ) : (
        <>
          <div className="d-flex  flex-wrap position-relative">
            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Task Name
              </div>
              <div className="cad-text-medium cad-fw-600 ">
                {taskById?.title}
              </div>
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2 ">
              <div className="cad-text-medium cad-text-light-grey cad-fw-500">
                Task type
              </div>
              <div className="cad-text-medium cad-fw-600 ">
                {taskById?.type?.title}
              </div>
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Due Date
              </div>
              <div className="cad-text-medium cad-fw-600 ">
                <DateFormatter inputDate={taskById?.due_date} />
              </div>
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Assigned Date
              </div>
              <div className="cad-text-medium  cad-fw-600 ">
                <DateFormatter inputDate={taskById?.createdAt} />
              </div>
            </div>

            {/* ===[ Status ]=== */}

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-text-light-grey cad-fw-500 col-4">
                Priority{" "}
              </div>
              <Chip
                style={{ fontWeight: "600", width: "100px" }}
                size="small"
                className={`${
                  taskById?.priority?.title === "Open"
                    ? "chip-low"
                    : taskById?.priority?.title === "Low"
                    ? "chip-low"
                    : taskById?.priority?.title === "High"
                    ? "chip-high"
                    : "chip-med"
                }`}
                label={
                  taskById?.priority?.title === "Open"
                    ? "Open"
                    : taskById?.priority?.title === "Low"
                    ? "Low"
                    : taskById?.priority?.title === "High"
                    ? "High"
                    : "Medium"
                }
              />
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-text-light-grey cad-fw-500">
                Status
              </div>
              <Chip
                style={{ fontWeight: "600", width: "100px" }}
                size="small"
                className={`${
                  taskById?.status?.title === "Open"
                    ? "chip-high"
                    : taskById?.status?.title === "On Hold"
                    ? "chip-high"
                    : taskById?.status?.title === "In Progress"
                    ? "chip-med"
                    : "chip-low"
                }`}
                label={
                  taskById?.status?.title === "Open"
                    ? "Open"
                    : taskById?.status?.title === "On Hold"
                    ? "On Hold"
                    : taskById?.status?.title === "In Progress"
                    ? "In Progress"
                    : "Completed"
                }
              />
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2 flex-wrap">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Task Mode
              </div>

              <span
                style={{
                  // top:10,
                  backgroundColor:
                    taskById?.mode?.title === "public" ? "#34A853" : "#FD394A",
                  width: "90px",
                }}
                className="text-white rounded-2 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
              >
                {taskById?.mode?.title}
                <LocalOfferOutlinedIcon className="cad-text-large" />
              </span>
            </div>

            <div
              style={{
                display:
                  taskById?.type?.title === "Fill Form" ? "none" : "flex",
              }}
              className="col-4 mb-4 flex-column gap-2 flex-wrap"
            >
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                {taskById?.type?.title === "Fill Form"
                  ? "Form you shared :"
                  : taskById?.type?.title === "Shared Document"
                  ? "Document you shared"
                  : "Requested Document"}
              </div>

              <Button
                variant="outlined"
                color="primary"
                style={{
                  width: "200px",
                  textTransform: "none",
                }}
                onClick={handleCheck}
                className=" p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
              >
                {taskById?.type?.title === "Fill Form"
                  ? "Form check"
                  : taskById?.type?.title === "Shared Document"
                  ? "Document check"
                  : "Document check"}
              </Button>
            </div>

            <div className="col-8 mb-4 d-flex flex-column gap-2 flex-wrap">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Tag
              </div>
              <div className="cad-text-medium d-flex gap-2 cad-text-dark flex-wrap cad-fw-600">
                {taskById?.tags?.map((tag, i) => (
                  <Tag key={i} title={tag?.title} />
                ))}
              </div>
            </div>
          </div>

          
          <div className="col-12 mb-4 d-flex flex-column gap-2 ">
              <div className="cad-text-medium cad-text-light-grey cad-fw-600 ">
                Owner
              </div>
              <div
                // key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >

               
<GroupAvatars data={taskById?.employees} />
              </div>
            </div>


          <div className="col-12 mb-4 d-flex align-items-start flex-column gap-2 flex-wrap">
            <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
              Clients
            </div>
            {/* <div className="cad-text-medium d-flex gap-2 cad-text-dark flex-wrap cad-fw-600">
              {taskById?.clients?.map((tag, i) => (
                <Tag key={i} title={tag?.first_name + " " + tag?.last_name} />
              ))}
            </div> */}

            
<GroupAvatars data={taskById?.clients} max={taskById?.clients?.length} />
          </div>


          <div className="w-100 h-auto">
            <Box
              className=" border-bottom "
              sx={{
                borderBottom: 0,
                padding: 0,
                width: "100%",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tags"
                style={{
                  height: "14px",
                }}
              >
                {/* Description Tab */}
                <Tooltip title="Description" placement="top-start">
                  <Tab
                    style={{
                      textTransform: "none",
                      display: "flex",
                      color: themeColorsObject.titleText,
                    }}
                    label="Description"
                    {...a11yProps(0)}
                  />
                </Tooltip>
                {/* Grid View Tab */}
                <Tooltip title="Activity" placement="top-start">
                  <Tab
                    style={{
                      textTransform: "none",
                      display: "flex",
                      color: themeColorsObject.titleText,
                    }}
                    label="Activity"
                    {...a11yProps(1)}
                  />
                </Tooltip>
                {/* Timeline View Tab */}
                <Tooltip title="Documents" placement="top-start">
                  <Tab
                    style={{
                      display: "flex",
                      textTransform: "none",
                      color: themeColorsObject.titleText,
                    }}
                    label="Documents"
                    {...a11yProps(1)}
                  />
                </Tooltip>

                {/* <Button className="btn btn-primary"> Add Tasks</Button> */}
              </Tabs>
            </Box>

            {/* Content for different tabs */}
            {/* List View Content */}
            <CustomTabPanel className="w-100" value={value} index={0}>
              <p className="cad-text-medium p-5 cad-fw-600">
                {taskById?.description}{" "}
              </p>
            </CustomTabPanel>
            {/* Grid View Content */}
            <CustomTabPanel className="w-100" value={value} index={1}>
              <div className="pb-4">
                {taskById?.task_activities?.map(
                  ({ id, createdAt, title, subtitle }) => (
                    <div key={id} className="d-flex flex-column gap-2 mt-3">
                      <div className="cad-text-large cad-fw-600">
                        {formatDateStringMonth(createdAt)}
                      </div>
                      <div className="d-flex gap-3 flex-start align-items-center">
                        <span
                          style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "#B8CFFF",
                          }}
                          className="text-white d-flex justify-content-center align-items-center rounded-5"
                        >
                          <EmailOutlinedIcon />
                        </span>
                        <span className="d-flex flex-column">
                          <span className="cad-text-medium cad-fw-500">
                            {title}
                          </span>
                          <span className="cad-text-small cad-fw-600 text-secondary">
                            {formatTime12Hour(createdAt)}
                          </span>
                        </span>
                      </div>

                      <span className="border-top mt-3" />
                    </div>
                  )
                )}
              </div>
            </CustomTabPanel>
            {/* Timeline View Content */}
            <CustomTabPanel value={value} index={2}>
              {/* {taskById?.type?.title === "Fill Form" ? "Fill form" : ""} */}

              <div className=" w-100 pt-5 pb-5 justify-content-center d-flex flex-wrap  gap-5">
                {isTaskByIdLoading ? (
                  <CircularProgress className="m-auto" />
                ) : taskById?.documents?.length > 0 ? (
                  taskById?.documents?.map((eachDoc) => {
                    return (
                      <div
                        key={eachDoc.id}
                        className="col-3 border rounded-3  gap-2 d-flex flex-column align-items-center"
                      >
                        <div className="p-3">
                          <img
                            src={
                              eachDoc?.mimetype.split("/").pop() === "msword"
                                ? wordLogo
                                : eachDoc?.mimetype.split("/").pop() ===
                                    "png" ||
                                  eachDoc?.mimetype.split("/").pop() ===
                                    "jpeg" ||
                                  eachDoc?.mimetype.split("/").pop() ===
                                    "jpg" ||
                                  eachDoc?.mimetype.split("/").pop() === "gif"
                                ? gallery
                                : pdfLogo
                            }
                            width={50}
                            alt="doc-img"
                          />
                        </div>

                        <div className=" p-2 border-top gap-1 w-100 d-flex flex-column">
                          <span className=" w-100 d-flex justify-content-between">
                            <div
                              style={{
                                whiteSpace: "nowrap",
                              }}
                              className="w-75 overflow-hidden"
                            >
                              <span
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                                className="text-right cad-text-small cad-fw-500 text-primary text-break"
                              >
                                {eachDoc?.filename}
                              </span>
                            </div>

                            <span
                              onClick={() =>
                                downloadFile(eachDoc?.path, eachDoc?.filename)
                              }
                              className="w-25 text-primary text-end "
                            >
                              <FileDownloadOutlinedIcon className="cad-text-xlarge pointer action-hover rounded-2" />
                            </span>
                          </span>

                          <span
                            style={{
                              textOverflow: "ellipsis",
                            }}
                            className="cad-text-small cad-fw-500 text-secondary text-break"
                          >
                            Last access :{" "}
                            <LastAcces timestamp={eachDoc?.updatedAt} />
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="w-100 h-100 d-flex justify-content-center text-primary align-items-center">
                    <Alert severity="warning">
                      <AlertTitle sx={{ fontWeight: 700 }}>
                        Missing Documents
                      </AlertTitle>
                      Neither the client nor our employee has attached any
                      documents to this task yet. Please ensure all required
                      documents are uploaded and attached as soon as possible to
                      avoid any delays.
                    </Alert>
                  </div>
                )}
              </div>
            </CustomTabPanel>
          </div>
        </>
      )}
    </MainModal>
  );
};

export default ViewModal;
