import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import "./AddTask.css";
import { Stack } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useRef, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Textarea from "@mui/joy/Textarea";
// import { MuiChipsInput } from "mui-chips-input";
import SelectChip from "react-select";
import makeAnimated from "react-select/animated";
import PriorityChipsDropdown from "../../Components/PriorityChipsDropdown/PriorityChipsDropdown";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Footer from "../../Components/Footer/Footer";
import MuiAlert from "@mui/material/Alert";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import tagsSlice, { fetchTag } from "../../Redux/Slice/tagsSlice";
import { getAllUsers } from "../../Redux/Slice/getAllUsers";
import { fetchTaskType } from "../../Redux/Slice/taskTypeSlice";
import { fetchReminderData } from "../../Redux/Slice/reminderSlice";
import { fetchPermissionData } from "../../Redux/Slice/permissionSlice";
import { fetchTaskPriorityData } from "../../Redux/Slice/taskPrioritySlice";
import { createTask } from "../../service/task.service";
import { fetchGlobalUser } from "../../Redux/Slice/globalUserSlice";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import {
  fetchGlobalClient,
  updateClients,
} from "../../Redux/Slice/globalClientSlice";
import { fetchGlobalCompany } from "../../Redux/Slice/globalCompanySlice";
import chroma from "chroma-js";
import Selector from "../../Components/Select";
import { fetchGlobalTags } from "../../Redux/Slice/globlalTags";
import ViewModal from "../../Components/ViewModal";
import { fetchTaskData } from "../../Redux/Slice/taskSlice";
import { fetchKanbanData } from "../../Redux/Slice/kanbanSlice";
import { notify } from "../../App";

const animatedComponents = makeAnimated();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toaster = ({ open, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom", // You can also use 'top'
        horizontal: "right", // You can also use 'left', 'center', or 'right'
      }}
      open={open}
      autoHideDuration={500}
    >
      <Alert severity="info">Please select task type!</Alert>
    </Snackbar>
  );
};

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...(styles[":active"] || {}),
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const AddTask = () => {
  const [selectedChip, setSelectedChip] = useState("");
  const [openToast, setOpenToast] = React.useState(false);
  const [taskName, setTaskName] = React.useState("");
  const [selectedDueDate, setSelectedDueDate] = useState("");
  const datePickerRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedOwners, setSelectedOwner] = useState([]);
  const [assignCompany, setAssignCompany] = useState([]);
  const [switchValue, setSwitchValue] = useState(false);
  const [switchMode, setSwitchMode] = useState(null);
  const tags = useSelector((state) => state.globalTags);
  // // console.log("global tagsss",globleTags);
  // const owners = useSelector((state) => state?.allUserData?.users?.data?.rows);
  const client = useSelector((state) => state.globalClient);
  const reminderArray = useSelector((state) => state?.reminders);
  const permission = useSelector((state) => state?.permission);
  const priorityData = useSelector((state) => state?.priority);
  const employee = useSelector((state) => state?.globalUser.globalUsers);
  const company = useSelector((state) => state?.globalCompany.globalCompany);
  const taskType = useSelector((state) => state?.taskTypeData);
  const {modes} = useSelector((state) => state?.globalModes);

  // // console.log(modes[0].title);



const [reminder, setReminder] = useState(
  reminderArray?.remindersData[3]?.id
);
const [permissionInput, setPermissionInput] = useState(
  permission.permissions[2]?.id
);
const [taskDiscription, setTaskDiscription] = useState("");
const [priority, setPriority] = useState(
  priorityData?.taskPriorities[0]?.id
);


// // console.log(permission.permissions[2]?.title);


  // ===============================================================

  const [openView, setOpenView] = React.useState(true);
  const handleViewOpen = () => setOpenView(true);
  const handleViewClose = () => setOpenView(false);

  // ================================================================

  const dispatch = useDispatch();

  useEffect(() => {
    
    dispatch(fetchGlobalTags());
    setSwitchMode(
      switchValue
        ? modes[0].id // private mode
        : modes[2].id // public mode
    );
  }, []);

  // // console.log(selectedClients);

  const [formData, setFormData] = useState({
    taskName: "",
    dueDate: "",
    priority: "",
    permission: "",
    reminder: "",
    owners: [],
    tags: [],
    description: "",
  });

  const tagsOptions = tags?.rows.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.title,
      color: "#0d6efd",
    };
  });

  const empOptions = employee?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name,
      color: "#0d6efd",
      isFixed: false,
    };
  });

  const clientFilter = () => {
    // dispatch(updateClients(client));
  };

  // // console.log("client redux",client.clients);

  const clientOptions = client?.clients?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name,
      color: "#0d6efd",
      isFixed: false,
    };
  });

  // // console.log(clientOptions);

  const comapnyOptions = company.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.company_name,
      color: "#fa983a",
      isFixed: false,
      clients: item.contacts,
    };
  });

  const reminderArrayy = reminderArray?.remindersData.map((item) => {
    return {
      id: item.id,
      title: item.title,
    };
  });

  const handleDueDateChange = () => {
    // Access the selected date using datePickerRef.current.value
    const selectedDateValue = datePickerRef.current.value;
    setSelectedDueDate(selectedDateValue);
  };


  const handleClose = () => {
    setOpenToast(false);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    // // console.log(permissionInput);

    const formValues = {
      title: taskName,
      due_date: selectedDueDate,
      description: taskDiscription,
      priority_xid: priority,
      permission_xid: permissionInput,
      reminder_set_xid: reminder,
      tags: selectedTags.map((item) => item.id),
      type_xid: selectedChip,
      employee_xid: selectedOwners[0]?.id, //user
      mode_xid: switchMode,
      clients: selectedClients.map((item) => item.id),
    };

    // const response = await createTask(formValues);
    // // console.log(response.data);


    try {
      const response = await createTask(formValues);

      if (response.status == 400) {
        setSubmitLoading(false);
        // // console.log(response);
        notify();
      } else if (response.status == 201) {
        setSubmitLoading(false);
        // console.log(response);
        switch (selectedChip) {
          case taskType?.tasksType[3].id:
            navigate("/other-form/");
            break;
          case taskType?.tasksType[0].id:
            navigate(`/share-documents/${response.data.id}`);
            break;
          case taskType?.tasksType[1].id:
            navigate("/sign-docs");
            break;
          case taskType?.tasksType[2].id:
            navigate(`/request-documents/${response.data.id}`);
            break;
          default:
            toast.info("Please select task type !");
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }

    let pagination = {
      size: 10,
      page: 1,
    };

    dispatch(fetchTaskData(pagination));
    dispatch(fetchKanbanData());

  };

  const handleChipClick = (chipLabel) => {
    setSelectedChip(chipLabel);
  };

  const handleSelectChange = (selectedTags) => {
    if (Array.isArray(selectedTags)) {
      setSelectedTags(selectedTags); // Update the state with selected values
    } else {
      console.error("Invalid selected options:", selectedTags);
    }
  };

  const onCompanyChange = (select) => {
    setAssignCompany(select);

    clientFilter();
  };

  const [submitLoading, setSubmitLoading] = useState(false);



  const handleSwitchChange = (event) => {
    setSwitchValue(event.target.checked);
    setSwitchMode(
      event.target.checked
        ? modes[0].id // private mode
        : modes[2].id // public mode
    );
  };


  // console.log(switchMode);

  function getCurrentDate() {
    const today = new Date();
    let month = "" + (today.getMonth() + 1);
    let day = "" + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <div className="overflow-auto m-2  h-100">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <form
        onSubmit={handleSubmit}
        className="d-flex m-2 rounded-3 overflow-auto  bg-white ps-5 pe-5  p-4  cad-shadow pb-5 flex-column gap-4"
      >
        <div className="d-flex justify-content-end align-items-center position-relative">
          <Link
            to="/task"
            style={{ left: -24 }}
            className="text-primary h6 text-decoration-none d-flex align-items-center gap-3 pointer top-0 position-absolute"
          >
            <ArrowBackRoundedIcon /> Add Task
          </Link>

          <Switch
            size="small"
            checked={switchValue}
            onChange={handleSwitchChange}
          />

          <span
            style={{
              right: -24,
              backgroundColor: switchValue ? "#FD394A" : "#34A853",
              width: "fit-content",
              height: "100%",
              width: "90px",
            }}
            className="text-white rounded-2 ms-3 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
          >
            {switchValue ? "Private" : "Public"}
            <LocalOfferOutlinedIcon className="cad-text-large" />
          </span>
        </div>

        <div className="col-6">
          <p className="cad-text-medium cad-fw-600">Task Name</p>

          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter task name"
            onChange={(e) => setTaskName(e.target.value)}
          />
        </div>

        <div>
          <div className="d-flex flex-column">
            <p className="cad-text-medium cad-fw-600">Task Type</p>
            <Stack useFlexGap flexWrap="wrap" direction="row" spacing={1}>
              {taskType?.tasksType.map((item) => {
                return (
                  <Chip
                    disabled={
                      // item.id === taskType?.tasksType[1].id ||
                      item.id === taskType?.tasksType[3].id
                        ? true
                        : false
                    }
                    key={item.id}
                    onClick={() => handleChipClick(item.id)}
                    className={`m-1 p-3 cad-text-medium border-primary text-secondary cad-fw-500 ${
                      selectedChip === item.id
                        ? "chip-active border-primary cad-fw-500 text-white"
                        : ""
                    }`}
                    variant="outlined"
                    label={item.title}
                  />
                );
              })}
            </Stack>
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <p className="cad-text-medium cad-fw-600">Due Date</p>

            <div
              className="input-group date col-3 pointer"
              data-provide="datepicker"
            >
              <input
                type="date"
                className="form-control rounded-2"
                ref={datePickerRef} // Add a ref to the date picker input element
                onChange={handleDueDateChange} // Call the handleDateChange function when the date changes
                min={getCurrentDate()}
              />
              <div className="input-group-addon">
                <span className="glyphicon glyphicon-th"></span>
              </div>
            </div>
          </div>

          <div className="col-3">
            <p className="cad-text-medium cad-fw-600">Priority</p>
            <select
              className="form-select pointer"
              aria-label="Default select example"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              {priorityData?.taskPriorities.map((item) => (
                <option
                  key={item.id}
                  className="text-success pointer"
                  value={item.id}
                >
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          <div className="col-3">
            <p className="cad-text-medium cad-fw-600">Permission</p>

            <select
              className="form-select pointer"
              aria-label="Default select example"
              value={permissionInput}
              onChange={(event) => setPermissionInput(event.target.value)}
            >
              {permission?.permissions.map((item) => (
                <option
                  key={item.id}
                  className="text-success pointer"
                  value={item.id}
                >
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          <div className="col-3">
            <p className="cad-text-medium cad-fw-600">Reminder</p>

            <select
              className="form-select pointer"
              aria-label="Default select example"
              value={reminder}
              onChange={(event) => setReminder(event.target.value)}
            >
              {reminderArrayy.map((item) => (
                <option
                  key={item.id}
                  className="text-success pointer"
                  value={item.id}
                >
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          <div className="col-6 mt-4">
            <p className="cad-text-medium cad-fw-600">Assign By</p>

            {/* ====================[ HighLighted ]=================== */}
            <Selector
              defaultValue={[]}
              isMulti={true}
              options={empOptions}
              setSelected={setSelectedOwner}
            />
          </div>

          <div className="col-6 mt-4">
            <p className="cad-text-medium cad-fw-600">Tags</p>

            <Selector
              defaultValue={[]}
              isMulti={true}
              options={tagsOptions}
              setSelected={setSelectedTags}
            />
          </div>

          <div className="col-6 mt-4">
            <p className="cad-text-medium cad-fw-600">Assign Company</p>

            <Selector
              defaultValue={[]}
              isMulti={false}
              options={comapnyOptions}
              setSelected={onCompanyChange}
            />
          </div>

          <div className="col-6 mt-4">
            <p className="cad-text-medium cad-fw-600">There Clients</p>

            <Selector
              defaultValue={[]}
              isMulti={true}
              options={clientOptions}
              setSelected={setSelectedClients}
            />
          </div>

          <div className="col-12 mt-4">
            <p className="cad-text-medium cad-fw-600">Description</p>

            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              value={taskDiscription}
              onChange={(e) => setTaskDiscription(e.target.value)}
            />
          </div>
        </div>

        <div className=" justify-content-center row mt-3">
          <div className="col-3">
            <Button
              type="submit"
              className="button-contained"
              variant="contained"
            >
              {submitLoading ? (
                <CircularProgress
                  style={{ height: 24, width: 24 }}
                  color="inherit"
                />
              ) : (
                "NEXT"
              )}
            </Button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default AddTask;
