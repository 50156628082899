import React, { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress } from "@mui/material";
import { forgotPasswordCodes } from "../../Utils/StatusCode/index";
import { login } from "../../service/auth.service";
import { useNavigate } from "react-router-dom";

const PasswordField = ({
  setMessage,
  setStatus,
  setOpen,
  email,
  setAlertType,
  setForgotPassword,
  setAuthorized
}) => {
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);

  const navigate = useNavigate();

  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must contain atleast one uppercase")
      .matches(/[a-z]/, "Password must contain atleast one lowercase")
      .matches(/[@#&]/, "Password must contain special character @,#,&")
      .min(8, "password must be at least 8 characters"),
  });

  const {
    register: passwordRegister,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passErrors }, // Changed from passwordFormState to formState
    reset: resetPassword,
  } = useForm({ mode: "all", resolver: yupResolver(passwordSchema) });

  const onConfirmPasswordSubmit = async (value) => {
    setIsPasswordLoading(true);
    const credentials = {
      email_address: email,
      password: value.password,
    };

    // console.log(credentials);
    const { status } = await login(credentials);

    // console.log(status);

    if (status === forgotPasswordCodes.STATUS_SUCCESS) {
      setAlertType("success");
      setIsPasswordLoading(false);
      setMessage("Login Succesfull");
      setStatus(forgotPasswordCodes.STATUS_SUCCESS);
      setOpen(true);
      navigate("/splash-screen");
      resetPassword();
    } else if (status === 401) {
      // console.log("wrong pass");
      setIsPasswordLoading(false);
      setStatus(status);
      setAlertType("error");
      setMessage("Wrong password");
      setOpen(true);
      resetPassword();
    }
  };

  return (
    <form
      onSubmit={handlePasswordSubmit(onConfirmPasswordSubmit)}
      className={`d-flex flex-column  justify-content-start h-100`}
    >
      <div className="mb-2">
        <label htmlFor="createPassword1" className="form-label text-primary">
          Enter password
        </label>
        <input
          maxLength={16} // Set the maximum character limit to 16 characters
          type="password"
          className="form-control p-4 ps-3 border-primary"
          id="createPassword1"
          placeholder="Enter password"
          {...passwordRegister("password")}
        />
        <label
          htmlFor="exampleInputPassword1"
          className="form-label text-primary form-text text-danger"
        >
          {passErrors.password?.message}
        </label>
      </div>

      <Button
        type="submit"
        className={`${
          isPasswordLoading ? "opacity-75" : ""
        } btn pt-2 fs-5 cad-fw-700 pb-2 button-contained`}
      >
        {isPasswordLoading ? (
          <CircularProgress size={36} color="inherit" />
        ) : (
          "Login"
        )}
      </Button>
    </form>
  );
};

export default PasswordField;
