import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import DateFormatter from "../../../Components/DateFormatter";
import UserProfile, { stringAvatar } from "../../../Components/GlobalTable/UserProfile";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  updateCompany,
} from "../../../service/company.service";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { fetchGlobalCompany } from "../../../Redux/Slice/globalCompanySlice";
import { notify } from "../../../App";
import { getCompanyData } from "../../../Redux/Slice/company.service.slice";
import { fetchGlobalCompanyByID } from "../../../Redux/Slice/getCompanyById";
import MuiSelector from "../../../Components/MuiSelector";
import AlertModal from "../../../Components/AlertModal";

const CompanyDetailsEditModal = ({
  closeModal,
  open,
  userId,
  handleOpenDpViewModal,
}) => {
  const dispatch = useDispatch();
  const { themeColorsObject } = useSelector((state) => state.theme);
  const { companyById, isLoading } = useSelector((state) => state?.getCompanyById);
  const client = useSelector((state) => state.globalClient);
  const tags = useSelector((state) => state.globalTags);

  const [selectedClients, setSelectedClients] = useState(client?.clients)
  const [selectedTags, setSelectedTags] = useState(client?.tags)
  const [isEditLoading, setEditLoading] = useState(false);
  const data = companyById?.data;

  const fileURL = (path) => {
    return new URL(process.env.REACT_APP_DOWLOAD_URL + "/" + path).toString();
  };
  const [previewImage, setPreviewImage] = useState(data?.profile_photo === null
    ? ""
    : fileURL(data?.profile_photo));

  const companyUpdateSchema = yup.object().shape({
    company_name: yup.string(),
    industry: yup.string(),
    country: yup.string(),
    city: yup.string(),
    postal_code: yup.string(),
    web_address: yup.string(),
    email_address: yup
      .string()
      .email()
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email address is required"
      ),
    phone_number: yup
      .string(),
    profile_photo: yup.mixed().test(
      "fileType",
      "Invalid file type",
      (value) => {
        if (!value || value.length === 0) {
          setPreviewImage(""); // Clear the preview when no file is selected
          return true; // Allow empty value (no file selected)
        }

        if (value[0].type.startsWith("image/")) {
          // Set the previewImage state with the selected image
          setPreviewImage(URL.createObjectURL(value[0]));
          return true; // Valid image file
        } else {
          setPreviewImage(""); // Clear the preview when an invalid file is selected
          return false; // Invalid file type
        }
      }
    ),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(companyUpdateSchema),
  });

  useEffect(() => {
    console.log(data);
    if (data) {
      data?.company_name && setValue("company_name", data?.company_name ?? "");
      data?.industry && setValue("industry", data?.industry ?? "");
      data?.email_address &&
        setValue("email_address", data?.email_address ?? "");
      data?.phone_number && setValue("phone_number", data?.phone_number ?? "");
      data?.city && setValue("city", data?.city ?? "");
      //   setValue("state", data?.state ?? "");
      data?.country && setValue("country", data?.country ?? "");
      data?.web_address && setValue("web_address", data?.web_address ?? "");
      data?.postal_code && setValue("postal_code", data?.postal_code ?? "");
    }
  }, [data, setValue]);

  const companyInformation = [
    {
      label: "Company name",
      value: data?.company_name,
    },
    // {
    //   label: "Phone Number",
    //   value: data?.phone_number ? data?.phone_number : "N/A",
    // },
    {
      label: "Industry",
      value: data?.industry ? data?.industry : "N/A",
    },
    {
      label: "Country",
      value: data?.country ? data?.country : "N/A",
    },
    {
      label: "City",
      value: data?.city ? data?.city : "N/A",
    },
    {
      label: "Postal code",
      value: data?.postal_code ? data?.postal_code : "N/A",
    },
    {
      label: "Web Address",
      value: data?.web_address ? data?.web_address : "N/A",
    },
    {
      label: "Email Address",
      value: data?.email_address,
    },
    {
      label: "Phone number",
      value: data?.phone_number ? data?.phone_number : "N/A",
    },
    {
      label: "Clients",
      value: data?.email_address,
      options: client?.clients?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.first_name,
          color: "#0d6efd",
          isFixed: false,
        };
      }),
      default: data?.clients?.map((item) => {
        return {
          id: item.id,
          value: item.first_name,
          label: item.first_name,
          color: "#0d6efd",
        };
      })
    },
    {
      label: "Tags",
      value: data?.email_address,
      options: tags?.rows?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.title,
          color: "#0d6efd",
          isFixed: false,
        };
      }),
      default: data?.tags?.map((item) => {
        return {
          id: item.id,
          value: item.title,
          label: item.title,
          color: "#0d6efd",
        };
      })
    },
  ];

  // console.log(companyInformation);



  const moreInformation = [
    {
      label: "Created Date",
      value: <DateFormatter inputDate={data?.createdAt} />,
    },
    {
      label: "Created By",
      value: data?.creator?.first_name ? (
        <UserProfile
          proPic={data?.creator?.profile_photo}
          companyName={`${data?.creator?.first_name}
    ${data?.creator?.last_name}`}
        />
      ) : (
        "N/A"
      ),
    },
    // {
    //   label: "Tax ID",
    //   value: data?.tax_id ? data?.tax_id : "N/A",
    // },
    // {
    //   label: "Bussiness Registration No/ID",
    //   value: data?.tax_id ? data?.tax_id : "N/A",
    // }
  ];

  const onSubmit = (data) => async () => {
    setEditLoading(true)
    const cleanedData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== "")
    );

    try {
      const { status } = await updateCompany({ ...cleanedData, contacts: selectedClients.map((item) => item?.id), tags: selectedTags.map((item) => item?.id) }, userId);
      console.log(status);

      if (status === 200) {
        dispatch(fetchGlobalCompanyByID(userId));
        dispatch(fetchGlobalCompany());
        dispatch(getCompanyData());
        setEditLoading(false)
        // handleClose();
        setOpenAlert(false)
        setFormData(undefined)
        notify(`Updated successfully`);
        closeModal();
      } else if (status === 400) {
        setEditLoading(false)
        closeModal();
      }
    } catch (error) {
      setEditLoading(false)
      notify(error?.data?.message, "error");
    }
  };




  const [openAlert, setOpenAlert] = useState(false);
  const [formData, setFormData] = useState({} || undefined);
  const handleAlert = (data) => {
    setOpenAlert(true);
    setFormData(data);
  };



  return (
    <MainModal
      title={"Edit Company Details"}
      height={"90%"}
      width={"60%"}
      open={open}
      handleClose={() => {
        // reset();
        closeModal();
      }}
    >


      <AlertModal
        message="Are you sure you want to update this data?"
        handler={onSubmit(formData)}
        open={openAlert}
        closeModal={() => setOpenAlert(false)}
      />
      <form onSubmit={handleSubmit(handleAlert)} className="h-100">
        {isLoading ? (
          <div className="d-flex w-100 h-100 p-5 justify-content-center align-items-center ">
            <CircularProgress className="m-5" />
          </div>
        ) : (
          <div className=" pb-4">
            {/* ===========[ border ]=============== */}
            <div className="col-12 border-bottom ps-3 pe-3 mb-3 pb-3 d-flex justify-content-between align-items-center">
              <span className="position-relative   d-flex justify-content-start gap-4 align-items-center">
                <Avatar
                  onClick={() => handleOpenDpViewModal(data?.profile_photo)}
                  style={{ width: 80, height: 80, cursor: "pointer" }}
                  alt={data?.company_name}
                  src={
                    data?.profile_photo === null
                      ? ""
                      : fileURL(data?.profile_photo)
                  }

                  {...stringAvatar(`${data?.company_name === undefined ? "N/A" : data?.company_name} `)}
                />
                <input
                  type="file"
                  id="profile-image-input"
                  accept="image/*"
                  style={{
                    display: "none",
                  }}
                  {...register("profile_photo")}
                />
                <label
                  style={{
                    position: "absolute",
                    bottom: "-0rem",
                    left: "4rem",
                    height: "30px",
                    width: "30px",
                    backgroundColor: themeColorsObject.backgroundColor,
                    padding: "4px",
                    borderRadius: "50%",
                    cursor: "pointer",


                    display: "none"
                  }}
                  htmlFor="profile-image-input"
                >
                  <CameraAltTwoToneIcon
                    sx={{
                      position: "absolute",
                      bottom: "0rem",
                      right: "0rem",
                      height: "30px",
                      width: "30px",
                      backgroundColor: themeColorsObject.backgroundColor,
                      color: themeColorsObject.textColor,
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",



                      display: "none"
                    }}
                  />
                </label>
                <div className="d-flex flex-column gap-1 position-relative">
                  <span className="cad-text-large cad-fw-600 ">
                    {data?.company_name}
                  </span>
                  <span className="cad-text-medium gap-2 d-flex cad-fw-500">
                    <span> Mob: {data?.phone_number}</span>
                  </span>

                  <span
                    style={{
                      right: "-36px",
                      bottom: "26px",
                    }}
                    className="position-absolute"
                  ></span>
                </div>
              </span>
            </div>

            <div>
              <h6>Company Information</h6>
            </div>
            <div className="d-flex flex-wrap mt-3">
              {companyInformation.map((item, key) => {

                switch (item?.label) {
                  case "Clients":
                    return <div
                      key={key}
                      className="col-12 d-flx align-items-center mb-4 gap-2"
                    >
                      <div className="col-12 mb-2">
                        <label
                          style={{ color: themeColorsObject.titleText }}
                          className="cad-text-medium w-100  cad-fw-500"
                        >
                          {item.label}
                        </label>
                      </div>
                      <div className="cad-text-medium w-100  cad-fw-600 ">
                        <MuiSelector
                          defaultValue={item?.default}
                          isMulti={true}
                          options={item?.options}
                          setSelected={setSelectedClients}
                        />

                      </div>
                    </div>

                  case "Tags":
                    return <div
                      key={key}
                      className="col-12 d-flx align-items-center mb-4 gap-2"
                    >
                      <div className="col-12 mb-2">
                        <label
                          style={{ color: themeColorsObject.titleText }}
                          className="cad-text-medium w-100  cad-fw-500"
                        >
                          Tags
                        </label>
                      </div>
                      <div className="cad-text-medium w-100  cad-fw-600 ">
                        <MuiSelector
                          defaultValue={item?.default}
                          isMulti={true}
                          options={item?.options}
                          setSelected={setSelectedTags}
                        />

                      </div>
                    </div>

                  default: return (
                    <div
                      key={key}
                      className="col-4 d-flx align-items-center mb-4 gap-2"
                    >
                      <div className="col-12">
                        <label
                          style={{ color: themeColorsObject.titleText }}
                          className="cad-text-medium w-100  cad-fw-500"
                        >
                          {item.label}
                        </label>
                      </div>
                      <div className="cad-text-medium w-100  cad-fw-600 ">
                        {/* <input className="cad-text-medium  form-control cad-fw-600" value={data}  /> */}
                        <input
                          className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
                          {...register(
                            item?.label?.toLowerCase().replace(" ", "_")
                          )}
                        />
                        {errors[item?.label?.toLowerCase().replace(" ", "_")] && (
                          <span className="error-message cad-text-small text-danger">
                            {
                              errors[item?.label?.toLowerCase().replace(" ", "_")]
                                .message
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }

              })}
            </div>

            <hr />
            <div>
              <h6>More Information</h6>
            </div>
            <div className="d-flex flex-wrap mt-3">
              {moreInformation.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="col-4 d-flx align-items-center mb-4 gap-2"
                  >
                    <div className="col-12">
                      <label
                        style={{ color: themeColorsObject.titleText }}
                        className="cad-text-medium w-100  cad-fw-500"
                      >
                        {item.label}
                      </label>
                    </div>
                    <div className="col-12">
                      <label
                        style={{ color: themeColorsObject.textColor }}
                        className="cad-text-medium w-100 p-1   cad-fw-600"
                      >
                        {item.value}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="col-12 d-flex justify-content-center">
              {/* <PrimaryButton
              variant="contained"
              title="Save"
              padding="0.2rem 1.2rem"
              fontSize="14px"
            //   onClick={closeModal}
              width="160px"
            /> */}
              <div className="col-4">
                <Button className="button-contained" type="submit">
                  {isEditLoading ? "Loading..." : "Save"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
    </MainModal>
  );
};

export default CompanyDetailsEditModal;
