import React, { useEffect } from "react";
import payemntSuccessVideo from "../../Images/Payment.mp4";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchPaymentHistory } from "../../Redux/Slice/paymentSelice";
import { useDispatch } from "react-redux";
import { pagination } from "../../Constants/Constants";
import CloseIcon from '@mui/icons-material/Close';

const PaymentFailed = () => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
      dispatch(fetchPaymentHistory(pagination))
          setTimeout(() => {
            navigate("/");
          }, 3000);
      }, []);


  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
      }}
    >
      <span
        className="rounded-4 overflow-hidden bg-danger d-flex justify-content-center align-items-center flex-column gap-4"
        style={{
          width: 400,
          height: 250,
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }}
      >
        <span style={{
            borderRadius:"50%",
            border:"5px solid #fff",
            padding:4

        }}>
        <CloseIcon style={{fontSize:"80px", color:"#fff"}}  /></span>
        <h3 className="fw-bold text-white">PaymentFailed</h3>
      </span>

      <span
        style={{
          borderStartStartRadius: "40%",
          borderBottomLeftRadius: "40%",
          position: "absolute",
          bottom: 12,
        }}
        className="overflow-hidden d-flex"
      >
        <span
          className="cad-fw-700 d-flex align-items-end"
          style={{
            color: "#4CCD8D",
            fontSize: "22px",
            transition: "0.5s",
          }}
        >
          Cloudify
        </span>
        <span
          className="text-primary d-flex align-items-end cad-fw-500"
          style={{
            fontSize: "20px",
            transition: "0.8s",
          }}
        >
          Docs
        </span>
      </span>
    </div>
  );
};

export default PaymentFailed;
