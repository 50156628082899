import api from "./api.service";
import TokenService from "./token.service";

export const emailCheck = async (data) => {
  try {
    const user = await api.post(`/auth/public/email-verification`, data);
    return user;
  } catch (error) {
    throw error;
  }
};

export const authMeService = async () => {
  try {
    return await api.get(`/auth/me`);
  } catch (error) {
    throw error.response;
  }
};

export const registrastion = async (data) => {
  try {
    const user = await api.post(`/auth/agent/register/${data?.token}`, data);
    return user;
  } catch (error) {
    throw error;
  }
};

export const ssoGoogleLogin = async (data) => {
  try {
    const user = await api.post(`/auth/agent/SSO/register`, data);
    return user;
  } catch (error) {
    throw error?.response;
  }
};

export const forgotPassword = async (data) => {
  try {
    const user = await api.post(`/auth/public/forgetPassword`, data);
    return user;
  } catch (error) {
    throw error;
  }
};

export const generateNewPassword = async (data, id, token) => {
  try {
    const user = await api.post(
      `/auth/public/resetPassword/${id}/${token}`,
      data
    );
    return user;
  } catch (error) {
    throw error;
  }
};

export const login = async (data) => {
  try {
    const user = await api.post(`/auth/agent/login`, data);
    if (user.status === 200) {
      TokenService.setUser(user.data);
    }
    return user;
  } catch (error) {
    return error.response;
  }
};
export const auth_login = async (data) => {
  try {
    const user = await api.post(`/auth/agent/login`, data);
    if (user.status === 200) {
      TokenService.setUser(user.data);
    }
    return user;
  } catch (error) {
    throw error;
  }
};


export const changePassword = async (data) => {
  try {
    const user = await api.post(`/auth/agent/changePassword`, data);
    return user;
  } catch (error) {
    throw error;
  }
};



export const google_login = async (data) => {
  try {
    const user = await api.post(`/auth/agent/SSO/login`, data);
    if (user.status === 200) {
      TokenService.setUser(user.data);
    }
    return user;
  } catch (error) {
    throw error;
  }
};

export const ReGenrateAccessToken = async (data) => {
  try {
    const result = await api.post(`/auth/agent/token`, data);
    return result;
  } catch (error) {
    throw error;
  }
};

export const BusinessStorage = async () => {
  try {
    return await api.get(`/agent/business/storage-size`)
  } catch (error) {
    throw error
  }
}
export const BusinessDetails = async () => {
  try {
    return await api.get(`/agent/business`)
  } catch (error) {
    throw error
  }
}
