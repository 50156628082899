import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getPermision } from "../../service/global.service";

// Define an async thunk for fetching permission data
export const fetchPermissionData = createAsyncThunk("fetchPermissionData", async () => {
  try {
    const res = await getPermision(); // Call your API service function to get permission data
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching permission data: ${err.message}`);
  }
});
// Create the permissionSlice
const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    permissions: [], // Property to store the fetched permission data
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchPermissionData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload.data; // Update permissions property with fetched data
      })
      .addCase(fetchPermissionData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

// Export the reducer generated by createSlice
export default permissionSlice.reducer;
