import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Footer from "../../Components/Footer/Footer";
import { useSelector } from "react-redux";
import { Button, CircularProgress, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { createRequestDocs, deleteRequestDocs } from "../../service/task.service";
import { fetchRequestDocs } from "../../Redux/Slice/requestDocsSlice";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { notify } from "../../App";

const RequestDocuments = () => {
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [note, setNote] = useState("");
  
  const { id } = useParams();

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRequestDocs(id))
  }, [])
  


  const {requestDocuments, isrequestDocumentsLoading } = useSelector(
    (state) => state?.getRequestDocs
  );




  const handleDelete = async (taskId) => {
    try {
      // Call the deleteTask function with the tagId
      const response = await deleteRequestDocs(taskId);

      // Handle the response from the deleteTask function
      // console.log("Task deleted successfully:", response);

      // Close the modal or perform any other action upon successful deletion
      // handleClose();
    } catch (error) {
      // Handle errors if the deletion fails
      console.error("Error deleting ta:", error);

      // Close the modal or perform any other action upon deletion failure
      // handleClose();
    }
    dispatch(fetchRequestDocs(id))
  };


  
  const handleSend = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    const reqData = {
      description: documentDescription,
      title: documentName,
      note: note
     }

     // console.log(reqData);



    try {
       const response = await createRequestDocs(reqData, id);

      if (response.status === 400) {
        setSubmitLoading(false);
        // console.log(response)

        notify("Something Went Wrong", "error");
      } else if (response.status === 201) {
        setSubmitLoading(false);
        // console.log(response);

        // console.log("submitedd");
      }
    } catch (error) {
      // console.log(error);
    }
    dispatch(fetchRequestDocs(id))
    setDocumentDescription("")
    setDocumentName("")
    setNote("")
  };





  return (

    <div className="overflow-auto m-3 h-100 rounded-3  bg-white ps-5 pe-5 p-4  cad-shadow pb-5  gap-4">    

      <div className="d-flex col-12 justify-content-end position-relative">
        <Link
          to="/add-task"
          style={{ left: -24, top: 0 }}
          className="text-primary h6 text-decoration-none d-flex align-items-center gap-3 pointer position-absolute"
        >
          <ArrowBackRoundedIcon /> Request Documents
        </Link>
      </div>

    <div className="w-12  d-flex">
      <form onSubmit={handleSend} className="d-flex pe-5 mt-5 flex-column col-6">



        <div className="mb-3">
          <label htmlFor="documentTitle" className="form-label cad-text-medium">
            Document title
          </label>
          <input
            type="text"
            className="form-control cad-text-medium"
            id="documentTitle"
            placeholder="Document name"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
        </div>



        <div className="mb-3">
          <label htmlFor="documentDescription" className="form-label cad-text-medium">
          Document description
          </label>
          <textarea
            className="form-control cad-text-medium"
            id="documentDescription"
            rows="3"
            value={documentDescription}
            onChange={(e) => setDocumentDescription(e.target.value)}
          />
        </div>

        
        <div className="mb-3">
          <label htmlFor="note" className="form-label cad-text-medium">
            Note 
          </label>
          <input
            type="text"
            className="form-control cad-text-medium"
            id="note"
            placeholder="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>

        <div className="w-100 gap-3 d-flex align-items-center flex-column justify-content-center">
                <div>
                  <Button
                    type="submit"
                    className="button-contained"
                    variant="contained"
                  >
                    {submitLoading ? (
                      <CircularProgress
                        style={{ height: 24, width: 24 }}
                        color="inherit"
                      />
                    ) : (
                      "Upload document"
                    )}
                  </Button>
              </div>
          </div>

        
      </form>






      <div className="col-6 d-flex mt-4 flex-column justify-content-between">
          <TableContainer className="h-100" square={false} elevation={1} component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell
                    className="cad-fw-600 cad-text-small"
                    style={{ width: 250 }}
                  >
                    Document Name
                  </TableCell>
                  <TableCell className="cad-fw-600 cad-text-small" align="left">
                    Description
                  </TableCell>
                  <TableCell
                    className="cad-fw-600 cad-text-small"
                    align="center"
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isrequestDocumentsLoading
                  ? // Show skeleton loading for the entire table rows
                    Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" height={30} />
                        </TableCell>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" height={30} />
                        </TableCell>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" height={30} />
                        </TableCell>
                      </TableRow>
                    ))
                  : requestDocuments?.map((eachDocs) => (
                      <TableRow
                        className="pointer"
                        key={eachDocs.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.transition = "0.5s";
                          e.currentTarget.style.boxShadow =
                            "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                          // setMouseEntered(true);
                          // setMouseEnteredId(id);
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                          e.currentTarget.style.scale = 1;
                          e.currentTarget.style.transition = "0.5s";
                          e.currentTarget.style.boxShadow = "none";
                          // setMouseEntered(false);
                        }}
                      >
                        <TableCell
                          className="cad-text-small"
                          component="th"
                          scope="row"
                        >
                          {eachDocs.title}
                        </TableCell>
                        <TableCell className="cad-text-small" align="left">
                          {eachDocs.description}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDelete(eachDocs.id)}
                          className="cad-text-small"
                          align="center"
                        >
                          <CloseIcon className="text-danger" />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>


          <div className="w-100  mt-5 gap-3 d-flex align-items-center flex-column justify-content-center">
            <div className="col-3">
              <Link to="/task">
              <Button
                type="submit"
                className="button-contained"
                variant="contained"
              >
                  Submit
              </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>





      
  
      {/* <Footer /> */}
    </div>

  );
};

export default RequestDocuments;
