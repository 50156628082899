import React, { useEffect, useState } from "react";
import HelpAndGuids from "../HelpAndGuids/HelpAndGuids";
import "./HelpComponent.css";
import helpImage from "../../Images/helpAndGuid.png";
import { useDispatch } from "react-redux";
import { fetchHelpAndGuide } from "../../Redux/Slice/helpAndGuideSlice";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

const HelpSkeleton = () => {
  return (
    <div className="pt-4 ps-3 pe-5">
      <Skeleton className="w-25" variant="text" sx={{ fontSize: "1rem" }} />
      <p className="cad-text-medium ">
        <Skeleton className="w-100" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton className="w-100" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton className="w-100" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton className="w-100" variant="text" sx={{ fontSize: "1rem" }} />
      </p>
    </div>
  );
};

const HelpComponent = () => {
  const dispatch = useDispatch();
  const { helpData, isLoading } = useSelector((state) => state.helpAndGuid);


  return (
    <div className="help-component overflow-auto h-100 support-panel rounded-bottom-3">
      <div
        style={{
          width: "100%",
          height: "180px",
          position: "relative",
          backgroundImage: `url(${helpImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="pt-5 ps-4"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          <h2 className="fw-bold text-white">
            Help & <br />
            Guidance
          </h2>
        </div>
      </div>

      {isLoading
        ? Array.from({ length: 3 }, (_, index) => {
            return <HelpSkeleton key={index} />;
          })
        : helpData.map((ele, index) => (
            <HelpAndGuids
              key={ele.id}
              SrNo={index + 1}
              title={ele.title}
              details={ele.content}
              hiddenDtails=""
            />
          ))}
    </div>
  );
};

export default HelpComponent;
