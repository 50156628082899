import React from "react";
import Switch from "@mui/material/Switch";
import * as themeColors from "../../Utils/Theme/Colors";
import { useSelector } from 'react-redux';
import Invoice from "../../Components/Invoice/Invoice";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Notifications = () => {
  const {theme, themeColorsObject} = useSelector((state) => state.theme);
  return (
    <div
    style={{
      height:"87vh",
      backgroundColor: themeColorsObject.megaCardBackgroundColor,
      color: themeColorsObject.titleText,
    }}
      className="mt-1 mb-4 rounded-3 cad-shadow"
    >
      <div
        className="p-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <h4
          className="cad-fw-600 cad-text-medium"
          style={{ marginBottom: "0" }}
        >
          Notify users or clients by email for each task activity?
        </h4>
        <Switch {...label} defaultChecked />
      </div>
      
      <div
        className="p-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <h4
          className="cad-fw-600 cad-text-medium"
          style={{ marginBottom: "0" }}
        >
          Notify users or clients by toast notification for each task activity?
        </h4>
        <Switch {...label} defaultChecked />
      </div>

    </div>
  );
};

export default Notifications;
