// Function to convert an array of objects to CSV format
function convertArrayOfObjectsToCSV(data) {
  // Define an array of column names to exclude from the CSV
  const excludedColumns = ['id', 'profilePic'];

  // Get the header (column names) excluding the excluded columns
  const header = Object.keys(data[0]).filter((fieldName) => !excludedColumns.includes(fieldName));

  // Create the CSV rows
  const csv = [
    header.join(','), // Header row
    ...data.map((row) =>
      header
        .map((fieldName) => {
          // Check if the column is in the excluded list
          if (excludedColumns.includes(fieldName)) {
            return ''; // Exclude the column value
          }
          return row[fieldName]; // Include the column value
        })
        .join(',')
    ),
  ];

  // Join the CSV rows with newline characters
  return csv.join('\n');
}

// Function to download CSV file
function downloadCSV(data, filename) {
  // Convert data to CSV format
  const csvData = convertArrayOfObjectsToCSV(data);

  // Create a Blob containing the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a download link
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // Append the link to the document body, trigger a click event to download, and remove the link
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// Export the downloadCSV function
export default downloadCSV;
