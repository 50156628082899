import Dashboard from "./Pages/Dashboard/Dashboard";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import Login from "./Pages/Login/Login";
import Registration from "./Pages/Registration/Registration";
import SplashScreen from "./Pages/SplashScreen/SplashScreen";
import Task from "./Pages/Task/Task";
import DefaultLayout from "./layout/defaultLayout";
import { Route, Routes, useNavigate } from "react-router-dom";
import * as themeColors from "./Utils/Theme/Colors";
import { useSelector } from "react-redux";
import PaymentSuccess from "./Pages/PaymentSuccess/PaymentSuccess";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import TokenService from "./service/token.service";
import { useEffect, useState } from "react";
import PrivacyNotice from "./Pages/PrivacyNotice/PrivacyNotice";
import GoogleLogin from "./Pages/GoogleLogin/GoogleLogin";
import OTPScreen from "./Pages/OTPScreen/OTPScreen";
import NoInternetScreen from "./Pages/NoInternetScreen";
import PaymentFailed from "./Pages/PaymentFailed/PaymentFailed";
import StripeSubscription from "./Pages/StripeSubscription/StripeSubscription";
import Invoice from "./Components/Invoice/Invoice";

export const notify = (message, type) => {
  if (type === "error") {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else if (type === "warn") {
    toast.warn(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else if (type === "info") {
    toast.info(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  }
};

function App() {
  const navigate = useNavigate();

  const dispatch = useDispatch();  


  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const user = TokenService.getUser();
  const isAuthenticate = user?.isAuthenticate || null;

  const { theme, themeColorsObject } = useSelector((state) => state.theme);

  return (
    <div
      style={{
        backgroundColor: themeColorsObject.backgroundColor,
      }}
    >
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/splash-screen" element={<SplashScreen />} />
        <Route path="/otp-verification" element={<OTPScreen />} />
        <Route path="/payment-successfull" element={<PaymentSuccess />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
        <Route path="/initial-subsription" element={<StripeSubscription />} />
        <Route path="/payment-failed" element={<PaymentFailed />} />
        <Route path="/invoice/:id" element={<Invoice />} />



        <Route
          path="/*"
          element={ 
            isOnline ? ( !isAuthenticate && !user ? <Login /> : <DefaultLayout />) : <NoInternetScreen/> }
        />
        <Route
          path="/forget-password-change/"
          element={<ForgotPassword />}
        />
        <Route path="/registration" element={<Registration />} />
        <Route path="/registration-plan/:id" element={<Registration />} />
        <Route path="/google-login/:id" element={<GoogleLogin />} />
      </Routes>

      <ToastContainer
        style={{
          minWidth: 300,
        }}
        className="cad-text-medium w-auto  "
      />
    </div>
  );
}

export default App;
