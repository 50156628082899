import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSupportTicket } from "../../service/support.service";

export const fetchSupportTicket = createAsyncThunk(
  "fetchSupportTickets",
  async () => {
    try {
      const res = await getSupportTicket();
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  }
);

const supportSlice = createSlice({
  name: "supportTickets",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    rows: [], // Initialize rows as an empty array
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportTicket.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchSupportTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload?.data.rows || [];
      })
      .addCase(fetchSupportTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default supportSlice.reducer;
