import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

export const addClient = async (data) => {
    try {
        const res = await api.post(`/contact/agent/client`, data, formDataConfig)
        // console.log(res);
        return res;
    } catch (error) {
        throw error
    }
}
export const getByIdClient = async (id) => {
    // try {
    //     return await api.get(`/contact/agent/client/${id}` )
    // } catch (error) {
    //     return error.response
    // }

    
    try {
        return await api.get(`/contact/agent/client/lite/${id}` )
    } catch (error) {
        return error.response
    }
}

export const deleteClient = async (userId) => {
    try {
        return await api.delete(`/contact/agent/client/delete/${userId}`)
    } catch (error) {
        return error.response
    }
}

export const getArchiveClientData = async (data, params) => {
    try {
        return await api.get(`/contact/agent/client/archive`, data, formDataConfig, {
            params: params,
        })
    } catch (error) {
        return error.response
    }
}

export const archiveClient = async (userId) => {
    try {
        return await api.put(`/contact/agent/client/delete/${userId}`)
    } catch (error) {
        return error.response
    }
}


export const unarchiveClient = async (userId) => {
    try {
        return await api.get(`/contact/agent/client/restore/${userId}`)
    } catch (error) {
        return error.response
    }
}

export const updateClients = async (userId, data) => {
    try {
        return await api.put(`/contact/agent/client/${userId}`, data)
    } catch (error) {
        return error.response
    }
}


export const restoreClient = async (userId) => {
    try {
        return await api.get(`/contact/agent/client/restore/${userId}`)
    } catch (error) {
        return error.response
    }
}
export const exportClient = async () => {
    try {
        return await api.get(`/contact/agent/client/download`)
    } catch (error) {
        return error.response
    }
}
