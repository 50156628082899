import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ReGenrateAccessToken,
  authMeService,
  login,
} from "../../../service/auth.service";

export const AuthMe = createAsyncThunk(
  "auth/Me",
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const res = await authMeService();
      if (res?.status === 200) return res.data;
      return rejectWithValue(res);
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const AuthLogin = createAsyncThunk(
  "auth/login",
  async (args, { rejectWithValue }) => {
    try {
      const res = await login(args);
      if (res?.status === 200) return res.data;
      return rejectWithValue(res);
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const AuthReGenrateAccessToken = createAsyncThunk(
  "auth/RegenrateAccessToken",
  async (args, { rejectWithValue }) => {
    try {
      const res = await ReGenrateAccessToken(args);
      if (res?.status === 200) return res.data;
      return rejectWithValue(res);
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  isLoading: true,
  isError: false,
  error: null,
  message: null,
  isAuthenticate: false,
  profileData: null,
  group: null,
  role: null,
  resources: [],
  actions: [],
  accessToken: null,
  refreshToken: null,
};

const authMeSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    logoutUser: (state, action) => {
      state = initialState;
      // console.log(initialState);
      // Handle logout logic if needed
    },
    closeError: (state) => {
      state.isError = false;
      state.error = null;
    },
    isAuthenticateFailed: (state) => {
      state.isAuthenticate = false;
    },
    authLogin: (state, action) => {
      state.isAuthenticate = true;
      state.role = action.payload.role[0].title;
      state.group = action.payload.group[0].title;
      state.actions = action.payload.role[0].actions;
      state.resources = action.payload.resources;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AuthMe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AuthMe.fulfilled, (state, action) => {
        // console.log(action.payload?.data?.resources);
        state.isLoading = false;
        state.profileData = action.payload?.data;
        state.resources = action.payload?.data?.resources;
      })
      .addCase(AuthMe.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(AuthReGenrateAccessToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AuthReGenrateAccessToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accessToken = action.payload?.accessToken || null;
      })
      .addCase(AuthReGenrateAccessToken.rejected, (state) => {
        state.isAuthenticate = false;
        state.isLoading = false;
      })
      .addCase(AuthLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AuthLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticate = true;
        state.role = action.payload.role[0].title;
        state.group = action.payload.group[0].title;
        state.actions = action.payload.role[0].actions;
        state.resources = action.payload.resources;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
      })
      .addCase(AuthLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const {
  updateAccessToken,
  logoutUser,
  closeError,
  isAuthenticateFailed,
  authLogin
} = authMeSlice.actions;
export default authMeSlice.reducer;
