import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getModes } from "../../service/global.service"; // Make sure to replace this import with the appropriate service function

export const fetchGlobalModes = createAsyncThunk("fetchGlobalModes", async () => {
  try {
    const res = await getModes(); // Replace this function call with the appropriate service function
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching modes data: ${err.message}`);
  }
});

const globalModesSlice = createSlice({
  name: "globalModes",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    modes: [], // Add a property to store the fetched modes data
    // Add other properties as needed
  },
  reducers: {
    // Add any specific reducers if required
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalModes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalModes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.modes = action.payload.data; // Update the property with the fetched modes data
      })
      .addCase(fetchGlobalModes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default globalModesSlice.reducer;
