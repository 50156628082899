import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTask, getTaskById } from "../../service/task.service";

export const fetchTaskData = createAsyncThunk(
  "fetchTaskData",
  async (params, { getState }) => {
    try {
      const { currentPage, size } = getState().tasks
      // console.log(currentPage, size, getState().tasks);
      const res = await getTask({ page: currentPage, size: size, search: params || undefined });
      // console.log(res.data);
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  }
);

export const fetchTaskDataByID = createAsyncThunk(
  "fetchTaskDataById",
  async (params) => {
    try {
      const res = await getTaskById(params);
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching data by ID: ${err.message}`);
    }
  }
);

const taskSlice = createSlice({
  name: "fetchTask",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    tasks: [],
    mappingTaskData: [],
    totalPages: 0,
    taskById: {},
    isTaskByIdLoading: false,
    currentPage: 1 || undefined,
    size: 10 || undefined
  },
  reducers: {
    nextPage: (state, action) => {
      state.currentPage = action.payload
    },
    // You can add additional reducers specific to kanban slice if needed
    updateMappingTaskData(state, action) {
      state.mappingTaskData = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchTaskData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tasks = action.payload.data?.rows;
        state.mappingTaskData = action.payload.data?.rows
        state.totalPages = action.payload.data?.totalPages;
        state.currentPage = action.payload.data?.currentPage;
      })
      .addCase(fetchTaskData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      })
      .addCase(fetchTaskDataByID.pending, (state) => {
        state.isTaskByIdLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchTaskDataByID.fulfilled, (state, action) => {
        state.isTaskByIdLoading = false;
        state.taskById = action.payload.data;
      })
      .addCase(fetchTaskDataByID.rejected, (state, action) => {
        state.isTaskByIdLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default taskSlice.reducer;
export const { nextPage } = taskSlice.actions
