import React from "react";
import "./Account.css";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, Paper } from "@mui/material";

const Account = ({ head, pera, btn, themeColorsObject, onClick }) => {
  return (
    <Paper
      elevation={1}
      className=" p-3"
              style={{
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.titleText,
              }}

    >
      <h4 className="cad-text-medium">
        {head}
      </h4>
      <p className="cad-text-small">{pera}</p>

      <div className="row">
        <div className="col-3">
          <Button onClick={onClick} className="button-contained">
            <FileDownloadOutlinedIcon style={{ marginRight: "6px" }} />
            {btn}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default Account;
