import React, { useEffect, useState } from "react";
import VerifyMail from "../Registration/Components/VerifyMail";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import VerifiedMail from "../Registration/Components/VerifiedMail";
import CompanyDetails from "../Registration/Components/CompanyDetails";
import StepperComp from "../Registration/Components/StepperComp";
import { emailCheck, google_login } from "../../service/auth.service";
import { notify } from "../../App";
import { useDispatch } from "react-redux";
import { fetchAuthLogin } from "../../Redux/Slice/fetchAuthMe";
import { authLogin } from "../../Redux/Slice/auth/auth.slice";

const GoogleLogin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { id } = useParams();
  const [companyDetails, setCompanyDetails] = useState(false);
  const [personalDetails, setPersonalDetails] = useState(false);
  const [registrationData, setRegistrationData] = useState({});
  const [regData, setRegData] = useState({});
  const [stepper, setStepper] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(true);
  const decodedData = jwtDecode(id);
  console.log(decodedData?.email);



  useEffect(() => {
    checkEmail(decodedData?.email); // Immediately invoke the async function
  }, [decodedData]);
  
  const checkEmail = async (email_address) => {
    try {
      const res = await emailCheck({ email_address });
      console.log(res);
      // notify("Please check your email");
    } catch (err) {
      console.log(err);
      gTokenRediection(id)
      // navigate("/login?gToken="+id)
      // notify(err?.data?.message, 'error');
    }
  };


  const gTokenRediection = async (token) => {
    try {
      const res = await google_login({token});
      if (res?.status === 200) {
        // console.log(res?.data?.is_2FA_on);
        // if (rememberMe) {
        //   const stringValue = JSON.stringify(value); // Convert object to string
        //   Cookies.set("userData", stringValue, { expires: 7 }); // Store the string
        // }
        // dispatch(authLogin(res.data));
        // notify(res.data.message);
        // navigate("/splash-screen");
        // setIsLoading(false);

        if (res?.data?.is_2FA_on) {
          dispatch(fetchAuthLogin(res.data));
          dispatch(authLogin(res.data));
          notify(res.data.message);
          navigate("/otp-verification");
          // setIsLoading(false);
        } else {         
           dispatch(fetchAuthLogin(res.data));
          dispatch(authLogin(res.data));
          notify(res.data.message);
          navigate("/splash-screen");
          // setIsLoading(false);
        }
      } else {
        // setIsLoading(false);
        // notify(res.data.message, "error");
      }
    } catch (error) {
      // setIsLoading(false);
      notify(error.data?.error?.message, "error");
      // console.log(error);
    }
  };




  return (
    <div
      style={{
        background:
          "linear-gradient(90deg, hsla(203, 69%, 84%, 0.5) 0%, hsla(18, 76%, 85%, 0.5) 100%)",
        minHeight: "100vh",
      }}
      className=" p-3   d-flex justify-content-center flex-column align-items-center"
    >
      <h1 style={{ fontSize: "30px", color: "#4CCD8D" }} className="cad-fw-800">
        Cloudify<span className="cad-fw-500 text-primary">Docs</span>
      </h1>
      <div className=" d-flex bg-white w-auto p-4 rounded-3 justify-content-center  h-auto cad-shadow">
        {companyDetails === false && stepper === false ? (
          <VerifiedMail
            firstName={decodedData?.given_name}
            lastName={decodedData?.family_name}
            setRegistrationData={setRegistrationData}
            setCompanyDetails={setCompanyDetails}
            emailEntered={decodedData?.email}
          />
        ) : companyDetails === true && stepper === false ? (
          <CompanyDetails
            setRegData={setRegData}
            setStepper={setStepper}
            setRegistrationData={setRegistrationData}
            registrationData={registrationData}
          />
        ) : (
          <StepperComp
            setCompanyDetails={setCompanyDetails}
            setRegData={setRegData}
            regData={regData}
            googleLogin={true}
            email={decodedData?.email}
          />
        )}
      </div>
    </div>
  );
};

export default GoogleLogin;
