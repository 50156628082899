import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'

const FAQAccordian = ({ accordionData }) => {

  const [expanded, setExpanded] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <div className='d-flex flex-wrap w-100'>
      {accordionData?.map((item, index) => {
        return (
            <div key={item.id} className="col-12 col-md-6 col-lg-4 mt-4 mt-md-0 p-2 ">
              <div className='d-flex  flex-wrap justify-content-start gap-2 align-items-center  pt-2'>
                <span className=' cad-text-medium cad-fw-600'>{item.title}</span>
              </div>
              {item.faqs?.map((item, index) => (
                <Accordion
                  key={index}
                  // expanded={expanded === ele.panelNumber}
                  // onChange={handleChange(ele.panelNumber)}
                  className='mt-3 rounded-2  overflow-hidden '>
                  <AccordionSummary

                    style={{
                      backgroundColor: "#E7EFFF",
                      borderRadius: "10px 10px 0 0"
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className=' cad-text-medium cad-fw-400' >{item?.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className=' cad-text-small cad-fw-400' >{item?.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
        )
      })}

    </div>

  )
}

export default FAQAccordian