import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCompany } from "../../service/global.service";

export const fetchGlobalCompany = createAsyncThunk("fetchGlobalCompany", async () => {
  try {
    const res = await getCompany();
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});


const globalCompanySlice = createSlice({
  name: "fetchGlobalCompany",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    globalCompany: [], // Add a faq property to store the fetched data
    allCompanyWithPagonation: [],
    companyDetails: {},
    companyClients: []
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalCompany.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.globalCompany = action.payload.data; // Update the faq property with the fetched data
      })
      .addCase(fetchGlobalCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default globalCompanySlice.reducer;
