import React, { useState } from 'react'
import { Button, Paper, Typography } from '@mui/material';
import CloseAccountModal from './CloseAccountModal';
import * as yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from '../../App';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../Redux/Slice/auth/auth.slice';
import { logoutAgent } from '../../Redux/Slice/fetchAuthMe';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { ClosedAccount } from '../../service/agent.service';

export const closedSchema = yup.object().shape({
    reason: yup.string().required('Reason is required'),
    improvement: yup.string().optional().required('Improvementason is required'),
})

const AccountInner = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues
    } = useForm({ mode: "all", resolver: yupResolver(closedSchema) });

    const dispatch = useDispatch()

    const handleCloseAccountUser = (value) => async () => {
        try {
            const { data, status } = await ClosedAccount(value)

            if (status === 200) {
                localStorage.removeItem("user");
                Cookies.remove("userData"); // Remove the cookie notification
                dispatch(logoutUser());
                dispatch(logoutAgent());
                return navigate("/");
            }
        } catch (error) {
            notify(error?.data.error.message, "error");
            console.log(error);
        }
        reset()
        setOpen(false)

    }

    return (
        <form onSubmit={handleSubmit(() => handleOpen())}>
            <Paper square elevation={0} className='accouts-sec p-4 pt-4 pb-5'>
                <div className='form-group mb-4'>
                    <h4 className='cad-text-medium cad-fw-500' style={{ color: '#4A5057' }}>Why are you leaving ? <span color='red'>*</span></h4>
                    <input
                        type='text'
                        placeholder='Answer'
                        style={{ border: '1px solid #ABABAB', width: '100%', height: '36px', borderRadius: '5px', paddingLeft: '15px' }}
                        {...register('reason')}
                    />
                    {errors && errors?.reason &&
                        <Typography Typography variant="caption" color={'error'} display="block" gutterBottom>
                            {errors?.reason?.message}
                        </Typography>
                    }

                </div>
                <div className='form-group'>
                    <h4 className='cad-text-medium cad-fw-500' style={{ color: '#4A5057' }}>How may we help to improve our services if you choose to stay/continue? <span color='red'>*</span></h4>
                    <input
                        type='text'
                        placeholder='Answer'
                        style={{ border: '1px solid #ABABAB', width: '100%', height: '36px', borderRadius: '5px', paddingLeft: '15px' }}
                        {...register('improvement')}
                    />
                    {errors && errors?.improvement &&
                        <Typography Typography variant="caption" color={'error'} display="block" gutterBottom>
                            {errors?.improvement?.message}
                        </Typography>
                    }
                </div>
                {/* <div style={{display:'flex',alignItems:'baseline',paddingTop:'24px'}}>
                <Checkbox style={{position:'relative',top:'6px',marginRight:'10px', padding:'0px'}}
                        {...label}
                        sx={{
                        color:'#4CCD8D',
                        '&.Mui-checked': {
                            color: '#4CCD8D',
                        },
                        }}
                />
                <p style={{fontSize:'16px', fontWeight:'600'}}>How may we help to improve our services if you choose to stay/continue?</p>
            </div> */}
            </Paper >
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '5rem' }}>
                <Button
                    type='submit'
                    style={{ border: 'none', color: '#fff', backgroundColor: '#DE5753', padding: '11px 98px', fontSize: '18px', borderRadius: '5px', height: "40px", textTransform: "none" }}>
                    Close Account
                </Button>
            </div>
            <CloseAccountModal setOpen={setOpen} open={open} handleCloseAccount={handleCloseAccountUser(getValues())} />
        </form>
    )
}

export default AccountInner
