import api from './api.service'

export const getTaskType = async () => {
    try {
        return await api.get(`/global/taskType`)
    } catch (error) {
        return error.response
    }
}


export const getModes = async () => {
    try {
        return await api.get(`/global/mode`)
    } catch (error) {
        return error.response
    }
}


export const getPriority = async () => {
    try {
        return await api.get(`/global/priority`)
    } catch (error) {
        return error.response
    }
}

export const getStatus = async () => {
    try {
        return await api.get(`/global/status`)
    } catch (error) {
        return error.response
    }
}


export const getType = async () => {
    try {
        return await api.get(`/global/type`)
    } catch (error) {
        return error.response
    }
}

export const getReminders = async () => {
    try {
        return await api.get(`/global/setRminder`);
      } catch (error) {
        return error.response;
      }
  }


  export const getPermision = async () => {
    try {
        return await api.get(`/global/permission`);
      } catch (error) {
        return error.response;
      }
  }

  
  export const getClient = async () => {
    try {
        return await api.get(`/global/client`);
      } catch (error) {
        return error.response;
      }
  }


  export const getUsers = async () => {
    try {
        return await api.get(`/global/user/`);
      } catch (error) {
        return error.response;
      }
  }

  export const getCompany = async () => {
    try {
        return await api.get(`/global/company`);
      } catch (error) {
        return error.response;
      }
  }

  export const getTags = async () => {
    try {
        return await api.get(`/global/tag`);
      } catch (error) {
        return error.response;
      }
  }

  
  export const getResources = async () => {
    // // console.log("hit");
    try {
        return await api.get(`/global/resource`);
      } catch (error) {
        return error.response;
      }
  }
  
  export const getActions = async () => {
    try {
      return await api.get(`/global/resource`);
    } catch (error) {
      return error.response;
    }
  }




    





