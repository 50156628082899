import React, { useContext, useEffect, useState } from "react";
import "../ChatBox/ChatBox.css";
// import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ChatBox from "../ChatBox/ChatBox";
import GlobalStateContext from "../../Contexts/GlobalStateContext";
import { Button, Paper, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import emptyChatBg from "../../Images/emptyChatBg.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import {
  createMessage,
  getContacts,
  getGroupInfo,
  getMessage,
} from "../../service/chat.service";
import UserProfile from "../GlobalTable/UserProfile";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function generateUID() {
  const timestamp = new Date().getTime().toString(36); // Convert current timestamp to base36
  const randomNumber = Math.random().toString(36).substring(2, 10); // Generate a random number and convert to base36
  return timestamp + randomNumber; // Combine timestamp and random number
}

const ChatsRight = ({
  chatContacts,
  handleOpenNewChat,
  setIsTyping,
  isTyping,
  isOnline,
  themeColorsObject,
}) => {
  const {
    socket,
    message,
    setMessage,
    setContacts,
    activeChatIndex,
    chatActiveId,
    activeChatProfile,
    setActiveChatProfile,
  } = useContext(GlobalStateContext);

  const { id } = useParams();
  const [sentMessage, setSentMessage] = useState("");
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);

  const fetchContacts = async () => {
    try {
      const response = await getContacts();
      setContacts(
        response?.data?.payload.map((chats) => {
          let profileName;
          if (!chats?.isGroupChat) {
            const { first_name, last_name, profile_photo } =
              chats?.participants[0];
            profileName = {
              name: first_name + " " + last_name,
              profile: profile_photo,
              chatId: chats?.id,
              first_name,
              last_name,
              last_message: chats?.messages[0],
            };
          } else {
            profileName = {
              name: chats.name,
              profile: chats.profile,
              chatId: chats?.id,
              last_message: chats?.messages[0],
            };
          }
          return profileName;
        }) || []
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSend = async () => {
    if (sentMessage) {
      const formData = new FormData();
      formData.append("content", sentMessage);
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        const res = await createMessage(chatActiveId, formData, signal);
        setMessage(res?.data?.payload);
        fetchContacts();
        setSentMessage("");
      } catch (error) {
        console.error("Error fetching agent data:", error);
      }
      return () => {
        abortController.abort();
      };
    }
    setSentMessage("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  async function fetchData(id) {
    try {
      const res = await getMessage(id);
      setMessage(res?.data?.payload);
    } catch (error) {
      console.error("Error fetching agent data:", error);
    }
  }

  async function getProfile(id) {
    try {
      const group = await getGroupInfo(id); //
      console.log(group?.data.payload);
      const { name, profile, participants, isGroupChat } = group?.data.payload;
      if (participants && !isGroupChat) {
        const participant = participants.filter(
          (item) => item.id !== authInfo?.id
        );
        const { first_name, last_name, profile_photo } = participant[0];
        setActiveChatProfile({
          name: first_name + " " + last_name,
          profile: profile_photo,
        });
      } else {
        setActiveChatProfile({
          name: name,
          profile: profile,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleTyping = () => {
    // Emit a typing event when the user starts typing
    socket.emit("typing", chatActiveId);
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
      getProfile(id);
    }
  }, [id]);

  useEffect(() => {
    if (socket) {
      socket.on("messageReceived", (data) => {
        // setIsOnline(true);
        if (id === data?.payload) {
          fetchData();
        }
      });
      socket.on("typing", () => {
        setIsTyping(true);
        setTimeout(() => {
          setIsTyping(false);
        }, 3000); // Adjust the duration as needed
      });
    }
  }, [id, setIsTyping, socket]);
  return (
    <div className="row m-0  flex-lg-nowrap overflow-hidden h-100">
      {activeChatIndex === null ? (
        <div className="w-100 h-100 d-flex justify-content-center gap-2 align-items-center flex-column">
          <img width={260} src={emptyChatBg} alt="" />
          <span className="cad-text-large cad-fw-600 mt-3">
            Your chats will show up here.
          </span>
          <span className="cad-text-large text-secondary cad-fw-500 w-50 text-center">
            Get started by tapping the new chat button here or on someone's
            profile
          </span>

          <Button
            onClick={handleOpenNewChat}
            variant="contained"
            className="mt-2"
            style={{ width: "30%" }}
          >
            <AddCircleOutlineIcon className="me-2" /> Start Chatting
          </Button>
        </div>
      ) : (
        <div className="col-lg-12 h-100 col-12 p-0 pb-5 position-relative">
          <div
            className="profile  border-bottom p-2 ps-3 d-flex gap-2"
            style={{
              height: "12%",
            }}
          >
            <div
              style={{ position: "relative" }}
              className="d-flex align-items-start flex-column justify-content-center"
            >
              {/* {isOnline ? ( */}

              {isOnline ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <UserProfile proPic={activeChatProfile.profile} />
                </StyledBadge>
              ) : (
                <UserProfile proPic={activeChatProfile.profile} />
              )}
            </div>

            <div className="  d-flex align-items-start flex-column justify-content-center">
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                variant="h6"
              >
                {activeChatProfile.name}
              </Typography>
              <Typography
                variant="p"
                style={{ fontSize: "12px", color: "#4A5057" }}
              >
                {/* {isTyping ? (
                 */}
                <div className={`bubble mb-3 ${isTyping ? "d-flex" : "d-none"}`}>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
                {/* ) : (
                  "Online"
                )} */}
              </Typography>
            </div>
          </div>

          <ChatBox
            isTyping={isTyping}
            chatContacts={chatContacts}
            activeChatIndex={activeChatIndex}
            chatArray={message}
          />

          <div className="chat-input pt-2  ">
            <input
              type="text"
              className={`form-control cad-text-medium rounded-5 border w-100 p-3 ps-3 pe-5 ${themeColorsObject.themed_input_class}`}
              placeholder="Message..."
              value={sentMessage}
              onChange={(e) => setSentMessage(e.target.value)}
              onKeyDown={handleKeyPress}
              maxLength={1000}
              onInput={handleTyping}
            />
            <InsertLinkOutlinedIcon className="chat-shareBtn" />
            <h6 onClick={handleSend} className="chat-sentBtn cad-fw-600">
              send
            </h6>
            {/* <SendOutlinedIcon onClick={handleSend} className="chat-sentBtn" /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatsRight;
