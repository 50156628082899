import { Avatar } from "@mui/material";
import React from "react";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

// ProfilePic component displays a user's profile picture and name
const UserProfile = ({ proPic, companyName, fw, fs }) => {
  let picUrl = proPic === null ? "" : process.env.REACT_APP_DOWLOAD_URL + "/" + proPic
  // className="text-truncate"
  return (
    <span style={{ display: "flex", alignItems: "center", gap: 10, fontWeight: fw ? fw : "", fontSize: fs ? fs : "" }}>
      <Avatar
        sx={{ width: 20, height: 20, borderRadius: "50%" }}
        // alt={companyName}
        src={picUrl}
        {...stringAvatar(`${companyName === undefined ? "N/A" : companyName} `)}
      />
      {companyName === undefined ? "" : companyName} {/* Display the user's name */}
    </span>
  );
};

export default UserProfile;
