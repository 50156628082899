import { Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";

const formControlLabelStyle = {
    "& .MuiFormControlLabel-label": {
      color: "#131313",
      fontFamily: "Open Sans",
      fontSize: "11.397px",
      fontStyle: "normal",
      fontEeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  };

const AdvanceFilter = ({
  setFilteredCellConfig,
  setFilteredTableHeadRowCell,
  checkboxValues,
  setCheckboxValues,
  cellConfig,
  tableHeadRowCell,
  bottom,
  left
}) => {
  const [isDivVisible, setIsDivVisible] = useState(false);

  useEffect(() => {
    setFilteredCellConfig(
      cellConfig.filter(
        (_, index) => index === cellConfig?.length - 1 || checkboxValues[Object.keys(checkboxValues)[index]]
      )
    );


    setFilteredTableHeadRowCell(
      tableHeadRowCell.filter(
        (_, index) => index === tableHeadRowCell?.length - 1 || checkboxValues[Object.keys(checkboxValues)[index]]
      )
    );
  }, [checkboxValues]);

  const handleButtonClick = () => {
    setIsDivVisible(!isDivVisible);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  return (
    <div className="position-relative ">
      <Button
        className=" button-outlined  h-100"
        onClick={handleButtonClick}
        // ref={buttonRef}
      >
        <BiFilterAlt className="fs-5" />
      </Button>
      {isDivVisible && (
        <div
          style={{
            bottom: bottom,
            left: left,
            width: "auto",
          }}
          className="category-filter-dropdown"
        >
          {Object.entries(checkboxValues).map(([label, isChecked]) => (
            <FormControlLabel
              key={label}
              value={label}
              control={
                <Checkbox
                  name={label}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "#4CCD8D",
                    "&.Mui-checked": {
                      color: "#4CCD8D",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 18 },
                  }}
                />
              }
              label={label}
              labelPlacement="end"
              sx={{ ...formControlLabelStyle }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AdvanceFilter;
