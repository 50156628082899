import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCompanyDetails } from "../../service/company.service";

export const fetchGlobalCompanyByID = createAsyncThunk("fetchGlobalCompany", async (id) => {
  try {
    const res = await getCompanyDetails(id);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});

const getCompanyByIdSlice = createSlice({
  name: "clientByIdData",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    companyById: {},
    // Define additional state properties if needed
  },
  reducers: {
    companyDetailsByIdData: (state, action) => {
      state.companyById = action.payload;
    },
    // Define additional reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalCompanyByID.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
        // Handle other common state updates if needed
      })
      .addCase(fetchGlobalCompanyByID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyById = action.payload;
        // Handle other state updates specific to the fulfilled case if needed
      })
      .addCase(fetchGlobalCompanyByID.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error?.message || action.data?.error;
        // Handle other state updates specific to the rejected case if needed
      });
  },
});

export default getCompanyByIdSlice.reducer;
export const { companyDetailsByIdData } = getCompanyByIdSlice.actions;
