import React from "react";
// import { SkeletonTable } from "./Skeleton/SkeletonTable";
import {
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import NoData from "./NoData";

const TableData = ({
  extractedDataArray,
  cellConfig,
  tableHeadRowCell,
  setMouseEntered,
  setMouseEnteredId,
  padding,
  handleViewOpen,
  isLoading,
  totalPages,
  handlePagination,
  message,
  currentPage,
}) => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  return (
    <div>
      {isLoading ? (
        <TableContainer className="pb-4">
          {extractedDataArray?.length > 0 ? (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeadRowCell?.map(({ id, title, align, width }, index) => (
                    <TableCell
                      key={index}
                      className="cad-fw-600 cad-text-small"
                      align={align}
                      width={400}
                      style={{
                        color: themeColorsObject.titleText,
                      }}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {extractedDataArray?.map((rowData, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      cursor: "pointer",
                      transition: "background-color 0.5s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent"; // Change the background color on hover
                      e.currentTarget.style.transition = "0.1s";
                      e.currentTarget.style.boxShadow =
                        "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                      setMouseEntered(true);
                      setMouseEnteredId(rowData.id);
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                      e.currentTarget.style.transition = "0.1s";
                      e.currentTarget.style.boxShadow = "none";
                      setMouseEntered(false);
                    }}
                  >
                    {/* Render the table cells based on the rowData */}
                    {cellConfig.map((cell, cellIndex) => (
                      <TableCell
                        className="cad-text-small p-2"
                        component="th"
                        scope="row"
                        style={{
                          color: themeColorsObject.textColor,
                        }}
                        
                        key={cellIndex}
                        align={cell.align}
                        width={400}
                        onClick={
                          cellIndex === cellConfig.length - 1 || cellIndex === 0
                            ? null
                            : () => handleViewOpen(rowData.id) // Define the onClick handler for other cells
                        }
                      >
                        <Skeleton variant="text" sx={{ fontSize: '0.7rem', padding: 1 }} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : null}
        </TableContainer>

      ) : (
        <TableContainer className="pb-4">
          {extractedDataArray?.length > 0 ? (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeadRowCell?.map(({ id, title, align, width }, index) => (
                    <TableCell
                      key={index}
                      className="cad-fw-600 cad-text-small"
                      align={align}
                      width={width}
                      style={{
                        color: themeColorsObject.titleText,
                      }}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {extractedDataArray.map((rowData, index) => (
                  <TableRow
                    key={index}

                    sx={{
                      cursor: "pointer",
                      backgroundColor: "transparent", // Set the default background color
                      transition: "background-color 0.5s ease", // Add a smooth transition effect
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                      e.currentTarget.style.transition = "0.1s";
                      e.currentTarget.style.boxShadow =
                        "rgba(0, 0, 0, 0.24) 0px 3px 8px";


                      setMouseEntered(true);
                      setMouseEnteredId(rowData.id);
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                      e.currentTarget.style.scale = 1;
                      e.currentTarget.style.transition = "0.1s";
                      e.currentTarget.style.boxShadow = "none";
                      setMouseEntered(false);
                    }}



                  >
                    {/* Render the table cells based on the rowData */}
                    {cellConfig.map((cell, cellIndex) => (

                      <TableCell
                        className={`cad-text-small ${padding ? padding : "table-row-padding"
                          }`}
                        component="th"
                        scope="row"
                        style={{
                          color: themeColorsObject.textColor,
                        }}
                        key={cellIndex}
                        align={cell.align}
                        width={cell.width}
                        onClick={cellIndex === cellConfig?.length - 1 ? null : () => handleViewOpen(rowData.id)}
                      >
                        {rowData[cell.dataKey]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : message ? (
            <div className="w-100 h-100 mt-3 d-flex justify-content-center text-primary align-items-center">
              <span className="text-primary cad-fw-600 mt-3 p-3 roun border border-primary">
                {message}
              </span>
            </div>
          ) : (
            <NoData message="No Data Found" />
          )}

          {extractedDataArray?.length > 0 ? (
            <div className="p-3 text-primary d-flex justify-content-end">
              {handlePagination && <Pagination
                count={totalPages}
                onChange={(e, page) => handlePagination(page)}
                color="primary"
                shape="rounded"
                page={currentPage}
              />}
            </div>
          ) : null}
        </TableContainer>
      )}
    </div>
  );
};

export default TableData;
