import React, { useEffect, useState } from 'react'
import { Avatar, Paper, Button } from '@mui/material'
import { Box } from '@mui/system'
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import PrimaryButton from '../../../Components/PrimaryButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsCaretLeft } from 'react-icons/bs';
import { AiOutlineLeft } from 'react-icons/ai';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createCompany, getCompanyDetails, updateCompany } from '../../../service/company.service';
import Select from '../../../Components/Select'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'; 
import { addCompanyClients, fetchGlobalClient, fetchGlobalClientById } from '../../../Redux/Slice/globalClientSlice';
import { fetchTag } from '../../../Redux/Slice/tagsSlice';
import { fetchGlobalTags } from '../../../Redux/Slice/globlalTags';

const EditCompany = () => {
    const dispatch = useDispatch()
    const { id } = useParams();

    const [initialValue, setInitialValue] = useState()

    const [loading, setLoading] = useState(true);
    const [contentData, setContentData] = useState()

    const clientData = useSelector((state) => state?.globalClient?.clients);
    
    const globalTags = useSelector((state) => state?.globalTags?.rows)

    const companyClientData = useSelector((state) => state?.globalClient?.companyClients);
    const companyTagData = useSelector((state) => state?.globalClient?.companyClientTags);
    

    const loadingTags = useSelector((state) => state?.globalTags?.isLoading)
    useEffect(() => {
        async function fetchData() {
            try {
                // Fetch your data and update state
                await Promise.all([
                    dispatch(fetchGlobalClient()),
                    dispatch(fetchGlobalClientById({ id })),
                    dispatch(fetchGlobalTags())
                ]);
                // globalTags.
                setLoading(loadingTags);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }

        fetchData();
    }, [id, dispatch]);

    const [selectClient, setSelectClient] = useState([]);
    const [selectTags, setSelectTags] = useState([]);

    const selectOption = (selectedOptions) => {
        // // console.log(selectedOptions)
        const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
        setSelectClient(selectedValues);
    }
    const selectTagsOption = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
        setSelectTags(selectedValues);
    };

    const generateForm = () => {
        // globalTags undefined
        // console.log(clientData, companyClientData, globalTags, companyTagData);
        if (Array.isArray(clientData) && Array.isArray(companyClientData) && globalTags && companyTagData) {
            // // console.log(true);
            setValue('company_name', clientData?.company_name);
            setValue('email_address', clientData?.email_address);

            const filteredData = clientData
                .filter((ele) => ele.details.length > 0)
                .map((ele) => ({
                    label: ele.last_name ? ele.first_name + ' ' + ele.last_name : ele.first_name,
                    value: ele.details[0].id,
                    color: 'blue',
                }))

            const defaultData = clientData
                .filter((ele) => ele.details.length > 0 && companyClientData?.includes(ele.details[0].id))
                .map((ele) => ({
                    label: ele.last_name ? ele.first_name + ' ' + ele.last_name : ele.first_name,
                    value: ele.details[0].id,
                    color: 'blue',
                })) || []

            const allTags = globalTags.length > 0
                ? globalTags?.map((ele) => ({
                    label: ele.title,
                    value: ele.id,
                    color: 'blue'
                })) : []

            const mappedTags = companyTagData
                .map((ele) => {
                    const globalTag = globalTags.find((tag) => tag.id === ele.id);

                    return globalTag
                        ? {
                            label: ele.title,
                            value: ele.id,
                            color: 'blue',
                        }
                        : null;
                })
                .filter((tag) => tag !== null);


            // // console.log("allTags", allTags);
            // // console.log("mappedTags", mappedTags);

            setSelectClient(defaultData.map(option => option.value))
            setSelectTags(mappedTags.map(option => option.value))

            setContentData([
                {
                    title: 'Company Information',
                    fields: [
                        {
                            label: 'Registered name',
                            type: 'text',
                            required: true,
                            name: 'company_name',
                            error_msg: true,
                        },
                        {
                            label: 'Web Address',
                            type: 'email',
                            required: true,
                            name: 'email_address',
                            error_msg: true,
                        },
                        {
                            label: 'Industry',
                            type: 'text',
                            name: 'industry',
                            error_msg: true,
                        },
                        {
                            label: 'Phone Number',
                            type: 'text',
                            name: 'phone_number',
                            error_msg: true,
                        },

                    ],
                },
                {
                    title: 'Address Details',
                    fields: [
                        {
                            label: 'Country',
                            type: 'text',
                            name: 'country',
                            error_msg: true,
                        },
                        {
                            label: 'City',
                            type: 'text',
                            name: 'city',
                            error_msg: true,
                        },
                        {
                            label: 'Pincode',
                            type: 'text',
                            name: 'postal_code',
                            error_msg: true,
                        }
                    ],
                },
                {
                    title: 'Other Information',
                    fields: [
                        {
                            label: 'Add Client',
                            type: 'multiple-select',
                            options: filteredData,
                            default: defaultData,
                            funtionToUse: selectOption
                        },
                        {
                            label: 'Tags',
                            type: 'multiple-select',
                            options: allTags,
                            // default: filteredMappedTags,
                            default: mappedTags,
                            funtionToUse: selectTagsOption
                        }
                    ],
                },
            ]);
        }
    }

    async function fetchCompanyDetails() {
        try {
            const response = await getCompanyDetails(id);
            if (response.status === 200) {
                // If the API call is successful (status code 200), set the data
                // // console.log(response.data.data);
                setInitialValue(response.data.data)

                const apiData = response.data.data // Adjust this according to your API response structure

                // Set default values after fetching data
                setValue('company_name', apiData.company_name);
                setValue('email_address', apiData.email_address);
                setValue('industry', apiData.industry);
                setValue('phone_number', apiData.phone_number);
                setValue('country', apiData.country);
                setValue('city', apiData.city);
                setValue('postal_code', apiData.postal_code);
                // dispatch(addCompanyClients( ))
            } else {
                // Handle other cases, e.g., error or non-200 response
                console.error('API request failed');
            }
        } catch (error) {
            // Handle network or other errors
            console.error('API request error', error);
        }
    }

    useEffect(() => {
        generateForm()
        fetchCompanyDetails()
    }, [clientData, companyClientData, globalTags, companyTagData, id]);

    const navigate = useNavigate();

    // // console.log(selectTags)

    const loadingData = useSelector((state) => state?.globalClient?.isLoading);

    useEffect(() => {
        // console.log(contentData);
    }, [contentData])



    const [previewImage, setPreviewImage] = useState('');

    const userSchema = Yup.object().shape({
        company_name: Yup.string()
            .required('First Name is required')
            .matches(/^\S*$/, 'First Name cannot contain spaces'),

        email_address: Yup.string()
            .email('Please enter a valid email address')
            .required('Email is required').matches(
                // Regular expression pattern including top-level domains (TLDs)
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 
                "Email address is required"
              ),

        phone_number: Yup.string()
            .notRequired() // Mark the field as optional
            .matches(/^[0-9]*$/, 'Phone Number must contain only numbers')
            .test('is-min-10-characters', 'Phone Number must be at least 10 characters', (value) => {
                if (!value) {
                    return true; // Pass the validation if the field is empty
                }
                return value.length >= 10;
            }),

        pincode: Yup.string()
            .matches(/^[0-9]*$/, 'Pincode must contain only numbers'),

        profile_photo: Yup.mixed()
            .test('fileType', 'Invalid file type', (value) => {
                if (!value || value.length === 0) {
                    setPreviewImage(''); // Clear the preview when no file is selected
                    return true; // Allow empty value (no file selected)
                }

                if (value[0].type.startsWith('image/')) {
                    // Set the previewImage state with the selected image
                    setPreviewImage(URL.createObjectURL(value[0]));
                    return true; // Valid image file
                } else {
                    setPreviewImage(''); // Clear the preview when an invalid file is selected
                    return false; // Invalid file type
                }
            }),

    });


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
        setValue,
        getFieldState,
        control
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userSchema),

        defaultValues: {
            company_name: '',
            email_address: '',
            industry: '',
            phone_number: '',
            country: '',
            city: '',
            postal_code: ''

        }

    })

    // console.log(errors);


    const onSubmit = async (values) => {
        // reset()
        // return // console.log(formData);
        // console.log('Form submit event triggered')
        // console.log(values)


        // Create a FormData object
        const formDataToSend = new FormData();

        // Append the data from each field
        values.profile_photo[0] && formDataToSend.append('profile_photo', values.profile_photo[0]);
        formDataToSend.append('company_name', values.company_name);
        formDataToSend.append('email_address', values.email_address);
        values.phone_number && formDataToSend.append('phone_number', values.phone_number);
        values.country && formDataToSend.append('country', values.country);
        values.city && formDataToSend.append('city', values.city);
        values.postal_code && formDataToSend.append('postal_code', values.postal_code);
        values.industry && formDataToSend.append('industry', values.industry);

        if (selectClient.length > 0) {
            selectClient.forEach((contacts, index) => {
                formDataToSend.append(`contacts[${index}]`, contacts);
            });
        }

        if (selectTags.length > 0) {
            selectTags.forEach((tags, index) => {
                formDataToSend.append(`tags[${index}]`, tags);
            });
        }

        // console.log(formDataToSend);

        try {
            const response = await updateCompany(formDataToSend, id)
            // console.log(response);
            // navigate('/contacts');
            generateForm()
            fetchCompanyDetails()
        }
        catch (error) {
            // console.log(error);
        }

    }




    return (

        <Paper
            elevation={0}
            style={{
                backgroundColor: "#f1f2f6"
            }}
            className=" h-100 w-100 p-3 d-flex overflow-hidden flex-column justify-content-start align-items-start "
        >
            <Box className="border-bottom rounded-top-3" sx={{ borderBottom: 0, padding: 0, backgroundColor: "#fff", width: "100%", overflow: 'scroll' }}>
                {loading ?
                    <p>loading</p> :
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='p-3'>
                            <Link to='/contacts' className='mb-4 d-flex align-items-center gap-1 text-decoration-none'>
                                <AiOutlineLeft className='pointer' size={18} />
                                <label className='pointer'>back to contacts</label>
                            </Link>
                            <h4 className='cad-text-dark cad-fw-600'>Edit Company</h4>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <Avatar
                                        className='m-1'
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            border: '4px solid #E9E9E9',
                                        }}
                                        alt='Remy Sharp'
                                        src={previewImage}
                                    />

                                    <input
                                        type='file'
                                        id='profile-image-input'
                                        accept='image/*'
                                        style={{
                                            display: 'none',
                                        }}
                                        {...register('profile_photo')}
                                    />

                                    <label
                                        style={{
                                            position: 'absolute',
                                            bottom: '0.9rem',
                                            left: '4.9rem',
                                            height: '30px',
                                            width: '30px',
                                            backgroundColor: '#E9E9E9',
                                            padding: '4px',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                        }}
                                        htmlFor='profile-image-input'>
                                        <CameraAltTwoToneIcon
                                            sx={{
                                                position: 'absolute',
                                                bottom: '0rem',
                                                right: '0rem',
                                                height: '30px',
                                                width: '30px',
                                                backgroundColor: '#E9E9E9',
                                                padding: '4px',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </label>
                                </div>
                                <label className='cad-text-medium mb-1 cad-text-light-grey cad-fw-500'>The maximum size of profile picture is 2MB.</label>
                            </div>
                        </div>
                        {
                            contentData?.map((section, index) => {
                                return (
                                    <div key={index}>
                                        <hr className='m-0 hr-border' />
                                        <div className='p-3'>
                                            <h5 className='cad-text-grey mb-3 cad-text-large'>{section.title}</h5>
                                            <div className='row'>
                                                {section.fields.map((field, fieldIndex) => (
                                                    <div className={field.type === 'checkboxGroup' ? 'col-4 mb-2' : 'col-4 mb-2'} key={fieldIndex}>
                                                        <label className={`cad-text-medium mb-1 cad-text-light-grey cad-fw-500 ${field.required ? 'text-danger' : ''}`}>
                                                            {field.label}
                                                            {field.required && <span className='text-danger'> *</span>}
                                                        </label>
                                                        {field.type === 'select' ? (
                                                            <select defaultValue={'none'} className={`form-control w-100  ${field.required ? 'required' : ''}`} {...register(field.name)}>
                                                                <option value="">none</option>
                                                                {field.options?.map((option, optionIndex) => (
                                                                    <option key={optionIndex} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : field.type === 'multiple-select' ? (

                                                            <div>
                                                                <Select
                                                                    isMulti={true}
                                                                    options={field.options}
                                                                    setSelected={field.funtionToUse}
                                                                    defaultValue={field.default}
                                                                />

                                                            </div>
                                                        ) : (
                                                            <input
                                                                className={`form-control w-100 ${field.required ? 'required' : ''}`}
                                                                type={field.type}
                                                                {...register(field.name)}
                                                            />
                                                        )}
                                                        {field.error_msg && (
                                                            <div htmlFor={field.name} className="form-label text-primary form-text text-danger">
                                                                {errors[field.name]?.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}

                        <div className='d-flex justify-content-center py-3 m-auto w-25'>
                            <Button className='button-contained' type='submit'>
                                Add
                            </Button>
                        </div>
                    </form>
                }
            </Box>
        </Paper >
    )
}

export default EditCompany