import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getResources } from "../../service/global.service"; // Replace this import with the appropriate service function

export const fetchGlobalResources = createAsyncThunk("fetchGlobalResources", async () => {
  try {
    const res = await getResources(); // Replace this function call with the appropriate service function
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching resources data: ${err.message}`);
  }
});

const globalResourcesSlice = createSlice({
  name: "globalResources",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    resources: [], // Add a property to store the fetched resources data
    // Add other properties as needed
  },
  reducers: {
    // Add any specific reducers if required
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalResources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalResources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resources = action.payload.data; // Update the property with the fetched resources data
      })
      .addCase(fetchGlobalResources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default globalResourcesSlice.reducer;
