import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers } from "../../service/global.service";

export const fetchGlobalUser = createAsyncThunk("fetchGlobalUser", async () => {
  try {
    const res = await getUsers();
    // console.log(res?.data);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
}); 

const  globalUser = createSlice({
    name: "fetchUsers",
    initialState: {
      isLoading: true,
      isError: false,
      error: null,
      globalUsers: [], // Add a faq property to store the fetched data
    },
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchGlobalUser.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        })
        .addCase(fetchGlobalUser.fulfilled, (state, action) => {
          state.isLoading = false;
          state.globalUsers = action.payload.data; // Update the faq property with the fetched data
        })
        .addCase(fetchGlobalUser.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.error = action.error.message; // Store the error message
        });
    },
  });
  
  export default globalUser.reducer;
  