import Chat from "./Chats/Chat";
import ViewTask from "./Pages/ViewTask/ViewTask";
import AccountsSecurity from "./Pages/AccountSecurity/AccountsSecurity";
import AddTask from "./Pages/AddTask/AddTask";
import CompanyBranding from "./Pages/CompanyBranding/CompanyBranding";
import AddNewClient from "./Pages/Contact/Client/AddNewClient";
import Contact from "./Pages/Contact/Contact";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Form from "./Pages/Form/Form";
import Notifications from "./Pages/Notifications/Notifications";
import Profile from "./Pages/Profile/Profile";
import Subscription from "./Pages/Subscription/Subscription";
import Support from "./Pages/Support/Support";
import Tags from "./Pages/Tags/Tags";
import Task from "./Pages/Task/Task";
import AddNewUser from "./Pages/User/AddNewUser";
import EditUser from "./Pages/User/EditUser";
import User from "./Pages/User/User";
import ClientDetails from "./Pages/Contact/Client/ClientDetails/ClientDetails";
import { Login } from "@mui/icons-material";
import GenerateNewTickets from "./Components/GenerateNewTickets/GenerateNewTickets";
import ShareDocuments from "./Pages/ShareDocuments/ShareDocuments";
import RequestDocuments from "./Pages/RequestDocuments/RequestDocuments";
import EditTask from "./Pages/EditTask/EditTask";
import AddCompany from "./Pages/Contact/Company/AddCompany";
import EditCompany from "./Pages/Contact/Company/EditCompany";
import SplashScreen from "./Pages/SplashScreen/SplashScreen";
import Client from "./Pages/Contact/Client";
import FormTemplate from "./Pages/Form/FormTemplate/FormTemplate";
import PersonalDetailsNew from "./Components/PersonalDetails/PersonalDetailsNew";
import MyForms from "./Pages/MyForms/MyForms";
import StripeSubscription from "./Pages/StripeSubscription/StripeSubscription";
import PaymentSuccess from "./Pages/PaymentSuccess/PaymentSuccess";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import PaymentFailed from "./Pages/PaymentFailed/PaymentFailed";

export const RouteLink = [
  { path: "/splash-screen", component: <SplashScreen /> },
  { path: "/", component: <Dashboard /> },
  // { path: "/", component: <EmpDashboard /> },
  { path: "/profile-old", component: <Profile /> },
  { path: "/profile", component: <PersonalDetailsNew /> },
  { path: "/task", component: <Task /> },
  { path: "/contacts", component: <Contact /> },
  { path: "/form", component: <Form /> },
  { path: "/template-forms/:id", component: <FormTemplate /> },
  { path: "/template-preview/:FormID", component: <FormTemplate /> },
  { path: "/my-forms", component: <MyForms /> },
  // chats routes
  { path: "/chats", component: <Chat /> },
  { path: "/chats/:id", component: <Chat /> },
  // { path: "/settings", component: <Settings /> },
  { path: "/support", component: <Support /> },
  { path: "/users", component: <User /> },
  { path: "/subscription", component: <Subscription /> },
  // { path: "/subscription", component: <StripeSubscription /> },
  { path: "/tags", component: <Tags /> },
  { path: "/company-branding", component: <CompanyBranding /> },
  { path: "/accounts-security", component: <AccountsSecurity /> },
  { path: "/notification", component: <Notifications /> },
  { path: "/add-new-client", component: <AddNewClient /> },
  { path: "/add-new-user", component: <AddNewUser /> },
  { path: "/edit-user/:id", component: <EditUser /> },
  { path: "/add-task", component: <AddTask /> },
  { path: "/client-details/:id", component: <ClientDetails /> },
  { path: "/support/generated-tickets/:id", component: <GenerateNewTickets /> },
  { path: "/view-task/:id", component: <ViewTask /> },
  { path: "/edit-task/:id", component: <EditTask /> },
  { path: "/login", component: <Login /> },
  { path: "/share-documents/:id", component: <ShareDocuments /> },
  { path: "/request-documents/:id", component: <RequestDocuments /> },
  { path: "/add-company", component: <AddCompany /> },
  { path: "/edit-company/:id", component: <EditCompany /> },
  { path: "/client2", component: <Client /> },
  { path: "/sign-docs", component: <ShareDocuments /> },
  { path: "/payment-successfull", component: <PaymentSuccess /> },
  { path: "/forget-password-change/:id", component: <ForgotPassword /> },
];
