import axios from "axios";
import { store } from "../Redux/store";
import {
  AuthReGenrateAccessToken,
  isAuthenticateFailed,
} from "../Redux/Slice/auth/auth.slice";
import { fetcIisAuthenticateFailed } from "../Redux/Slice/fetchAuthMe";

store.subscribe(listener);

function select(state) {
  return {
    accessToken: state.authMe?.accessToken,
    refreshToken: state.authMe?.refreshToken,
  };
}

let _accessToken, _refreshToken;

function listener() {
  // // console.log(store.getState());
  const { accessToken, refreshToken } = select(store.getState());
  _accessToken = accessToken;
  _refreshToken = refreshToken;
}

// const instance = axios.create({
//   // baseURL:`${process.env.REACT_APP_DB_HOST}api/development/v1`,
//   baseURL: process.env.REACT_APP_DB_HOST,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
const instance = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_BASEURL +
    "/api/" +
    process.env.REACT_APP_NODE_ENV +
    "/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    // const token = TokenService.getLocalAccessToken();
    const token = _accessToken;
    // // console.log(token);
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 403 && !originalConfig.__isRetryRequest) {
        originalConfig.__isRetryRequest = true;

        try {
          // Refresh token logic
          await store.dispatch(
            AuthReGenrateAccessToken({ refreshToken: _refreshToken })
          );

          // Retry original request with the new token
          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.status === 401) {
            store.dispatch(isAuthenticateFailed());
            store.dispatch(fetcIisAuthenticateFailed());
          }
          return Promise.reject(_error.response);
        }
      }
      if (err.response?.status === 401) {
        store.dispatch(isAuthenticateFailed());
        store.dispatch(fetcIisAuthenticateFailed());
      }
      if (err.response?.status === 429) {
        store.dispatch(isAuthenticateFailed());
        store.dispatch(fetcIisAuthenticateFailed());
      }
      // return err.response;
      return Promise.reject(err.response);
    }
    console.log(err);
    return Promise.reject(err.response);
  }
);

export default instance;
