import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTaskType } from "../../service/global.service";

export const fetchTaskType = createAsyncThunk("fetchTaskType", async () => {
  try {
    const res = await getTaskType();
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});

const  taskTypeSlice = createSlice({
    name: "fetchTask",
    initialState: {
      isLoading: true,
      isError: false,
      error: null,
      tasksType: [], // Add a faq property to store the fetched data
    },
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchTaskType.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        })
        .addCase(fetchTaskType.fulfilled, (state, action) => {
          state.isLoading = false;
          state.tasksType = action.payload.data; // Update the faq property with the fetched data
        })
        .addCase(fetchTaskType.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.error = action.error.message; // Store the error message
        });
    },
  });
  
  export default taskTypeSlice.reducer;
  