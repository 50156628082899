import React from "react";
import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Avatar, Chip, Tab, Tabs, Tooltip } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import "./ViewTask.css";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import Tag from "../../Components/Tag/Tag";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { useSelector } from "react-redux";
import DateFormatter from "../../Components/DateFormatter";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewTask = () => {

  const { tasks } = useSelector((state) => state?.tasks);


  const { id } = useParams();

  const foundObject = tasks.find((obj) => obj.id === id);

  if (foundObject) {
    // console.log("Found Object:", foundObject);
  } else {
    // console.log("Object not found");
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // console.log(foundObject);

  return (
    <div className="overflow-auto m-2  h-100">
      <div className="d-flex m-2 rounded-3  h-100  bg-white ps-5 pe-5 p-4  cad-shadow pb-5 flex-column gap-4">
        <div className="d-flex align-items-center justify-content-end position-relative">
          <Link
            to="/task"
            style={{ left: -24 }}
            className="text-primary h6 text-decoration-none d-flex align-items-center gap-3 pointer top-0 position-absolute"
          >
            <ArrowBackRoundedIcon /> View Task
          </Link>
          <span
            style={{
              right: -24,
              backgroundColor:
                foundObject?.mode?.title === "public" ? "#34A853" : "#FD394A",
              height: "100%",
              width: "90px",
            }}
            className="text-white rounded-2 ms-5 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
          >
            {foundObject?.mode?.title}
            <LocalOfferOutlinedIcon className="cad-text-large" />
          </span>
        </div>

        <div className="d-flex mt-4 flex-wrap position-relative">
          <div className="col-4 mb-5 d-flex flex-column gap-3">
            <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
              Task Name
            </div>
            <div className="cad-text-medium cad-fw-600 cad-text-dark">
              {foundObject?.title}
            </div>
          </div>


          
          <div className="col-4 mb-5 d-flex flex-column gap-3 ">
            <div className="cad-text-medium cad-text-light-grey cad-fw-500 col-3">
              Task type :
            </div>
            <div className="cad-text-medium cad-fw-600 cad-text-dark">
              {foundObject?.type?.title}
            </div>
          </div>

          

          <div className="col-4 mb-5 d-flex flex-column gap-3">
            <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
              Due Date :
            </div>
            <div className="cad-text-medium cad-fw-600 cad-text-dark">
              <DateFormatter inputDate={foundObject?.due_date} />
            </div>
          </div>



          <div className="col-4 mb-5 d-flex flex-column gap-3">
            <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
              Assigned Date :
            </div>
            <div className="cad-text-medium  cad-fw-600 cad-text-dark">
              <DateFormatter inputDate={foundObject?.createdAt} />
            </div>
          </div>


          {/* ===[ Status ]=== */}
          
          <div className="col-4 mb-5 d-flex flex-column gap-3">
            <div className="cad-text-medium cad-text-light-grey cad-fw-500 col-4">
              Priority{" "}
            </div>
            <Chip
              style={{ fontWeight: "600", width: "100px" }}
              size="small"
              className={`${
                foundObject?.priority?.title === "Open"
                  ? "chip-low"
                  : foundObject?.priority?.title === "Medium"
                  ? "chip-med"
                  : "chip-high"
              }`}
              label={
                foundObject?.priority?.title === "Open"
                  ? "Low"
                  : foundObject?.priority?.title === "Medium"
                  ? "Medium"
                  : "High"
              }
            />
          </div>

          <div className="col-4 mb-5 d-flex flex-column gap-3 ">
            <div className="cad-text-medium cad-text-light-grey cad-fw-500">
              Status :
            </div>
            <Chip
              style={{ fontWeight: "600", width: "100px" }}
              size="small"
              className={`${
                foundObject?.status.title === "Open"
                  ? "chip-low"
                  : foundObject?.status.title
                  ? "chip-med"
                  : "chip-high"
              }`}
              label={
                foundObject?.status.title === "Open"
                  ? "Open"
                  : foundObject?.status.title
                  ? "Pending"
                  : "On Going"
              }
            />
          </div>

          <div className="col-4 mb-5 d-flex flex-column gap-3 ">
            <div className="cad-text-medium cad-text-light-grey cad-fw-600 col-3">
              Assigned By :
            </div>
            {/* {foundObject?.employees.map((el, i) => ( */}
            <div
              // key={i}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Avatar
                style={{ width: "32px", height: "32px" }}
                className="p-0 cad-text-small"
                sizes="small"
                {...stringAvatar(`${foundObject?.employees[0]?.first_name}`)}
              />
              <div
                className="cad-text-medium cad-text-dark cad-fw-600"
                style={{ marginLeft: "6px", marginRight: "12px" }}
              >
                {foundObject?.employees[0]?.first_name}
              </div>
            </div>
            {/* ))} */}
          </div>

          <div className="col-4 mb-5 d-flex flex-column gap-3">
            <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
              Tag :
            </div>
            <div className="cad-text-medium d-flex gap-2 cad-text-dark cad-fw-600">
              {foundObject?.tags.map((tag, i) => (
                <Tag key={i} title={tag?.title} />
              ))}
            </div>
          </div>

          {/* <span style={{fontSize:"60px", bottom:"-80px", color:"#B8CFFF"}} className="position-absolute end-0 cad-fw-800 opacity-50">{foundObject?.type?.title}</span> */}
        </div>

        <div className="w-100 bg-warning">
          <Box
            className=" border-bottom "
            sx={{
              borderBottom: 0,
              padding: 0,
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tags"
              style={{
                height: "14px",
              }}
            >
              {/* Description Tab */}
              <Tooltip title="Description" placement="top-start">
                <Tab
                  style={{ textTransform: "none", display: "flex" }}
                  label="Description"
                  {...a11yProps(0)}
                />
              </Tooltip>
              {/* Grid View Tab */}
              <Tooltip title="Activity" placement="top-start">
                <Tab
                  style={{ textTransform: "none", display: "flex" }}
                  label="Activity"
                  {...a11yProps(1)}
                />
              </Tooltip>
              {/* Timeline View Tab */}
              <Tooltip title="Documents" placement="top-start">
                <Tab
                  style={{ display: "flex", textTransform: "none" }}
                  label="Documents"
                  {...a11yProps(2)}
                />
              </Tooltip>

              {/* <Button className="btn btn-primary"> Add Tasks</Button> */}
            </Tabs>
          </Box>

          {/* Content for different tabs */}
          {/* List View Content */}
          <CustomTabPanel
            className="w-100 bg-white overflow-auto"
            value={value}
            index={0}
          >
            <p className="cad-text-medium p-3 cad-fw-600">
              {foundObject?.description}{" "}
            </p>
          </CustomTabPanel>
          {/* Grid View Content */}
          <CustomTabPanel
            className="w-100 bg-white overflow-auto"
            value={value}
            index={1}
          >
            <p>Activity</p>
          </CustomTabPanel>
          {/* Timeline View Content */}
          <CustomTabPanel value={value} index={2}>
            {/* Display files here */}
            <div className="d-flex w-100  p-4 d-flex  gap-0 justify-content-evenly bg-white overflow-auto bg-warning ">
              {/* {foundObject?.documents.map((document, index) => (
                <div
                  key={index}
                  className="col-3 p-3 d-flex flex-column justify-content-center align-items-start"
                >
                  <div
                    style={{ height: "200px" }}
                    className="w-100 border rounded-2 align-items-center d-flex justify-content-center"
                  >
                    <img src={document.imgLink} alt="" />
                  </div>
                  <span className="p-2 border rounded-2 border-top-0 d-flex flex-column w-100">
                    <span className="cad-text-medium cad-fw-600 p-0 d-flex justify-content-between">
                      {document.fileName}
                      <Link
                        to={document.wordFile}
                        download="Example-PDF-document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <VerticalAlignBottomIcon className="pointer" />
                      </Link>
                    </span>
                    <span className="cad-text-small p-0 text-secondary">
                      {document.size}
                    </span>
                    <span className="cad-text-small p-0 text-secondary">
                      {document.lastUpdated}
                    </span>
                  </span>
                </div>
              ))} */}
            </div>
          </CustomTabPanel>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewTask;
