import { Button } from "@mui/material";
import React from "react";
import google from "../../../Images/google-icon.png";
import windows from "../../../Images/wondows-icon.png";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { emailCheck } from "../../../service/auth.service";
import { notify } from "../../../App";

const VerifyMail = ({ emailEntered }) => {
  
  const openGmail = () => {
    window.open("https://mail.google.com", "_blank"); // Opens link in a new tab/window
  };

  const openOutlook = () => {
    window.open("https://outlook.office.com", "_blank"); // Opens link in a new tab/window
  };

  const resendMail = async () => {
    try {
      const res = await emailCheck({email_address:emailEntered});
      notify("Please check your email");
    } catch (err) {
      notify(err?.data?.message);
    }
  };

  return (
    <div className="d-flex flex-column gap-2 justify-content-start h-100 ">

      <h2 style={{ color: "#3c3c3c" }} className="text-center cad-fw-800">
        Please verify your email
      </h2>
      <p
        style={{ color: "#3c3c3c" }}
        className="text-center cad-text-medium cad-fw-600 mb-5"
      >
        Once you verify your email address , you and your team can get started
      </p>

      <Button
        onClick={openGmail}
        className=" mt-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd"
      >
        <img className="me-3" width={24} src={google} alt="" />
        <span>OPEN GMAIL</span>
      </Button>

      <Button
        onClick={openOutlook}
        className=" mt-2 mb-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd"
      >
        <img className="me-2" width={28} src={windows} alt="" />
        <span>OPEN OUTLOOK</span>
      </Button>
      <p className="text-center cad-text-small mt-2">
        Didn’t Received an email ?{" "}
        <u className="pointer" onClick={resendMail}>
          Resend Email
        </u>{" "}
      </p>
    </div>
  );
};

export default VerifyMail;
