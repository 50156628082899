import * as React from "react";
import Modal from "@mui/material/Modal";
import { Button, Paper } from "@mui/material";
import GlobalStateContext from "../../Contexts/GlobalStateContext";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createSupportTicket } from "../../service/support.service";
import { useDispatch } from "react-redux";
import { fetchSupportTicket } from "../../Redux/Slice/supportSlice";

const schema = yup.object().shape({
  subject: yup.string().required("Subject is required"),
  discription: yup.string().required("Description is required"),
});

const CheckTicketModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(schema) });

  // const { generateTicketTable, setGenerateTicketTable } =
  //   React.useContext(GlobalStateContext);
  const handleClose = () => {
    setOpen(false);
    reset(); // Reset the form
  };

  const dispatch = useDispatch();

  const onSubmit = async (ticketData) => {
    const { status, data } = await createSupportTicket({
      title: ticketData.subject, // Get the subject from form data
      description: ticketData.discription, // Get the discription from form data
    });
    // console.log(status, data);
    reset();
    handleClose();
    dispatch(fetchSupportTicket());

  };

  return (
    <div className="p-2">
      <Modal
        open={open}
        className="cad-modal-blur p-2"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-discription"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            p: 2,
            pt: 3,
            backgroundColor: "#fff",
            // height: 300,
            borderRadius: 8,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <div className="text-primary d-flex justify-content-between mb-3">
              <span className="cad-text-large cad-fw-600">Generate Ticket</span>
              <span
                onClick={handleClose}
                className="cad-text-small cad-fw-600 pointer action-hover rounded-2"
              >
                <CloseIcon />
              </span>
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label cad-text-medium cad-fw-600"
              >
                Subject <span className="text-danger">*</span>
              </label>
              <input
                {...register("subject")}
                type="text"
                className="form-control cad-text-medium"
                id="exampleFormControlInput1"
                placeholder="Ticket subject"
              />
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label cad-text-small text-danger cad-fw-600"
              >
                {errors.subject?.message}
              </label>
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label cad-text-medium cad-fw-600"
              >
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                {...register("discription")}
                className="form-control cad-text-medium"
                id="exampleFormControlTextarea1"
                rows="3"
              />
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label cad-text-small text-danger cad-fw-600"
              >
                {errors.discription?.message}
              </label>
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-3">
                <Button type="submit" className="button-contained">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default CheckTicketModal;
