import React, { useEffect, useState } from "react";
import "./Dashboard.css"; // Importing styles
import DashboardSmallCard from "../../Components/DashboardSmallCard.js/DashboardSmallCard"; // Importing DashboardSmallCard component
import LineChart from "../../Components/Charts/LineCart/LineChart"; // Importing LineChart component
import Doughnut from "../../Components/Charts/DoughnutChart/Doughnut"; // Importing Doughnut component
import { Alert, Avatar, Button, Modal, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import DateFormatter from "../../Components/DateFormatter";
import { getPlans, makePayment } from "../../service/billing.service";
import { Box } from "@mui/system";

const Dashboard = () => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const { globalUsers } = useSelector((state) => state?.globalUser);
  const { globalCompany } = useSelector((state) => state?.globalCompany);
  const { clients } = useSelector((state) => state?.globalClient);
  const { payments } = useSelector(
    (state) => state?.paymentHistory
  );
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchPlans = async () => {
    try {
      const res = await getPlans();
      setPlans(res?.data?.data);
      return res;
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  };

  // console.log("===========================", plans[0]?.unit_amount);

  useEffect(() => {
    fetchPlans();
  }, []);

  const { authInfo } = useSelector((state) => state?.fetchAuthMe);
  // console.log(authInfo);

  const rolbase = useSelector((state) => state?.authMe);
  const { tasks } = useSelector((state) => state?.tasks);

  // Function to get dynamic labels based on current day interval from start date
  function getDynamicLabels(startDate) {
    const currentDate = new Date(); // Get the current date
    const daysDifference = Math.ceil(
      (currentDate - startDate) / (1000 * 60 * 60 * 24)
    ); // Calculate difference in days

    return daysDifference;
  }

  function getDaysRemaining(targetDateString) {
    // Get the current date
    const currentDate = new Date();

    // Set the target date
    const targetDate = new Date(targetDateString);

    // Calculate the difference in milliseconds between the two dates
    const differenceInMs = targetDate.getTime() - currentDate.getTime();

    // Convert the difference to days
    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    return differenceInDays;
  }

  const startDate = new Date(authInfo?.createdAt);
  const subscriptionExpDate = getDaysRemaining(
    payments[0]?.transactions?.effective_to
  );
  const expiryDate = getDynamicLabels(startDate);
  const expiryDateFreeTriel = authInfo?.business[0]?.trial_days;
  const [dashboardCardDetails, setDashboardCardDetails] = useState([
    {
      title: "Users",
      iconName: "GoPeople",
      count: globalUsers?.length === 0 ? "0" : globalUsers?.length,
      disc: "Current active users",
    },
    {
      title: "Company",
      iconName: "BiBriefcaseAlt2",
      count: globalCompany?.length === 0 ? "0" : globalCompany?.length,
      disc: "Current active companies",
    },
    {
      title: "Contacts",
      iconName: "IoCallOutline",
      count: clients?.length === 0 ? "0" : clients?.length,
      disc: "Current active contacts",
    },
  ]);

  const [userDashboardCardDetails, setUserDashboardCardDetails] = useState([
    {
      title: "Total Tasks",
      iconName: "BiBriefcaseAlt2",
      count: tasks?.length === 0 ? "0" : tasks?.length,
      disc: "Current active Tasks",
    },
    {
      title: "Total clients",
      iconName: "IoCallOutline",
      count: clients?.length === 0 ? "0" : clients?.length - 1,
      disc: "Current active users",
    },
  ]);

  const handleMakePayment = async (id) => {
    setOpen(true);
    try {
      const res = await makePayment(id);
      const stripePaymentLink = res?.data?.data;
      setOpen(false);
      window.location.href = stripePaymentLink;
      return res;
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  };

  return (
    <div className="dashboard mb-4 pb-1  overflow-auto  align-items-start w-85 row p-0 rounded-2 fs-2 fw-bold text-white">
      {rolbase?.resources.length === 0 ? (
        authInfo?.business[0]?.subscribed_user ? (
          ""
        ) : (
          <div  className="col-lg-4 col-12 p-2">
            <Paper
              style={{
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
                height:180
              }}
              elevation={1}
              className="upgrade-plan-card overflow-hidden justify-content-between d-flex flex-column w-100 "
            >
              {payments[0]?.transactions?.effective_from !== undefined ? (
                <Alert
                  className="cad-fw-500 rounded-0"
                  style={{
                    backgroundColor: "rgba(84, 140, 255, 0.176)",
                    color: themeColorsObject.textColor,
                  }}
                  severity="info"
                >
                  Your subscrition will expire in{" "}
                  {subscriptionExpDate - expiryDate} days
                </Alert>
              ) : (
                <Alert className="cad-fw-500 rounded-0" severity="warning">
                  Your free trial will expire in{" "}
                  {expiryDateFreeTriel - expiryDate + 1} days
                </Alert>
              )}

              {/* Upgrade Plan Details */}
              <div
                style={{
                  backgroundColor: themeColorsObject.megaCardBackgroundColor,
                  color: themeColorsObject.textColor,
                }}
                className="pb-3 d-flex justify-content-evenly"
              >
                {/* <div className="d-flex flex-column justify-content-center">
                  <span className="cad-text-grey cad-text-medium">Status</span>
                  <p className=" cad-text-small cad-fw-500">
                    {payments[0]?.transactions?.effective_from === undefined
                      ? "Inactive"
                      : "Active"}
                  </p>
                </div> */}
                <div className="d-flex flex-column justify-content-center gap-2">
                  <span className="cad-text-grey cad-text-medium">
                    Free trial end date.
                  </span>
                  <p className="cad-text-small cad-fw-500">
                    {authInfo?.business[0]?.trial_end === undefined ? (
                      "Subscription Unpaid"
                    ) : (
                      <DateFormatter
                        inputDate={authInfo?.business[0]?.trial_end}
                      />
                    )}
                  </p>
                </div>
                <div className="d-flex flex-column justify-content-center gap-2">
                  <span className="fw-bold cad-text-large">
                    {`${plans[0]?.unit_amount} ₹ / m`}
                  </span>
                  <Button
                    className={` text-capitalize w-100 ps-2 pe-2 p-0 cad-text-medium`}
                    variant="contained"
                    onClick={() => handleMakePayment(plans[0]?.id)}
                  >
                    Subscribe now
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        )
      ) : (
        ""
      )}

      {/* Small Cards */}

      {rolbase?.resources?.length === 0 ? (
        ""
      ) : (
        <div draggable className="parrent col-lg col-12 p-2">
          <Paper
            elevation={1}
            style={{
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.titleText,
            }}
            className="dashboard-small-cards p-4 d-flex justify-content-start align-items-center gap-4"
          >
            <Avatar
              alt="Remy Sharp"
              src={
                process.env.REACT_APP_DOWLOAD_URL +
                "/" +
                authInfo?.profile_photo
              }
              sx={{ width: 80, height: 80 }}
            />
            <div className="d-flex align-items-start justify-content-center flex-column ">
              <span
                style={{
                  color: themeColorsObject?.titleText,
                }}
                className="cad-fw-600 fs-5"
              >
                Welcome,
              </span>

              <span
                style={{
                  color: themeColorsObject?.titleText,
                }}
                className="cad-fw-500 fs-6"
              >
                {authInfo?.first_name} {authInfo?.last_name}
              </span>
            </div>
          </Paper>
        </div>
      )}

      {rolbase?.resources.length === 0
        ? dashboardCardDetails.map((eachEl, index) => (
          <DashboardSmallCard
            subscribed={authInfo?.business[0]?.subscribed_user}
            agent={rolbase?.resources.length === 0}
            key={index}
            iconName={eachEl.iconName}
            title={eachEl.title}
            count={eachEl.count}
            disc={eachEl.disc}
          />
        ))
        : userDashboardCardDetails.map((eachEl, index) => (
          <DashboardSmallCard
            agent={rolbase?.resources.length === 0}
            key={index}
            iconName={eachEl.iconName}
            title={eachEl.title}
            count={eachEl.count}
            disc={eachEl.disc}
          />
        ))}

      {/* Line Chart */}

      {rolbase?.resources.length === 0 ? (
        <div className="col-lg-6 col-12 p-2">
          <LineChart themeColorsObject={themeColorsObject} />
        </div>
      ) : (
        ""
      )}

      {/* Doughnut Chart */}
      {rolbase?.resources.length === 0 ? (
        <div className="col-lg-6 col-12 p-2 ">
          <Doughnut themeColorsObject={themeColorsObject} />
        </div>
      ) : (
        ""
      )}

      {/* Map */}
      {rolbase?.resources.length === 0 ? (""
        // <div className="col-12 d-flex justify-content-center p-2">
        //   <Map themeColorsObject={themeColorsObject} />
        // </div>
      ) : (
        ""
      )}

      <Modal
        open={open}
        // onClose={()=>setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.titleText,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span className="cad-text-large">Please wait...</span>
        </Box>
      </Modal>
    </div>
  );
};

export default Dashboard;
