import * as React from "react";
import { Chip, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { AiOutlineDelete, AiOutlineUpload } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteTask } from "../../../service/task.service";
import {
  fetchTaskData,
  fetchTaskDataByID,
  nextPage,
} from "../../../Redux/Slice/taskSlice";
import PrimaryButton from "../../../Components/PrimaryButton";
import { fetchRequestDocs } from "../../../Redux/Slice/requestDocsSlice";
import DateFormatter from "../../../Components/DateFormatter";
import EditModal from "../EditModal";
import ViewModal from "../ViewModal";
import { useEffect } from "react";
import TableData from "../../../Components/TableData";
import Refresher from "../../../Components/Refresher";
import LightTooltip from "../../../Components/Tooltip/LightTooltip";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import { fetchSharedDocs } from "../../../Redux/Slice/getSharedDocs";
import AlertModal from "../../../Components/AlertModal";
import { notify } from "../../../App";
import AdvanceFilter from "../../../Components/AdvanceFilter/AdvanceFilter";
import SecondaryButton from "../../../Components/SecondaryButton";
import GroupAvatars from "../../../Components/Avatar/AvatarGroup";
import { Box } from "@mui/system";
import { fetchKanbanData } from "../../../Redux/Slice/kanbanSlice";

const TaskListView = ({
  themeColorsObject,
  setOpenAddTaskModal,
  setOpenShareDocumentModal,
  setOpenReciveDocumentModal,
  setOpenFillFormModal,
  operationTaskId,
  setSelectedTaskTypeId,
  setOperationTaskId,
}) => {
  const [deleteId, setDeleteId] = useState(null);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [openView, setOpenView] = useState(false);
  const [filteredCellConfig, setFilteredCellConfigg] = useState([]);
  const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([]);
  const dispatch = useDispatch();
  const { isLoading, currentPage, totalPages, mappingTaskData,  } = useSelector(
    (state) => state?.tasks
  );
  const { mappingKanbanData } = useSelector((state) => state?.kanban);

  useEffect(() => {
    dispatch(fetchTaskData(searchTerm ? searchTerm : undefined));
    dispatch(fetchKanbanData());
  }, [dispatch, searchTerm]);

  // console.log(mappingTaskData);

  // const filteredTaskArray = mappingTaskData?.filter((row) => {
  //   return (
  //     row?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     row?.priority?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     row?.employees[0]?.first_name
  //       .toLowerCase()
  //       .includes(searchTerm?.toLowerCase()) ||
  //     row?.status?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
  //   );
  // });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleEditOpen = (id) => {
    setOperationTaskId(id);
    setOpenEdit(true);
    dispatch(fetchTaskDataByID(id));
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleViewOpen = (id) => {
    dispatch(fetchRequestDocs(id));
    dispatch(fetchTaskDataByID(id));
    dispatch(fetchSharedDocs(id));
    // const foundObject = tasks.find((obj) => obj.id === id);
    // foundObject?.type?.title === "Recevied Document"
    //   ? dispatch(fetchRequestDocs(id))
    //   : dispatch(fetchTaskDataByID(mouseEnteredId));
    setOpenView(true);
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const handleDelete = async (taskId) => {
    try {
      await deleteTask(taskId);
      dispatch(fetchTaskData());
      handleClose();
      notify("Task deleted successfully");
    } catch (error) { }
  };

  const handlePagination = (value) => {
    dispatch(nextPage(value));
    dispatch(fetchTaskData());
  };

  const extractedDataArray = mappingTaskData?.map((user, index) => {
    const extractedData = {
      // srNo: index + 1,
      Owner: (
        <Box display={"flex"} justifyContent={"flex-start"} >
          <GroupAvatars data={user?.employees} />
        </Box>
      ),
      id: user.id,
      taskName: user.title,
      priority: (
        <Chip
          style={{ fontWeight: "500" }}
          size="small"
          className={`${user?.priority?.title === "Open"
              ? "chip-onhold"
              : user?.priority?.title === "High"
                ? "chip-high"
                : user?.priority?.title === "Medium"
                  ? "chip-med"
                  : "chip-low"
            }`}
          label={user?.priority?.title}
        />
      ),
      assignedDate: <DateFormatter inputDate={user?.createdAt} />,
      clients: (
        // <UserProfile
        //   proPic={user?.employees[0]?.profile_photo}
        //   companyName={user?.employees[0]?.first_name}
        // />
        <Box display={"flex"} justifyContent={"start"}>
          <GroupAvatars data={user?.clients} />
        </Box>
      ),
      status: (
        <Chip
          style={{ fontWeight: "500" }}
          size="small"
          className={`${user?.status.title === "Open"
              ? "chip-high"
              : user?.status.title === "On Hold"
                ? "chip-onhold"
                : user?.status.title === "In Progress"
                  ? "chip-med"
                  : "chip-low"
            }`}
          label={
            user?.status.title === "Open"
              ? "Open"
              : user?.status.title === "On Hold"
                ? "On Hold"
                : user?.status.title === "In Progress"
                  ? "In Progress"
                  : "Completed"
          }
        />
      ),
      dueDate:
        mouseEntered && mouseEnteredId === user?.id ? (
          <div className="d-flex justify-content-center">
            {/* <Link to={`/edit-task/${id}`}> */}
            <Tooltip title="Edit" placement="top-start">
              <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover text-primary"
              >
                <AiOutlineEdit
                  onClick={() => handleEditOpen(user?.id)}
                  style={{ fontSize: "20px" }}
                />
              </span>
            </Tooltip>

            <Tooltip title="Delete" placement="top-start">
              <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover text-primary"
              >
                <AiOutlineDelete
                  onClick={() => handleOpen(user?.id)}
                  style={{ fontSize: "20px" }}
                />
              </span>
            </Tooltip>

            {/* <Tooltip title="View" placement="top-start">
              <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover text-primary"
              >
                <RemoveRedEyeOutlinedIcon
                  onClick={() => handleViewOpen(user?.id)}
                  style={{ fontSize: "20px" }}
                />
              </span>
            </Tooltip> */}
          </div>
        ) : (
          <DateFormatter inputDate={user?.due_date} />
        ),
    };

    return extractedData;
  });

  const [checkboxValues, setCheckboxValues] = useState({
    // "Sr No.": false,
    Owner: true,
    "Task Name": true,
    Priority: true,
    "Assigned Date": true,
    "Assigned Clients": true,
    Status: true,
  });

  const cellConfig = [
    {
      dataKey: "Owner",
      width: "5%",
      align: "left",
    },
    {
      dataKey: "taskName",
      width: "23%",
      align: "left",
    },
    {
      dataKey: "priority",
      width: "10%",
      align: "center",
    },
    {
      dataKey: "assignedDate",
      width: "14%",
      align: "center",
    },
    {
      dataKey: "clients",
      width: "14%",
      align: "center",
    },
    {
      dataKey: "status",
      width: "10%",
      align: "center",
    },
    {
      dataKey: "dueDate",
      width: "14%",
      align: "center",
    },
  ];

  const tableHeadRowCell = [
    {
      title: "Owner",
      width: "5%",
      align: "left",
    },
    {
      title: "Task Name",
      width: "23%",
      align: "left",
    },
    {
      title: "Priority",
      width: "10%",
      align: "center",
    },
    {
      title: "Assigned Date",
      width: "10%",
      align: "center",
    },
    {
      title: "Assigned Clients",
      width: "14%",
      align: "left",
    },
    {
      title: "Status",
      width: "10%",
      align: "center",
    },
    {
      title: "Due Date",
      width: "14%",
      align: "center",
    },
  ];

  // Calculate total number of tasks
  const totalTasks = mappingKanbanData.reduce(
    (total, category) => total + category.tasks.length,
    0
  );

  return (
    <section className="h-100">
      <div className="d-flex p-3 w-100 justify-content-between">
        <div className="col-md-3 col-12 d-flex align-itmes-center">
          {/* <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="search"
            placeholder="Search..."
            // className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
            className={`form-control cad-text-medium `}
            style={{
              backgroundColor: themeColorsObject.backgroundColor,
              color: themeColorsObject.textColor,
              '::placeholder': {
                  color: themeColorsObject.textColor , // Use the provided placeholder color or a default color
                  fontStyle: 'italic' // Customize other placeholder styles if needed
              },
              WebkitTextFillColor: themeColorsObject.textColor// For Safari compatibility
          }}
          /> */}

          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="d-flex w-100 align-items-center gap-2 justify-content-end">
          <div className="d-flex  gap-3 align-items-center">
            <div
              className="task-progressbar pointer d-flex"
              style={{
                backgroundColor: "#E0E0E0",
                height: 20,
                borderRadius: 4,
                overflow: "hidden",
              }}
            >
              <LightTooltip
                color="#e74c3c"
                title={`${mappingKanbanData[0]?.title} tasks ${mappingKanbanData[0]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${(mappingKanbanData[0]?.tasks?.length / totalTasks) * 100
                      }%`,
                    backgroundColor: "#e74c3c",

                    height: "100%",
                    transition: "0.5s",
                    // borderRadius: "4px 0px 0px 4px",
                  }}
                />
              </LightTooltip>

              <LightTooltip
                color="#E39C009d"
                title={`${mappingKanbanData[1]?.title} tasks ${mappingKanbanData[1]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${(mappingKanbanData[1]?.tasks?.length / totalTasks) * 100
                      }%`,
                    backgroundColor: "#E39C009d",
                    height: "100%",
                    transition: "0.5s",
                    // borderRadius: 0,
                  }}
                />
              </LightTooltip>

              <LightTooltip
                color="#548CFF9d"
                title={`${mappingKanbanData[2]?.title} tasks ${mappingKanbanData[2]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${(mappingKanbanData[2]?.tasks?.length / totalTasks) * 100
                      }%`,
                    backgroundColor: "#548CFF9d",
                    height: "100%",
                    transition: "0.5s",
                    // borderRadius: 0,
                  }}
                />
              </LightTooltip>

              <LightTooltip
                color="#34A8539d"
                title={`${mappingKanbanData[3]?.title} tasks ${mappingKanbanData[3]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${(mappingKanbanData[3]?.tasks?.length / totalTasks) * 100
                      }%`,
                    backgroundColor: "#34A8539d",
                    height: "100%",
                    transition: "0.5s",
                    cursor: "pointer",
                    ":hover": {
                      scale: 1.5,
                    },
                    // borderRadius: "0px 4px 4px 0",
                  }}
                />
              </LightTooltip>
            </div>
          </div>

          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(fetchTaskData())}
          />

          <PrimaryButton
            variant="contained"
            title="Add New Task"
            padding="0.3rem 0.6rem"
            icon={<AddIcon size={13} />}
            fontSize="14px"
            width="200px"
            // path={"/add-task"}
            onClick={() => setOpenAddTaskModal(true)}
          />



          <AdvanceFilter
            bottom="-170px"
            left="-67px"
            cellConfig={cellConfig}
            tableHeadRowCell={tableHeadRowCell}
            setFilteredCellConfig={setFilteredCellConfigg}
            setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
          />
        </div>
      </div>

      <TableData
        tableHeadRowCell={filteredTableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={filteredCellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        handleViewOpen={handleViewOpen}
        totalPages={totalPages}
        handlePagination={handlePagination}
        currentPage={currentPage}
        isLoading={isLoading}
      />

      <AlertModal
        open={open}
        message="Are you sure you want to delete client?"
        closeModal={handleClose}
        handler={() => {
          handleDelete(deleteId);
        }}
      />

      <ViewModal
        themeColorsObject={themeColorsObject}
        open={openView}
        handleOpen={handleViewOpen}
        handleClose={handleViewClose}
        id={mouseEnteredId}
        setOpenFillFormModal={setOpenFillFormModal}
        setOperationTaskId={setOperationTaskId}
        operationTaskId={operationTaskId}
        setOpenShareDocumentModal={setOpenShareDocumentModal}
        setOpenReciveDocumentModal={setOpenReciveDocumentModal}
      />

      <EditModal
        setSelectedTaskTypeId={setSelectedTaskTypeId}
        themeColorsObject={themeColorsObject}
        open={openEdit}
        handleOpen={handleEditOpen}
        handleClose={handleEditClose}
        id={mouseEnteredId}
        setOperationTaskId={setOperationTaskId}
        operationTaskId={operationTaskId}
        setOpenReciveDocumentModal={setOpenReciveDocumentModal}
        setOpenShareDocumentModal={setOpenShareDocumentModal}
      />
      {/* <ToastContainer className="cad-text-small p-0"/> */}
    </section>
  );
};

export default TaskListView;
