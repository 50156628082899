import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getByIdClient } from "../../service/client.service"; // Import your client service function here

export const getClientById = createAsyncThunk(
  "getClientById/getClientById",
  async (id) => {
    try {
      const res = await getByIdClient(id);
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  }
);

const getClientByIdSlice = createSlice({
  name: "clientByIdData",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    clientDetails: null,
    // Define additional state properties if needed
  },
  reducers: {
    clientDetailsByIdData: (state, action) => {
      // console.log(action.payload);
      state.clientDetails = action.payload;
    },
    // Define additional reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
        // Handle other common state updates if needed
      })
      .addCase(getClientById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clientDetails = action.payload;
        // Handle other state updates specific to the fulfilled case if needed
      })
      .addCase(getClientById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error?.message || action.data?.error;
        // Handle other state updates specific to the rejected case if needed
      });
  },
});

export default getClientByIdSlice.reducer;
export const { clientDetailsByIdData } = getClientByIdSlice.actions;
