import React, { useState } from "react";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { Avatar, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import Footer from "../Footer/Footer";
import { useFormik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import VerifiedIcon from "@mui/icons-material/Verified";
import { updateAgent } from "../../service/agent.service";
import { useDispatch } from "react-redux";
import { AuthMe } from "../../Redux/Slice/auth/auth.slice";
import { fetchAuthMe } from "../../Redux/Slice/fetchAuthMe";
import * as themeColors from "../../Utils/Theme/Colors";
import { notify } from "../../App";
import { Password } from "@mui/icons-material";
import { changePassword } from "../../service/auth.service";

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

const PersonalDetailsNew = () => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  // console.log(themeColorsObject.backgroundColor);

  const [isEditing, setIsEditing] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const { isLoading, authInfo } = useSelector((state) => state?.fetchAuthMe);
  const metadata = authInfo?.metadata;
  
  const { profileData } = useSelector((state) => state?.authMe);
  const [profileDetails, setProfileDetails] = useState({
    profilePic:
      process.env.REACT_APP_DOWLOAD_URL + "/" + authInfo?.profile_photo,
  });

  const [passwordChange, setPasswordChange] = useState({
    password: "",
    confirmPassword: "",
  });

  const [ isResetLoading, setIsResetLoading ] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(passwordChange);
  };

  const dispatch = useDispatch();

  // console.log(authInfo);

  const profileDataArray = [
    {
      title: "First Name",

      value: authInfo?.first_name,
    },
    {
      title: "Last Name",
      value: authInfo?.last_name,
    },
    // {
    //   title: "Date Of Birth",
    //   value: authInfo?.date_of_birth,
    // },
    // {
    //   title: "Register Date",
    //   value: formatDate(authInfo?.createdAt),
    // },
    {
      title: "Phone Number",
      value:   "+" +authInfo?.phone_number,
    },
    {
      title: "Email Address",
      value: authInfo?.email_address,
    },
    {
      title: "Designation",
      value: authInfo?.designation || "Not Define",
    },
    // {
    //   title: "Department",
    //   value: metadata?.department,
    // },
    // {
    //   title: "Location",
    //   value: metadata?.location,
    // },
  ];



  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/^[A-Za-z]+$/, "First Name must contain only letters")
      .min(3, "First Name must be at least 3 characters"),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(/^[A-Za-z]+$/, "Last Name must contain only letters")
      .min(3, "Last Name must be at least 3 characters"),
    // date_of_birth: Yup.date().nullable(),
    // createdAt: Yup.date().nullable(),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{6,16}$/, "Invalid Phone number"),
    // designation: Yup.string().required("Designation is required")
    // .min(3, "Designation must be at least 3 characters"),
    // department: Yup.string().required("Department is required")
    // .min(3, "Department must be at least 3 characters"),
    // location: Yup.string().required("Location is required")
    // .min(3, "Location must be at least 3 characters"),
    email_address: Yup.string()
      .required("Email Address is required")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .email("Invalid email address"),
  });

  // Initial form values from authInfo
  const initialValues = {
    first_name: authInfo?.first_name || "",
    last_name: authInfo?.last_name || "",
    date_of_birth: authInfo?.date_of_birth || null,
    createdAt: formatDate(authInfo?.createdAt) || null,
    phone_number: authInfo?.phone_number || "",
    designation: authInfo?.designation || "",
    department: metadata?.department || "",
    location: metadata?.location || "",
    email_address: authInfo?.email_address || "",
  };

  
  const profileSchema = Yup.object().shape({
    profile_photo: Yup.mixed()
      .test("required", "You need to provide a file", (files) => {
        // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
        if (files) return true;
        return false;
      })
      .test(
        "fileSize",
        " The maximum size of profile picture is 2MB.",
        (files) => {
          //if u want to allow only certain file sizes
          try {
            if (files.length !== 0) {
              return files[0].size <= 2000000;
            }
            return true;
          } catch (error) {
            return false;
          }
        }
      )
      .test("file_formate", "Image file has unsupported format.", (files) => {
        // // console.log(files[0].type)

        const SUPPORTED_FORMATS = [
          "image/jpeg",
          "image/png",
          // "image/gif",
          "image/tiff",
          "image/svg+xml",
        ];
        try {
          if (files.length !== 0) {
            setProfileDetails({...profileDetails, profilePic :URL.createObjectURL(files[0])});
            return files && SUPPORTED_FORMATS.includes(files[0].type);
          }
          return true;
        } catch (error) {
          return false;
        }
      })
      .optional(),
  });


  const handleImageChange = async (e) => {
    // console.log("hit");
    const file = e.target.files[0];

    const formData = new FormData();

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileDetails({
          ...profileDetails,
          profilePic: event.target.result,
        });
      };
      reader.readAsDataURL(file);

      formData.append("profile_photo", file);
      try {
        const res = await updateAgent(formData);
        notify("Profile Updated Successfully");
        dispatch(fetchAuthMe());
        dispatch(AuthMe());
        // console.log(res);
      } catch (error) {
        notify(error?.data?.error?.message);
      }
    }
  };

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Password do not match"),
  });

  const {
    handleSubmit: handlePasswordSubmit,
    formState: { errors: password_errors },
    reset,
    register,
  } = useForm({
    resolver: yupResolver(passwordValidationSchema),
  });


  const passwordField = [
    {
      title: "New Password",
      value: "",
      registerName:"password",
      passwordError: password_errors.password,
    },
    {
      title: "Confirm Password",
      value: "",
      registerName:"confirmPassword",
      passwordError: password_errors.confirmPassword,
    },
  ];

  console.log(password_errors);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (data) => {
    console.log("hit");
    console.log("Form submitted with values:", data);
    // console.log("Form submitted with values:", data);
    // Create FormData object
    const formData = new FormData();

    // formData.append("location", data?.location);
    // formData.append("department", data?.department);
    formData.append("designation", data?.designation);
    formData.append("phone_number", data?.phone_number);
    // formData.append("createdAt", data?.createdAt);
    // formData.append("date_of_birth", data?.date_of_birth);
    formData.append("last_name", data?.last_name);
    formData.append("first_name", data?.first_name);
    // formData.append("profile_photo");

    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const res = await updateAgent(formData);
      console.log(res?.data?.statusCode);

      if (res?.data?.statusCode === 200) {
        notify("Profile Updated Successfully");
      }
      // console.log(res);
    } catch (error) {
      // console.log(error);
    }

    dispatch(fetchAuthMe());
    dispatch(AuthMe());
    setIsEditing(false);
  };

  const onPasswordSubmit = async (data) => {
    setIsResetLoading(true)
    try {
      const res = await changePassword(data)
      notify(res?.data?.message)
      setIsResetLoading(false)
      reset()
      setIsPassword(false)


    } catch (error) {
      notify(error?.data?.error?.message, "error")
      setIsResetLoading(false)
      reset()
    }
  };

  const onProfileSubmit = (data) => {
    console.log(data);
  }

  
  const {
    register: profileReg,
    handleSubmit : handleProfileUpdate,
    formState: { errors: profileUpdate },
    reset: profileReset,
    // getValues,
    // getFieldState,
  } = useForm({ mode: "all", resolver: yupResolver(profileSchema) });


  return (
    <div
      style={{
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        minHeight: "82vh",
      }}
      className="mt-2 mb-4 gap-3 cad-shadow rounded-3  flex-column  d-flex justify-content-start align-items-start"
    >
      <div className="w-100   border-white justify-content-between flex-column  rounded-2 gap-1 d-flex p-4 ">
        <div className="gap-4 d-flex ms-1">
          <form
            style={{
              position: "relative",
            }}
            onSubmit={handleProfileUpdate(onProfileSubmit)}
          >
            <Avatar
              className="m-1"
              style={{
                width: "100px",
                height: "100px",
                border: "4px solid #E9E9E9",
              }}
              alt={authInfo?.first_name}
              src={profileDetails?.profilePic}
            />

            <input
              type="file"
              id="profile-image-input"
              accept="image/*"
              style={{
                display: "none",
              }}
              {...profileReg("profile_photo")}

              onChange={handleImageChange}
              className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
            />
            {profileUpdate?.profile_photo && (
              <span className="text-danger cad-text-small cad-fw-600">
                {profileUpdate?.profile_photo?.message}
              </span>
            )}

            <label
              style={{
                position: "absolute",
                bottom: "0.8rem",
                right: "-0.5rem",
                height: "30px",
                width: "30px",
                backgroundColor: "#E9E9E9",
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              htmlFor="profile-image-input"
            >
              <CameraAltTwoToneIcon
                sx={{
                  position: "absolute",
                  bottom: "0rem",
                  right: "0rem",
                  height: "30px",
                  width: "30px",
                  backgroundColor: "#E9E9E9",
                  padding: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
            </label>
          </form>

          <div className="pro-details d-flex justify-content-center flex-column">
            <span className="cad-fw-600 d-flex gap-2 cad-text-large">
              {authInfo?.first_name} {authInfo?.last_name}
            </span>
            <span className="cad-fw-400 text-grey cad-text-medium">
              {authInfo?.phone_number}
            </span>
            <span className="cad-fw-400 text-grey cad-text-medium">
              {authInfo?.business[0]?.business_name}
            </span>
          </div>
        </div>

        <span className="cad-text-small text-secondary">
          The maximum size of profile picture should be 2MB.
        </span>
      </div>

      <div className="w-100 d-flex gap-3 justify-content-end align-items-center pe-3">
        {isPassword ? (
          ""
        ) : (
          <div className="col-1 d-flex align-items-center">
            <Button
              className="button-contained col-1"
              variant="contained"
              size="small"
              onClick={() => setIsEditing(!isEditing)}
            >
              {isEditing ? "Close" : "Edit"}
            </Button>
          </div>
        )}

        {isEditing ? (
          " "
        ) : (
          <div className="col-2 d-flex align-items-center">
            <Button
              size="small"
              className="button-contained col-1"
              variant="contained"
              onClick={() => setIsPassword(!isPassword)}
            >
              {isPassword ? "Close" : "Password"}
            </Button>
          </div>
        )}
      </div>

      {isPassword ? (
        <div
          style={{
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          }}
          className="w-100  rounded-2 d-flex flex-wrap p-3"
        >
          <form
            style={{
              display: "block",
              width: "100%",
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.textColor,
            }}
            onSubmit={handlePasswordSubmit(onPasswordSubmit)}
          >
            <div className=" rounded-2 w-100 d-flex justify-content-center flex-wrap p-3">
              {passwordField.map(({ title, value, name, passwordError, registerName }, index) => (
                <div
                  className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                  key={index}
                >
                  {/* <label
                      className="cad-text-small cad-fw-500 text-uppercase"
                      htmlFor={title}
                    >
                      {title}
                    </label> */}

                  <TextField
                    label={title}
                    type="password"
                    {...register(registerName)}
                    size="small"
                    error={!!passwordError}
                    helperText={passwordError?.message}
                  />
                </div>
              ))}
              <div className="w-100 d-flex justify-content-center">
                <div className="col-1 d-flex align-items-center">
                  <Button
                    className="button-contained col-1"
                    variant="contained"
                    size="small"
                    type="submit"
                  >{isResetLoading ? "Loading..." : 
                    "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          }}
          className="w-100  rounded-2 d-flex flex-wrap p-3"
        >
          {profileDataArray.map(({ title, value }, index) => (
            <div
              className="col-3 mb-3 d-flex flex-column gap-1 p-2"
              key={index}
            >
              <span className="cad-text-small cad-fw-500 text-uppercase">
                {title}
              </span>
              <span className="cad-text-medium cad-fw-600">{value}</span>
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          overflow: "hidden",
          display: "block",
          width: "100%",
          height: isEditing ? "fit-content" : 0,
          opacity: isEditing ? 1 : 0,
          transition: "height 2s, opacity 1s",
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        className={`${isEditing ? " rounded-3" : ""}`}
      >
        <form
          style={{
            transform: isEditing ? "translateY(0)" : "translateY(-210px)",
            opacity: isEditing ? 1 : 0,
            zIndex: isEditing ? 1 : -2,
            transition: "transform 0.5s, opacity 1s",
            display: "block",
            width: "100%",
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className=" rounded-2 d-flex flex-wrap p-3">
            {profileDataArray.map(({ title, value }, index) =>
              title === "Date Of Birth" ||
              title === "Register Date" ||
              title === "Email Address" ? (
                <div
                  className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                  key={index}
                >
                  <span className="cad-text-small cad-fw-500 text-uppercase">
                    {title}
                  </span>
                  <span className="cad-text-medium cad-fw-600">{value}</span>
                </div>
              ) : (
                <div
                  className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                  key={index}
                >
                  <label
                    className="cad-text-small cad-fw-500 text-uppercase"
                    htmlFor={title}
                  >
                    {title}
                  </label>
                  <Controller
                    control={control}
                    name={title.toLowerCase().replace(" ", "_")}
                    render={({ field }) => (
                      <input
                        className={`form-control ${themeColorsObject.themed_input_class}`}
                        {...field}
                      />
                    )}
                  />
                  {errors[title.toLowerCase().replace(" ", "_")] && (
                    <div className="cad-text-small text-danger">
                      {errors[title.toLowerCase().replace(" ", "_")].message}
                    </div>
                  )}
                </div>
              )
            )}
            <div className="w-100 d-flex justify-content-center">
              <div className="col-1 d-flex align-items-center">
                <Button
                  className="button-contained col-1"
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetailsNew;
