import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AttachmentSharpIcon from "@mui/icons-material/AttachmentSharp";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import wordLogo from "../../Images/wordLogo.png";
import pdfLogo from "../../Images/pdfLogo.png";
import { fetchSharedDocs } from "../../Redux/Slice/getSharedDocs";
import {
  createRequestDocs,
  deleteRequestDocs,
  deleteShareDocs,
  getRequestDocs,
  sharedDocs,
} from "../../service/task.service";
import { fetchTaskData } from "../../Redux/Slice/taskSlice";
import { Zoom } from "react-toastify";
import { fetchRequestDocs } from "../../Redux/Slice/requestDocsSlice";
import MainModal from "../../Components/MainModal";
import { notify } from "../../App";

const style = {
  height: "95%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const ReciveDocumentModal = ({ open, setOpen, id, toast, setOperationId }) => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [note, setNote] = useState("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchRequestDocs(id))
  // }, [])

  const { requestDocuments, isrequestDocumentsLoading } = useSelector(
    (state) => state?.getRequestDocs
  );

  const handleDelete = async (taskId) => {
    // console.log("click");
    // console.log(requestDocuments?.id);
    try {
      // Call the deleteTask function with the tagId
      const response = await deleteRequestDocs(requestDocuments?.id, taskId);

      // Handle the response from the deleteTask function
      // console.log("Task deleted successfully:", response);

      // Close the modal or perform any other action upon successful deletion
      // handleClose();
    } catch (error) {
      // Handle errors if the deletion fails
      console.error("Error deleting ta:", error);

      // Close the modal or perform any other action upon deletion failure
      // handleClose();
    }
    dispatch(fetchRequestDocs(id));
  };

  // this will be shown in error element
  const requestDocumentSchema = Yup.object().shape({
    title: Yup.string()
      .required("Document title is required")
      .min(5, "Document title must be at least 5 characters"),
    note: Yup.string()
      .required("Note is required")
      .min(5, "Note must be at least 5 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(requestDocumentSchema) });

  const onSubmit = async (value) => {
    setSubmitLoading(true);

    try {
      const response = await createRequestDocs(value, id);
      if (response.status === 400) {
        setSubmitLoading(false);
        // console.log(response);

        notify("Something Went Wrong");
        dispatch(fetchRequestDocs(id));
        setDocumentDescription("");
        setDocumentName("");
        setNote("");
        reset();
      } else if (response.status === 201) {
        setSubmitLoading(false);
        notify("Requested successfully");
        dispatch(fetchRequestDocs(id));
        setDocumentDescription("");
        setDocumentName("");
        setNote("");
        reset();
      } else if (response.status === 500) {
        setSubmitLoading(false);
        notify(response?.data?.message, "error");
        dispatch(fetchRequestDocs(id));
        setDocumentDescription("");
        setDocumentName("");
        setNote("");
        reset();
      }
    } catch (error) {
      // console.log(error);
      setSubmitLoading(false);
      notify(error?.data?.error?.message, "error");
    }
    // setOperationId(null);
  };

  const handleClose = () => {
    setOpen(false);

    reset();
  };

  return (
    <MainModal
      width={"60%"}
      open={open}
      handleClose={handleClose}
      title="Request Documents"
    >
      <div className="w-100 d-flex flex-column ">
        <form
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 gap-3 gap-2 d-flex flex-column align-items-start"
        >
          <div className="w-100 gap-3 d-flex flex-column">
            <label className="cad-text-medium cad-fw-600">
              Document title :
            </label>

            <div className=" w-100 d-flex position-relative align-items-center flex-wrap">
              <input
                type="text"
                className={`form-control w-100 cad-text-medium ${themeColorsObject.themed_input_class}`}
                placeholder="Document title"
                {...register("title")}
              />
              {errors.title && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.title.message}
                </div>
              )}
            </div>
          </div>

          <div className="w-100 gap-3 d-flex flex-column">
            <label className="cad-text-medium cad-fw-600">
              Document description :
            </label>

            <div className=" d-flex align-items-center  flex-wrap">
              <textarea
                type="text"
                className={`form-control w-100 cad-text-medium ${themeColorsObject.themed_input_class}`}
                placeholder="Document description"
                rows={3}
                {...register("description")}
              />
              {errors.description && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.description.message}
                </div>
              )}
            </div>
          </div>

          <div className="w-100 gap-3 d-flex flex-column">
            <label className="cad-text-medium cad-fw-600">Note :</label>

            <div className=" d-flex align-items-center  flex-wrap">
              <textarea
                type="text"
                className={`form-control w-100 cad-text-medium ${themeColorsObject.themed_input_class}`}
                placeholder="Note"
                rows={1}
                {...register("note")}
              />
              {errors.note && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.note.message}
                </div>
              )}
            </div>
          </div>

          <div className="w-100 mt-3 gap-3 d-flex align-items-center flex-column justify-content-center">
            <div className="col-4">
              <Button
                type="submit"
                className="button-contained"
                variant="contained"
              >
                {submitLoading ? (
                  <CircularProgress
                    style={{ height: 24, width: 24 }}
                    color="inherit"
                  />
                ) : (
                  "Save requested document"
                )}
              </Button>
            </div>
          </div>
        </form>

        <div className="col-12 pb-3 mt-4  d-flex flex-column justify-content-between">
          {isrequestDocumentsLoading ? (
            <span>Loading...</span>
          ) : requestDocuments.length === 0 ? (
            <div className="w-100 h-100 d-flex justify-content-center text-primary align-items-center">
              <span className="text-primary cad-fw-600 mt-3 p-3 roun border border-primary">
                No Document Requested
              </span>
            </div>
          ) : (
            <>
              <TableContainer className="h-100">
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          color: themeColorsObject.textColor,
                          width: 200,
                        }}
                        className="cad-fw-600 cad-text-medium"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="cad-fw-600 cad-text-medium"
                        align="left"
                        style={{
                          color: themeColorsObject.textColor,
                        }}
                      >
                        Note
                      </TableCell>
                      <TableCell
                        className="cad-fw-600 cad-text-medium"
                        align="center"
                        style={{
                          color: themeColorsObject.textColor,
                        }}
                        width={"5%"}
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestDocuments?.map((eachDocs) => (
                      <TableRow
                        className="pointer"
                        key={eachDocs.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.transition = "0.5s";
                          e.currentTarget.style.boxShadow =
                            "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                          // setMouseEntered(true);
                          // setMouseEnteredId(id);
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                          e.currentTarget.style.scale = 1;
                          e.currentTarget.style.transition = "0.5s";
                          e.currentTarget.style.boxShadow = "none";
                          // setMouseEntered(false);
                        }}
                      >
                        <TableCell
                          className="cad-text-medium"
                          component="th"
                          scope="row"
                          style={{
                            color: themeColorsObject.textColor,
                          }}
                        >
                          {eachDocs?.title}
                        </TableCell>
                        <TableCell
                          style={{
                            color: themeColorsObject.textColor,
                          }}
                          className="cad-text-medium"
                          align="left"
                        >
                          {eachDocs?.note}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDelete(eachDocs?.id)}
                          className="cad-text-medium"
                          align="center"
                          style={{
                            color: themeColorsObject.textColor,
                          }}
                        >
                          <CloseIcon className="text-danger action-hover rounded-2" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </MainModal>
  );
};

export default ReciveDocumentModal;
