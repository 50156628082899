import React, { useEffect, useState } from "react";
import { Button, Paper, } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./Subscription.css";
import PrimaryButton from "../../Components/PrimaryButton";
import DownloadIcon from "@mui/icons-material/Download";
import { FiUsers } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import { useSelector } from "react-redux";
import StripeSubscription from "../StripeSubscription/StripeSubscription";
import { useDispatch } from "react-redux";
import { fetchPaymentHistory } from "../../Redux/Slice/paymentSelice";
import DateFormatter from "../../Components/DateFormatter";
import UserProfile from "../../Components/GlobalTable/UserProfile";
import TableData from "../../Components/TableData";
import { getInvoiceDownload } from "../../service/billing.service";
import { setSubscription } from "../../Redux/Slice/business.slice";
import { Link, useNavigate } from "react-router-dom";

const Subscription = () => {
  // const navigate = useNavigate()
  const { themeColorsObject } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);
  // const { subscription } = useSelector((state) => state?.business);
  useEffect(() => {
    dispatch(fetchPaymentHistory());
    authInfo.business[0]?.subscriptions && dispatch(setSubscription(authInfo.business[0]?.subscriptions[0]))
  }, [authInfo.business, dispatch]);


  const { totalPages, payments, lastPayment } = useSelector(
    (state) => state?.paymentHistory
  );
  const isLoadingPaymentHistory = useSelector((state) => state?.paymentHistory);
  const DemoPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    height: "100%",
    // padding: theme.spacing(2),
  }));

  const [filterText, setFilterText] = useState("");
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  function capitalizeFirstLetter(string) {
    if (typeof string === "string") {
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    } else {
      return string && string[0]?.charAt(0).toUpperCase() + string[0]?.slice(1);
    }
  }

  const handleDownloadInvoice = (id) => async () => {
    try {
      const { data } = await getInvoiceDownload(id);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf'); // Set the name of the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  }

  const filteredTaskArray = payments?.filter((row) => {
    return (
      row?.principal[0]?.first_name
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      row?.principal[0]?.last_name
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      row.payment_method_types[0]
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      row?.payment_status.toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const extractedDataArray = filteredTaskArray?.map((item) => {
    const extractedData = {
      // id: payments[0]?.id,
      user: (
        <UserProfile
          proPic={item?.principal[0]?.profile_photo}
          companyName={
            item.principal[0]?.first_name + " " + item.principal[0]?.last_name
          }
        />
      ),
      status: (
        <span
          className={`${item?.payment_status === "paid"
            ? "text-success border-success"
            : "text-danger border-danger"
            }    cad-fw-600 ps-2 pe-2 rounded-2 border `}
        >
          {item?.payment_status === "paid" ? "Success" : "Failed"}
        </span>
      ),

      paymentDate: <DateFormatter inputDate={item?.createdAt} />,
      transactionId: item.id,
      modeOfPayment: (
        <span className="text-white cad-fw-600 ps-3 pe-3 rounded-2 border bg-primary border-primary">
          {/* {typeof item?.payment_method_types[0] === "string"
            ? capitalizeFirstLetter(JSON.parse(item?.payment_method_types)?.[0])
            : capitalizeFirstLetter(item?.payment_method_types?.[0])
          } */}

          {item?.payment_method_types?.[0]}

        </span>
      ),
      action: (
        // <div onClick={handleDownloadInvoice(item.id)}  className="d-flex justify-content-center">
        //   <span className="ps-2 pe-2 p-1 rounded-2 action-hover text-primary">
        //     <span>Invoice</span>
        //   </span>
        // </div>
        // <Button onClick={handleDownloadInvoice(item.id)} disabled={item?.payment_status !== "paid"} variant="text">Invoice</Button>
        <Link to={`/invoice/${item.id}`}>
          <Button disabled={item?.payment_status !== "paid"} variant="text">Invoice</Button></Link>
      ),
    };
    return extractedData;
  });

  const cellConfig = [
    {
      dataKey: "user",
      width: "14%",
      align: "left",
    },
    {
      dataKey: "paymentDate",
      width: "10%",
      align: "center",
    },
    {
      dataKey: "transactionId",
      width: "14%",
      align: "left",
    },
    {
      dataKey: "modeOfPayment",
      width: "5%",
      align: "center",
    },
    {
      dataKey: "status",
      width: "8%",
      align: "center",
    },
    {
      dataKey: "action",
      width: "10%",
      align: "center",
    },
  ];

  const tableHeadRowCell = [
    {
      title: "User",
      width: "14%",
      align: "left",
    },
    {
      title: "Payment Date",
      width: "10%",
      align: "center",
    },
    {
      title: "Transaction ID",
      width: "14%",
      align: "left",
    },
    {
      title: "Mode",
      width: "5%",
      align: "center",
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
    },
    {
      title: "Action",
      width: "10%",
      align: "center",
    },
  ];

  const handlePagination = (value) => {
    dispatch(
      fetchPaymentHistory({
        size: 10,
        page: value,
      })
    );
  };

  return (
    <>
      {authInfo?.subscribed_user ? (
        <div
          style={{
            height: "auto",
            color: themeColorsObject.titleText,
          }}
          className="mt-4 mb-4"
        >
          <div className="row ">
            <div className="col-12 col-md-6 mb-3 ">
              <DemoPaper
                style={{
                  backgroundColor: themeColorsObject.megaCardBackgroundColor,
                  color: themeColorsObject.titleText,
                }}
              >
                <div className="p-2 card1-bg rounded-top">
                  <label className="mb-1 cad-text-small text-dark">
                    Current Plan
                  </label>
                  <div className="row d-flex align-items-center justify-content-between text-dark">
                    <div className="col-6 text-left">
                      <label className="cad-text-medium cad-fw-500">
                        Startup ( Monthly )
                      </label>
                    </div>
                    <div className="col-6 text-end">
                      <label className="card-font3">
                        {lastPayment?.checkout_plans?.currency}{" "}
                        {lastPayment?.checkout_plans?.unit_amount} / {lastPayment?.checkout_plans?.recurring === "12" ? "Yr" : "Mo"}{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pt-2 ps-2 pe-2 pb-3">
                  <div className="row">
                    <div className="col-12 text-left">
                      <label className="cad-text-medium cad-fw-400 mb-4">
                        Started On :{" "}
                        {lastPayment?.transactions?.effective_from ===
                          undefined ? (
                          "Payment Failed"
                        ) : (
                          <DateFormatter
                            inputDate={
                              lastPayment?.transactions?.effective_from
                            }
                          />
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 text-left">
                      <PrimaryButton
                        path="/initial-subsription"
                        variant="contained"
                        title="Upgrade/Downgrade"
                        padding="0.3rem 0.3rem"
                        fontSize="14px"
                      />
                    </div>
                  </div>
                </div>
              </DemoPaper>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <DemoPaper
                style={{
                  backgroundColor: themeColorsObject.megaCardBackgroundColor,
                  color: themeColorsObject.titleText,
                }}
                variant="elevation"
              >
                <div className="p-3">
                  <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
                    <FiUsers style={{ fontSize: "20px" }} />
                    <label className="cad-text-medium">Users</label>
                  </div>
                  <label className="text-start cad-text-medium cad-fw-400 ">
                    Maximum users unlimited
                  </label>
                </div>
                <div className="ps-3 pe-3 pt-1 pb-2">
                  <div className="row">
                    <div className="col-5 ">
                      <label className="cad-text-small cad-fw-500">
                        Users
                      </label>
                    </div>
                    <div className="col-7">
                      <label className="cad-text-small ">: {lastPayment?.checkout_plans?.package?.users}</label>
                    </div>
                    <div className="col-5">
                      <label className="cad-text-small cad-fw-500">
                        Storage
                      </label>
                    </div>
                    <div className="col-7">
                      <label className="cad-text-small ">: {lastPayment?.checkout_plans?.package?.storage} GB</label>
                    </div>
                    <div className="col-5">
                      <label className="cad-text-small cad-fw-500">
                        Domain
                      </label>
                    </div>
                    <div className="col-7">
                      <label className="cad-text-small ">: {lastPayment?.checkout_plans?.package?.domain ? "Yes" : "No"}</label>
                    </div>
                  </div>
                </div>
              </DemoPaper>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-12 mb-3">
              <DemoPaper
                style={{
                  backgroundColor: themeColorsObject.megaCardBackgroundColor,
                  color: themeColorsObject.titleText,
                }}
                variant="elevation"
              >
                <div className="p-3 row">
                  <div className="col-12 d-flex justtify-content-start align-items-center gap-2 mb-3">
                    <BiRupee style={{ fontSize: "20px" }} />
                    <label className="cad-text-medium">Payment Details</label>
                  </div>
                  <div className="col-4 d-flex flex-column text-start mb-4">
                    <label className="cad-text-small cad-fw-500 mb-1">
                      Subscription Type
                    </label>
                    <label className="cad-text-small ">{lastPayment?.checkout_plans?.recurring === "12" ? "12 Month" : "1 Month"}</label>
                  </div>
                  <div className="col-4 d-flex flex-column text-start mb-4">
                    <label className="cad-text-small cad-fw-500 mb-1">
                      Next Payment Date
                    </label>
                    <label className="cad-text-small">
                      {lastPayment?.transactions?.effective_to === undefined ? (
                        "Payment Failed"
                      ) : (
                        <DateFormatter
                          inputDate={lastPayment?.transactions?.effective_to}
                        />
                      )}
                    </label>
                  </div>
                  <div className="col-4 d-flex flex-column text-start mb-4">
                    <label className="cad-text-small cad-fw-500 mb-1">
                      Last Payment Date
                    </label>
                    <label className="cad-text-small">
                      {lastPayment?.transactions?.effective_from ===
                        undefined ? (
                        "Payment Failed"
                      ) : (
                        <DateFormatter
                          inputDate={lastPayment?.transactions?.effective_from}
                        />
                      )}
                    </label>
                  </div>
                  <div className="col-4 d-flex flex-column text-start">
                    <label className="cad-text-small cad-fw-500 mb-1">
                      Transaction ID
                    </label>
                    <label className="cad-text-small">
                      {lastPayment?.transactions?.transaction_xid}
                    </label>
                  </div>
                  <div className="col-4 d-flex flex-column text-start">
                    <label className="cad-text-small cad-fw-500 mb-1">
                      Mode of payment
                    </label>
                    <label className="cad-text-small">
                      <span className="text-white cad-fw-600 ps-3 pe-3  rounded-2 border bg-primary border-primary">
                        {typeof lastPayment?.payment_method_types === "string"
                          ? capitalizeFirstLetter(
                            JSON.parse(lastPayment?.payment_method_types)
                          )
                          : capitalizeFirstLetter(
                            lastPayment?.payment_method_types)

                        }
                      </span>
                    </label>
                  </div>
                  <div className="col-4 d-flex justify-content-start">
                    <Link to={`/invoice/${lastPayment?.id}`}>
                      <Button variant="contained"
                        startIcon={<DownloadIcon />}
                        style={{
                          border: "1px solid #4CCD8D",
                          padding: "0.3rem 4rem",
                          backgroundColor: "#4CCD8D",
                          textTransform: "none",
                          fontSize: "14px",
                        }}>
                        Download Invoice</Button>
                    </Link>
                  </div>
                </div>
              </DemoPaper>
            </div>
          </div>
          <div
            style={{
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.titleText,
              height: "fit-content",
            }}
            className="row align-items-start   m-0 pt-2 card"
          >
            <div className="col-12 d-flex  justify-content-between align-items-center pt-2 mb-2">
              <div className="col-6 ps-2 d-flex align-items-center">
                <h4 className=" p-0 m-0">Previous Payments</h4>
              </div>
              <div className="col-6 d-flex justify-content-end pe-2">
                <div className="w-50">
                  <div className="w-100">
                    <input
                      type="text"
                      placeholder="Search..."
                      className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
                      onChange={(e) => setFilterText(e.target.value)}
                    // onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableData
              tableHeadRowCell={tableHeadRowCell}
              extractedDataArray={extractedDataArray}
              cellConfig={cellConfig}
              setMouseEnteredId={setMouseEnteredId}
              setMouseEntered={setMouseEntered}
              totalPages={totalPages}
              handlePagination={handlePagination}
              isLoading={isLoadingPaymentHistory?.isLoading}
            />
          </div>
        </div>
      ) : (
        <StripeSubscription inDashboard={true} />
      )}
    </>
  );
};

export default Subscription;
