import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGlobalForms } from "../../service/form.service";

export const fetchGlobalForms = createAsyncThunk("fetchGlobalForms", async () => {
  try {
    const res = await getGlobalForms(); // Replace this function call with the appropriate service function
    // console.log(res);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching resources data: ${err.message}`);
  }
});

const fetchFormSlice = createSlice({
  name: "globalForms",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    forms: [], 
  },
  reducers: {
    // Add any specific reducers if required
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalForms.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalForms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forms = action.payload?.payload; // Update the property with the fetched resources data
      })
      .addCase(fetchGlobalForms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default fetchFormSlice.reducer;
