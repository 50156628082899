import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Footer from "../../Components/Footer/Footer";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Button,
  CircularProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { deleteShareDocs, sharedDocs } from "../../service/task.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchSharedDocs } from "../../Redux/Slice/getSharedDocs";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AttachmentSharpIcon from "@mui/icons-material/AttachmentSharp";
import { fetchTaskData } from "../../Redux/Slice/taskSlice";

import wordLogo from "../../Images/wordLogo.png";
import pdfLogo from "../../Images/pdfLogo.png";
import { notify } from "../../App";

const ShareDocuments = () => {
  const [files, setFiles] = useState([
    { document: null, title: "", description: "" },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [note, setNote] = useState("");

  const [submitLoading, setSubmitLoading] = React.useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();

  // console.log(id);

  useEffect(() => {
    dispatch(fetchSharedDocs(id));
  }, []);

  const { sharedDocuments, isLoading } = useSelector(
    (state) => state?.getSharedDocs
  );
  // console.log(sharedDocuments);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files);
  };

  // // console.log(selectedFile[0].type);

  // const handleDocumentNameChange = (event, index) => {
  //   setDocumentName(event.target.value);
  // };

  const handleAddInput = () => {
    setFiles([...files, { document: null, title: "", description: "" }]);
  };

  const handleDeleteInput = (index) => {
    const updatedFiles = [...files];

    if (updatedFiles.length > 1) {
      updatedFiles.splice(index, 1); // Remove the item at the specified index
      setFiles(updatedFiles);
    }
  };

  // const handleSend = async () => {

  //   // console.log(files);

  //   setSubmitLoading(true);

  //   const notify = () => {
  //     toast.error("Something went wrong", {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  // }

  // try {
  //   const response = await sharedDocs(files, id);

  //     if (response.status == 400) {
  //       setSubmitLoading(false);
  //         // console.log(response);
  //         notify()
  //     } else if (response.status == 201) {
  //         setSubmitLoading(false);
  //         // console.log(response);
  //     }
  //   }
  //   catch (error) {
  //       // console.log(error);
  //   }

  // }
  const handleSend = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    const formData = new FormData();

    // Loop through each selected file
    for (let i = 0; i < selectedFile.length; i++) {
        const file = selectedFile[i];
        console.log(file.size);

        // Check if the file size exceeds the maximum allowed size
        if (file.size > (2 * 1024 * 1024)) {
            // Notify the user that the file size is too large
            notify("File size exceeds the maximum allowed size", "error");
            setSubmitLoading(false); // Stop loading
            return; // Stop further processing
        }

        // Append the file to the FormData object
        formData.append(`document`, file);
    }

    // Append other form data
    formData.append("title", documentName);
    formData.append("task_xid", id);
    formData.append("description", documentDescription);
    formData.append("note", note);

    try {
        // Call sharedDocs with the FormData object and id
        const response = await sharedDocs(formData, id);

        if (response.status === 400) {
            // Handle other error cases
            // ...
        } else if (response.status === 201) {
            // Handle success case
            // ...
        } else if (response.status === 413) {
            notify("File is too large", "error");
        }
    } catch (error) {
        // Handle error
        notify("An error occurred", "error");
    }

    // Reset form fields and state
    setSubmitLoading(false);
    setSelectedFile(null);
    setDocumentName("");
    setDocumentDescription("");
    setNote("");

    // Fetch shared documents
    dispatch(fetchSharedDocs(id));
};


  const handleDelete = async (taskId) => {
    try {
      // Call the deleteTask function with the tagId
      const response = await deleteShareDocs(taskId);

      // Handle the response from the deleteTask function
      // console.log("Task deleted successfully:", response);

      // Close the modal or perform any other action upon successful deletion
      // handleClose();
    } catch (error) {
      // Handle errors if the deletion fails
      console.error("Error deleting ta:", error);

      // Close the modal or perform any other action upon deletion failure
      // handleClose();
    }

    let pagination = {
      size: 10,
      page: 1,
    };

    dispatch(fetchTaskData(pagination));
  };

  // ===================[ Return ]==========================

  // console.log(sharedDocuments[0]);

  return (
    <div className="overflow-auto m-2 h-100 rounded-3">
      <div className="d-flex m-2 h-100 rounded-3 overflow-auto bg-white p-4 cad-shadow">
        <div className="d-flex p-4 col-6 justify-content-end position-relative">
          <Link
            to="/add-task"
            style={{ left: -24 }}
            className="text-primary h6 ps-4 text-decoration-none d-flex align-items-center gap-3 pointer top-0 position-absolute"
          >
            <ArrowBackRoundedIcon /> Share Documents
          </Link>
          <div className="w-100 mt-5 d-flex ">
            <form
              encType="multipart/form-data"
              onSubmit={handleSend}
              className="w-100 gap-3 gap-2 d-flex flex-column align-items-start"
            >
              <div className="w-100 gap-3 d-flex flex-column">
                <label className="cad-text-medium cad-fw-600">
                  Document title :
                </label>

                <div className=" w-100 d-flex position-relative align-items-center">
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Document title"
                    value={documentName}
                    onChange={(event) => setDocumentName(event.target.value)}
                  />
                  <label
                    htmlFor={`file-upload`}
                    style={{
                      position: "absolute",
                      right: "0px",
                      width: "60px",
                      height: "100%",
                      borderRadius: "0 0.5rem 0.5rem 0",
                    }}
                    className="d-flex pointer bg-primary justify-content-center align-items-center"
                  >
                    <AttachmentSharpIcon className="fs-3 text-white fw-normal" />
                  </label>
                  <input
                  
                    id={`file-upload`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => handleFileChange(event)}
                    accept=".pdf, .xlsx, .xls, .docx, .doc, image/*"
                    multiple={true}
                  />
                </div>
                <div className="d-flex flex-wrap gap-2">
                  {selectedFile === null ? (
                    <span className="cad-text-medium ps-1 text-danger">Please select document</span>
                  ) : selectedFile[0].type === "application/msword" || selectedFile[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <span className="d-flex flex-wrap gap-2 align-items-end">
                      <img src={wordLogo} alt="" width={50} height={50} />
                      <span>{selectedFile[0].name}</span>
                    </span>
                  ) : selectedFile[0].type === "application/pdf" ? (
                    <span className="d-flex flex-wrap gap-2 align-items-end">
                      <img src={pdfLogo} alt="" width={25} height={25} />
                      <span className="cad-text-small">{selectedFile[0].name}</span>
                    </span>
                  ) : (
                    <span className="cad-text-medium ps-1 text-danger">Invalid file format</span>
                  )}
                </div>
              </div>

              <div className="w-100 gap-3 d-flex flex-column">
                <label className="cad-text-medium cad-fw-600">
                  Document description :
                </label>

                <div className=" d-flex align-items-center gap-2">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Document description"
                    rows={3}
                    value={documentDescription}
                    onChange={(event) =>
                      setDocumentDescription(event.target.value)
                    }
                  />
                </div>
              </div>

              <div className="w-100 gap-3 d-flex flex-column">
                <label className="cad-text-medium cad-fw-600">Note :</label>

                <div className=" d-flex align-items-center gap-2">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Note"
                    value={note}
                    rows={1}
                    onChange={(event) => setNote(event.target.value)}
                  />
                </div>
              </div>

              <div className="w-100 gap-3 d-flex align-items-center flex-column justify-content-center">
                <div>
                  <Button
                    type="submit"
                    className="button-contained"
                    variant="contained"
                  >
                    {submitLoading ? (
                      <CircularProgress
                        style={{ height: 24, width: 24 }}
                        color="inherit"
                      />
                    ) : (
                      "Upload document"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-6   d-flex flex-column justify-content-between">
          <TableContainer
            className="h-100"
            square={false}
            elevation={1}
            component={Paper}
          >
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="cad-fw-600 cad-text-small" align="left">
                    Doc Type
                  </TableCell>
                  <TableCell
                    className="cad-fw-600 cad-text-small"
                    style={{ width: 200 }}
                  >
                    Name
                  </TableCell>
                  <TableCell className="cad-fw-600 cad-text-small" align="left">
                    Description
                  </TableCell>
                  <TableCell
                    className="cad-fw-600 cad-text-small"
                    align="center"
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? // Show skeleton loading for the entire table rows
                    Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" height={30} />
                        </TableCell>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" height={30} />
                        </TableCell>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" height={30} />
                        </TableCell>
                      </TableRow>
                    ))
                  : sharedDocuments?.map((eachDocs) => (
                      <TableRow
                        className="pointer"
                        key={eachDocs.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.transition = "0.5s";
                          e.currentTarget.style.boxShadow =
                            "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                          // setMouseEntered(true);
                          // setMouseEnteredId(id);
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                          e.currentTarget.style.scale = 1;
                          e.currentTarget.style.transition = "0.5s";
                          e.currentTarget.style.boxShadow = "none";
                          // setMouseEntered(false);
                        }}
                      >
                      <TableCell className="cad-text-small" align="left">
                      <img
                            src={
                              eachDocs?.document?.mimetype.split("/").pop() ===
                              "pdf"
                                ? pdfLogo
                                : wordLogo
                            }
                            width={20}
                            alt="doc-img"
                          /> <span>{eachDocs?.document?.mimetype.split("/").pop() ===
                          "pdf"
                            ? "PDF"
                            : "Word"}</span>
                      </TableCell>
                        <TableCell
                          className="cad-text-small"
                          component="th"
                          scope="row"
                        >
                          {eachDocs.title}
                        </TableCell>
                        <TableCell className="cad-text-small" align="left">
                          {eachDocs.description}
                        </TableCell>
                        <TableCell
                          onClick={() => handleDelete(eachDocs.id)}
                          className="cad-text-small"
                          align="center"
                        >
                          <CloseIcon className="text-danger" />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100  mt-5 gap-3 d-flex align-items-center flex-column justify-content-center">
            <div className="col-3">
              <Link to="/task">
                <Button
                  type="submit"
                  className="button-contained"
                  variant="contained"
                >
                  Submit
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShareDocuments;
