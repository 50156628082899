import React, { useEffect, useState } from 'react';

const LastAccess = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const calculateTimeAgo = () => {
      const currentDate = new Date();
      const previousDate = new Date(timestamp);
      const timeDifference = currentDate - previousDate;

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

      if (days >= 1) {
        setTimeAgo(`${days} days and ${hours} hrs ago`);
      } else {
        setTimeAgo(`${hours} hrs ago`);
      }
    };

    calculateTimeAgo();

    // Update time every minute (optional)
    const interval = setInterval(calculateTimeAgo, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [timestamp]);

  return (
    <span>{timeAgo}</span>
  );
};

export default LastAccess;
