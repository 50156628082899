import { Button, Modal } from "@mui/material";
import React from "react";
import "./AddTag.css";
import { createTags } from "../../service/tags.service";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { fetchTag } from "../../Redux/Slice/tagsSlice";
import { fetchGlobalTags } from "../../Redux/Slice/globlalTags";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  p: 2,
  pt: 3,
  backgroundColor: "#fff",
  borderRadius: 8,
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const schema = yup.object().shape({
  tagName: yup.string().required("Tag name is required"),
  discription: yup.string().required("Description field is required"),
});

const AddTag = ({ open, setOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(schema) });

  const dispatch = useDispatch();

  const onSubmit = async (tagData) => {
    // console.log(tagData.tagName);

    const { status, data } = await createTags({
      title: tagData.tagName,
      description: tagData.discription,
    });
    // console.log(status, data);
    reset();
    setOpen(false);
    dispatch(fetchTag());
    dispatch(fetchGlobalTags());
  };

  const handleClose = () => {
    setOpen(false)
    reset()
  }

  return (
    <Modal
      className="cad-modal-blur"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
        <div className="text-primary d-flex justify-content-between m-3 mb-0">
          <span className="cad-text-large cad-fw-600">Add Tag </span> 
          <span
            onClick={handleClose}
            className="cad-text-small cad-fw-600 pointer action-hover rounded-2"
          >
            <CloseIcon />
          </span>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-4">
          <div className="mb-3">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label cad-text-small cad-fw-600"
            >
              Tag Name <span className="text-danger">*</span>
            </label>
            <input
              {...register("tagName")}
              type="text"
              className="form-control cad-text-medium"
              id="exampleFormControlInput1"
              placeholder="Tag name"
            />
            <label
              htmlFor="exampleFormControlTextarea1"
              className="form-label cad-text-small text-danger cad-fw-600"
            >
              {errors.tagName?.message}
            </label>
          </div>
          <div className="mb-3">
            <label
              htmlFor="exampleFormControlTextarea1"
              className="form-label cad-text-small cad-fw-600"
            >
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              {...register("discription")}
              className="form-control cad-text-medium"
              id="exampleFormControlTextarea1"
              rows="3"
            />
            <label
              htmlFor="exampleFormControlTextarea1"
              className="form-label cad-text-small text-danger cad-fw-600"
            >
              {errors.discription?.message}
            </label>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-3">
              <Button type="submit" className="button-contained">
                Add
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddTag;
