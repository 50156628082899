// import necessary dependencies and services
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getContacts, getMessage } from "../../service/chat.service";


// create an asynchronous thunk for fetching chat contacts
export const fetchChatContacts = createAsyncThunk("fetchChatContacts", async () => {
  // console.log("hiited");
  try {
    const response = await getContacts();
    // console.log(response);
    response?.data?.payload.map((chats) => {
      let profileName;
      if (!chats?.isGroupChat) {
        const { first_name, last_name, profile_photo, messages } =
          chats?.participants[0];
        profileName = {
          name: first_name + " " + last_name,
          profile: profile_photo,
          chatId: chats?.id,
          first_name,
          last_name,
        };
      } else {
        profileName = {
          name: chats.name,
          profile: chats.profile,
          chatId: chats?.id,
        };
      }
      return profileName;
    });
    
    return  response?.data?.payload.map((chats) => {
        // console.log(chats);
        let profileName;
        if (!chats?.isGroupChat) {
          const { first_name, last_name, profile_photo } =
            chats?.participants[0];
          profileName = {
            name: first_name + " " + last_name,
            profile: profile_photo,
            chatId: chats?.id,
            first_name,
            last_name,
            last_message: chats?.messages[0],
          };
        } else {
          profileName = {
            name: chats.name,
            profile: chats.profile,
            chatId: chats?.id,
          };
        }
        return profileName;
        // // console.log(profileName);
        // const userName = chats?.
      }) || []
  } catch (error) {
    // console.log(error);
  }
});


export const fetchMessage = createAsyncThunk("fetchMessage", async (chatId) => {
  try {
    const response = await getMessage(chatId);
    return(response?.data?.payload);
  } catch (error) {
    // console.log(error);
  }
});



// create a slice for chat contacts
const chatContactsSlice = createSlice({
  name: "fetchChatContacts",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    chatContacts: [], // property to store the fetched chat contacts
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchChatContacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatContacts = action.payload;
      })
      .addCase(fetchChatContacts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // store the error message
      });
  },
});

export default chatContactsSlice.reducer;
