import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { getFeatures } from "../../service/feature.service";
import { newFeatureRequest } from "../../service/support.service";

export const fetchRequestFeatures = createAsyncThunk(
  "fetchRequestFeatures",
  async () => {
    try {
      const res = await newFeatureRequest();
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching request features: ${err.message}`);
    }
  }
);

const getRequestFeatures = createSlice({
  name: "getRequestFeatures",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    requestFeatures: [], // Property to store fetched request features
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestFeatures.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchRequestFeatures.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requestFeatures = action.payload.data; // Update property with fetched data
      })
      .addCase(fetchRequestFeatures.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default getRequestFeatures.reducer;
