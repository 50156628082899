import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

export const getCompany = async (params) => {
    try {
        return await api.get(`/contact/agent/company/`, { params: params})
    } catch (error) {
        return error.response
    }
}

export const createCompany = async (data) => {
    // console.log(data);
    try {
        return await api.post(`/contact/agent/company`, data, formDataConfig)
    } catch (error) { 
        return error.response
    }
}

export const updateCompany = async (data, id) => {
    // console.log(data);
    try {
        return await api.put(`/contact/agent/company/${id}`, data)
    } catch (error) {
        throw error
    }
}

export const getCompanyDetails = async (id) => {
    try {
        return await api.get(`/contact/agent/company/${id}`)
    } catch (error) {
        return error.response
    }
}

export const deleteCompany = async (userId) => {
    try {
        return await api.delete(`/contact/agent/company/delete/${userId}`)
    } catch (error) {
        return error.response
    }
}