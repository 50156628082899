import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUser, userDetails } from "../../service/user.service";

export const getAllUsers = createAsyncThunk("getAllUsers", async (params) => {
  try {
    const res = await getUser(params);
    // // console.log(res.data);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});

export const userDetailsById = createAsyncThunk(
  "getAllUsers/userDetailsById",
  async ({ id }, { rejectWithValue }) => {
    try {
      // console.log("user id", id);
      const res = await userDetails(id);
      if (res.status === 200) {
        // console.log(res.data);
        return res.data;
      }
      return rejectWithValue(res.data);
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  }
);

const getAllUserSlice = createSlice({
  name: "allUserData",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    users: [], // Initialize rows as an empty array
    userDetails: [],
    getManager: null,
    getResources: [],
  },
  reducers: {
    allUserDetails: (state, action) => {
      // Action payload should contain the new clients data
      // console.log(action.payload);
      state.users = action.payload;
    },

    userDetailsByIdData: (state, action) => {
      // Action payload should contain the new clients data
      // console.log(action.payload);
      state.userDetails = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //     builder
  //         .addCase(getAllUsers.pending, (state) => {
  //             state.isLoading = true;
  //             state.isError = false;
  //             state.error = null;
  //         })
  //         .addCase(getAllUsers.fulfilled, (state, action) => {
  //             state.isLoading = false;
  //             state.users = action.payload || [];
  //         })
  //         .addCase(getAllUsers.rejected, (state, action) => {
  //             state.isLoading = false;
  //             state.isError = true;
  //             state.error = action.error.message; // Store the error message
  //         });
  // },

  extraReducers: (builder) => {
    builder
      // getAllUsers
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload || [];
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error?.message || action.data?.error;
      })
      // userDetailsById
      .addCase(userDetailsById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(userDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action?.payload;
        state.getManager = action.payload?.data?.managers;
        state.getResources = action.payload?.data?.resources;
      })
      .addCase(userDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error?.message || action.data?.error;
      });
  },
});

export default getAllUserSlice.reducer;
export const { allUserDetails, userDetailsByIdData } = getAllUserSlice.actions;
