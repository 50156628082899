import React, { useEffect } from "react";
import payemntSuccessVideo from "../../Images/Payment.mp4";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchPaymentHistory } from "../../Redux/Slice/paymentSelice";
import { useDispatch } from "react-redux";
import { pagination } from "../../Constants/Constants";
import { logoutUser } from "../../Redux/Slice/auth/auth.slice";
import { logoutAgent } from "../../Redux/Slice/fetchAuthMe";
import Cookies from "js-cookie";

const PaymentSuccess = () => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
      dispatch(fetchPaymentHistory(pagination))
          setTimeout(() => {
            
            localStorage.removeItem("user");
            Cookies.remove("userData"); // Remove the cookie notification
            dispatch(logoutUser());
            dispatch(logoutAgent());
            navigate("/");
          }, 5000);
      }, []);


      const handleLogout = () => {
        localStorage.removeItem("user");
            Cookies.remove("userData"); // Remove the cookie notification
            dispatch(logoutUser());
            dispatch(logoutAgent());
            navigate("/");
      }


  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        flexDirection:"column",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
      }}
    >
      <span
        className="rounded-4 overflow-hidden"
        style={{
          width: 400,
          height: 250,
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }}
      >
        <video
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          autoPlay
          muted
          loop
        >
          <source src={payemntSuccessVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </span>
      <span onClick={handleLogout} style={{color:"#949494"}} className="text-primary fw-bold cad-text-medium mt-3 action-hover ps-2 pe-2 pointer rounded-1">Please Login</span>


      <span
        style={{
          borderStartStartRadius: "40%",
          borderBottomLeftRadius: "40%",
          position: "absolute",
          bottom: 12,
        }}
        className="overflow-hidden d-flex"
      >
        <span
          className="cad-fw-700 d-flex align-items-end"
          style={{
            color: "#4CCD8D",
            fontSize: "22px",
            transition: "0.5s",
          }}
        >
          Cloudify
        </span>
        <span
          className="text-primary d-flex align-items-end cad-fw-500"
          style={{
            fontSize: "20px",
            transition: "0.8s",
          }}
        >
          Docs
        </span>
      </span>
    </div>
  );
};

export default PaymentSuccess;
