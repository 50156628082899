import React, { useContext, useEffect, useState } from "react";
// import './Chat.css'
import ChatsLeft from "../Components/ChatComponent/ChatsLeft";
import ChatsRight from "../Components/ChatComponent/ChatsRight";
import io from "socket.io-client";
import TokenService from "../service/token.service";
import { useSelector } from "react-redux";
import { getContacts, getContactsWithoutSort } from "../service/chat.service";
import * as themeColors from "../Utils/Theme/Colors";
import { AuthMe } from "../Redux/Slice/auth/auth.slice";
import { useDispatch } from "react-redux";
import GlobalStateContext from "../Contexts/GlobalStateContext";
import GlobalStateProvider from "../Contexts/GlobalStateProvider";
import { notify } from "../App";

const Chat = () => {
  const { socket, setSocket, setMessage, setContacts, chatActiveId } =
    useContext(GlobalStateContext);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { theme, themeColorsObject } = useSelector((state) => state.theme);

  useEffect(() => {
    dispatch(AuthMe());
  }, []);

  // const [socket, setSocket] = useState(null);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [chatContacts, setChatContacts] = useState([]);
  const [profileSrc, setProfileSrc] = useState("");
  const [openNewChat, setOpenNewChat] = useState(false);
  const handleOpenNewChat = () => setOpenNewChat(true);
  const handleCloseNewChat = () => {setOpenNewChat(false)};

  const [openNewGroupChat, setOpenGroupNewChat] = useState(false);
  const handleOpenNewGroupChat = () => setOpenGroupNewChat(true);
  const handleCloseNewGroupChat = () => {
    setOpenGroupNewChat(false)
    setNewGroupDetails({
      name: "",
      profile: "",
      description: "",
      id: "",
      chatId: "",
      receiverId: "",
      participants: [],
    });
    setProfileSrc("");
  };
  const [newGroupDetails, setNewGroupDetails] = useState({
    name: "",
    profile: "",
    description: "",
    id: "",
    chatId: "",
    receiverId: "",
    participants: [],
  });

  const handleNewGroupDetails = (e) => {
    const { name, value } = e.target;

    setNewGroupDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isTyping, setIsTyping] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      const response = await getContacts();
      // const response = await getContactsWithoutSort();
      setContacts(
        response?.data?.payload.map((chats) => {
          let profileName;
          if (!chats?.isGroupChat) {
            const { first_name, last_name, profile_photo } =
              chats?.participants[0];
            profileName = {
              name: first_name + " " + last_name,
              profile: profile_photo,
              chatId: chats?.id,
              first_name,
              last_name,
              last_message: chats?.messages[0],
            };
          } else {
            profileName = {
              name: chats.name,
              profile: chats.profile,
              chatId: chats?.id,
              last_message: chats?.messages[0],
            };
          }
          return profileName;
        }) || []
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    const token = TokenService.getLocalAccessToken();
    const newSocket = io(process.env.REACT_APP_DOWLOAD_URL, {
      withCredentials: false,
      auth: { token },
    });
    setSocket(newSocket);
    chatActiveId && newSocket.emit("joinChat", chatActiveId);

    return () => {
      newSocket.disconnect();
      setIsOnline(false);
    };
  }, [io, chatActiveId]);

  if (socket) {
    socket.on("messageReceived", (data) => {
      setIsOnline(true);
      // setMessage(data?.payload);
      // console.log(data);
      fetchContacts();
    });
    socket.on("typing", (chatActiveId) => {
      setIsOnline(true);
    });
  }

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
      }}
      className=" cad-shadow mt-1   rounded-3 mb-4 pt-2"
    >
      <div className="d-flex w-100  h-100">
        <div className="col-4  h-100">
          <ChatsLeft
            isChatLoading={isLoading}
            isTyping={isTyping}
            handleCloseNewChat={handleCloseNewChat}
            openNewChat={openNewChat}
            handleOpenNewChat={handleOpenNewChat}
            handleCloseNewGroupChat={handleCloseNewGroupChat}
            openNewGroupChat={openNewGroupChat}
            handleOpenNewGroupChat={handleOpenNewGroupChat}
            chatContacts={chatContacts}
            setChatContacts={setChatContacts}
            themeColorsObject={themeColorsObject}
            newGroupDetails={newGroupDetails}
            setNewGroupDetails={setNewGroupDetails}
            handleNewGroupDetails={handleNewGroupDetails}
            profileSrc={profileSrc}
            setProfileSrc={setProfileSrc}
          />
        </div>

        <div
          style={{
            borderLeft: "1px solid #DEE2E6",
          }}
          className="col-8 p-0 h-100"
        >
          <ChatsRight
            themeColorsObject={themeColorsObject}
            isOnline={isOnline}
            setIsOnline={setIsOnline}
            setIsTyping={setIsTyping}
            isTyping={isTyping}
            handleCloseNewChat={handleCloseNewChat}
            openNewChat={openNewChat}
            handleOpenNewChat={handleOpenNewChat}
            chatContacts={chatContacts}
          />
        </div>
      </div>
    </div>
  );
};
export default Chat;
