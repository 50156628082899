
import * as yup from 'yup';


export const pagination = {
  size: 10,
  page: 1,
};

export const phoneRegExp = /^(?:[0-9] ?){6,14}[0-9]$/;



export const personalInfoSchema = yup.object().shape({
  suffix: yup.string(),
  prefix: yup.string(),
  given_name: yup.string(),
  surname: yup.string(),
  civil_status: yup.string(),
  date_of_birth: yup.date(),
  sex: yup.string().oneOf(['Male', 'Female', 'Other']),
});

// Contact Details Schema
export const contactDetailsSchema = yup.object().shape({
  mobile_number: yup.string().required('Mobile number is required'),
  home_phone_number: yup.string(),
  office_phone_number: yup.string(),
  primary_email_address: yup.string().email('Invalid email address').required('Primary email address is required'),
  additional_email_address1: yup.string().email('Invalid email address'),
  additional_email_address2: yup.string().email('Invalid email address'),
});

// Address Schema
export const addressSchema = yup.object().shape({
  permanent_street_address_L1: yup.string().required('Permanent street address is required'),
  permanent_street_address_L2: yup.string(),
  permanent_city: yup.string().required('Permanent city is required'),
  permanent_state: yup.string(),
  permanent_province: yup.string(),
  permanent_postal_code: yup.string(),
  permanent_country: yup.string(),
  present_street_address_L1: yup.string().when('present_address_same', {
    is: false,
    then: yup.string().required('Present street address is required'),
  }),
  present_street_address_L2: yup.string().when('present_address_same', {
    is: false,
    then: yup.string(),
  }),
  present_city: yup.string().when('present_address_same', {
    is: false,
    then: yup.string().required('Present city is required'),
  }),
  present_state: yup.string().when('present_address_same', {
    is: false,
    then: yup.string(),
  }),
  present_province: yup.string().when('present_address_same', {
    is: false,
    then: yup.string(),
  }),
  present_postal_code: yup.string().when('present_address_same', {
    is: false,
    then: yup.string(),
  }),
  present_country: yup.string().when('present_address_same', {
    is: false,
    then: yup.string(),
  }),
});