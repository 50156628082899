import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AttachmentSharpIcon from "@mui/icons-material/AttachmentSharp";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";

import wordLogo from "../../Images/wordLogo.png";
import pdfLogo from "../../Images/pdfLogo.png";
import { fetchSharedDocs } from "../../Redux/Slice/getSharedDocs";
import { deleteShareDocs, sharedDocs } from "../../service/task.service";
import { fetchTaskData, fetchTaskDataByID } from "../../Redux/Slice/taskSlice";
import { Zoom, toast } from "react-toastify";
import MainModal from "../../Components/MainModal";
import { notify } from "../../App";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import gallery from "../../Images/gallery.png";

const style = {
  height: "95%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const ShareDocumentModal = ({ open, setOpen, id, toast, setOperationId }) => {
  const dispatch = useDispatch();
  const { theme, themeColorsObject } = useSelector((state) => state.theme);

  // useEffect(() => {
  //   dispatch(fetchSharedDocs(id));
  // },[]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [note, setNote] = useState("");

  const { sharedDocuments, isLoading } = useSelector(
    (state) => state?.getSharedDocs
  );

  // this will be shown in error element
  const ShareDocumentSchema = Yup.object().shape({
    title: Yup.string()
      .required("Document title name is required")
      .min(5, "Document title must be at least 5 characters"),
    note: Yup.string()
      .required("Note is required")
      .min(5, "Note must be at least 5 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters"),
  });

  const onSubmit = async (value) => {
    // console.log(value);
    // e.preventDefault();

    setSubmitLoading(true);
    if (!selectedFile || selectedFile?.length === 0) {
      notify("Please select a document", "error");
      setSubmitLoading(false);
      return;
    }

    if (
      selectedFile[0].type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      notify("Invalid file format", "error");
      setSubmitLoading(false);
      return;
    }

    const formData = new FormData();
    // Loop through each selected file
    for (let i = 0; i < selectedFile.length; i++) {
        const file = selectedFile[i];

        // Check if the file size exceeds the maximum allowed size
        if (file.size > (10 * 1024 * 1024)) {
            // Notify the user that the file size is too large
            notify("File size exceeds the maximum allowed size", "error");
            setSubmitLoading(false); // Stop loading
            return; // Stop further processing
        }

        // Append the file to the FormData object
        formData.append(`document`, file);
    }

    // Wrap the file in an array
    formData.append("title", value.title);
    formData.append("task_xid", id);
    formData.append("description", value.description);
    formData.append("note", value.note);

    try {
      const response = await sharedDocs(formData, id);
      console.log(response);
      notify("Document uploaded");
      if (response.status === 400) {
        setSubmitLoading(false);
        // console.log(response.data.message);

        if (
          response.data.message ===
          "Internal server error: File type not allowed"
        ) {
          notify("Invalid File Format", "error");
        }

        notify("Something Went Wrong", "error");
      } else if (response.status === 201) {
        setSubmitLoading(false);
        // console.log(response);
        // console.log("submitted");
      } else if (response.status === 500) {
        notify(response.data.message, "error");
      }
    } catch (error) {
      console.log(error);
    }

    setSubmitLoading(false);
    dispatch(fetchSharedDocs(id));
    setSelectedFile(null);
    dispatch(fetchTaskDataByID(id));
    dispatch(fetchSharedDocs(id));
    reset();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(ShareDocumentSchema) });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files);
  };

  const handleDelete = async (taskId) => {
    try {
      // Call the deleteTask function with the tagId
      const response = await deleteShareDocs(taskId);

      // Handle the response from the deleteTask function
      // console.log("Task deleted successfully:", response);

      dispatch(fetchSharedDocs(id));

      // Close the modal or perform any other action upon successful deletion
      // handleClose();
    } catch (error) {
      // Handle errors if the deletion fails
      console.error("Error deleting ta:", error);

      // Close the modal or perform any other action upon deletion failure
      // handleClose();
    }

    let pagination = {
      size: 10,
      page: 1,
    };

    dispatch(fetchTaskData(pagination));
  };

  // open={open}
  // onClose={() => setOpen(false)}
  const handleClose = () => {
    reset();
    dispatch(fetchTaskDataByID(id));
    dispatch(fetchSharedDocs(id));
    setOpen(false);
  };

  return (
    <MainModal
      width={"60%"}
      open={open}
      title="Share Documents"
      handleClose={handleClose}
    >
      <div className="w-100 d-flex flex-column ">
        <form
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 gap-3 gap-2 d-flex flex-column align-items-start"
        >
          <div className="w-100 gap-3 d-flex flex-column">
            <label className="cad-text-medium cad-fw-600">
              Document title : <span className="text-danger">*</span>
            </label>

            <div className=" w-100 flex-wrap d-flex position-relative align-items-center">
              <input
                type="text"
                className="form-control w-100"
                placeholder="Document title"
                {...register("title")}
              />
              {errors.title && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.title.message}
                </div>
              )}
            </div>

            <span className="w-100 gap-3 d-flex flex-column">
              <label className="cad-text-medium cad-fw-600">
                Please select file : <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                onChange={(event) => handleFileChange(event)}
                accept=".pdf, .xlsx, .xls, .docx, .doc, image/*"
                multiple={true}
                className="form-control cad-text-medium cad-fw-500"
                id="formFileSm"
              />
              <span className="text-info cad-text-small ">
                Maximum size of document must be 10MB
              </span>
            </span>

            <div className="d-flex flex-wrap gap-2">
              {selectedFile === null ? (
                <span className="text-danger d-flex align-items-center gap-1 cad-text-medium cad-fw-500 p-1 pb-0">
                  <ErrorOutlineRoundedIcon className="cad-text-large" />{" "}
                  Document not selected yet
                </span>
              ) : selectedFile[0]?.type === "image/png" ||
                selectedFile[0]?.type === "image/jpeg" ? (
                <span className="d-flex flex-wrap gap-2 align-items-end">
                  <img src={gallery} alt="" width={25} height={25} />
                  <span>{selectedFile[0].name}</span>
                </span>
              ) : selectedFile[0]?.type === "application/msword" ||
                selectedFile[0]?.type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                <span className="d-flex flex-wrap gap-2 align-items-end">
                  <img src={wordLogo} alt="" width={25} height={25} />
                  <span>{selectedFile[0].name}</span>
                </span>
              ) : selectedFile[0]?.type === "application/pdf" ? (
                <span className="d-flex flex-wrap gap-2 align-items-end">
                  <img src={pdfLogo} alt="" width={25} height={25} />
                  <span className="cad-text-small">
                    {selectedFile[0]?.name}
                  </span>
                </span>
              ) : (
                <span className="text-danger d-flex align-items-center gap-1 cad-text-medium cad-fw-500 p-1 pb-0">
                  <ErrorOutlineRoundedIcon className="cad-text-large" /> Invalid
                  file format
                </span>
              )}
            </div>
          </div>

          <div className="w-100 gap-3 d-flex flex-column">
            <label className="cad-text-medium cad-fw-600">
              Document description : <span className="text-danger">*</span>
            </label>

            <div className=" d-flex align-items-center flex-wrap gap-2">
              <textarea
                type="text"
                className="form-control"
                placeholder="Document description"
                rows={3}
                {...register("description")}
              />
              {errors.description && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.description.message}
                </div>
              )}
            </div>
          </div>

          <div className="w-100 gap-3 d-flex flex-column">
            <label className="cad-text-medium cad-fw-600">
              Note : <span className="text-danger">*</span>
            </label>

            <div className=" d-flex align-items-center flex-wrap gap-2">
              <textarea
                type="text"
                className="form-control"
                placeholder="Note"
                rows={1}
                {...register("note")}
              />
              {errors.note && (
                <div className="text-danger cad-text-small cad-fw-500 p-1 pb-0">
                  {errors.note.message}
                </div>
              )}
            </div>
          </div>

          <div className="w-100 gap-3 d-flex align-items-center flex-column justify-content-center">
            <div className="col-4">
              <Button
                type="submit"
                className="button-contained"
                variant="contained"
              >
                {submitLoading ? (
                  <CircularProgress
                    style={{ height: 24, width: 24 }}
                    color="inherit"
                  />
                ) : (
                  "Upload document"
                )}
              </Button>
            </div>
          </div>
        </form>

        <div className="col-12 pb-3 mt-4  d-flex flex-column justify-content-between">
          {sharedDocuments.length === 0 ? (
            <div className="w-100 h-100 d-flex justify-content-center text-primary align-items-center">
              <span className="text-primary cad-fw-600 mt-3 p-3 roun border border-primary">
                Please Upload Document
              </span>
            </div>
          ) : (
            <>
              <TableContainer className="h-100">
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ color: themeColorsObject.titleText }}
                        className="cad-fw-600 cad-text-small"
                        align="left"
                      >
                        Doc Type
                      </TableCell>
                      <TableCell
                        style={{
                          color: themeColorsObject.titleText,
                          width: 200,
                        }}
                        className="cad-fw-600 cad-text-small"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{ color: themeColorsObject.titleText }}
                        className="cad-fw-600 cad-text-small"
                        align="left"
                      >
                        Description
                      </TableCell>
                      <TableCell
                        style={{ color: themeColorsObject.titleText }}
                        className="cad-fw-600 cad-text-small"
                        align="center"
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading
                      ? // Show skeleton loading for the entire table rows
                        Array.from({ length: 6 }).map((_, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{ color: themeColorsObject.titleText }}
                              className="cad-text-small"
                              align="center"
                            >
                              <Skeleton variant="text" height={30} />
                            </TableCell>
                            <TableCell
                              style={{ color: themeColorsObject.titleText }}
                              className="cad-text-small"
                              align="center"
                            >
                              <Skeleton variant="text" height={30} />
                            </TableCell>
                            <TableCell
                              style={{ color: themeColorsObject.titleText }}
                              className="cad-text-small"
                              align="center"
                            >
                              <Skeleton variant="text" height={30} />
                            </TableCell>
                          </TableRow>
                        ))
                      : sharedDocuments?.map((eachDocs) => (
                          <TableRow
                            className="pointer"
                            key={eachDocs.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor =
                                "transparent";
                              e.currentTarget.style.transition = "0.5s";
                              e.currentTarget.style.boxShadow =
                                "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                              // setMouseEntered(true);
                              // setMouseEnteredId(id);
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor =
                                "transparent"; // Revert to default background color on hover out
                              e.currentTarget.style.scale = 1;
                              e.currentTarget.style.transition = "0.5s";
                              e.currentTarget.style.boxShadow = "none";
                              // setMouseEntered(false);
                            }}
                          >
                            <TableCell
                              style={{ color: themeColorsObject.textColor }}
                              className="cad-text-small"
                              align="left"
                            >
                              <img
                                src={
                                  eachDocs?.document?.mimetype
                                    .split("/")
                                    .pop() === "pdf"
                                    ? pdfLogo
                                    : eachDocs?.document?.mimetype
                                        .split("/")
                                        .pop() === "jpeg" ||
                                      eachDocs?.document?.mimetype
                                        .split("/")
                                        .pop() === "png"
                                    ? gallery
                                    : wordLogo
                                }
                                width={20}
                                alt="doc-img"
                              />{" "}
                              <span>


                                  {eachDocs?.document?.mimetype
                                    .split("/")
                                    .pop() === "pdf"
                                    ? "Pdf"
                                    : eachDocs?.document?.mimetype
                                        .split("/")
                                        .pop() === "jpeg" ||
                                      eachDocs?.document?.mimetype
                                        .split("/")
                                        .pop() === "png"
                                    ? "Image"
                                    : "Word"}

                              </span>
                            </TableCell>
                            <TableCell
                              style={{ color: themeColorsObject.textColor }}
                              className="cad-text-small"
                              component="th"
                              scope="row"
                            >
                              {eachDocs.title}
                            </TableCell>
                            <TableCell
                              style={{ color: themeColorsObject.textColor }}
                              className="cad-text-small"
                              align="left"
                            >
                              {eachDocs.description}
                            </TableCell>
                            <TableCell
                              style={{ color: themeColorsObject.textColor }}
                              onClick={() => handleDelete(eachDocs.id)}
                              className="cad-text-small"
                              align="center"
                            >
                              <CloseIcon className="text-danger" />
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </MainModal>
  );
};

export default ShareDocumentModal;
