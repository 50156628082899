import React from 'react';
import "./SlideBtn.css"
import { useLocation } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

function SlideBtn({ Icon, iconName, size, title, path, isMenuVisible, themeColorsObject }) {
  const location = useLocation();
  const iconComponents = {
    AiOutlineAppstore: GridViewOutlinedIcon,
    FaTasks: ListAltRoundedIcon,
    FaWpforms: InsertDriveFileOutlinedIcon,
    LuContact: PermContactCalendarOutlinedIcon,
    BsChatSquareText: MessageOutlinedIcon,
    FiSettings: SettingsOutlinedIcon,
    IoDocumentTextOutline: InsertDriveFileOutlinedIcon

  };


  const getTitle = () => {
    switch (path) {
      case "/task":
        return "Task";
      case "/contacts":
        return "Contacts";
      case "/form":
        return "Form";
      case "/chats":
        return "Chats";
      case "/add-task":
        return "Task » Add task";
      case "/support":
        return "Setting » Support";
      case "/user":
        return "Setting » User";
      case "/subscription":
        return "Setting » Subscription";
      case "/tags":
        return "Setting » Tags";
      case "/company-branding":
        return "Setting » Company Branding";
      case "/accounts-security":
        return "Setting » Accounts & Security";
      case "/notification":
        return "Setting » Notifications";
      case "/upgrade-plan":
        return "Upgrade Plan";
      case "/profile":
        return "Profile";
      default:
        return "Dashboard";
    }
  };

  const isActive = location.pathname === path;

  return (
    <NavLink
      style={{
        textDecoration: "none",
      }}
      className="w-100 bg- rounded-1"
      to={path}>

      <Button
        style={{
          color: "inherit",
          backgroundColor: "inherit",
          textTransform: "none",
          fontSize: "10px",
        }}
        className={`${themeColorsObject.side_btn_class} w-100   d-flex gap-3 ${isActive ? 'active' : ''}  align-items-center justify-content-start`}
      >
        <span
          className=' p-1 ps-2 side-button-title cad-text-small cad-fw-800'
        >
          <Tooltip placement="right-end" title={getTitle()}>
            <> {Icon} </>
          </Tooltip>
        </span>
        <span
          style={{
            opacity: isMenuVisible ? 0 : 1,
            display: isMenuVisible ? "none" : "flex",
          }}
          className=' side-button-title cad-text-medium'>{title}</span>

      </Button>
    </NavLink>

  );
}

export default SlideBtn;
