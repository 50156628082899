import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

export const getUser = async (params) => {
    try {
        return await api.get(`/user/agent/`,{
            params: params
        })
    } catch (error) {
        return error.response
    }
}

export const updateUser = async (data, id) => {
    try {
        return await api.put(`/user/agent/${id}`, data)
    } catch (error) {
        return error.response
    }
}


export const userDetails = async (userId) => {
    try {
        return await api.get(`/user/agent/${userId}`)
    } catch (error) {
        return error.response
    }
}

export const createUser = async (data) => {
    // console.log(data);
    try {
        return await api.post(`/user/agent/`, data, formDataConfig)
    } catch (error) {
        throw error
    }
}

export const deleteUser = async (userId) => {
    try {
        return await api.delete(`/user/agent/delete/${userId}`)
    } catch (error) {
        return error.response
    }
}

export const resource = async () => {
    try {
        return await api.get(`global/resource`)
    } catch (error) {
        return error.response
    }
}

export const userReporting = async () => {
    try {
        return await api.get(`/user/agent/all/employee`)
    } catch (error) {
        return error.response
    }
}
