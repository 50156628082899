import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPriority } from "../../service/global.service";

// Define an async thunk for fetching task priority data
export const fetchTaskPriorityData = createAsyncThunk("fetchTaskPriorityData", async () => {
  try {
    const res = await getPriority(); // Call your API service function to get task priority data
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching task priority data: ${err.message}`);
  }
});

// Create the taskPrioritySlice
const taskPrioritySlice = createSlice({
  name: "taskPriority",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    taskPriorities: [], // Property to store the fetched task priority data
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskPriorityData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchTaskPriorityData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.taskPriorities = action.payload.data; // Update taskPriorities property with fetched data
      })
      .addCase(fetchTaskPriorityData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

// Export the reducer generated by createSlice
export default taskPrioritySlice.reducer;
