import React from 'react';

const CustomStatusCell = ({ value }) => {
    let backgroundColor = '';
    let color = '';
    let padding = '5px 10px';
    let borderRadius = '12.278px';
    let fontSize = '12px';

    if (value === 'Pending' || value === 'Medium'){
        backgroundColor = 'rgba(252, 194, 61, 0.17)';
        color = '#E39C00'; 
    } else if (value === 'On Going' || value === 'High'){
        backgroundColor = 'rgba(255, 57, 73, 0.24)';
        color = '#FD394A'
    } else if (value === 'Completed' || value === 'Low'){
        backgroundColor = 'rgba(76, 168, 3, 0.13)';
        color = '#34A853'
    }

    return (
        <div style={{ backgroundColor, color, padding, borderRadius, fontSize }}>
            {value}
        </div>
    );
};

export default CustomStatusCell;
