import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import {
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import GroupAvatars from "../Avatar/AvatarGroup";

const formatDatee = (inputDate) => {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const Tag = ({ title }) => {
  return (
    <span
      style={{
        backgroundColor: "#DEE6F8",
        fontSize: "12px",
        color: "#216AFF",
      }}
      className="p-1 ps-3 pe-3 fw-bold rounded-1"
    >
      {title}
    </span>
  );
};

// function stringToColor(string) {
//   let hash = 0;
//   let i;

//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   let color = "#";

//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */

//   return color;
// }

// function stringAvatar(name) {
//   return {
//     sx: {
//       bgcolor: stringToColor(name),
//     },
//     children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
//   };
// }

const TimelineView = () => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const currentDate = new Date(); // Get the current date and time

  const [month, setMonth] = useState(currentDate.getMonth());

  const tasks = useSelector((state) => state?.tasks.tasks);

  const filteredMonthTasks = tasks?.filter((task) => {
    const dueDateMonth = new Date(task?.due_date);

    const monthh = dueDateMonth.getMonth(); // This will return 10 for November (zero-based index)

    return monthh === month;
  });

  const filteredOverdueTasks = filteredMonthTasks?.filter((task) => {
    const dueDate = new Date(task?.due_date); // Parse task's due date string to Date object
    // return dueDate
    return (
      dueDate < currentDate && dueDate?.getDate() !== currentDate?.getDate()
    );
  });

  const filteredTasks = filteredMonthTasks?.filter((task) => {
    const dueDate = new Date(task?.due_date); // Parse task's due date string to Date object
    return dueDate;
  });

  const classifiedTasks = filteredTasks?.reduce((classified, task) => {
    const dueDate = task?.due_date;
    if (!classified[dueDate]) {
      classified[dueDate] = [];
    }
    classified[dueDate].push(task);
    return classified;
  }, {});

  // Sort the classifiedTasks by due dates in ascending order
  const sortedClassifiedTasks = Object.keys(classifiedTasks)
    ?.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA - dateB;
    })
    ?.reduce((sorted, key) => {
      sorted[key] = classifiedTasks[key];
      return sorted;
    }, {});

  const classifiedTasksArray = Object.entries(sortedClassifiedTasks)?.map(
    ([dueDate, tasks]) => ({
      dueDate,
      tasks,
    })
  );

  const handleChange = (event) => {
    setMonth(event.target.value);
  };

  const getFormattedDate = () => {
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are 0-based (January is 0)
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();
    return `${currentMonth}/${currentDay}/${currentYear}`;
  };

  // ===============================[ Need Attention ]===========================================

  // const onMonthChangeTask = classifiedTasksArray?.filter((task) => {
  //   const dueDate = new Date(task.dueDate);
  //   return dueDate.getUTCMonth() + 1 === 10; // 10 represents November (months are zero-indexed)
  // });
  // ===============================[ Need Attention End ]===========================================

  return (
    <div
      style={{
        minHeight: "80vh",
      }}
      className="p-2"
    >
      <div className="d-flex p-3 pt-2 w-100 justify-content-between align-items-center">
        <FormControl size="small" sx={{ minWidth: 200, minHeight: 40 }}>
          <Select
            style={{
              color: themeColorsObject.textColor,
              border: `1px solid ${themeColorsObject.textColor}`
            }}
            className="cad-text-medium"
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={month}
            onChange={handleChange}
            label=""
          >
            <MenuItem className="cad-text-medium" value={10}>
              Select Month
            </MenuItem>
            <MenuItem className="cad-text-medium" value={0}>
              JAN 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={1}>
              FEB 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={2}>
              MAR 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={3}>
              APR 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={4}>
              MAY 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={5}>
              JUNE 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={6}>
              JULY 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={7}>
              AUG 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={8}>
              SEP 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={9}>
              OCT 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={10}>
              NOV 2024
            </MenuItem>
            <MenuItem className="cad-text-medium" value={11}>
              DEC 2024
            </MenuItem>
          </Select>
        </FormControl>

        <div className="col-3 d-flex justify-content-end">
          <Tooltip title="Total Overdue">
            <span
              className=" d-flex justify-content-center pointer align-items-center cad-text-large cad-fw-700 ps-3 pe-3 rounded-1"
              style={{
                height: 36,
                width: "auto",
                backgroundColor: "#EFF0F4",
                color: "#FD394A",
                fontSize: "20px",
              }}
            >
              Total task {filteredTasks?.length}
            </span>
          </Tooltip>
        </div>
      </div>

      {/* ======[ Overdue Task ]====== */}
      <div className="col-12 timeline-data p-2 pb-0 mb-3 ">
        <div className="d-flex border-bottom justify-content-between align-items-center p-2">
          <span className="cad-fw-500">Overdue</span>

          <Tooltip title="Total Overdue">
            <span
              className=" d-flex justify-content-center pointer align-items-center cad-text-medium cad-fw-700 text-white rounded-1"
              style={{ height: 26, width: 26, backgroundColor: "#FD394A" }}
            >
              {filteredOverdueTasks?.length}
            </span>
          </Tooltip>
        </div>

        {filteredOverdueTasks?.map(
          ({ id, title, due_date, tags, employees, priority }) => {
            return (
              <div
                key={id}
                className="d-flex border-bottom justify-content-between p-2 pt-3 pb-3"
              >
                <div className="d-flex gap-2 align-items-start">
                  <CircleTwoToneIcon
                    style={{
                      color: priority?.title === "High" ? "#E39C00" : "#FD394A",
                    }}
                    className="cad-text-large mt-1"
                  />
                  <div className="d-flex flex-column gap-2">
                    <span className="cad-text-medium">{title}</span>
                    <span
                      style={{
                        color:
                          priority?.title === "High" ? "#E39C00" : "#FD394A",
                      }}
                      className="cad-text-small d-flex align-items-center gap-1 "
                    >
                      <DateRangeOutlinedIcon className="cad-text-small" />
                      {formatDatee(due_date)}
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-end gap-2">

                  <GroupAvatars data={employees} />
                  <div className="d-flex gap-2">
                    {tags?.map((tag, i) => (
                      <Tag key={i} title={tag?.title} />
                    ))}
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>

      {/* ======[ Daily Task ]====== */}

      {classifiedTasksArray.map((eachTask, index) => {
        return (
          <div key={index} className="col-12 timeline-data p-2 pb-0 mb-3 ">
            <div className="d-flex border-bottom justify-content-between">
              <h6 className="cad-fw-500">
                {eachTask?.dueDate === getFormattedDate()
                  ? "Today"
                  : formatDatee(eachTask?.dueDate)}
              </h6>
            </div>

            {eachTask?.tasks?.map((task) => {
              return (
                <div
                  key={task?.id}
                  className="d-flex w-100 justify-content-between p-2 pt-3"
                >
                  <div className="d-flex col-4 gap-2 align-items-start ">
                    <CircleTwoToneIcon
                      style={{
                        color:
                          task?.priority === "High" ? "#FD394A" : "#E39C00",
                      }}
                      className="cad-text-large mt-1 text-danger"
                    />
                    <div className="d-flex flex-column gap-2">
                      <span className="cad-text-medium">{task?.title}</span>
                      <span
                        style={{
                          color:
                            task?.priority === "High" ? "#FD394A" : "#E39C00",
                        }}
                        className="cad-text-small d-flex align-items-center gap-1"
                      >
                        <DateRangeOutlinedIcon className="cad-text-small" />
                        {formatDatee(task?.due_date)}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex col-8 flex-column align-items-end gap-2">
                    <GroupAvatars data={task?.employees} />
                    <div className="d-flex gap-2 flex-wrap justify-content-end">
                      {task?.tags?.map((tag, i) => (
                        <Tag key={i} title={tag.title} />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TimelineView;
