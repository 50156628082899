import AutorenewIcon from '@mui/icons-material/Autorenew';
import * as themeColors from "../Utils/Theme/Colors";
import { useSelector } from "react-redux";
import LightTooltip from './Tooltip/LightTooltip';

const Refresher = ({ handleRefresh, isLoading}) => {
  const {theme, themeColorsObject} = useSelector((state) => state.theme);
    // }


  return (
    <LightTooltip
                color="#e74c3c"
                title="Refresh"
              >
    <span
      style={{ padding: "0.3rem 0.3rem", color: themeColorsObject.textColor }}
      className="pointer action-hover rounded-2 "
      onClick={handleRefresh}
    >
      <AutorenewIcon className={`cad-text-xlarge text-primary ${isLoading ? "refresher" : ""}`} />
    </span></LightTooltip>
  );
};

export default Refresher;
