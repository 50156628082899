import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  createFeatureRequest,
  deleteFeatureRequest,
} from "../../service/support.service";
import { useDispatch } from "react-redux";
import { fetchRequestFeatures } from "../../Redux/Slice/getRequestFeatures";
import { useSelector } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import noData from "../../Images/no-data.png";
import PrimaryButton from "../PrimaryButton";

import AddIcon from "@mui/icons-material/Add";
import NoData from "../NoData";
import { AiOutlineDelete } from "react-icons/ai";
import SearchInput from "../SearchInput/SearchInput";

import CloseIcon from "@mui/icons-material/Close";
import AdvanceFilter from "../AdvanceFilter/AdvanceFilter";

const schema = yup.object().shape({
  feature: yup.string().required("Feature is required").min(4).max(200),
  description: yup.string().required("Description is required").min(4).max(200),
});

const NewFeaturesComponent = () => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(schema) });


  
  const handleClose = () => {
    setOpen(false)
    reset()
  };

  const { requestFeatures, isLoading } = useSelector(
    (state) => state.reqNewFeatures
  );
  // const featuress = useSelector((state) => state.reqNewFeatures)
  // // console.log(requestFeatures);

  const dispatch = useDispatch();

  const onSubmit = async (value) => {
    setSubmitLoading(true);
    try {
      const { status, data, error } = await createFeatureRequest(value);

      if (status === 400) {
        setSubmitLoading(false);
        // console.log(status);
      } else if (status === 201) {
        setSubmitLoading(false);
        // console.log(error);
      }
    } catch (error) {
      // console.log(error);
    }

    reset();
    handleClose();
    dispatch(fetchRequestFeatures());
  };

  const handleDelete = async (id) => {
    try {
      const { data } = await deleteFeatureRequest(id);
      // console.log(data);
      setOpen(false);
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
    dispatch(fetchRequestFeatures());
  };

  return (
    <div className="border-top rounded-bottom-3 h-100">
      <div className="col-12 d-flex justify-content-between p-3 gap-2">
        <div className="col-3">
          <SearchInput />
        </div>

        <div className="d-flex gap-2">
          <PrimaryButton
            onClick={handleOpen}
            variant="contained"
            icon={<AddIcon />}
            title="Add new features"
            // path="/support"
            padding="0.3rem 2rem"
          />

          
{/* <AdvanceFilter 
                bottom="-200px"
                left="-90px"

                cellConfig={cellConfig}
                tableHeadRowCell={tableHeadRowCell}

                setFilteredCellConfig={setFilteredCellConfigg}
                setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}

                checkboxValues={checkboxValues}
                setCheckboxValues={setCheckboxValues}
                /> */}
        </div>
      </div>

      <div className="d-flex h-75 col-12 mb-3">
        <TableContainer className="col-12 h-100">
          {requestFeatures?.rows?.length > 0 ? (
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: themeColorsObject.titleText,
                      width: 70,
                    }}
                    className="cad-fw-600 cad-text-small"
                    align="center"
                  >
                    Sr No
                  </TableCell>
                  <TableCell
                    style={{
                      color: themeColorsObject.titleText,
                      width: 250,
                    }}
                    className="cad-fw-600 cad-text-small"
                  >
                    Feature Name
                  </TableCell>
                  <TableCell
                    style={{ color: themeColorsObject.titleText }}
                    className="cad-fw-600 cad-text-small"
                    align="left"
                  >
                    Description
                  </TableCell>
                  <TableCell
                    className="cad-fw-600 cad-text-small"
                    style={{ color: themeColorsObject.titleText, width: 60 }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? // Show skeleton loading for the entire table rows
                    Array.from({ length: 5 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" width={30} height={20} />
                        </TableCell>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" width={250} height={20} />
                        </TableCell>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton variant="text" width={100} height={20} />
                        </TableCell>
                        <TableCell className="cad-text-small" align="center">
                          <Skeleton
                            className="m-auto"
                            variant="text"
                            width={70}
                            height={30}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : requestFeatures?.rows.map((row, index) => (
                      <TableRow
                        key={row.id}
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}

                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.transition = "0.1s";
                          e.currentTarget.style.boxShadow =
                            "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                          e.currentTarget.style.cursor = "pointer";
                          // setMouseEntered(true)
                          // setMouseEnteredId(id)
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                          e.currentTarget.style.scale = 1;
                          e.currentTarget.style.transition = "0.1s";
                          e.currentTarget.style.boxShadow = "none";
                          // setMouseEntered(false)
                        }}
                      >
                        <TableCell
                          className="cad-text-small"
                          component="th"
                          align="center"
                          scope="row"
                          style={{ color: themeColorsObject.textColor }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="cad-text-small"
                          component="th"
                          scope="row"
                          style={{ color: themeColorsObject.textColor }}
                        >
                          {row.feature}
                        </TableCell>
                        <TableCell
                          style={{ color: themeColorsObject.textColor }}
                          className="cad-text-small"
                          align="left"
                        >
                          {row.description}
                        </TableCell>
                        <TableCell
                          style={{ color: themeColorsObject.textColor }}
                          className="cad-text-small"
                          align="center"
                        >
                          <span
                            style={{ borderRadius: "18%" }}
                            className="p-2 action-hover"
                          >
                            <AiOutlineDelete
                              onClick={() => handleDelete(row.id)}
                              style={{ fontSize: "20px", color: "#444746" }}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          ) : (
            <NoData message={"No new features"} />
          )}
        </TableContainer>
      </div>

      <Modal
        open={open}
        className="cad-modal-blur"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-discription"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            p: 2,
            pt: 3,
            backgroundColor: "#fff",
            // height: 300,
            borderRadius: 8,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <div className="text-primary d-flex justify-content-between m-3 mb-0">
            <span className="cad-text-large cad-fw-600">New Features Request</span>
            <span
              onClick={handleClose}
              className="cad-text-small cad-fw-600 pointer action-hover rounded-2"
            >
              <CloseIcon />
            </span>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className=" p-4 col-12">
            <div className="mb-2 col-12">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label cad-text-medium cad-fw-600"
              >
                Feature Name{" "}
                <span className="text-danger cad-text-small cad-fw-600">*</span>
              </label>
              <input
                {...register("feature")}
                type="text"
                className="form-control cad-text-medium"
                id="exampleFormControlInput1"
                placeholder="Feature Name"
              />
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label cad-text-small text-danger cad-fw-600"
              >
                {errors.feature?.message}
              </label>
            </div>
            <div className="mb-2 col-12">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label cad-text-medium cad-fw-600"
              >
                Description{" "}
                <span className="text-danger cad-text-small cad-fw-600">*</span>
              </label>
              <textarea
                {...register("description")}
                className="form-control cad-text-medium"
                id="exampleFormControlTextarea1"
                rows="3"
              />
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label cad-text-small text-danger cad-fw-600"
              >
                {errors.description?.message}
              </label>
            </div>
            <div className="d-flex col-12 justify-content-center">
              <div className="col-3">
                <Button
                  type="submit"
                  className="button-contained"
                  variant="contained"
                >
                  {submitLoading ? (
                    <CircularProgress
                      style={{ height: 24, width: 24 }}
                      color="inherit"
                    />
                  ) : (
                    "SAVE"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default NewFeaturesComponent;
