import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Select } from "@mui/material";
import * as Yup from "yup";
import CountryCode from "../../../Utils/COUNTRY_CODE.json";
import Selector from "../../../Components/Select";
import { phoneRegExp } from "../../../Constants/Constants";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

import 'react-phone-input-2/lib/bootstrap.css'

const CompanyDetails = ({ registrationData, setRegData, setStepper }) => {
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [phone, setPhone] = useState(null);


  

  const companyDetailSchema = Yup.object().shape({
    business_name: Yup.string()
      .required("Business name is required")
      .min(3, "Business name must be at least 3 characters"),
    business_email: Yup.string()
      .required("Business email is required")
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    phone_number: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]{6,16}$/, "Invalid Contact Number"),
    feture_updates: Yup.boolean().oneOf([true], "Please accept the terms"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(companyDetailSchema) });
  
  useEffect(() => {
    setValue('phone_number', phone)
  }, [phone])



  const onSubmit = (value) => {
    const combinedObject = {
      ...registrationData,
      ...value,
      ...{ country_code: selectedCountry },
    };

    console.log(combinedObject);
    setRegData(combinedObject);
    setStepper(true);
    reset();
  };

  // console.log(registrationData);
  console.log(errors);

  const countryCodeOptions = CountryCode?.map((item) => {
    return {
      id: item.code,
      value: item.name,
      label: item.name,
      color: "#0d6efd",
    };
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column  justify-content-start h-100 "
    >
      <p className="text-primary cad-text-medium text-center cad-fw-600">
        Hii {registrationData.first_name}
      </p>
      <p className="text-center cad-text-large cad-fw-600">
        Please fill company details
      </p>

      <>
        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          Business Name <span className="text-danger cad-text-small">*</span>
        </label>
        <input
          type="text"
          className="form-control p-4 w-100 ps-3 border-primary"
          placeholder="Business Name"
          {...register("business_name")}
        />

        <div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.business_name?.message}
        </div>
      </>
      <>
        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          Business Email <span className="text-danger cad-text-small">*</span>
        </label>
        <input
          type="text"
          className="form-control p-4 w-100 ps-3 border-primary"
          placeholder="Business Email"
          {...register("business_email")}
        />

        <div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.business_email?.message}
        </div>
      </>

      <div className="mb-3 ">
        {/* <input
        type="string"
        className="form-control p-4 w-100 ps-3 border-primary"
        placeholder="Country code"
        {...register("country_code")}
      />

      <div className="form-label text-primary form-text text-danger">
        {errors.country_code?.message}
      </div> */}

        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          Select Country <span className="text-danger cad-text-small">*</span>
        </label>
        <Selector
          isMulti={false}
          options={countryCodeOptions}
          setSelected={setSelectedCountry}
        />
      </div>

      <>
        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          Contact Number <span className="text-danger cad-text-small">*</span>
        </label>
        {/* <input
          type="number"
          maxLength={15}
          className="form-control p-4 w-100 ps-3 border-primary"
          placeholder="Contact Number"
          {...register("phone_number")}
        />

        <div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.phone_number?.message}
        </div> */}

        <PhoneInput
  country={'us'}
  value={phone}
  onChange={setPhone}
  inputclassName="w-100"
  inputStyle={{
    height:50,
    width:"100%"
  }}
/>

<Alert className="mt-3"  severity="info">Please enter full mobile number</Alert>
<div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.phone_number?.message}
        </div>
      </>

      <div className=" d-flex justify-content-center mt-2">
        <span className="form-check d-flex justify-content-start gap-2">
          <input
            className="form-check-input w-auto pointer border-primary"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="cad-text-small d-flex align-items-center">
            Get feature updates and tips via email(recommended)
          </label>
        </span>
      </div>

      <div className="col-8 mb-3 d-flex flex-column gap-2 align-self-center mt-2">
        <Button
          type="submit"
          className="btn mt-2 pt-1 fs-5 cad-fw-700 pb-1 button-contained"
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default CompanyDetails;
