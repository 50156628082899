import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const VerifiedMail = ({
  emailEntered,
  setCompanyDetails,
  setRegistrationData,
  firstName,
  lastName,
}) => {

  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);






  const registerDetailSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required")
    .matches(/^[A-Za-z]+$/, "First Name can only contain letters")
    .min(3, "First Name must be at least 3 letters"),
    last_name: Yup.string().required("Last name is required")
    .matches(/^[A-Za-z]+$/, "Last Name can only contain letters")
    .min(3, "Last Name must be at least 3 letters"),
    password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password do not match")
      .required("Confirm password is required"),
  });



  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(registerDetailSchema) });




  useEffect(() => {
    firstName && setValue("first_name", firstName)
    lastName && setValue("last_name", lastName)
  }, [])
  


  const onSubmit = (value) => {
    // console.log(value);
    setRegistrationData(value);
    setCompanyDetails(true);
    reset();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state to show/hide password
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle the state to show/hide password
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column  justify-content-start h-100 "
    >
      <h2 className="text-center">Welcome to our company</h2>
      <p className="text-center cad-text-medium mb-4">
        You’re signing up as{" "}
        <span className="text-primary cad-fw-600">{emailEntered}</span>{" "}
      </p>

      <>
        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          First Name <span className="text-danger cad-text-small">*</span>
        </label>
        <input
          title="Only alphabets are allowed"
          type="text"
          pattern="[A-Za-z]+"
          className="form-control p-4 w-100 ps-3 border-primary"
          placeholder="First name"
          {...register("first_name")}
        />

        <div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.first_name?.message}
        </div>
      </>

      <>
        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          Last Name <span className="text-danger cad-text-small">*</span>
        </label>
        <input
          title="Only alphabets are allowed"
          type="text"
          pattern="[A-Za-z]+"
          className="form-control p-4 w-100 ps-3 border-primary"
          placeholder="Last name"
          {...register("last_name")}
        />

        <div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.last_name?.message}
        </div>
      </>

      <>
        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          Create Password <span className="text-danger cad-text-small">*</span>
        </label>
        
        <div className="input-group d-flex  w-100">
        <input
          maxLength={16}
          type={showPassword ? "text" : "password"}
          className="form-control p-4 ps-3 border-primary"
          placeholder="Create password"
          {...register("password")}
        />
        <button
          className="btn btn-outline-primary "
          type="button"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
            <VisibilityOffIcon className="cad-text-large" />
          ) : (
            <RemoveRedEyeIcon className="cad-text-large" />
          )}
        </button>
        </div>
        <div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.password?.message}
        </div>
      </>

      <>
        <label className="cad-text-medium cad-fw-500 mb-1 text-primary">
          Confirm Password <span className="text-danger cad-text-small">*</span>
        </label>
        
        <div className="input-group d-flex  w-100">
        <input
          maxLength={16}
          type={showConfirmPassword ? "text" : "password"}
          className="form-control p-4 ps-3 border-primary"
          placeholder="Confirm password"
          {...register("confirmPassword")}
        />
        <button
          className="btn btn-outline-primary "
          type="button"
          onClick={toggleConfirmPasswordVisibility}
        >
          {showConfirmPassword ? (
            <VisibilityOffIcon className="cad-text-large" />
          ) : (
            <RemoveRedEyeIcon className="cad-text-large" />
          )}
        </button>
        </div>

        <div className="cad-text-small p-1 cad-fw-600 text-danger">
          {errors.confirmPassword?.message}
        </div>
      </>

      <div className="col-8 mt-4 mb-3 d-flex flex-column gap-2 align-self-center mt-2">
        <Button
          type="submit"
          className="btn mt-2 pt-1 fs-5 cad-fw-700 pb-1 button-contained"
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default VerifiedMail;
