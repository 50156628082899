import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Paper, Tabs, Tab } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import PropTypes from "prop-types";
import ClientDetailsTasks from "./ClientDetailsTasks";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {getClientById} from "../../../../Redux/Slice/getClientById";

const formatDate = (inputDate) => {
  const day = inputDate.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is zero-based, so we add 1
  const year = inputDate.getFullYear();
  // console.log(`${day}/${month}/${year}`);
  return (`${day}/${month}/${year}`);
}

// Custom Tab Panel Component
function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Define the AddUser component
const ClientDetails = () => {
  let { id } = useParams();
  const { rows } = useSelector((state) => state?.client?.clients);
  let dataa = rows


  const dispatch = useDispatch()


  const filteredObj = dataa.filter((item) => item.id === id);



  
  useEffect(() => {
    dispatch(getClientById(id))
  }, [])

  

  const clientById = useSelector((state) => state)

  

  const inputDate = new Date(filteredObj[0].createdAt);



  const htmlData = [
    {
      type: "header",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
      name: "Amelia Jones",
    },
    {
      type: "divider",
    },
    {
      type: "section",
      title: "Personal Information",
      content: [
        {
          label: "First name",
          value:
          filteredObj[0]?.principal[0]?.first_name != null
              ? filteredObj[0]?.principal[0]?.first_name
              : "N/A",
        },
        {
          label: "Last name",
          value:
          filteredObj[0]?.principal[0]?.last_name != null
              ? filteredObj[0]?.principal[0]?.last_name
              : "N/A",
        }
      ],
    },
    {
      type: "divider",
    },
    {
      type: "section",
      title: "More Information",
      content: [
        {
          label: "Passport Name",
          value:
            filteredObj[0]?.given_name != null
              ? filteredObj[0]?.passport_name
              : "N/A",
        },
        {
          label: "Country",
          value:
            filteredObj[0]?.country != null ? filteredObj[0]?.country : "N/A",
        },
        {
          label: "Passport",
          value:
            filteredObj[0]?.passport_number != null
              ? filteredObj[0]?.passport_number
              : "N/A",
        },
        {
          label: "City",
          value: filteredObj[0]?.city != null ? filteredObj[0]?.city : "N/A",
        },
        {
          label: "National ID",
          value:
            filteredObj[0]?.national_ID != null
              ? filteredObj[0]?.national_ID
              : "N/A",
        },
        {
          label: "Pincode",
          value:
            filteredObj[0]?.present_postal_code != null
              ? filteredObj[0]?.present_postal_code
              : "N/A",
        },
        {
          label: "Profession",
          value:
            filteredObj[0]?.profession != null
              ? filteredObj[0]?.profession
              : "N/A",
        },
        {
          label: "Job Title",
          value:
            filteredObj[0]?.job_title != null
              ? filteredObj[0]?.job_title
              : "N/A",
        },
        {
          label: "Last engagement date",
          value:
            filteredObj[0]?.last_engagement_date != null
              ? filteredObj[0]?.last_engagement_date
              : "N/A",
        },
        {
          label: "Created by/added by",
          value:
            formatDate(inputDate) != null
              ? formatDate(inputDate)
              : "N/A",
        },
      ],
    },
    {
      type: "divider",
    },
    {
      type: "notes",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    },
  ];

  // State for handling the active tab
  const [value, setValue] = useState(0);

  // Function to handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "#f1f2f6",
      }}
      className=" h-100 w-100 p-3 d-flex overflow-hidden flex-column justify-content-start align-items-start "
    >
      <Box
        className="border-bottom rounded-top-3"
        sx={{
          borderBottom: 0,
          padding: 0,
          backgroundColor: "#fff",
          width: "100%",
          overflow: "scroll",
        }}
      >
        {htmlData.map((item, index) => {
          const key = item.type + "-" + index;
          if (item.type === "header") {
            return (
              <div key={key}>
                <div className="pt-3 ps-3 pe-3 pb-0">
                  <Link
                    to="/contacts"
                    className="mb-4 d-flex align-items-center gap-1 text-decoration-none"
                  >
                    <AiOutlineLeft className="pointer" size={18} />
                    <label className="pointer">back to contacts</label>
                  </Link>
                  <h4 className="cad-text-dark cad-fw-600">Client Details</h4>
                </div>
                <div className="d-flex justify-content-start align-items-center mb-4 gap-3 ps-3 pe-3 pt-0 pb-0">
                  <div
                    style={{
                      backgroundImage: `url("${item.imageUrl}")`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "50%",
                      backgroundPosition: "center",
                      height: 100,
                      width: 100,
                    }}
                  ></div>
                  <label
                    className="cad-text-dark cad-fw-600"
                    style={{ fontSize: "20px" }}
                  >
                    {item.name}
                  </label>
                </div>
              </div>
            );
          } else if (item.type === "divider") {
            return <hr className="m-0 hr-border" key={key} />;
          } else if (item.type === "section") {
            return (
              <div className="row p-3 w-100" key={key}>
                <h5 className="cad-text-grey mb-3 cad-text-large">
                  {item.title}
                </h5>
                {item.content.map((contentItem) => (
                  <div
                    className="col-4 mb-3 d-flex justify-content-start flex-column"
                    key={contentItem.label}
                  >
                    <label className="cad-text-medium cad-text-light-grey cad-fw-500">
                      {contentItem.label}
                    </label>
                    <label className="cad-text-medium cad-text-dark cad-fw-600">
                      {contentItem.value}
                    </label>
                  </div>
                ))}
              </div>
            );
          } else if (item.type === "notes") {
            return (
              <div className="p-3 w-100" key={key}>
                <h5 className="cad-text-grey mb-3 cad-text-large mb-0">
                  Notes
                </h5>
                <label className="cad-text-medium cad-text-dark cad-fw-600">
                  {item.text}
                </label>
              </div>
            );
          }
        })}

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tags"
          style={{
            height: "14px",
          }}
        >
          <Tab
            style={{
              textTransform: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
            label="Tasks"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
            label="Activity"
            {...a11yProps(1)}
          />
          <Tab
            style={{
              textTransform: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
            label="Documents"
            {...a11yProps(2)}
          />
        </Tabs>
        <CustomTabPanel
          className="w-100 bg-white rounded-bottom-3 custom-panel overflow-auto"
          value={value}
          index={0}
        >
          <ClientDetailsTasks />
        </CustomTabPanel>

        <CustomTabPanel
          className="w-100 h-100 p-0 custom-panel bg-white rounded-bottom-3 overflow-auto"
          value={value}
          index={1}
        >
          {/* <Company /> */}
        </CustomTabPanel>

        <CustomTabPanel
          className="w-100 custom-panel bg-white rounded-bottom-3 overflow-auto h-100"
          value={value}
          index={2}
        >
          {/* <ArchivedClients /> */}
        </CustomTabPanel>
      </Box>
    </Paper>
  );
};

export default ClientDetails; // Export the AddUser component
