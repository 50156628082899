import { useSelector } from "react-redux";
import * as themeColors from "../../Utils/Theme/Colors";
import { useState } from "react";
import FormViewModal from "../Form/FormViewModal";
import { Link } from "react-router-dom";
import { Button, Chip, Grid, Tooltip, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { deleteForm, toggleForm } from "../../service/form.service";
import { useDispatch } from "react-redux";
import { fetchForms, fetchFormsActive } from "../../Redux/Slice/getFormsSlice";
import { notify } from "../../App";
import { fetchGlobalForms } from "../../Redux/Slice/getGlobalForms";
import { Box, Stack } from "@mui/system";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PublicOffIcon from '@mui/icons-material/PublicOff';
import PublicIcon from '@mui/icons-material/Public';
import AlertModal from "../../Components/AlertModal";

const AutoEllipsis = ({ text, maxLength }) => {
  const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  return <Tooltip title={text} arrow placement="bottom">
    <span className="cad-text-small cad-fw-600 pt-2">{truncatedText}</span>
  </Tooltip>
};

const MyForms = () => {
  const dispatch = useDispatch();
  const [openFormView, setOpenFormView] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [mouseEnterId, setMouseEnterId] = useState("");
  const [ ationId, setActionId ] = useState(null);
  const theme = useSelector((state) => state.theme.theme);
  const { formArray } = useSelector((state) => state?.getForms);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;



  const [ alertModalOpen, setAlertModalOpen ] = useState(false);

  const handleOpenFormView = (id) => {
    setMouseEnterId(id);
    setOpenFormView(true);
  };

  const handleDelete = async (id) => {
    setActionId(id);
    setAlertModalOpen(true)
  };

  const handleToggle = async (id) => {
    try {
      const res = await toggleForm(id);
      dispatch(fetchForms());
      dispatch(fetchGlobalForms());
      dispatch(fetchFormsActive());
      console.log(res?.data.message);
      notify(res?.data.message);
    } catch (error) {
      console.log(error?.data.error.message);
      notify(error?.data.error.message, "warn");
    }
  };


  const handleDeleteForm = async () => {
    setIsLoading(true)

    try {
      const res = await deleteForm(ationId);
      dispatch(fetchForms());
      dispatch(fetchGlobalForms());
      dispatch(fetchFormsActive());
      setIsLoading(false)
      setAlertModalOpen(false)
      notify("Form deleted");
    } catch (error) {
      console.log(error);
      notify(error?.data?.error?.message, "warn");
      setIsLoading(false)
    }
  }


    // Function to close the modal
    const closeModalDelete = () => {
      setAlertModalOpen(false);
    };

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
      }}
      className=" mt-1 position-relative cad-shadow overflow-auto rounded-3 mb-4 d-flex flex-wrap gap-3 p-4"
    >
      <div
        style={{
          height: "2rem",
          position: "absolute",
          top: 14,
        }}
        className="w-100 d-flex gap-3 align-items-center "
      >
        <Link to="/form">
          <ArrowBackIcon className="fs-2 text-primary pointer" />
        </Link>
        <span className="text-primary cad-text-large fw-500">My forms</span>
      </div>
      {formArray?.map(({ id, title, status, isPersonal }) => (
        <div
          key={id}
          style={{
            height: "320px",
            width: "23%",
            marginTop: 28,
          }}
          className=" create-form-card d-flex justify-content-between align-items-between flex-column p-3"
        >
          <div className="form-hover h-75 cad-shadow form-png rounded-3 w-100">
            <Box className=" icon-button rouded-3 cad-fw-700 text-black w-100">
              <Stack direction="row" spacing={2} justifyContent={'space-between'} px={2} mb={0}>
                <Box>
                  <Tooltip title="Delete" placement="top" arrow >
                    <button
                      style={{ "backgroundColor": "#0a1551b6" }}
                      onClick={() => handleDelete(id)}
                      className="btn  action-hover "
                    >
                      <DeleteOutlineIcon
                        style={{
                          fontSize: "18px",
                          color: "#ffffff"
                        }}
                      />
                    </button>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Preview" placement="top" arrow >
                    <button
                      style={{ "backgroundColor": "#0a1551b6" }}
                      onClick={() => handleOpenFormView(id)}
                      className="btn  action-hover"
                    >
                      <RemoveRedEyeIcon style={{ color: "#ffffff", fontSize: "18px", }} />
                    </button>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title={status ? "Public" : "Private"} placement="top" arrow >
                    <button
                      style={{ "backgroundColor": "#0a1551b6" }}
                      onClick={() => handleToggle(id)}
                      className="btn action-hover"
                    >
                      {status ? (
                        <PublicIcon
                          style={{
                            fontSize: "18px",
                            color: "#ffffff"
                          }}
                        // className="text-success"
                        />
                      ) : (
                        <PublicOffIcon
                          style={{
                            color: "#ffffff",
                            fontSize: "18px",
                          }}
                        // className="text-danger"
                        />
                      )}
                    </button>
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
          </div>

          <div className="d-flex flex-column gap-1 mt-3">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="caption" component="div">
                <AutoEllipsis text={title} maxLength={15} />
              </Typography>
              <Typography gutterBottom variant="caption" component="div">
                <Chip sx={{ fontWeight: 700 }} label={status ? "Public" : "Private"} color={status ? "success" : "error"} variant="outlined" size="small" />
              </Typography>
            </Stack>

            <Link to={isPersonal ? `/template-preview/${id}` : `/template-forms/${id}`}>
              <button variant="outlined" className="btn button-outlined ">
                Use Template
              </button>
            </Link>
          </div>
        </div>
      ))}

      <FormViewModal
        id={mouseEnterId}
        open={openFormView}
        handleOpen={handleOpenFormView}
        handleClose={() => setOpenFormView(false)}
      />


<AlertModal
          message={"Are you sure you want to delete this form?"}
          closeModal={closeModalDelete}
          open={alertModalOpen}
          handler={handleDeleteForm}
        />
    </div>
  );
};

export default MyForms;
