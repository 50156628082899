import api from './api.service'

export const getPlans = async () => {
    try {
        return await api.get(`/billing/`)
    } catch (error) {
        return error.response
    }
}
export const getSubscription = async () => {
    try {
        return await api.get(`/billing/subscription`)
    } catch (error) {
        throw error
    }
}


export const makePayment = async (id) => {
    try {
        return await api.post(`/billing/makePayment/${id}`)
    } catch (error) {
        return error.response
    }
}
export const makePaymentAfterRegistartion = async (plan_xid, principal_xid) => {
    try {
        return await api.get(`/billing/make-payment/plan/${plan_xid}/principal/${principal_xid}`)
    } catch (error) {
        throw error
    }
}

export const getPaymentHistory = async (params) => {
    try {
        return await api.get(`/billing/payment`, {
            // params: { size: size, page: page, search: search },
            params: params,
        })
    } catch (error) {
        return error.response
    }
}

export const getInvoiceDownload = async (id) => {
    try {
        return await api.get(`/billing/invoice/${id}`, { responseType: 'blob' })
    } catch (error) {
        throw error
    }
}