import React, { useState } from "react";
import { Button, Tab, Tabs, Tooltip } from "@mui/material";
import "./Task.css"; // Importing styles
import TaskListView from "./TaskListView/TaskListView"; // Importing TaskListView component
import TaskGridView from "./TaskGridView/TaskGridView"; // Importing TaskGridView component
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import TimelineView from "../../Components/TimelineView/TimelineView";
import * as themeColors from "../../Utils/Theme/Colors";
import { useSelector } from "react-redux";
import AddTaskModal from "./AddTaskModal";
import { ToastContainer, toast } from "react-toastify";
import ShareDocumentModal from "./ShareDocumentModal";
import ReciveDocumentModal from "./ReciveDocumentModal";
import FillFormModal from "./FillFormModal";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthMe } from "../../Redux/Slice/auth/auth.slice";
import adobe from "../../Images/adobe.png";

// Custom Tab Panel Component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <section
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </section>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Task = () => {
  const dispatch = useDispatch();
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const [operationTaskId, setOperationTaskId] = useState("");
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const [openShareDocumentModal, setOpenShareDocumentModal] = useState(false);
  const [openReciveDocumentModal, setOpenReciveDocumentModal] = useState(false);
  const [openFillFormModal, setOpenFillFormModal] = useState(false);
  // console.log(openReciveDocumentModal);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(AuthMe());
  }, [dispatch]);

  const onAdobeCick = () => {
    window.open("https://www.adobe.com/sign/online-signature.html", '_blank')
  }

  return (
    <div
      style={{
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        height: "87vh",
      }}
      className=" w-100 mt-1 rounded-3 mb-4 cad-shadow d-flex overflow-auto flex-column justify-content-start align-items-start "
    >
      <Box
        sx={{
          borderBottom: 0,
          padding: 0,
          width: "100%",
          position: "relative",
        }}
      >

        <span
          className="cad-text-small h-100 d-flex gap-2 pe-3 align-items-center"
          style={{ position: "absolute", right: 0 }}
        >
          <Button
            onClick={onAdobeCick}
            variant="contained"
            style={{
              backgroundColor: "#584CCC",
              color: "#ffffff",
              textTransform: "none",
              padding: "0.1rem",
              zIndex: 999,
            }}

            className=" ps-2 cad-text-small pe-2 pointer adobe rounded-0 cad-fw-600 "
          >
            <img alt="Adobe" width={20} src={adobe} className="me-1" />
            E-Signature
          </Button>
        </span>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tags"
          style={{
            height: "14px",
          }}
        >
          <Tooltip title="Table" placement="top-start">
            <Tab
              style={{
                textTransform: "none",
                display: "flex",
                color: themeColorsObject.titleText,
              }}
              label="Task List View"
              {...a11yProps(0)}
            />
          </Tooltip>
          <Tooltip title="Grid View" placement="top-start">
            <Tab
              style={{
                textTransform: "none",
                display: "flex",
                color: themeColorsObject.titleText,
              }}
              label="Grid View"
              {...a11yProps(1)}
            />
          </Tooltip>
          <Tooltip title="Timeline View" placement="top-start">
            <Tab
              style={{
                display: "flex",
                textTransform: "none",
                color: themeColorsObject.titleText,
              }}
              label="Timeline View"
              {...a11yProps(2)}
            />
          </Tooltip>
        </Tabs>
      </Box>

      <CustomTabPanel
        className="w-100 h-100  bg-none rounded-bottom-3 border-top tab-scroll custom-panel  h-auto overflow-auto"
        value={value}
        index={0}
      >
        <TaskListView
          setOpenAddTaskModal={setOpenAddTaskModal}
          themeColorsObject={themeColorsObject}
          setOperationTaskId={setOperationTaskId}
          setOpenFillFormModal={setOpenFillFormModal}
          setOpenShareDocumentModal={setOpenShareDocumentModal}
          setOpenReciveDocumentModal={setOpenReciveDocumentModal}
        />
      </CustomTabPanel>
      <CustomTabPanel
        className="w-100  bg-none tab-scroll  rounded-bottom-3 border-top overflow-auto custom-panel  h-100"
        value={value}
        index={1}
      >
        <TaskGridView
          setOpenAddTaskModal={setOpenAddTaskModal}
          themeColorsObject={themeColorsObject}
          theme={theme}
          setOperationTaskId={setOperationTaskId}
          setOpenFillFormModal={setOpenFillFormModal}
          setOpenShareDocumentModal={setOpenShareDocumentModal}
          setOpenReciveDocumentModal={setOpenReciveDocumentModal}
        />
      </CustomTabPanel>
      <CustomTabPanel
        className="w-100 custom-panel  overflow-auto border-top"
        value={value}
        index={2}
      >
        <TimelineView themeColorsObject={themeColorsObject} />
      </CustomTabPanel>

      <AddTaskModal
        themeColorsObject={themeColorsObject}
        toast={toast}
        setOperationTaskId={setOperationTaskId}
        open={openAddTaskModal}
        setOpen={setOpenAddTaskModal}
        setOpenShareDocumentModal={setOpenShareDocumentModal}
        setOpenReciveDocumentModal={setOpenReciveDocumentModal}
      />

      <ShareDocumentModal
        themeColorsObject={themeColorsObject}
        toast={toast}
        id={operationTaskId}
        setOperationId={setOperationTaskId}
        open={openShareDocumentModal}
        setOpen={setOpenShareDocumentModal}
      />

      <ReciveDocumentModal
        themeColorsObject={themeColorsObject}
        toast={toast}
        id={operationTaskId}
        setOperationId={setOperationTaskId}
        open={openReciveDocumentModal}
        setOpen={setOpenReciveDocumentModal}
      />

      <FillFormModal
        themeColorsObject={themeColorsObject}
        toast={toast}
        id={operationTaskId}
        setOperationId={setOperationTaskId}
        open={openFillFormModal}
        setOpen={setOpenFillFormModal}
      />
    </div>
  );
};

export default Task;
