import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DateFormatter from "../../Components/DateFormatter";
import {
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineUpload,
} from "react-icons/ai";
import ClientViewModal from "./ClientViewModal";
import { getClientById } from "../../Redux/Slice/getClientById";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import UserProfile from "../../Components/GlobalTable/UserProfile";
import { PiArchive } from "react-icons/pi";
import { fetchClient } from "../../Redux/Slice/contactClientSlice";
import DpViewModal from "../../Components/DpViewModal";
import TableData from "../../Components/TableData";
import Refresher from "../../Components/Refresher";
import AddClientModal from "./AddClientModal";
import SearchInput from "../../Components/SearchInput/SearchInput";
import { archiveClient, deleteClient } from "../../service/client.service";
import { getArchiveClient } from "../../Redux/Slice/contactArchiveClientSlice";
import { pagination } from "../../Constants/Constants";
import AlertModal from "../../Components/AlertModal";
import { fetchGlobalClient } from "../../Redux/Slice/globalClientSlice";
import { notify } from "../../App";
import AdvanceFilter from "../../Components/AdvanceFilter/AdvanceFilter";
import { downloadCSV } from "../../Utils/ExportFunction/ExportFunction";
import { getExportContacts } from "../../service/agent.service";

const Client = ({ themeColorsObject, theme }) => {
  const [, setAnchorEl] = useState(null);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [openView, setOpenView] = useState(false);
  const [openAddNewClientModal, setOpenAddNewClientModal] = useState(false);
  const [filteredCellConfig, setFilteredCellConfigg] = useState([]);
  const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([]);

  const { role, actions } = useSelector((state) => state?.authMe);
  const [searchTerm, setSearchTerm] = useState(undefined);

  const [DpViewModalOpen, setDpViewModalOpen] = useState(false);
  const [DpUrl, setDpUrl] = useState("");

  const handleOpenDpViewModal = (url) => {
    setDpUrl(url);
    setDpViewModalOpen(true);
  };
  const handleCloseDpViewModal = () => setDpViewModalOpen(false);
  const dispatch = useDispatch();

  const handleViewOpen = (id) => {
    dispatch(getClientById(id));
    setOpenView(true);
  };

  useEffect(() => {
    dispatch(fetchClient());
  }, [dispatch]);

  const handleViewClose = () => setOpenView(false);

  const { isLoading, clients, totalPages, currentPage } = useSelector(
    (state) => state?.client
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to open the modal
  const openModalDelete = () => {
    setDeleteModalOpen(true);
    handleClose();
  };

  // Function to close the modal
  const closeModalDelete = () => {
    setDeleteModalOpen(false);
  };

  // Function to close the modal
  const closeModalArchive = () => {
    setArchiveModalOpen(false);
  };

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  const openModalArchive = () => {
    setArchiveModalOpen(true);
    handleClose();
  };

  const handlePagination = (value) => {
    // console.log(value);
    dispatch(
      fetchClient({
        size: 10 || undefined,
        page: value || 1 || undefined,
      })
    );
  };

  console.log(clients[0]);

  const extractedDataArray = Array.isArray(clients) && clients?.map((user) => {
    const extractedData = {
      id: user.id,
      checkBox: (
        // <input
        //   style={{ width: 16 }}
        //   className="form-check-input pointer"
        //   type="checkbox"
        //   value=""
        //   name="checkBox"
        //   id="flexCheckDefault"
        // checked={!!checkedRows[user?.id]}
        // onChange={(e) => handleCheckboxChange(e,user?.id)}
        // />


        <input
          style={{ width: 16 }}
          className="form-check-input pointer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
      ),
      name: (
        <UserProfile
          proPic={user?.profile_photo}
          companyName={`${user?.first_name}
        ${user?.last_name}`}
        />
      ),
      email:
        user?.details?.primary_email_address === null
          ? "N/A"
          : user?.details?.primary_email_address,
      mobileNumber:
        user?.details?.mobile_number === null
          ? "N/A"
          : user?.details?.mobile_number,
      country:
        user?.details?.permanent_country === null
          ? "N/A"
          : user?.details?.permanent_country,
      city:
        user?.details?.permanent_city === null
          ? "N/A"
          : user?.details?.permanent_city,
      createdDate:
        mouseEntered && mouseEnteredId === user?.id ? (
          <div className="d-flex  align-items-center justify-content-center">
            {/* <span
              style={{ borderRadius: "18%" }}
              className="p-2 action-hover text-primary"
            >
              <AiOutlineEye
                onClick={() => handleViewOpen(user?.id)}
                style={{ fontSize: "20px" }}
              />
            </span> */}
            {/* </Link> */}

            {role === "Admin" ? (
              ""
            ) : (
              <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover text-primary"
              >
                <AiOutlineDelete
                  onClick={() => openModalDelete(user?.id)}
                  style={{ fontSize: "20px" }}
                />
              </span>
            )}

            {role === "Admin" ? (
              ""
            ) : (
              <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover text-primary"
              >
                <PiArchive
                  onClick={openModalArchive}
                  style={{ fontSize: "20px" }}
                />
              </span>
            )}
          </div>
        ) : (
          <div className="ps-4 pe-4">
            <DateFormatter inputDate={user?.createdAt} />
          </div>
        ),
    };

    return extractedData;
  });

  const [checkboxValues, setCheckboxValues] = useState({
    Checkbox: false,
    Name: true,
    "Primary Email": true,
    "Mobile Number": true,
    "City": true,
    "Country": true,
  });

  // const handleCheckboxChange = (id) => {
  //   setCheckedRows((prevCheckedRows) => ({
  //     ...prevCheckedRows,
  //     [id]: !prevCheckedRows[id],
  //   }));
  // };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const cellConfig = [
    {
      dataKey: "checkBox",
      width: "1%",
      align: "center",
    },
    {
      dataKey: "name",
      width: "15%",
      align: "left",
    },
    {
      dataKey: "email",
      width: "20%",
      align: "center",
    },
    {
      dataKey: "mobileNumber",
      width: "15%",
      align: "center",
    },
    {
      dataKey: "country",
      width: "10%",
      align: "center",
    },
    {
      dataKey: "city",
      width: "10%",
      align: "center",
    },
    {
      dataKey: "createdDate",
      width: "20%",
      align: "center",
    },
  ];

  const tableHeadRowCell = [
    {
      title: (
        <input
          style={{ width: 16 }}
          className="form-check-input pointer"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        // checked={!!checkedRows[user?.id]}
        // onChange={() => handleCheckboxChange(user?.id)}
        />
      ),
      width: "1%",
      align: "center",
    },
    {
      title: "Name",
      width: "20%",
      align: "left",
    },
    {
      title: "Primary Email",
      width: "25%",
      align: "center",
    },
    {
      title: "Mobile Number",
      width: "15%",
      align: "center",
    },
    {
      title: "City",
      width: "10%",
      align: "center",
    },
    {
      title: "Country",
      width: "10%",
      align: "center",
    },
    {
      title: "Created Date",
      width: "34%",
      align: "center",
    },
  ];

  const handleArchiveClient = async () => {
    try {
      // Call the deleteUser function and pass the user ID
      const response = await archiveClient(mouseEnteredId);

      if (response.status === 200) {
        // The user was successfully deleted, handle the success
        // // console.log('User deleted successfully');
        dispatch(fetchClient(pagination));
        dispatch(getArchiveClient(pagination));
        closeModalArchive();
        notify("Client archived succesfully");
      } else {
        // Handle errors or show a message to the user
        console.error("Failed to delete user");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error deleting user", error);
    }
  };

  const handleDeleteClient = async () => {
    try {
      // Call the deleteUser function and pass the user ID
      const response = await deleteClient(mouseEnteredId);

      if (response.status === 200) {
        // The user was successfully deleted, handle the success
        // console.log("User deleted successfully");
        dispatch(fetchClient());
        dispatch(getArchiveClient());
        closeModalDelete();
        notify("Client deleted succesfully");
      } else {
        // Handle errors or show a message to the user
        console.error("Failed to delete user");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error deleting user", error);
    }

    dispatch(fetchClient());
    dispatch(fetchGlobalClient());
  };

  const handleContactExport = async () => {
    // console.log("hit");
    try {
      const res = await getExportContacts();
      downloadCSV(res?.data, "Contacts");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-100">
      <div className="d-flex align-items-center justify-content-between p-3 col-md-12">
        <div className="col-3">
          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="d-flex gap-2">
          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(fetchClient())}
          />
          <PrimaryButton
            variant="contained"
            title="Add New Client"
            padding="0.3rem 0.6rem"
            icon={<AiOutlinePlus size={13} />}
            fontSize="14px"
            width="200px"
            // path={"/add-new-client"}
            onClick={() => setOpenAddNewClientModal(true)}
          />
          <SecondaryButton
            title="Export"
            padding="0.2rem 2rem"
            icon={<AiOutlineUpload size={16} />}
            fontSize="13px"
            onClick={handleContactExport}
          />


          {/* <SecondaryButton
            title="Export"
            padding="0.2rem 2rem"
            icon={<AiOutlineUpload size={16} />}
            fontSize="13px"
            onClick={handleExportClick}
          /> */}

          <AdvanceFilter
            bottom="-169px"
            left="-73px"
            cellConfig={cellConfig}
            tableHeadRowCell={tableHeadRowCell}
            setFilteredCellConfig={setFilteredCellConfigg}
            setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
          />
        </div>
      </div>

      <TableData
        tableHeadRowCell={filteredTableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={filteredCellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        handleViewOpen={handleViewOpen}
        totalPages={totalPages}
        handlePagination={handlePagination}
        isLoading={isLoading}
        currentPage={currentPage}
      />

      <ClientViewModal
        theme={theme}
        themeColorsObject={themeColorsObject}
        idu={mouseEnteredId}
        open={openView}
        handleClose={handleViewClose}
        handleOpenDpViewModal={handleOpenDpViewModal}
      />
      <AddClientModal
        theme={theme}
        themeColorsObject={themeColorsObject}
        openAddNewClientModal={openAddNewClientModal}
        setOpenAddNewClientModal={setOpenAddNewClientModal}
      />

      <DpViewModal
        DpViewModalOpen={DpViewModalOpen}
        handleCloseDpViewModal={handleCloseDpViewModal}
        DpUrl={DpUrl}
      />
      <AlertModal
        message="Are you sure you want to archive client?"
        handler={handleArchiveClient}
        open={archiveModalOpen}
        closeModal={closeModalArchive}
      />

      <AlertModal
        message="Are you sure you want to delete client?"
        closeModal={closeModalDelete}
        open={deleteModalOpen}
        handler={handleDeleteClient}
      />
    </div>
  );
};

export default Client;
