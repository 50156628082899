import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTags } from "../../service/global.service"; // Assuming a service function for fetching tags

export const fetchGlobalTags = createAsyncThunk("fetchGlobalTags", async (_, { rejectWithValue }) => {
  try {
    const res = await getTags(); // Use the appropriate service function to fetch tags
    // // console.log(`global tags : \n`, res);
    return res.data;

  } catch (err) {
    throw new Error(`Error fetching tags: ${err.message}`);
  }
});

const globalTags = createSlice({
  name: "fetchTags",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    rows: [], // Add a property to store the fetched tags
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalTags.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload.data; // Update the property with the fetched tags
      })
      .addCase(fetchGlobalTags.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default globalTags.reducer;
