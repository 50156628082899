import { Avatar, Badge, Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import UserProfile from "../GlobalTable/UserProfile";
import {
  createOrGetOneOnOneChat,
  getContacts,
} from "../../service/chat.service";
import NoProfileLogo from "../../Images/profile_img.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GroupList from "./GroupList";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { notify } from "../../App";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const NewGroupChatModal = ({
  openNewChat,
  handleCloseNewChat,
  setContacts,
  contacts,
  themeColorsObject,
  setOpenGroupList,

  newGroupDetails,
  setNewGroupDetails,
  handleNewGroupDetails,
  profileSrc,
  setProfileSrc,
}) => {
  const [mouseEntered, setMouseEntered] = useState(false);
  const { isLoading, clients } = useSelector((state) => state?.globalClient);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    setNewGroupDetails({
      ...newGroupDetails,
      profile: file,
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileSrc(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      open={openNewChat}
      onClose={handleCloseNewChat}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      <div
        className="rounded-2 "
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 480,
          height: "75%",
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="d-flex justify-content-center">
          <h4 className="p-3 cad-fw-700">New Group Chat</h4>
        </div>

        <div className="d-flex justify-content-center position-relavtive flex-column mb-3 p-3 pt-0 align-items-center gap-4">
          <div
            style={{
              position: "relative",
            }}
          >
            <Avatar
              className="m-1"
              style={{
                width: "100px",
                height: "100px",
                border: "4px solid #E9E9E9",
              }}
              alt="Remy Sharp"
              src={profileSrc}
            />

            <input
              type="file"
              id="profile-image-input"
              accept="image/*"
              style={{
                display: "none",
              }}
              onChange={handleImageChange}
            />

            <label
              style={{
                position: "absolute",
                bottom: "1rem",
                right: "0.2rem",
                height: "30px",
                width: "30px",
                backgroundColor: "#E9E9E9",
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              htmlFor="profile-image-input"
            >
              <CameraAltTwoToneIcon
                sx={{
                  position: "absolute",
                  bottom: "0rem",
                  right: "0rem",
                  height: "30px",
                  width: "30px",
                  backgroundColor: "#E9E9E9",
                  padding: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </label>
          </div>

          <input
            value={newGroupDetails.name}
            onChange={handleNewGroupDetails}
            name="name"
            type="text"
            placeholder="Group name"
            className={`form-control cad-text-xlarge p-4 cad-fw-700 cad-text-medium rounded-0 text-center  ${themeColorsObject.themed_input_class}`}
          />

          <textarea
            type="text"
            value={newGroupDetails.description}
            onChange={handleNewGroupDetails}
            name="description"
            placeholder="Description.."
            className={`form-control cad-text-medium p-2 cad-fw-700 cad-text-medium rounded-0 text-center  ${themeColorsObject.themed_input_class}`}
            rows={2}
          />
        </div>

        <Button
          className=" d-flex align-items-center justify-content-center bg-primary"
          variant="contained"
          onClick={() => {
            if (newGroupDetails.name === "") {
              return notify("Please input Group Name", "warn");
            } else if (newGroupDetails.description === "") {
              return notify("Please input Group Description", "warn");
            } else {
              setOpenGroupList(true);
              handleCloseNewChat();
            }
          }}
          style={{
            height: 70,
            width: 70,
            borderRadius: "50%",
            position: "absolute",
            right: 16,
            bottom: 16,
          }}
        >
          <ArrowForwardIcon className="fs-2" />
        </Button>

        {/* <div className="w-100 mb-3 d-flex justify-content-center">
          <input
            type="text"
            className="form-control searchbox"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) =>setSearchTerm(e.target.value)}
          />
        </div> 
        <div className="w-100 d-flex flex-column overflow-auto h-75 pb-3">
          {filteredData?.map(({ id, first_name, last_name, profile_photo }, index) => (
            <span
              onClick={() => handleChatClick(id)}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
                e.currentTarget.style.transition = "0.5s";
                e.currentTarget.style.boxShadow =
                  "rgba(0, 0, 0, 0.24) 0px 3px 8px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                e.currentTarget.style.scale = 1;
                e.currentTarget.style.transition = "0.5s";
                e.currentTarget.style.boxShadow = "none";
              }}
              style={{
                cursor: "pointer",
              }}
              key={id}
              className="ms-3 me-3 border-bottom p-2  d-flex gap-2 align-items-center "
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <UserProfile proPic={profile_photo}   />
              </StyledBadge>
              <span className="cad-text-medium ms-1 cad-fw-600">
              {first_name} {last_name}
              </span>
            </span>
          ))}
        </div>  */}
      </div>
    </Modal>
  );
};

export default NewGroupChatModal;
