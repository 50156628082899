import { Button } from "@mui/material";
import { useEffect, useState } from "react";

const CookiePolicy = ({showPolicy, setShowPolicy}) => {

  useEffect(() => {
    // This code will run after the component is rendered
    // Set showPolicy to true after the document is successfully loaded
    setShowPolicy(true);
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount in class components


  return (
    <div
      className="bg-white w-100 p-3"
      style={{
        position: "absolute",
        left: 0,
        bottom: 0,
        // height:350,
        zIndex: 999,
        // height:150,
        // borderRadius:"16px 16px 0 0"
        transform: showPolicy ? "translateY(0px)" : "translateY(500px)",
        transition:"all 0.5s ease",
        // borderTop:"1px solid #000"
      }}
    >
      <p className="cad-text-medium">
        We prioritize transparency and user privacy. Our site employs cookies to
        enhance your browsing experience, analyze site traffic, and personalize
        content. By continuing to use our website, you consent to the use of
        cookies as outlined in our privacy policy. Our site employs cookies to
        enhance your browsing experience, analyze site traffic, and personalize
        content. By continuing to use our website, you consent to the use of
        cookies as outlined in our privacy policy.
      </p>
      <div className="d-flex justify-content-end gap-2">
        {/* <Button
        onClick={()=> setShowPolicy(true)}
          variant="outlined"
          className=" rounded-0 text-primary border-primary cad-text-small"
        >
          Reject All
        </Button> */}
        <Button onClick={()=> setShowPolicy(false)} variant="contained" className="bg-primary rounded-0 cad-text-small">
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookiePolicy;
