import React, { useCallback, useEffect, useState } from "react";
import "./Login.css";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { auth_login, google_login, } from "../../service/auth.service";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import google from "../../Images/google-icon.png";
import windows from "../../Images/wondows-icon.png";
import EmailField from "./EmailField";
import PasswordField from "./PasswordField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { authLogin } from "../../Redux/Slice/auth/auth.slice";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import CookiePolicy from "../../Components/CookiePolicy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { PublicClientApplication } from "@azure/msal-browser";
import { useSelector } from "react-redux";
import { notify } from "../../App";
import Cookies from "js-cookie";
import { fetchAuthLogin } from "../../Redux/Slice/fetchAuthMe";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority:
      `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_APP_ID}`,
    // redirectUri: process.env.REACT_APP_BACKEND_BASEURL, // Update with your redirect URI
    redirectUri: 'http://localhost:3000/login', // Update with your redirect URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, // Set to true for IE 11 support
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const Login = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const gToken = searchParams.get('gToken'); // Extract the token from the URL


  const [localStorageData, setLocalStorageData] = useState({});

  useEffect(() => {
    const data = {};
    
    // Retrieve all localStorage key-value pairs
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      data[key] = value;
    }
console.log(data);
    // Set the data to state
    setLocalStorageData(data);
  }, []);


  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmailId, setForgotPasswordEmailId] = useState("");
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  const [openDialogue, setOpenDialogue] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { isAuthenticate } = useSelector((state) => state?.authMe);

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);

  const [alertType, setAlertType] = useState("success");

  const [token, setToken] = useState(null);

  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  const initializeMSAL = async () => {
    try {
      await msalInstance.initialize();
      await msalInstance.handleRedirectPromise();
      const account = msalInstance.getAllAccounts()[0];
      if (account) {
        msalInstance.setActiveAccount(account);
      }
    } catch (error) {
      console.error("MSAL initialization failed:", error);
    } finally {
      setIsMsalInitialized(true); // Ensure this is always set to true after the attempt
    }
  };
  useEffect(() => {
    initializeMSAL();
  }, []);


  const handleMSLogin = async () => {
    if (!isMsalInitialized) {
      console.error("MSAL instance not initialized yet");
      return;
    }

    try {
      const loginRequest = {
        scopes: ["user.read"], // Add additional scopes as needed
      };
      await msalInstance.loginRedirect(loginRequest);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const onSuccess = async (credentialResponse) => {
    try {
      const res = await google_login({ token: credentialResponse?.credential });
      if (res?.status === 200) {
        console.log(res?.data?.is_2FA_on);
        // if (rememberMe) {
        //   const stringValue = JSON.stringify(value); // Convert object to string
        //   Cookies.set("userData", stringValue, { expires: 7 }); // Store the string
        // }
        // dispatch(authLogin(res.data));
        // notify(res.data.message);
        // navigate("/splash-screen");
        // setIsLoading(false);

        if (res?.data?.is_2FA_on) {
          dispatch(fetchAuthLogin(res.data));
          dispatch(authLogin(res.data));
          notify(res.data.message);
          navigate("/otp-verification");
          setIsLoading(false);
        } else {         
           dispatch(fetchAuthLogin(res.data));
          dispatch(authLogin(res.data));
          notify(res.data.message);
          navigate("/splash-screen");
          setIsLoading(false);
        }
      } else {
        // setIsLoading(false);
        // notify(res.data.message, "error");
      }
    } catch (error) {
      setIsLoading(false);
      notify(error.data?.error?.message, "error");
      // console.log(error);
    }
  };

  const onError = (error) => {
    console.error("Login failed:", error);
  };

  const cookieVerification = async () => {
    if (Cookies.get("userData")) {
      const cookie = JSON.parse(Cookies.get("userData"));
      // console.log(cookie);
      try {
        const res = await auth_login(cookie);
        if (res?.status === 200) {
          dispatch(authLogin(res.data));
          notify(res.data.message);
          navigate("/splash-screen");
        } else {
          notify(res.data.message, "error");
        }
      } catch (error) {
        notify(error.data?.error?.message, "error");
        // console.log(error);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    cookieVerification();
  }, []);

  // useEffect(() => {
  //   // Initialize MSAL instance
  //   const initializeMSAL = async () => {
  //     try {
  //       await msalInstance.handleRedirectPromise();
  //     } catch (error) {
  //       console.error("MSAL initialization failed:", error);
  //     }
  //   };

  //   initializeMSAL();
  // }, []);

  // const handleMSLogin = async () => {
  //   try {
  //     // Ensure MSAL is initialized before calling loginPopup
  //     await msalInstance.handleRedirectPromise();
  //     const loginRequest = {
  //       scopes: ["user.read"], // Add additional scopes as needed
  //     };
  //     await msalInstance.loginRedirect(loginRequest);
  //   } catch (error) {
  //     console.error("Login failed:", error);
  //   }
  // };

  const onLoginStart = useCallback(() => {
    alert("login start");
    console.log(provider?.access_token);
    console.log(profile);

    const accessToken = provider?.access_token;

    fetch("https://www.googleapis.com/oauth2/v1/userinfo", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const emailSchema = Yup.object().shape({
    email_address: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      ),

    password: Yup.string()
      .required("Password is required")
      // .matches(/[A-Z]/, "Password must contain atleast one uppercase")
      // .matches(/[a-z]/, "Password must contain atleast one lowercase")
      // .matches(/[@#&]/, "Password must contain special character @,#,&")
      .min(8, "password must be at least 8 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(emailSchema) });

  const handleClickOpen = (status) => {
    setStatus(status);
    setOpenDialogue(true);
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };

  const onSubmit = async (value) => {
      setIsLoading(true);
      try {
        const res = await auth_login(value);
        if (res.status === 200 && res.data.group[0]?.title === "Agent") {
          if (rememberMe) {
            const stringValue = JSON.stringify(value); // Convert object to string
            Cookies.set("userData", stringValue, { expires: 7 }); // Store the string
          }

          dispatch(fetchAuthLogin(res.data));
          dispatch(authLogin(res.data));
          notify(res.data.message);
          setIsLoading(false);
          navigate("/splash-screen");
        } else {
          setIsLoading(false);
          notify("Invalid Credintails", "error");
        }
        // dispatch(AuthLogin(value));
        // const { status, data } = await login(value);
        // setMessage(data.message);
        // setAlertType("error");
        // setOpen(true);

        // if (status === forgotPasswordCodes.STATUS_SUCCESS) {
        //   setAlertType("success");
        //   setStatus(forgotPasswordCodes.STATUS_SUCCESS);

        // if (isAuthenticate) {
        //   navigate("/splash-screen");
        // } else {
        //   notify("Invalid Credential", "error");
        //   navigate("/login");
        // }
        // } else if (status === 401) {
        //   // console.log("wrong pass");
        //   setStatus(status);
        //   setOpen(true);
        // }
      } catch (error) {
        setIsLoading(false);
        notify(error.data?.error?.message, "error");
        // console.log(error);
      }
      reset();
    
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state to show/hide password
  };

  // const onSuccess = (response) => {
  //   console.log('Login successful:', response);
  //   // Send the access token to your backend for further processing
  //   fetch('/api/auth/google', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ accessToken: response.access_token }),
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     // Handle successful login on the frontend (e.g., redirect to home)
  //   })
  //   .catch((error) => {
  //     console.error('Error sending access token to backend:', error);
  //   });
  // };

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log(tokenResponse),
  });

  const onFail = (error) => {
    console.error("Login failed:", error);
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      // Fetch user data using the access token
      fetch("https://www.googleapis.com/auth/userinfo.profile", {
        headers: {
          Authorization: `Bearer ${tokenResponse.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((userData) => console.log("User Data:", userData))
        .catch((error) => console.error("Error fetching user data:", error));
    },
  });

  let logScreen;

  if (forgotPassword === true && authorized === false) {
    logScreen = (
      <EmailField
        handleClickOpen={handleClickOpen}
        setForgotPassword={setForgotPassword}
        setAuthorized={setAuthorized}
        setForgotPasswordEmailId={setForgotPasswordEmailId}
      />
    );
  } else if (forgotPassword === false && authorized === false) {
    logScreen = isEmailEntered ? (
      <PasswordField
        setOpen={setOpen}
        setStatus={setStatus}
        setMessage={setMessage}
        email={email}
        setAlertType={setAlertType}
      />
    ) : (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column  justify-content-start h-100 "
      >
        <div>
          <label
            htmlFor="exampleInputEmail1"
            className="form-label text-primary"
          >
            Email address
          </label>
          <input
            type="text"
            className="form-control p-4 ps-3 border-primary"
            id="exampleInputEmail1"
            placeholder="Email address"
            aria-describedby="emailHelp"
            // value={email}
            // onChange={(e) => setEmail(e.target.value)}
            {...register("email_address")}
          />

          <div
            htmlFor="exampleInputEmail1"
            className="form-label text-primary form-text text-danger"
          >
            {errors.email_address?.message}
          </div>
        </div>
        <div>
          <label htmlFor="createPassword1" className="form-label text-primary">
            Enter password
          </label>

          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control p-4 ps-3 border-primary "
              id="createPassword1"
              placeholder="Enter password"
              maxLength={20} // Set the maximum length to 20 characters
              {...register("password")}
            />
            <button
              className="btn btn-outline-primary "
              type="button"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <VisibilityOffIcon className="cad-text-large" />
              ) : (
                <RemoveRedEyeIcon className="cad-text-large" />
              )}
            </button>
          </div>
          <label
            htmlFor="exampleInputPassword1"
            className="form-label text-primary form-text text-danger"
          >
            {errors.password?.message}
          </label>
        </div>
        <div className=" d-flex justify-content-between">
          <span className="form-check d-flex justify-content-start gap-3">
            <input
              className="form-check-input w-auto pointer border-primary"
              type="checkbox"
              value={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              id="flexCheckDefault"
            />
            <label>keep me logged in</label>
          </span>

          <span className="text-center ">
            <span
              onClick={() => setForgotPassword(true)}
              className="cad-text-medium float-end text-primary text-decoration-none pointer"
            >
              Forgot password?
            </span>
          </span>
        </div>
        <div className="col-8 d-flex flex-column gap-2 align-self-center mt-2">
          <Button
            type="submit"
            className="btn mt-2 pt-1 fs-5 cad-fw-700 pb-1 button-contained"
          >
            {" "}
            {isLoading ? (
              <CircularProgress className="text-white" />
            ) : (
              <span>Log In</span>
            )}
          </Button>
        </div>
        <hr className="mt-4 mb-4"></hr>

        {/* <button className="btn-primary p-3"  onClick={() => login()} title="Login with google"/> */}

        {/* <GoogleLogin
        clientId={process.env.REACT_APP_GG_APP_ID || ""} // Replace with your actual client ID
        buttonText="Login with Google"
        onSuccess={onSuccess}
        onFailure={onFail}
        cookiePolicy="single_host_origin" // Optional cookie policy
      /> */}

        {/* <GoogleLogin
            onSuccess={(credentialResponse) => {
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />  */}

        {/* <LoginSocialGoogle
          isOnlyGetToken
          client_id={process.env.REACT_APP_GG_APP_ID || ""}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <LoadingButton
            type="button"
            className=" mt-2 mb-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd"
          >
            <img className="me-2" width={28} src={google} alt="" />
            <span>log In With Google</span>
          </LoadingButton>
        </LoginSocialGoogle> */}

        <div
          style={{
            position: "relative",
            height: 46,
          }}
          className="w-100 d-flex mb-2"
        >
          <div
            style={{
              opacity: 0,
              position: "absolute",
              top: 12,
              zIndex: 999,
            }}
            className="w-100 d-flex  justify-content-center"
          >
            <GoogleLogin
              clientId={process.env.REACT_APP_GG_APP_ID}
              buttonText="Login with Google"
              onSuccess={onSuccess}
              onError={onError}
              width={440}
              GsiButtonConfiguration={{
                shape: "pill",
                type: "icon",
              }}
            />
          </div>

          <LoadingButton
            style={{
              opacity: 1,
              position: "absolute",
              top: 0,
            }}
            className=" mt-2 mb-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd rounded-1 "
          >
            <img className="me-2" width={24} src={google} alt="" />
            <span>Log In With Google</span>
          </LoadingButton>
        </div>

        {/* <LoadingButton
          type="button"
          className=" mt-2 mb-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd"
          onClick={() => loginGoogle()}
        >
          <img className="me-2" width={28} src={google} alt="" />
          <span>Log In With Google</span>
        </LoadingButton> */}

        <LoadingButton
          type="button"
          onClick={handleMSLogin}
          className=" mt-2 mb-2 pt-2 fs-6 cad-fw-500 pb-2 button-outlinedd"
        >
          <img className="me-2" width={28} src={windows} alt="" />
          <span>Log In With Microsoft</span>
        </LoadingButton>
      </form>
    );
  }

  return (
    <div
      style={{
        background:
          "linear-gradient(90deg, hsla(203, 69%, 84%, 0.5) 0%, hsla(18, 76%, 85%, 0.5) 100%)",
      }}
      className="login position-relative container-fluid overflow-hidden"
    >

      <div className="container  d-flex align-items-center w-100  h-100">
        <div className="d-flex w-100 justify-content-center align-items-center  h-75 p-0">
          <div className="col-left pe-3 pag- d-flex flex-column  col-7 h-50 d-flex align-items-start justify-content-center">
            <h1 style={{ fontSize: "50px" }} className="cad-fw-800">
              Cloudify<span className="cad-fw-500 text-primary">Docs</span>
            </h1>
            <p style={{ color: "#1c1e21" }} className=" fs-4 cad-fw-600">
              Empowering Visions, Engineering Solutions: Your Cloudify Docs
              Partner.
            </p>
            {forgotPassword === true && authorized === false ? (
              ""
            ) : (
              <p
                style={{ color: "#1c1e21" }}
                className="cad-text-large cad-fw-600"
              >
                Don’t Have An Account?{" "}
                <Link className="text-decoration-underline" to="/registration">
                  Create An Account
                </Link>
              </p>
            )}
          </div>

          <div className=" col-right col-5 h-auto  p-3">{logScreen}</div>
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={openDialogue}
        onClose={handleDialogueClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          className="text-center cad-text-large cad-fw-500 
          text-primary"
          id="alert-dialog-slide-description"
        >
          Kindly review your email as we've dispatched a reset link.
        </DialogTitle>
        <DialogActions className="d-flex justify-content-center">
          <div className="mb-3">
            <Button
              className="button-contained cad-text-small"
              autoFocus
              onClick={handleDialogueClose}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
