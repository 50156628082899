import React, { useEffect, useRef, useState } from 'react'
import MainModal from '../../Components/MainModal'
import { useSelector } from 'react-redux';
import FormPrevField from './FormPrevField';
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Alert, AlertTitle, Button } from '@mui/material';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Box } from '@mui/system';
import DocumentBoxComponents from '../../Components/Document/DocumentBoxComponents';

const FillFormModal = ({ open, setOpen, id, toast, setOperationId }) => {
  const pdfRef = useRef();
  const { taskById } = useSelector((state) => state?.tasks);
  const [formData, setFormData] = useState([]);
  const [formDataCopy, setFormDataCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (taskById?.FormHistoryCopy && taskById.FormHistoryCopy.length > 0) {
      const parsedFormData = taskById.FormHistoryCopy[0]?.formData;
      const formData = typeof parsedFormData === "string" ? JSON.parse(parsedFormData) : parsedFormData
      setFormData(formData);
      setFormDataCopy(Array.isArray(taskById?.FormHistoryCopy) && taskById?.FormHistoryCopy.length && taskById?.FormHistoryCopy.map((item) => item.formCopy))
    }
  }, [taskById]);

  // console.log(formDataCopy);
  const handleClose = () => { setOpen(false) };

  function replaceUnderscoresWithSpaces(str) {
    return str?.replace(/_/g, " ");
  }

  const createGradientCanvas = (width, height) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    // Create gradient
    const gradient = ctx.createLinearGradient(0, 0, width, height);
    gradient.addColorStop(0, "#fff");
    gradient.addColorStop(1, "#fff");

    // Fill with gradient
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);

    return canvas;
  };

  const handlePdfDownload = () => {
    setLoading(true); // Set loading state to true
    const input = pdfRef.current;
    const pdf = new jsPDF("p", "pt", "a4", true); // 'p' for portrait
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Create gradient canvas
    const gradientCanvas = createGradientCanvas(pdfWidth, pdfHeight);
    const gradientData = gradientCanvas.toDataURL("image/png");

    html2canvas(input, { scale: 5 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Define padding
      const padding = 10;


      // Add gradient background
      pdf.addImage(gradientData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Calculate the scaling factor to fit the image in the PDF with padding
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratioX = (pdfWidth - 2 * padding) / imgWidth;
      const ratioY = (pdfHeight - 2 * padding) / imgHeight;
      const ratio = Math.min(ratioX, ratioY);
      const imgX = padding; // Add left padding
      const imgY = padding; // Add top padding

      // Add the content image
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );

      pdf.save("cloudify.pdf");
      setLoading(false); // Set loading state to false after download is complete
    });
  };


  return (
    <MainModal width={"70%"} isForm={true} open={open} handleClose={handleClose} title="Form View">
      <span
        ref={pdfRef}
        className={` bg-white p-2 rounded-2 position-relative  h-100h-auto mb-4`}
      >
        <Button
          size='small'
          variant='text'
          style={{
            top: -48,
            right: 40
          }}
          disabled={!(Array.isArray(formData) && formData.length && formData)}
          onClick={handlePdfDownload}
          className='position-absolute p-2 pt-0 pb-0'>{loading ? "Loading..." : "Pdf download"}</Button>
        {!(Array.isArray(formData) && formData.length && formData) ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Alert severity="warning">
              <AlertTitle sx={{ fontWeight: 700 }}>Incomplete Forms</AlertTitle>
              It appears that the client has not yet completed and submitted the necessary forms. Please follow up with them to ensure that all required information is provided as soon as possible. Thank you!
            </Alert>
          </Box>
        ) : (
          <div className="d-flex flex-wrap w-100 h-100 justify-content-start gap-3 m-0">
            {Array.isArray(formData) && formData.length && formData?.map((field, index) => {
              switch (field.type) {
                case "header":
                  return (
                    <div
                      style={{
                        borderBottom: `2px solid #bbb`,
                        borderRadius: 4,
                        // borderBottom: `1px solid #000000`,
                      }}
                      className={`${field.alignment} position-relative  p-4 pb-2   w-100 d-flex justify-content-center flex-column`}
                    >
                      <h3 className={`${field.className} cad-fw-700 `}>
                        {field.label}
                      </h3>
                      <p style={{ color: "#808080" }} className={field.subTitleClassName}>
                        {field.subTitle}
                      </p>
                    </div>
                  );

                // ====[ width 50% ]===
                case "text":
                case "number":
                case "date":
                case "email":
                case "select-one":
                case "checkbox":
                case "radio":
                case undefined:
                  // case "dropdown":
                  return field.section === "details" ? (
                    <></>
                  ) : (
                    <FormPrevField
                      type={field.type}
                      name={field.name}
                      value={field.value}
                      formData={formData}
                    />
                  );

                // ====[ width 100% ]===
                case "textarea":
                  return (
                    <FormPrevField
                      width={"100%"}
                      name={field.name}
                      value={field.value}
                      formData={formData}
                    />
                  );

                case "paragraph":
                  return (
                    <div className="col-12">
                      <div
                        className={`${field.alignment} position-relative p-2 gap-2 d-flex flex-column`}
                      >
                        <p className={`${field.className}  cad-fw-500`}>
                          {field.label}
                        </p>
                      </div>
                    </div>
                  );

                case "hr":
                  return (
                    <div className="position-relative w-100 p-2 gap-2 d-flex flex-column">
                      <span
                        className="mt-2 mb-2 w-100"
                        style={{
                          borderTop: `0.5px solid #000`,
                        }}
                      />
                    </div>
                  );

                case "footer":
                  return (
                    <div className="w-100 mt-3">
                      <div
                        style={{
                          borderTop: `1px solid ${(Array.isArray(formData) && formData.length && formData[0]?.color) || '#000000'}`
                        }}
                        className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0 w-100 d-flex justify-content-between `}
                      >
                        <span className={`${field.className} cad-fw-700`}>
                          {field.label}
                        </span>
                        <span className={field.subTitleClassName}>
                          {field.subTitle}
                        </span>
                      </div>
                    </div>
                  );

                // =====[ above is copmleted ]=====

                case "details-heading":
                  return (
                    <div className="position-relative p-2 gap-2 d-flex flex-column">
                      <label className="cad-text-large cad-fw-700">
                        {field.label}
                      </label>
                      <label className="cad-text-medium cad-fw-500">
                        {field.subTitle}
                      </label>
                    </div>
                  );

                case "details":
                  return (
                    <div className=" w-100 p-2 gap-2 d-flex flex-column">
                      <div className="d-flex  flex-wrap w-100">
                        <label className="col-12 mb-1 mt-2 cad-fw-600">
                          {field.detailsTitle}
                        </label>



                        {Array.isArray(field?.inputsArray) && field?.inputsArray?.map((input, index) => (
                          <div
                            key={index}
                            className="col-3 p-1  flex-column d-flex justify-content-start mb-2"
                          >
                            <input
                              type="text"
                              style={{
                                borderColor: formData[0]?.color,
                                border: `1px dotted #000000`,
                              }}
                              className="form-control cad-text-small m-1 rounded-0 "
                              placeholder={field?.value}
                            // value={td[index]}
                            // onChange={(e) => handleInputChange(e, index)}
                            />
                            <span className="cad-text-small ps-1">
                              {input?.fieldInput}
                            </span>
                          </div>
                        ))}




                        {/* <div className="col-4 p-2 ps-0 flex-column d-flex justify-content-start mb-2">
                            <span
                              style={{
                                border: `1px dotted #000000`,
                              }}
                              className="cad-text-medium w-100 cad-fw-500 p-2"
                            >
                              {replaceUnderscoresWithSpaces(field?.name)}
                            </span>
                            <span className="cad-text-small ps-1">
                              {field?.fieldOne}
                            </span>
                          </div> */}


                      </div>
                    </div>
                  );

                case "submit":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      className="w-100"
                    >
                      {replaceUnderscoresWithSpaces(field.name)}
                    </div>
                  );

                case "file":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      className="w-100"
                    >
                      {replaceUnderscoresWithSpaces(field.name)}
                    </div>
                  );

                case "table":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      className="w-100"
                    >
                      {replaceUnderscoresWithSpaces(field.name)}
                    </div>
                  );

                case "signeture":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      className="w-100"
                    >
                      {replaceUnderscoresWithSpaces(field.name)}
                    </div>
                  );

                default:
                  return null;
              }
            })}
            {/* <FormDataTable formData={formData} /> */}
             {/* <DocumentBoxComponents data={formDataCopy} /> */}
          </div>
        )}
      </span>

    </MainModal>
  )
}

export default FillFormModal