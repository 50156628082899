import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  FormControlLabel,
  Checkbox,
  Paper,
  Avatar,
  Button,
  Modal,
  CircularProgress,
} from "@mui/material";
import CountryCode from "../../../Utils/COUNTRY_CODE.json";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { createUser, resource } from "../../../service/user.service";
import { userReporting } from "../../../service/user.service";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { getAllUsers } from "../../../Redux/Slice/getAllUsers";
import { fetchGlobalUser } from "../../../Redux/Slice/globalUserSlice";
import { useDispatch } from "react-redux";
import Selector from "../../../Components/Select";

import { addClient } from "../../../service/client.service";
import { useSelector } from "react-redux";
import { getCompanyData } from "../../../Redux/Slice/company.service.slice";
import makeAnimated from "react-select/animated";
import { getTags } from "../../../service/tags.service";
import Select from "../../../Components/Select";
import { toast, ToastContainer } from "react-toastify";
import { fetchClient } from "../../../Redux/Slice/contactClientSlice";
import { fetchGlobalClient } from "../../../Redux/Slice/globalClientSlice";
import MuiSelector from "../../../Components/MuiSelector";
import MainModal from "../../../Components/MainModal";
import darkPro from "../../../Images/darkPro.jpg";
import dummyLogo from "../../../Images/dummyLogo.png";
import { notify } from "../../../App";
import { fetchGlobalTags } from "../../../Redux/Slice/globlalTags";
import { createCompany } from "../../../service/company.service";
import { fetchGlobalCompany } from "../../../Redux/Slice/globalCompanySlice";

const AddCompanyModal = ({
  openAddNewCompanyModal,
  setOpenAddNewCompanyModal,
}) => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGlobalClient());
    dispatch(fetchGlobalTags());
  }, []);

  const clientData = useSelector((state) => state?.globalClient?.clients);
  const tags = useSelector((state) => state?.globalTags?.rows);

  const [selectClient, setSelectClient] = useState([]);
  const [selectTags, setSelectTags] = useState([]);
  const [addLoading, setAddLoading] = useState(false);

  const countryCodeOptions = CountryCode?.map((item) => {
    return {
      id: item.code,
      value: item.name,
      label: item.name,
      color: "#0d6efd",
    };
  });

  const selectOption = (selectedOptions) => {
    // // console.log("working");
    // const selectedValues = selectedOptions
    //   ? selectedOptions.map((option) => option.value)
    //   : [];
    // setSelectClient(selectedValues);
  };

  const selectTagsOption = (selectedOptions) => {
    // const selectedValues = selectedOptions
    //   ? selectedOptions.map((option) => option.value)
    //   : [];
    // setSelectTags(selectedValues);
  };

  const contentData = [
    {
      title: "Company Information",
      fields: [
        {
          label: "Registered name",
          type: "text",
          required: true,
          name: "company_name",
          error_msg: true,
        },
        {
          label: "Email Address",
          type: "email",
          required: true,
          name: "email_address",
          error_msg: true,
        },
        {
          label: "Web Address",
          type: "text",
          // required: true,
          name: "web_address",
          error_msg: true,
        },
        {
          label: "Industry",
          type: "text",
          name: "industry",
          error_msg: true,
        },
        {
          label: "Phone Number",
          type: "text",
          name: "phone_number",
          error_msg: true,
        },
      ],
    },
    {
      title: "Address Details",
      fields: [
        // {
        //   label: "Country",
        //   type: "text",
        //   name: "country",
        //   error_msg: true,
        // },
        {
          label: "Country",
          type: "select",
          options: countryCodeOptions,
          name: "country",
          // required: true,
          error_msg: true,
        },
        {
          label: "City",
          type: "text",
          name: "city",
          error_msg: true,
        },
        {
          label: "Postal code",
          type: "text",
          name: "postal_code",
          error_msg: true,
        },
      ],
    },
    {
      title: "Other Information",
      fields: [
        {
          label: "Add Client",
          type: "multiple-select",
          funtionToUse: setSelectClient,
          options: Array.isArray(clientData)
            ? clientData // Filter out elements with details.length <= 0
                .map((ele) => ({
                  label: ele.last_name
                    ? ele.first_name + " " + ele.last_name
                    : ele.first_name,
                  value: ele.id,
                  color: "blue",
                }))
            : [],
        },
        {
          label: "Tags",
          type: "multiple-select",
          funtionToUse: setSelectTags,
          options:
            tags?.length > 0
              ? tags?.map((ele) => ({
                  label: ele.title,
                  value: ele.id,
                  color: "blue",
                }))
              : [],
        },
      ],
    },
  ];

  // console.log(tags);

  const [previewImage, setPreviewImage] = useState(
    theme === "dark-black" ? darkPro : ""
  );

  const userSchema = Yup.object().shape({
    company_name: Yup.string().required("Company Name is required"),
    // .matches(/^\S*$/, "Company Name cannot contain spaces"),

    email_address: Yup.string()
      .email("Email address must be a valid email")
      // .required("Email is required")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email address is required"
      ),
    web_address: Yup.string(),

    phone_number: Yup.string()
      .notRequired() // Mark the field as optional
      .matches(/^[0-9]*$/, "Phone Number must contain only numbers")
      .test(
        "is-min-10-characters",
        "Phone Number must be at least 10 numbers",
        (value) => {
          if (!value) {
            return true; // Pass the validation if the field is empty
          }
          return value.length >= 10;
        }
      ),

    pincode: Yup.string(),

    profile_photo: Yup.mixed()
      .test("fileType", "Invalid file type", (value) => {
        if (!value || value.length === 0) {
          setPreviewImage(""); // Clear the preview when no file is selected
          return true; // Allow empty value (no file selected)
        }

        if (value[0].type.startsWith("image/")) {
          // Set the previewImage state with the selected image
          setPreviewImage(URL.createObjectURL(value[0]));
          return true; // Valid image file
        } else {
          setPreviewImage(""); // Clear the preview when an invalid file is selected
          return false; // Invalid file type
        }
      })
      .test("required", "You need to provide a file", (files) => {
        // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
        if (files) return true;
        return false;
      })
      .test(
        "fileSize",
        " The maximum size of profile picture is 2MB.",
        (files) => {
          //if u want to allow only certain file sizes
          try {
            if (files.length !== 0) {
              return files[0].size <= 2000000;
            }
            return true;
          } catch (error) {
            return false;
          }
        }
      )
      .test("file_formate", "Image file has unsupported format.", (files) => {
        // // console.log(files[0].type)

        const SUPPORTED_FORMATS = [
          "image/jpeg",
          "image/png",
          // "image/gif",
          "image/tiff",
          "image/svg+xml",
        ];
        try {
          if (files.length !== 0) {
            setPreviewImage(URL.createObjectURL(files[0]));
            return files && SUPPORTED_FORMATS.includes(files[0].type);
          }
          return true;
        } catch (error) {
          return false;
        }
      })
      .optional(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(userSchema) });

  // console.log(errors);

  const onSubmit = async (values) => {
    // reset()
    // return // console.log(formData);
    // console.log("Form submit event triggered");
    // console.log(values);

    // Create a FormData object
    setAddLoading(true);
    const formDataToSend = new FormData();

    // Append the data from each field
    values.profile_photo[0] &&
      formDataToSend.append("profile_photo", values.profile_photo[0]);
    values.company_name &&
      formDataToSend.append("company_name", values.company_name);
    values.web_address &&
      formDataToSend.append("web_address", values.web_address);
    values.email_address &&
      formDataToSend.append("email_address", values.email_address);
    values.phone_number &&
      formDataToSend.append("phone_number", values.phone_number);
    values.country && formDataToSend.append("country", values.country);
    values.city && formDataToSend.append("city", values.city);
    values.pincode && formDataToSend.append("postal_code", values.pincode);
    values.industry && formDataToSend.append("industry", values.industry);

    if (selectClient.length > 0) {
      selectClient.forEach((contacts, index) => {
        formDataToSend.append(`contacts[${index}]`, contacts?.value);
      });
    }

    if (selectTags.length > 0) {
      selectTags.forEach((tags, index) => {
        formDataToSend.append(`tags[${index}]`, tags?.value);
      });
    }

    try {
      const response = await createCompany(formDataToSend);
      // console.log(response);

      if (response?.status === 404) {
        // console.log(response?.status);
        setAddLoading(false);
        notify(response?.data?.message, "error");
      } else if (response.status === 201) {
        setAddLoading(false);
        dispatch(fetchGlobalCompany());
        dispatch(getCompanyData());
        reset();
        handleClose();
        notify(response?.data?.message);
      }
    } catch (error) {
      setAddLoading(false);
      notify("Email id must be unique", "error");
    }
  };

  const handleClose = () => {
    setOpenAddNewCompanyModal(false);
    setPreviewImage("");
    reset();
  };

  console.log(errors);

  return (
    <MainModal
      open={openAddNewCompanyModal}
      handleClose={handleClose}
      title="Add Company"
      width={"60%"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-3">
          <div className="d-flex justify-content-start align-items-center flex-column gap-2">
            <div
              style={{
                position: "relative",
              }}
            >
              <Avatar
                className="m-1"
                style={{
                  width: "100px",
                  height: "100px",
                  border: `4px solid ${themeColorsObject.backgroundColor}`,
                }}
                alt="Remy Sharp"
                src={previewImage}
              />

              <input
                type="file"
                id="profile-image-input"
                accept="image/*"
                style={{
                  display: "none",
                }}
                {...register("profile_photo")}
              />

              <label
                style={{
                  position: "absolute",
                  bottom: "0.9rem",
                  left: "4.9rem",
                  height: "30px",
                  width: "30px",
                  backgroundColor: themeColorsObject.backgroundColor,
                  padding: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                htmlFor="profile-image-input"
              >
                <CameraAltTwoToneIcon
                  sx={{
                    position: "absolute",
                    bottom: "0rem",
                    right: "0rem",
                    height: "30px",
                    width: "30px",
                    backgroundColor: themeColorsObject.backgroundColor,
                    color: themeColorsObject.textColor,
                    padding: "4px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </label>
            </div>
            {errors?.profile_photo?.message && (
              <span className="cad-text-small mb-1  text-danger cad-fw-600">
                {errors?.profile_photo?.message}
              </span>
            )}
            <label className="cad-text-medium mb-1 cad-text-light-grey cad-fw-500">
              Upload Profile Picture
            </label>

            <span className=" cad-text-small text-secondary">
              Please image less than 2 MB
            </span>
          </div>
        </div>
        {contentData.map((section, index) => (
          <div key={index}>
            <hr className="m-0 hr-border" />
            <div className="p-3">
              <h5 className="cad-text-grey mb-3 cad-text-large">
                {section.title}
              </h5>
              <div className="row">
                {section.fields.map((field, fieldIndex) => (
                  <div
                    className={
                      field.type === "multiple-select"
                        ? "col-12 mb-3"
                        : "col-4 mb-2"
                    }
                    key={fieldIndex}
                  >
                    <label
                      className={`cad-text-medium mb-1 cad-text-light-grey cad-fw-500 ${
                        field.required ? "text-danger" : ""
                      }`}
                    >
                      {field.label}
                      {field.required && (
                        <span className="text-danger"> *</span>
                      )}
                    </label>
                    {field.type === "select" ? (
                      <select
                        defaultValue={"none"}
                        className={`form-control w-100  ${
                          field.required ? "required" : ""
                        }`}
                        {...register(field.name)}
                      >
                        <option value="">none</option>
                        {field.options?.map((option, optionIndex) => (
                          <option key={optionIndex} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : field.type === "multiple-select" ? (
                      <Select
                        isMulti={true}
                        options={field.options}
                        setSelected={field.funtionToUse}
                      />
                    ) : (
                      // <MuiSelector
                      //   defaultValue={[]}
                      //   isMulti={true}
                      //   options={field?.options}
                      //   setSelected={field.funtionToUse}
                      // />

                      <input
                        className={`form-control w-100 ${
                          field.required ? "required" : ""
                        }`}
                        type={field.type}
                        {...register(field.name)}
                      />
                    )}
                    {field.error_msg && (
                      <div
                        htmlFor={field.name}
                        className="form-label text-primary form-text text-danger cad-text-small cad-fw-500"
                      >
                        {errors[field.name]?.message}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        <div className="d-flex justify-content-center py-3 m-auto w-25">
          <Button className="button-contained" type="submit">
            {addLoading ? "Loading..." : "Add"}
          </Button>
        </div>
      </form>
    </MainModal>
  );
};

export default AddCompanyModal;
