import React from "react";
import "./Doughnuts.css";
import { Doughnut } from "react-chartjs-2";
import { GoDotFill } from "react-icons/go";
import { Chart as ChartJs, ArcElement, Tooltip } from "chart.js";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";

ChartJs.register(
  ArcElement,
  // Legend,
  Tooltip
);

const DoughnutChart = ({ themeColorsObject }) => {
  const { kanbanData, isLoading } = useSelector((state) => state?.kanban);
  const totalTasks = kanbanData?.reduce(
    (total, category) => total + category.tasks.length,
    0
  );

  console.log(kanbanData);

  const data = {
    labels: [
      kanbanData[0]?.title,
      kanbanData[1]?.title,
      kanbanData[2]?.title,
      kanbanData[3]?.title,
    ],
    datasets: [
      {
        label: `Tasks out of ${totalTasks} `,
        data: [
          kanbanData[0]?.tasks?.length,
          kanbanData[1]?.tasks?.length,
          kanbanData[2]?.tasks?.length,
          kanbanData[3]?.tasks?.length,
        ],
        backgroundColor: ["#8AACF3", "#FD394A", "#76BD89", "#E2B656"],
        hoverOffset: 4,
        borderWidth: 5, // Remove the border
        borderColor: themeColorsObject.megaCardBackgroundColor, // Set the border color here
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true, // Hide tooltips as well (optional)
      },
      legend: {
        display: false, // You can choose to hide or show the legend here
        labels: {
          font: {
            size: 12, // Decrease the font size as needed
          },
        },
      },
      datalabels: {
        display: true, // Hide data labels
      },
    },
  };
  

  return (
    <Paper
      elevation={1}
      style={{
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.titleText,
      }}
      className="doughnuts w-100 d-flex justify-content-evenly align-items-center p-2 pe-5  pe-md-0 ps-5 ps-md-0 gap-4"
    >
      <div className="doughnut-chart-cnt  d-flex justify-content-center  align-items- flex-column">
        <h6 style={{ color: themeColorsObject.titleText }} className="">
          Task divided by their status
        </h6>
        <div className="h-75 w-auto d-flex justify-content-center">
          <Doughnut style={{ width: "400px" }} data={data} options={options} />
        </div>
      </div>

      <div
        style={{ color: themeColorsObject.titleText }}
        className="col-4 d-flex flex-column justify-content-center align-items-center"
      >
        <div
          style={{ color: themeColorsObject.titleText }}
          className="d-flex w-100 justify-content-between align-items-center"
        >
          <div className="align-items-center d-flex">
            <GoDotFill color="#8AACF3" size={28} />
            <span className="cad-text-medium cad-fw-500">
              {kanbanData[0]?.title}
            </span>
          </div>
          <span className="ms-3 cad-text-medium cad-fw-500">
            
          {kanbanData[0]?.tasks?.length === 0 ? "0 %" :`${Math.round(
              (kanbanData[0]?.tasks?.length / totalTasks) * 100
            )}%`}
          </span>
        </div>

        <div
          style={{ color: themeColorsObject.titleText }}
          className="d-flex w-100 justify-content-between align-items-center"
        >
          <div className="align-items-center d-flex">
            <GoDotFill color="#FD394A" size={28} />
            <span className="cad-text-medium  cad-fw-500">
              {kanbanData[1]?.title}
            </span>
          </div>
          <span className="cad-text-medium cad-fw-500">
            
          {kanbanData[1]?.tasks?.length === 0 ? "0 %" :`${Math.round(
              (kanbanData[1]?.tasks?.length / totalTasks) * 100
            )}%`}
          </span>
        </div>

        <div
          style={{ color: themeColorsObject.titleText }}
          className="d-flex w-100 justify-content-between align-items-center"
        >
          <div className="align-items-center d-flex">
            <GoDotFill color="#76BD89" size={28} />
            <span className="cad-text-medium  cad-fw-500">
              {kanbanData[2]?.title}
            </span>
          </div>
          <span className="cad-text-medium cad-fw-500">
            
          {kanbanData[2]?.tasks?.length === 0 ? "0 %" :`${Math.round(
              (kanbanData[2]?.tasks?.length / totalTasks) * 100
            )}%`}
          </span>
        </div>

        <div
          style={{ color: themeColorsObject.titleText }}
          className="d-flex w-100 justify-content-between align-items-center"
        >
          <div className="align-items-center d-flex ">
            <GoDotFill color="#E2B656" size={28} />
            <span className="cad-text-medium cad-fw-500">
              {kanbanData[3]?.title}
            </span>
          </div>
          <span className="cad-text-medium  cad-fw-500">
            
          {kanbanData[3]?.tasks?.length === 0 ? "0 %" :`${Math.round(
              (kanbanData[3]?.tasks?.length / totalTasks) * 100
            )}%`}
          </span>
        </div>
      </div>
    </Paper>
  );
};

export default DoughnutChart;
