import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getClient } from "../../service/global.service";
import { getCompanyDetails } from "../../service/company.service";

export const fetchGlobalClient = createAsyncThunk(
  "fetchClient/fetchGlobalClient",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getClient();
      if (res.status === 200) {
        return res.data;
      }
      return rejectWithValue(res.data);
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  }
);

export const fetchGlobalClientById = createAsyncThunk(
  "fetchClient/fetchGlobalClientById",
  async ({ id }, { rejectWithValue }) => {
    try {
      // // console.log("slice id", id);
      const res = await getCompanyDetails(id);
      if (res.status === 200) {
        // dispatch(addCompanyClients(res?.data?.data.contacts?.map(contact => contact.id)))
        return res.data;
      }
      return rejectWithValue(res.data);
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  }
);

const sortData = (data) => {
  // Extracting the id from the first element of details
  return data.map((item) => {
    const details_xid = item?.details?.id;
    return {
      ...item,
      details_xid,
    };
  });
};

const globalClientSlice = createSlice({
  name: "fetchClient",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    clients: [], // Add a faq property to store the fetched data
    clientsMappingData:[],
    companyClients: [],
    companyClientTags: [],
  },
  reducers: {
    updateClients: (state, action) => {
      // Action payload should contain the new clients data

      // // console.log(action.payload);
      state.clients = action.payload;
    },

    addCompanyClients: (state, action) => {
      // // console.log(action.payload);
      state.companyClients = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchGlobalClient
      .addCase(fetchGlobalClient.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clients = sortData(action.payload?.data) || [];
      })
      .addCase(fetchGlobalClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error?.message || action.data?.error;
      })
      // fetchGlobalClientById
      .addCase(fetchGlobalClientById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalClientById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyClients =
          action.payload?.data.contacts?.map((contact) => contact.id) || [];
        state.companyClientTags =
          action.payload?.data?.tags?.map((tag) => tag) || [];
      })
      .addCase(fetchGlobalClientById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error?.message || action.data?.error;
      });
  },

  // extraReducers: {
  //   [fetchGlobalClient.pending]: (state, action) => {
  //     state.isLoading = true;
  //     state.isError = false;
  //     state.error = null;
  //   },
  //   [fetchGlobalClient.fulfilled]: (state, action) => {
  //     state.isLoading = false;
  //     state.clients = sortData(action.payload.data);
  //   },
  //   [fetchGlobalClient.rejected]: (state, action) => {
  //     state.isLoading = false;
  //     state.isError = true;
  //     state.error = action.error?.message || action.data?.error; // Store the error message
  //   },
  //   // getById
  //   [fetchGlobalClientById.pending]: (state, action) => {
  //     state.isLoading = true;
  //     state.isError = false;
  //     state.error = null;
  //   },
  //   [fetchGlobalClientById.fulfilled]: (state, action) => {
  //     state.isLoading = false;
  //     state.companyClients = action.payload?.data.contacts?.map(
  //       (contact) => contact.id
  //     );
  //     state.companyClientTags= action.payload?.data?.tags?.map(tag => tag)
  //   },
  //   [fetchGlobalClientById.rejected]: (state, action) => {
  //     state.isLoading = false;
  //     state.isError = true;
  //     state.error = action.error?.message || action.data?.error; // Store the error message
  //   },
  // },
});

export default globalClientSlice.reducer;
export const { updateClients, addCompanyClients } = globalClientSlice.actions;
