import api from "./api.service";
let formDataConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const getSupportChat = async (id) => {
  try {
    return await api.get(`/supportTicket/agent/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const getSupportTicket = async () => {
  try {
    return await api.get(`/supportTicket/agent/`);
  } catch (error) {
    return error.response;
  }
};

export const createSupportTicket = async (data) => {
  try {
    const user = await api.post(`/supportTicket/agent/`, data);
    return user;
  } catch (error) {
    return error.response;
  }
};

export const createSupportChat = async (data, id) => {
  try {
    return await api.post(
      `/supportTicket/agent/ticket/message/${id}`,
      data,
      formDataConfig
    );
  } catch (error) {
    return error.response;
  }
};

export const newFeatureRequest = async () => {
  try {
      return await api.get(`/reqFeature/agent`)
     
  } catch (error) {
      return error.response
  }
}


export const createFeatureRequest = async (data) => {
  try {
    return await api.post(`/reqFeature/agent`, data);
  } catch (error) {
    return error.response;
  }
};


export const deleteFeatureRequest = async (id) => {
  // console.log("hitted");
  try {
    return await api.put(`/reqFeature/agent/delete/${id}`);
  } catch (error) {
    return error.response;
  }
};







