import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStatus } from "../../service/global.service";

export const fetchGlobalStatus = createAsyncThunk("fetchGlobalStatus", async () => {
  try {
    const res = await getStatus();
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching status: ${err.message}`);
  }
});

const globalStatusSlice = createSlice({
  name: "fetchStatus",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    statusData: [],
  },
  reducers: {
    // You can add additional reducers specific to status if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalStatus.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchGlobalStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.statusData = action.payload.data;
      })
      .addCase(fetchGlobalStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export const { /* any additional actions */ } = globalStatusSlice.actions;
export default globalStatusSlice?.reducer;
