import React, { useState } from "react";
import "./Support.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Paper } from "@mui/material";
import SupportComponent from "../../Components/SupportComponent/SupportComponent";
import FAQ from "../../Components/FAQ/FAQ";
import NewFeaturesComponent from "../../Components/NewFeaturesComponent/NewFeaturesComponent";
import HelpComponent from "../../Components/HelpComponent/HelpComponent";
import * as themeColors from "../../Utils/Theme/Colors";
import { useSelector } from "react-redux";

const Support = () => {
  const [value, setValue] = useState(0);
  const {theme, themeColorsObject} = useSelector((state) => state.theme);

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div 
    style={{
      minHeight: "87vh",
      backgroundColor: themeColorsObject.megaCardBackgroundColor,
      color: themeColorsObject.textColor,
      // transition:"0.3s"
    }}
    className="w-100 mt-1 cad-shadow rounded-3  mb-4 d-flex flex-column ">
      
        <Tabs value={value} onChange={handleChange} left="true">
          <Tab
            label="Support"
            style={{ width: "8%", textTransform: "none", fontSize: "14px", color: themeColorsObject.titleText, }}
          />
          <Tab
            label="FAQ"
            style={{ width: "8%", textTransform: "none", fontSize: "14px", color: themeColorsObject.titleText, }}
          />
          <Tab
            label="New Features Request"
            style={{ width: "18%", textTransform: "none", fontSize: "14px", color: themeColorsObject.titleText, }}
          />
          <Tab
            label="Help & Guide"
            style={{ width: "12%", textTransform: "none", fontSize: "14px", color: themeColorsObject.titleText, }}
          />
        </Tabs>
      {value === 0 && <SupportComponent themeColorsObject={themeColorsObject} />}
      {/* Render SupportComponent when the first tab is selected */}
      {value === 1 && <FAQ />}
      {/* Render FAQsComponent when the second tab is selected */}
      {value === 2 && <NewFeaturesComponent />}
      {/* Render NewFeaturesComponent when the third tab is selected */}
      {value === 3 && <HelpComponent />}
      {/* Render HelpComponent when the fourth tab is selected */}
    </div>
  );
};

export default Support;
