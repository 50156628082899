import api from './api.service'

export const getClient = async (params) => {
    try {
        return await api.get(`/contact/agent/client/`, {
            params: params,
        })
    } catch (error) {
        return error.response
    }
}


