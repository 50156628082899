import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DateFormatter from "./DateFormatter";
import {
  Avatar,
  Chip,
  CircularProgress,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import Tag from "../Components/Tag/Tag";
import { useDispatch } from "react-redux";
import { fetchSharedDocs } from "../Redux/Slice/getSharedDocs";
import wordLogo from "../Images/wordLogo.png";
import pdfLogo from "../Images/pdfLogo.png";
import gallery from "../Images/gallery.png";
import LastAcces from "./LastAcces";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { fetchRequestDocs } from "../Redux/Slice/requestDocsSlice";
import noData from "../Images/no-data.png"

const style = {
  height: "95%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const   ViewModal = ({ open, handleClose, id }) => {
  const { tasks } = useSelector((state) => state?.tasks);

  const { sharedDocuments, isLoading } = useSelector(
    (state) => state?.getSharedDocs
  );

  const { requestDocuments, isrequestDocumentsLoading } = useSelector(
    (state) => state?.getRequestDocs
  );

  console.log(sharedDocuments);

  const foundObject = tasks?.find((obj) => obj.id === id);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fileURL = (path) => {
    return new URL(path, process.env.REACT_APP_DOWLOAD_URL).toString();
  };

  const handleDownload = async (imageUrl, imgName) => {
    // // console.log(fileURL(imageUrl));
    try {
      const response = await fetch(fileURL(imageUrl));
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob({ type: "image/jpeg" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = imgName; // Desired name for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      <div
        style={style}
        className="d-flex  rounded-3 w-50   bg-white   cad-shadow p-4 pb-0 flex-column gap-2"
      >
        <div className="d-flex align-items-center justify-content-end">
          <span
            style={{
              // top:10,
              right: -24,
              backgroundColor:
                foundObject?.mode?.title === "public" ? "#34A853" : "#FD394A",
              width: "fit-content",
              height: "100%",
              width: "90px",
            }}
            className="text-white rounded-2 ms-5 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
          >
            {foundObject?.mode?.title}
            <LocalOfferOutlinedIcon className="cad-text-large" />
          </span>
        </div>

        <div className="overflow-auto">
          <div className="d-flex  flex-wrap position-relative">
            <div className="col-6 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Task Name
              </div>
              <div className="cad-text-medium cad-fw-600 cad-text-dark">
                {foundObject?.title}
              </div>
            </div>

            <div className="col-6 mb-4 d-flex flex-column gap-2 ">
              <div className="cad-text-medium cad-text-light-grey cad-fw-500 col-3">
                Task type
              </div>
              <div className="cad-text-medium cad-fw-600 cad-text-dark">
                {foundObject?.type?.title}
              </div>
            </div>

            <div className="col-6 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Due Date
              </div>
              <div className="cad-text-medium cad-fw-600 cad-text-dark">
                <DateFormatter inputDate={foundObject?.due_date} />
              </div>
            </div>

            <div className="col-6 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Assigned Date
              </div>
              <div className="cad-text-medium  cad-fw-600 cad-text-dark">
                <DateFormatter inputDate={foundObject?.createdAt} />
              </div>
            </div>

            {/* ===[ Status ]=== */}

            <div className="col-6 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-text-light-grey cad-fw-500 col-4">
                Priority{" "}
              </div>
              <Chip
                style={{ fontWeight: "600", width: "100px" }}
                size="small"
                className={`${
                  foundObject?.priority?.title === "Open"
                    ? "chip-low"
                    : foundObject?.priority?.title === "Low"
                    ? "chip-low"
                    : foundObject?.priority?.title === "High"
                    ? "chip-high"
                    : "chip-med"
                }`}
                label={
                  foundObject?.priority?.title === "Open"
                    ? "Open"
                    : foundObject?.priority?.title === "Low"
                    ? "Low"
                    : foundObject?.priority?.title === "High"
                    ? "High"
                    : "Medium"
                }
              />
            </div>

            <div className="col-6 mb-4 d-flex flex-column gap-2">
              <div className="cad-text-medium cad-text-light-grey cad-fw-500">
                Status
              </div>
              <Chip
                style={{ fontWeight: "600", width: "100px" }}
                size="small"
                className={`${
                  foundObject?.status.title === "Open"
                    ? "chip-high"
                    : foundObject?.status.title === "On Hold"
                    ? "chip-high"
                    : foundObject?.status.title === "In Progress"
                    ? "chip-med"
                    : "chip-low"
                }`}
                label={
                  foundObject?.status.title === "Open"
                    ? "Open"
                    : foundObject?.status.title === "On Hold"
                    ? "On Hold"
                    : foundObject?.status.title === "In Progress"
                    ? "In Progress"
                    : "Completed"
                }
              />
            </div>

            <div className="col-6 mb-4 d-flex flex-column gap-2 ">
              <div className="cad-text-medium cad-text-light-grey cad-fw-600 ">
                Assigned By
              </div>
              {/* {foundObject?.employees.map((el, i) => ( */}
              <div
                // key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Avatar
                  style={{ width: "32px", height: "32px" }}
                  className="p-0 cad-text-small"
                  sizes="small"
                  {...stringAvatar(`${foundObject?.employees[0]?.first_name}`)}
                />
                <div
                  className="cad-text-medium cad-text-dark cad-fw-600"
                  style={{ marginLeft: "6px", marginRight: "12px" }}
                >
                  {foundObject?.employees[0]?.first_name}
                </div>
              </div>
              {/* ))} */}
            </div>

            <div className="col-6 mb-4 d-flex flex-column gap-2 flex-wrap">
              <div className="cad-text-medium cad-fw-500 cad-text-light-grey">
                Tag :
              </div>
              <div className="cad-text-medium d-flex gap-2 cad-text-dark flex-wrap cad-fw-600">
                {foundObject?.tags.map((tag, i) => (
                  <Tag key={i} title={tag?.title} />
                ))}
              </div>
            </div>
          </div>

          <div className="w-100 h-auto">
            <Box
              className=" border-bottom "
              sx={{
                borderBottom: 0,
                padding: 0,
                backgroundColor: "#fff",
                width: "100%",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tags"
                style={{
                  height: "14px",
                }}
              >
                {/* Description Tab */}
                <Tooltip title="Description" placement="top-start">
                  <Tab
                    style={{ textTransform: "none", display: "flex" }}
                    label="Description"
                    {...a11yProps(0)}
                  />
                </Tooltip>
                {/* Grid View Tab */}
                <Tooltip title="Activity" placement="top-start">
                  <Tab
                    style={{ textTransform: "none", display: "flex" }}
                    label="Activity"
                    {...a11yProps(1)}
                  />
                </Tooltip>
                {/* Timeline View Tab */}
                <Tooltip title="Documents" placement="top-start">
                  <Tab
                    style={{ display: "flex", textTransform: "none" }}
                    label="Documents"
                    {...a11yProps(2)}
                  />
                </Tooltip>

                {/* <Button className="btn btn-primary"> Add Tasks</Button> */}
              </Tabs>
            </Box>

            {/* Content for different tabs */}
            {/* List View Content */}
            <CustomTabPanel
              className="w-100 bg-white overflow-auto"
              value={value}
              index={0}
            >
              <p className="cad-text-medium p-3 cad-fw-600">
                {foundObject?.description}{" "}
              </p>
            </CustomTabPanel>
            {/* Grid View Content */}
            <CustomTabPanel
              className="w-100 bg-white overflow-auto"
              value={value}
              index={1}
            >
              <p>Activity</p>
            </CustomTabPanel>
            {/* Timeline View Content */}
            <CustomTabPanel value={value} index={2}>
              {/* Display files here */}

              {foundObject?.type?.title === "Recevied Document" ? (

             



                <div className="w-100 d-flex mt-0 flex-column justify-content-between">

                  {requestDocuments.length > 0 ? 
                  <TableContainer
                    className="h-100"
                    square={false}
                    elevation={1}
                    component={Paper}
                  >
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="cad-fw-600 cad-text-small"
                            style={{ width: 250 }}
                          >
                            Document Name
                          </TableCell>
                          <TableCell
                            className="cad-fw-600 cad-text-small"
                            align="left"
                          >
                            Description
                          </TableCell>
                          <TableCell
                            className="cad-fw-600 cad-text-small"
                            align="center"
                          >
                            Note
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isrequestDocumentsLoading
                          ? // Show skeleton loading for the entire table rows
                            Array.from({ length: 6 }).map((_, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  className="cad-text-small"
                                  align="center"
                                >
                                  <Skeleton variant="text" height={30} />
                                </TableCell>
                                <TableCell
                                  className="cad-text-small"
                                  align="center"
                                >
                                  <Skeleton variant="text" height={30} />
                                </TableCell>
                                <TableCell
                                  className="cad-text-small"
                                  align="center"
                                >
                                  <Skeleton variant="text" height={30} />
                                </TableCell>
                              </TableRow>
                            ))
                          : requestDocuments?.map((eachDocs) => (
                              <TableRow
                                className="pointer"
                                key={eachDocs.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent";
                                  e.currentTarget.style.transition = "0.5s";
                                  e.currentTarget.style.boxShadow =
                                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                                  // setMouseEntered(true);
                                  // setMouseEnteredId(id);
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent"; // Revert to default background color on hover out
                                  e.currentTarget.style.scale = 1;
                                  e.currentTarget.style.transition = "0.5s";
                                  e.currentTarget.style.boxShadow = "none";
                                  // setMouseEntered(false);
                                }}
                              >
                                <TableCell
                                  className="cad-text-small"
                                  component="th"
                                  scope="row"
                                >
                                  {eachDocs.title}
                                </TableCell>
                                <TableCell
                                  className="cad-text-small"
                                  align="left"
                                >
                                  {eachDocs.description}
                                </TableCell>
                                <TableCell
                                  className="cad-text-small"
                                  align="center"
                                >
                                  {eachDocs.note}
                                </TableCell>
                              </TableRow>
                            ))
                            
                            
                            }
                      </TableBody>
                    </Table>
                  </TableContainer> : 
                  <div className=" p-5 d-flex flex-column align-items-center gap-2">
                    <img src={noData} width={40} alt="" />
                    <Link to={`/request-documents/${id}`} className="cad-text-small cad-fw-600 text-primary text-decoration-underline">Please upload requested documents</Link>
                  </div>
                    }
                  
                </div>
              ) : (
                <div className=" w-100 pt-5 pb-5 justify-content-start  d-flex flex-wrap  gap-5">
                  {isLoading ? (
                    <CircularProgress className="m-auto" />
                  ) : (

                    sharedDocuments.length > 0 ?
                    sharedDocuments.map((eachDoc) => {
                      return (
                        <div
                          key={eachDoc.id}
                          className="col-3 border rounded-3  gap-2 d-flex flex-column align-items-center"
                        >
                          <div className="p-3">
                            <img
                              src={
                                eachDoc?.document?.mimetype.split("/").pop() ===
                                "msword"
                                  ? wordLogo : eachDoc?.document?.mimetype.split("/").pop() ===
                                  "png" ||  eachDoc?.document?.mimetype.split("/").pop() ===
                                  "jpeg" || eachDoc?.document?.mimetype.split("/").pop() ===
                                  "jpg"
                                  ? gallery : pdfLogo
                              }
                              width={50}
                              alt="doc-img"
                            />
                          </div>

                          <div className=" p-2 border-top gap-1 w-100 d-flex flex-column">
                            <span className=" w-100 d-flex justify-content-between">
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                                className="w-75 overflow-hidden"
                              >
                                <span
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                  className="text-right cad-text-small cad-fw-500 text-primary text-break"
                                >
                                  {eachDoc?.document?.filename}
                                </span>
                              </div>

                              <span
                                onClick={() =>
                                  handleDownload(
                                    eachDoc?.document?.path,
                                    eachDoc?.document?.originalname
                                  )
                                }
                                className="w-25 text-primary text-end"
                              >
                                <FileDownloadOutlinedIcon className="cad-text-large pointer" />
                              </span>
                            </span>

                            <span
                              style={{
                                textOverflow: "ellipsis",
                              }}
                              className="cad-text-small cad-fw-500 text-secondary text-break"
                            >
                              Last access :{" "}
                              <LastAcces timestamp={eachDoc?.updatedAt} />
                            </span>
                          </div>
                        </div>
                      );
                    }):
                    <div className=" p-5 d-flex flex-column align-items-center gap-2">
                      <img src={noData} width={40} alt="" />
                      <Link to={`/share-documents/${id}`} className="cad-text-small cad-fw-600 text-primary text-decoration-underline">Please upload documents</Link>
                    </div>
                  )}
                </div>
              )}
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewModal;
