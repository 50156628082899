import axios from "axios";
import api from "./api.service";

export const getPrivacyData = async (setData) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_BASEURL +
        "/api/" +
        process.env.REACT_APP_NODE_ENV +
        "/v1"
      }/organization/privacyPolice`
    );
    console.log(response?.data?.data); // Assuming your server returns a token upon successful login
    return setData(response?.data?.data);
  } catch (error) {
    throw error.response.data.error; // Handle login errors
  }
};
