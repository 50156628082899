import { useEffect } from "react";
import NewDrawerPersistace from "../Components/NewDrawerPersistace";
import { useDispatch } from "react-redux";
import { fetchSubscription } from "../Redux/Slice/paymentSelice";
import { useSelector } from "react-redux";
import { logoutAgent } from "../Redux/Slice/fetchAuthMe";
import { logoutUser } from "../Redux/Slice/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const DefaultLayout = () => {

  const { isAuthenticate } = useSelector((state) => state?.fetchAuthMe);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticate) {
      localStorage.removeItem("user");
      Cookies.remove("userData"); // Remove the cookie notification
      dispatch(logoutUser());
      dispatch(logoutAgent());
      return navigate("/");
    }
    dispatch(fetchSubscription())
  }, [dispatch, isAuthenticate, navigate])
  return <NewDrawerPersistace />;
};

export default DefaultLayout;
