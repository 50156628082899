import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPaymentHistory, getSubscription } from "../../service/billing.service";
import { setSubscription, setSubscription_user } from "./business.slice";

export const fetchPaymentHistory = createAsyncThunk(
  "fetchPaymentHistory",
  async (params, { dispatch }) => {
    try {
      const res = await getPaymentHistory(params);
      const paidPayments = res.data.data?.rows.filter(payment => payment.payment_status === 'paid');
      const latestPaidPayment = paidPayments.reduce((latest, payment) => {
        return !latest || new Date(payment.createdAt) > new Date(latest.createdAt) ? payment : latest;
      }, null);
      dispatch(setLastPayment(latestPaidPayment));
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching payment history: ${err.message}`);
    }
  }
);
export const fetchSubscription = createAsyncThunk(
  "fetchSubscription",
  async (_, { dispatch }) => {
    try {
      const res = await getSubscription();
      dispatch(setSubscription(res.data.data))
      dispatch(setSubscription_user(!res.data?.data.canceled_at))
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching payment history: ${err.message}`);
    }
  }
);
const paymentSlice = createSlice({
  name: "paymentHistory",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    payments: [],
    subscription: null,
    totalPages: 0,
    lastPayment: undefined
  },
  reducers: {
    setLastPayment: (state, action) => {
      state.lastPayment = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentHistory.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payments = action.payload.data?.rows;
        state.totalPages = action.payload.data?.totalPages;
      })
      .addCase(fetchPaymentHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchSubscription.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscription = action.payload.data;
      })
      .addCase(fetchSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});
export const { setLastPayment } = paymentSlice.actions
export default paymentSlice.reducer;
