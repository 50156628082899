import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import LightTooltip from "../Tooltip/LightTooltip";

export default function GroupAvatars({ data, max }) {
  const stringToColor = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).slice(-2);
    }
    return color;
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${
        name.split(" ")[1] ? name.split(" ")[1][0] : ""
      }`,
    };
  };

  //   console.log(data);
  const UserProfile = ({ profile_photo, last_name, first_name }) => (
    <LightTooltip color="#e74c3c" title={first_name + " " + last_name}>
      <Avatar
        // sx={{ width: 30, height: 30 }}
        sx={{
          cursor: "pointer",
          ...(profile_photo
            ? {}
            : stringAvatar(first_name + " " + last_name).sx),
        }}
        alt={first_name + " " + last_name}
        src={process.env.REACT_APP_DOWLOAD_URL + "/" + profile_photo}
      />
    </LightTooltip>
  );
  return (
    <AvatarGroup max={max ? max : 3}>
      {Array.isArray(data) &&
        data.length > 0 &&
        data.map((item, index) => <UserProfile key={index} {...item} />)}
    </AvatarGroup>
  );
}
