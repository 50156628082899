import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getClient } from "../../service/contact.client.service";

export const fetchClient = createAsyncThunk("fetchClient", async (params) => {
  try {
    const res = await getClient(params);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});

const clientSlice = createSlice({
  name: "fetchClient",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    clients: [], // Add a faq property to store the fetched data
    clientTableData: [],
    currentPage: 1 || undefined,
    totalItems: 0,
    totalPages: 0 || undefined,
    size: 10 || undefined
  },
  reducers: {},
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchClient.pending, (state) => {
  //       state.isLoading = true;
  //       state.isError = false;
  //       state.error = null;
  //     })
  //     .addCase(fetchClient.fulfilled, (state, action) => {
  //       state.isLoading = false;
  //       state.clients = action.payload.data; // Update the faq property with the fetched data
  //     })
  //     .addCase(fetchClient.rejected, (state, action) => {
  //       state.isLoading = false;
  //       state.isError = true;
  //       state.error = action.error.message; // Store the error message
  //     });
  // },
  // extraReducers: {
  //   [fetchClient.pending]: (state, action) => {
  //     state.isLoading = true;
  //   },
  //   [fetchClient.fulfilled]: (state, action) => {
  //     state.isLoading = false;
  //     state.clients = action.payload.data;
  //     // console.log(action.payload.data);

  //   },
  //   [fetchClient.rejected]: (state, action) => {
  //     state.isLoading = false;
  //   },
  // },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClient.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchClient.fulfilled, (state, action) => {
        // console.log(action.payload.data);
        state.isLoading = false;
        state.clients = action.payload.data.rows;
        state.totalItems = action.payload.data.totalItems;
        state.totalPages = action.payload.data.totalPages;
        state.currentPage = action.payload.data.currentPage;
      })
      .addCase(fetchClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default clientSlice.reducer;
