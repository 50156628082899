import { Button, Chip, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import Switch from "@mui/material/Switch";
import { Box, styled } from "@mui/system";
import { getPlans, makePayment } from "../../service/billing.service";
import Loader from "../../Components/Loader/Loader";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/Slice/auth/auth.slice";
import { logoutAgent } from "../../Redux/Slice/fetchAuthMe";
import Cookies from "js-cookie";
import AlertModal from "../../Components/AlertModal";

export const planDescription = [
  "Customized plans designed to fit your business requirements.",
  "The preferred substruction plan.",
  "Make your business accessible 24/7.",
];

function isDateLessThanCurrent(dateString) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();
  return givenDate < currentDate;
}
const StripeSubscription = ({ inDashboard = false }) => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const [plans, setPlans] = useState([]);
  const [isMonthly, setIsMonthly] = useState(true); // State variable to track the selected option
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { subscribed_user } = useSelector((state) => state?.business);
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);
  const SubscriptionEndDate = isDateLessThanCurrent(authInfo?.business[0]?.subscriptions[0]?.end_date);
  const navigate = useNavigate()
  const [openLogOut, setOpenLogOut] = useState(false);
  const dispatch = useDispatch()

  const PurpleSwitch = styled(Switch)({
    "& .MuiSwitch-switchBase": {
      color: "#0D6EFD",
      "&.Mui-checked": {
        color: "#0D6EFD",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#0D6EFD",
      },
    },
  });

  const fetchPlans = async () => {
    setIsLoading(true);
    try {
      const res = await getPlans();
      setPlans(res?.data?.data);
      setIsLoading(false);
      return res;
    } catch (err) {
      setIsLoading(false);
      throw new Error(`Error fetching data: ${err.message}`);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const separatedData = plans?.reduce((acc, obj) => {
    const recurringValue = obj?.recurring; // Assuming product_prices always has at least one item

    // Check if the recurring value exists as a key in the accumulator
    if (!acc[recurringValue]) {
      // If not, initialize an array with the current object
      acc[recurringValue] = [obj];
    } else {
      // If yes, push the current object to the existing array
      acc[recurringValue].push(obj);
    }

    return acc;
  }, {});

  const recurring12Array = separatedData["12"] || [];
  const recurring1Array = separatedData["1"] || [];



const premiumIndex = recurring12Array?.sort((a, b) => {
  const order = { "Essential": 0, "Premium": 1 };
  return (order[a.package.title] ?? 2) - (order[b.package.title] ?? 2);
});



const premiumIndex2 = recurring1Array?.sort((a, b) => {
  const order = { "Essential": 0, "Premium": 1 };
  return (order[a.package.title] ?? 2) - (order[b.package.title] ?? 2);
});





  // Function to handle switch toggle
  const handleSwitchToggle = () => {
    setIsMonthly((prevState) => !prevState);
    // console.log("isMonthly:", isMonthly);
  };
  // console.log(isMonthly);

  const handleMakePayment = async (id) => {
    setOpen(true);
    try {
      const res = await makePayment(id);
      const stripePaymentLink = res?.data?.data;
      setOpen(false);
      window.location.href = stripePaymentLink;
      return res;
    } catch (err) {
      setIsLoading(false);
      throw new Error(`Error fetching data: ${err.message}`);
    }
  };

  const handleOpen = () => {
    setOpenLogOut(true); // Close the other Popper
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.titleText,
      }}
      className="form cad-shadow pb-5 mb-3  overflow-auto rounded-3  "
    >
      <AlertModal
        open={openLogOut}
        closeModal={() => setOpenLogOut(false)}
        handler={() => {
          localStorage.removeItem("user");
          Cookies.remove("userData"); // Remove the cookie notification
          dispatch(logoutUser());
          dispatch(logoutAgent());
          navigate("/");
        }}
        message={"Are your sure you want to logout?"}
      />
      <div className="d-flex align-items-center  flex-column p-4">
        <Box component={'div'} display={inDashboard ? 'none' : 'flex'} position={'absolute'} marginLeft={"20%"} width={"100%"} >
          {!(subscribed_user && !SubscriptionEndDate)
            ? <Button onClick={() => handleOpen()}>
              <LogoutIcon /> Logout</Button>
            : <Button onClick={() => navigate(-1)}><KeyboardBackspaceIcon /> back</Button>}

        </Box>
        <h1
          style={{ width: "60%", fontSize: "34px" }}
          className=" text-center"
        >
          Find Your Perfect Fit
          Adaptable Packages
        </h1>
        <p style={{ width: "35%" }} className=" text-center cad-text-small">
          Start Your Free 14-Day Trial (Cancel Anytime)
        </p>
        <span className="mb-2 cad-fw-500 cad-text-medium">
          <span className={`${isMonthly ? "text-primary" : ""}`}>
            {" "}
            Monthly{" "}
          </span>{" "}
          <Switch value={true} onChange={handleSwitchToggle} />{" "}
          <span className={`${isMonthly ? "" : "text-primary"}`}>
            {" "}
            Anually{" "}
          </span>
        </span>
      </div>

      <div
        className={`w-100 d-flex justify-content-center align-items-center gap-5 flex-wrap ${isLoading ? "h-50" : ""
          }`}
      >
        {isLoading ? (
          <Loader />
        ) : isMonthly ? (
          premiumIndex?.map((plan, index) => {
            return (
              <div
                key={index}
                style={{
                  width: 280,
                  height: 380,
                  border: plan?.package.title === "Premium" ? "1px solid #0D6EFD" : "1px solid #4ccd8d",
                  transition: "transform 0.3s ease",
                  position: "relative",

                  // backgroundColor: themeColorsObject.premiumCard,
                }}
                className="subscription_card  rounded-3 p-3"
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.1)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >


                {plan?.package?.title === "Premium" ? 
                <span
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#bed6f8",
                    color: "#4CCD8D",
                    position: "absolute",
                    top: -10.5,
                    left: 42,
                    padding: 1.5
                  }}
                  className="ps-5 fw-bold pe-5  text-primary  rounded-5 "
                >
                  MOST POPULAR
                </span> : ""}
                <span
                  style={{ borderBottom: "1px dashed #0D6EFD", height: 70 }}
                  className="d-flex flex-column justify-content-start pb-3"
                >
                  <span className="cad-fw-600 cad-text-medium">
                    {plan?.package?.title}
                  </span>
                  <span className="cad-fw-500 cad-text-small">
                    {planDescription[index]}
                  </span>
                </span>

                <span className="d-flex flex-column justify-content-start pb-3">
                  <h5 className="mt-2 cad-fw-600">
                    {plan?.currency} {plan?.unit_amount}{" "}
                    {plan?.recurring === "1" ? "monthly" : "anually"}
                  </h5>
                  <Button
                    onClick={() => handleMakePayment(plan?.id)}
                    style={{
                      backgroundColor: plan?.package.title === "Premium" ? "#0D6EFD" : "#4CCD8D",
                      textTransform: "none",
                    }}
                    className="btn cad-text-small text-white mt-2"
                  >
                    Get Started
                  </Button>
                  <div className="d-flex flex-column gap-2 mt-3">
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> 100 GB Secure Data Storage
                    </span>
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Up to 25
                      accounts
                    </span>
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Detailed
                      analytics
                    </span>
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Seamless
                      integration with apps
                    </span>
                    {/* <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> 24/7 assistance
                    </span> */}
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Unlimited
                      website bandwidth
                    </span>
                  </div>
                </span>
              </div>
            );
          })
        ) : (
          premiumIndex2?.map((plan, index) => {
            return (
              <div
                style={{
                  width: 280,
                  height: 380,
                  border: plan?.package.title === "Premium" ? "1px solid #0D6EFD" : "1px solid #4ccd8d",
                  transition: "transform 0.3s ease",
                  position: "relative",

                  // backgroundColor: themeColorsObject.premiumCard,
                }}
                className="subscription_card  rounded-3 p-3"
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.1)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >


                {plan?.package?.title === "Premium" ? <span
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#bed6f8",
                    color: "#4CCD8D",
                    position: "absolute",
                    top: -10.5,
                    left: 42,
                    padding: 1.5
                  }}
                  className="ps-5 fw-bold pe-5  text-primary  rounded-5 "
                >
                  MOST POPULAR
                </span> : ""}
                <span
                  style={{ borderBottom: "1px dashed #0D6EFD", height: 70 }}
                  className="d-flex flex-column justify-content-start pb-3"
                >
                  <span className="cad-fw-600 cad-text-medium">
                    {plan?.package?.title}
                  </span>
                  <span className="cad-fw-500 cad-text-small">
                    {planDescription[index]}
                  </span>
                </span>

                <span className="d-flex flex-column justify-content-start pb-3">
                  <h5 className="mt-2 cad-fw-600">
                    {plan?.currency} {plan?.unit_amount}{" "}
                    {plan?.recurring === "1" ? "monthly" : "anually"}
                    <Chip
                      variant="outlined"
                      size="small"
                      label="Save 20%"
                      className="ms-2 cad-text-xsmall"
                      color="primary"
                    />
                  </h5>
                  <Button
                    onClick={() => handleMakePayment(plan?.id)}
                    style={{
                      backgroundColor: plan?.package.title === "Premium" ? "#0D6EFD" : "#4CCD8D",
                      textTransform: "none",
                    }}
                    className="btn cad-text-small text-white mt-2"
                  >
                    Get Started
                  </Button>
                  <div className="d-flex flex-column gap-2 mt-3">
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> 100 GB Storage
                    </span>
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Up to 25
                      accounts
                    </span>
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Detailed
                      analytics
                    </span>
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Seamless
                      integration with apps
                    </span>
                    {/* <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> 24/7 assistance
                    </span> */}
                    <span className="cad-text-small cad-fw-600">
                      <CheckIcon className="cad-text-medium" /> Unlimited
                      website bandwidth
                    </span>
                  </div>
                </span>
              </div>
            );
          })
        )}
      </div>

      <Modal
        open={open}
        // onClose={()=>setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.titleText,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span className="cad-text-large">Please wait...</span>
        </Box>
      </Modal>
    </div>
  );
};

export default StripeSubscription;
