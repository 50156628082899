import { Paper } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { authMeService } from "../../service/auth.service";
import { convertTimestampToTime } from "../ChatComponent/ChatsLeft";
import UserProfile from "../GlobalTable/UserProfile";
import "./loading.css";
import GlobalStateContext from "../../Contexts/GlobalStateContext";

const ChatBox = ({ chatArray, proPic, isTyping }) => {
  const { isChatLoading } = useContext(GlobalStateContext);
  const [myId, setMyId] = useState("");

  const chatWindowRef = useRef(null);
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const fetchAuthMe = async () => {
      // setIsLoading(true);
      try {
        const res = await authMeService();
        setMyId(res?.data?.data?.id);
        // setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchAuthMe();
    scrollToBottom();
  }, [chatArray]);

  // console.log(chatArray);

  return (
    <div
      ref={chatWindowRef}
      className="chat-screen mb-5 p-3 h-100 w-100 d-flex flex-column overflow-auto"
    >
      {isChatLoading ? (
        <div className="d-flex justify-content-center cad-text-small">
          <span className="bg-primary text-white p-3 pt-1 pb-1 rounded-5">
            Loding message...
          </span>
        </div>
      ) : (
        chatArray?.map(({ content, id, sender, createdAt }, index) => (
          <div
            key={id}
            className={
              sender?.id === myId
                ? // true
                  "sending-msg-bubble-chat-box mb-3 d-flex flex-column"
                : "chat-receiving-msg-bubble mb-4 d-flex flex-column"
            }
          >
            <div
              // className={chat.sender ? "d-none" : "d-block"}
              style={{ position: "absolute", top: "-6px", left: "-49px" }}
            >
              {sender?.id !== myId && (
                <UserProfile proPic={sender?.profile_photo} />
              )}
            </div>
            <span
              className={`${
                sender?.id === myId ? "text-white" : "text-primary"
              }    cad-text-small cad-fw-500`}
            >
              {sender?.first_name} {sender?.last_name}
            </span>
            <span className="msg">{content}</span>
            <span className="msg-time">
              {convertTimestampToTime(createdAt)}
            </span>
          </div>
        ))
      )}
      {/* {isTyping ? (
        <div className="bubble mb-3">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : null} */}
    </div>
  );
};

export default ChatBox;
