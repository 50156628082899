import wordLogo from "../../Images/wordLogo.png";
import pdfLogo from "../../Images/pdfLogo.png";
import gallery from "../../Images/gallery.png";
import LastAcces from "../../Components/LastAcces";
import { notify } from "../../App";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box } from "@mui/system";

const DocumentBoxComponents = ({ data }) => {
  console.log(data);
  const openFile = (filePath, fileType) => {
    if (fileType === "pdf") {
      // Open PDF directly
      // window.open(filePath, '_blank');
      return;
    } else if (fileType === "docx") {
      // Open Word document using Office Online Viewer
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        filePath
      )}`;
      window.open(officeViewerUrl, "_blank");
    } else {
      // Download other file types
      fetch(filePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filePath.split("/").pop()); // Use the file name from the URL
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error("There was a problem with the file download:", error);
        });
    }
  };
  const downloadFile = (filePath, fileName) => {
    // console.log(filePath, fileName);

    fetch(process.env.REACT_APP_DOWLOAD_URL + "/" + filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notify("Document downloaded");
        setTimeout(() => {
          openFile(
            process.env.REACT_APP_DOWLOAD_URL + "/" + filePath,
            filePath.split(".").pop()
          );
        }, 3000);
      })
      .catch((error) => {
        console.error("There was a problem with the file download:", error);
      });
  };
  return (
    <Box display={"flex"} gap={2} py={3}>
      {Array.isArray(data) &&
        data.length > 0 &&
        data?.map((eachDoc) => {
          return (
            <div
              key={eachDoc.id}
              className="col-3 border rounded-3  gap-2 d-flex flex-column align-items-center"
            >
              <div className="p-3">
                <img
                  src={
                    eachDoc?.mimetype.split("/").pop() === "msword"
                      ? wordLogo
                      : eachDoc?.mimetype.split("/").pop() === "png" ||
                        eachDoc?.mimetype.split("/").pop() === "jpeg" ||
                        eachDoc?.mimetype.split("/").pop() === "jpg" ||
                        eachDoc?.mimetype.split("/").pop() === "gif"
                      ? gallery
                      : pdfLogo
                  }
                  width={50}
                  alt="doc-img"
                />
              </div>

              <div className=" p-2 border-top gap-1 w-100 d-flex flex-column">
                <span className=" w-100 d-flex justify-content-between">
                  <div
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    className="w-75 overflow-hidden"
                  >
                    <span
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      className="text-right cad-text-small cad-fw-500 text-primary text-break"
                    >
                      {eachDoc?.filename}
                    </span>
                  </div>

                  <span
                    onClick={() =>
                      downloadFile(eachDoc?.path, eachDoc?.filename)
                    }
                    className="w-25 text-primary text-end "
                  >
                    <FileDownloadOutlinedIcon className="cad-text-xlarge pointer action-hover rounded-2" />
                  </span>
                </span>

                <span
                  style={{
                    textOverflow: "ellipsis",
                  }}
                  className="cad-text-small cad-fw-500 text-secondary text-break"
                >
                  Last access : <LastAcces timestamp={eachDoc?.updatedAt} />
                </span>
              </div>
            </div>
          );
        })}
    </Box>
  );
};
export default DocumentBoxComponents;
