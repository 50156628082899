const HelpAndGuids = ({ SrNo, title, details }) => {
  return (
    <div className="pt-4 ps-3 pe-5">
      <p className="cad-text-medium "><div dangerouslySetInnerHTML={{ __html: details }} /></p>
      
    </div>
  );
};

export default HelpAndGuids;
