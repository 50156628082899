import api from "./api.service";

export const getForms = async () => {
  try {
    return await api.get(`/form/`);
  } catch (error) {
    throw error;
  }
};
export const getFormsAll = async () => {
  try {
    return await api.get(`/form/all`);
  } catch (error) {
    throw error;
  }
};
export const toggleForm = async (id) => {
  try {
    return await api.post(`/form/toggle/${id}`);
  } catch (error) {
    throw error;
  }
};

export const createForm = async (data) => {
  try {
    return await api.post(`/form/`, data);
  } catch (error) {
    return error;
  }
};

export const deleteForm = async (id) => {
  try {
    return await api.delete(`/form/delete/${id}/id`);
  } catch (error) {
    throw error;
  }
};

export const getGlobalForms = async () => {
  try {
    return await api.get(`/form/global`);
  } catch (error) {
    throw error;
  }
};

export const getFormsById = async (id) => {
  try {
    return await api.get(`/form/${id}/id`);
  } catch (error) {
    throw error;
  }
};

export const putFormsById = async (data, id, { signal }) => {
  try {
    console.log(signal);
    return await api.put(`/form/${id}/id`, data, { signal });
  } catch (error) {
    throw error;
  }
};
