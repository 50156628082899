import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSupportChat } from "../../service/support.service";


export const fetchSupportChats = createAsyncThunk("fetchSupportChats", async (id) => {
  try {
    const res = await getSupportChat(id);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});

const supportChatSlice = createSlice({
  name: "supportChat",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    chats: [], // Initialize rows as an empty array
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportChats.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchSupportChats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chats = action.payload?.data?.ticket_message || [];
      })
      .addCase(fetchSupportChats.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
})


export default supportChatSlice.reducer;

