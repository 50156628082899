// import necessary dependencies and services
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getContacts, getMessage } from "../../service/chat.service";


export const fetchMessage = createAsyncThunk("fetchMessage", async (chatId) => {
  try {
    const response = await getMessage(chatId);
    return(response?.data?.payload);
  } catch (error) {
    // console.log(error);
  }
});



// create a slice for chat contacts
const chatMessageSlice = createSlice({
  name: "fetchChatMessage",
  initialState: {
    isChatMessageLoading: true,
    isError: false,
    error: null,
    chatMessage: [], // property to store the fetched chat contacts
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessage.pending, (state) => {
        state.isChatMessageLoading = true;
      })
      .addCase(fetchMessage.fulfilled, (state, action) => {
        state.isChatMessageLoading = false;
        state.chatMessage = action.payload;
      })
      .addCase(fetchMessage.rejected, (state, action) => {
        state.isChatMessageLoading = false;
        state.isError = true;
        state.error = action.error.message; // store the error message
      });
  },
});

export default chatMessageSlice.reducer;
