import React, { useEffect, useState } from "react";
import Account from "../../Components/Account/Account";
import Checkbox from "@mui/material/Checkbox";
import { Button, Paper } from "@mui/material";
import "./AccountsSecurity.css";
import AccountInner from "../../Components/Account/AccountInner";
import { styled } from "@mui/material/styles";
import * as themeColors from "../../Utils/Theme/Colors";
import { useSelector } from "react-redux";
import {
  getExportContacts,
  getExportContactsZip,
} from "../../service/agent.service";
// import downloadCSV from "../User/DownloadCSV";
import { downloadCSV } from "../../Utils/ExportFunction/ExportFunction";
import { notify } from "../../App";
import { useDispatch } from "react-redux";
import { fetchBusinessStorage } from "../../Redux/Slice/business.slice";

const AccountsSecurity = () => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const { storage } = useSelector((state) => state.business);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBusinessStorage())
  }, [dispatch])

  console.log(storage);

  const [isCloseAccountOpen, setIsCloseAccountOpen] = useState(true);

  const handleCloseAccount = () => {
    setIsCloseAccountOpen(!isCloseAccountOpen);
  };

  const DemoPaper = styled(Paper)(({ theme }) => ({
    width: "100%",
    height: "100%",
    borderRadius: "0px",
    // padding: theme.spacing(2),
  }));

  const handleContactExport = async () => {
    try {
      const res = await getExportContacts();
      downloadCSV(res?.data, "Contacts");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDocumentZipExport = async () => {
    try {
      const { data } = await getExportContactsZip();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Documents.zip'); // Set the name of the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      notify(error?.data.error.message, "error");
      console.log('Failed to download zip file:', error);
    }
  };

  return (
    <div
      style={{
        minHeight: "80vh",
      }}
      className="mt-1 mb-4 "
    >
      {isCloseAccountOpen ? (
        <div>
          <Paper
            elevation={1}
            className="sec-card p-3 "
            style={{
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.titleText,
            }}
          >
            <h4 className="cad-text-medium">
              {storage} GB of the total utilized GB
            </h4>
            <p className="mb-0 cad-text-small">
              This represents the current usage out of the total available storage.
            </p>
          </Paper>
          <hr className="border-top-0" />
          {/* </div> */}

          <Account
            themeColorsObject={themeColorsObject}
            head="Export contact"
            pera="Export your contacts in a convenient format. This will allow you to use your contact information in other applications or services."
            btn="Download .xlsx file "
            onClick={handleContactExport}
          />
          <hr className="border-top-0" />
          <Account
            themeColorsObject={themeColorsObject}
            head="Export uploaded files"
            pera="Export all the files you have uploaded. This will create a zip file containing all your uploaded files for easy download."
            btn="Download .zip file  "
            onClick={handleDocumentZipExport}
          />
          <hr className="border-top-0" />
          <Paper
            elevation={1}
            className="devices p-3"
            style={{
              display: "flex",
              alignItems: "baseline",
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.titleText,
            }}
          >
            <Checkbox
              style={{
                position: "relative",
                top: "6px",
                marginRight: "8px",
                color: themeColorsObject.titleText,
              }}
            />
            <div>
              <h4 className="cad-text-large">Sign out from all devices</h4>
              <p className="cad-text-small">
                Logged in on a shared device but forgot to sign out? End all
                sessions by signing out from all devices.
              </p>
            </div>
          </Paper>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Button
              onClick={handleCloseAccount}
              style={{
                border: "none",
                color: "#fff",
                backgroundColor: "#DE5753",
                padding: "11px 98px",
                fontSize: "18px",
                borderRadius: "5px",
                height: "40px",
                textTransform: "none",
              }}
            >
              Close Account
            </Button>
          </div>
        </div>
      ) : (
        <AccountInner handleCloseAccount={handleCloseAccount} />
      )}
    </div>
  );
};

export default AccountsSecurity;
