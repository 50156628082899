import noInternetScreen from "../Images/seo-and-web.png"

const NoInternetScreen = () => {
  return (
    <div style={{height:"100vh"}} className=' w-100 d-flex justify-content-center align-items-center'>
      <div className="d-flex flex-column justify-content-center align-items-center gap-3">
      <img width={120} src={noInternetScreen} />
      <span style={{ color: "#283548"}} className=" cad-fw-800">No Internet Connection</span>
      </div>
    </div>
  )
}

export default NoInternetScreen