import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const FormPrevField = ({ formData, name, value, width, type }) => {
  // console.log(type);

  function replaceUnderscoresWithSpaces(str) {
    return str?.replace(/_/g, " ");
  }

  return (
    <div
      style={{
        // border: `0.1px solid ${formData[0]?.color}`,
        borderBottom: `2px solid #bbb`,
        // borderRadius: 4,
        width: width ? width : "49%",
      }}
    >
      <div className="p-2 gap-1 d-flex flex-column">
        <label
          style={{ color: "#808080" }}
          className="cad-text-small cad-fw-500 cad-fw-bold"
        >
          {replaceUnderscoresWithSpaces(name)} :
        </label>
        <span
          style={
            {
              // border: `1px dotted #000000`,
            }
          }
          className="cad-text-medium cad-fw-500 p-1 ps-0"
        >
          {
          type === "checkbox"
            ? value?.map((item) => (
                <span className="p-1" key={item}>
                  <CheckBoxIcon className="text-success cad-text-xlarge me-1" />
                  {item}
                </span>
              ))
            : type === "radio"
            ?  
              <span className="p-1" >
                <RadioButtonCheckedIcon className="text-primary cad-text-xlarge me-1" />
                {value}
              </span>
            : value
        }
        </span>
      </div>
    </div>
  );
};

export default FormPrevField;
