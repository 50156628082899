import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFaq } from "../../service/faq.service";

export const fetchFaqData = createAsyncThunk("fetchFaqData", async () => {
  try {
    const res = await getFaq();
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});



const faqSlice = createSlice({
    name: "fetchFaq",
    initialState: {
      isLoading: true,
      isError: false,
      error: null,
      faq: [], // Add a faq property to store the fetched data
    },
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchFaqData.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        })
        .addCase(fetchFaqData.fulfilled, (state, action) => {
          state.isLoading = false;
          state.faq = action.payload.data; // Update the faq property with the fetched data
        })
        .addCase(fetchFaqData.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.error = action.error.message; // Store the error message
        });
    },
  });
  
  export default faqSlice.reducer;
  