import React, { useEffect, useRef, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { formElement } from "./FormTemplate";
import * as Yup from "yup";
import "../Form.css";
import SignaturePad from "react-signature-canvas";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useSelector } from "react-redux";

const FormCanvas = ({
  id,
  formData = [],
  setFormData,
  handleOpenEditElement,
  themeObject,
}) => {
  const [editableIndex, setEditableIndex] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});
  const [showButtons, setShowButtons] = useState(true);

  
  const { bussiness_logo } = useSelector((state) => state?.business);    const [selectedImage, setSelectedImage] = useState(
    bussiness_logo
      ? process.env.REACT_APP_DOWLOAD_URL + "/" + bussiness_logo
      : null
  );

  // console.log(themeObject);

  const [td, setTd] = useState([]);
  const [tableData, setTableData] = useState(Array(formData?.length).fill([]));

  useEffect(() => {
    if (Array.isArray(formData)) {
      formData?.map((field, index) => {
        switch (field?.type) {
          case "details":
            return setTd(Array(field?.inputsArray?.length).fill(""));
          default:
            return null;
        }
      });
    }

    // console.log(td);
  }, [formData]); // Include any dependencies that affect this effect


  // console.log(formData);

  const handleInputChange = (e, index) => {
    const newInputValues = [...td];
    newInputValues[index] = e.target.value;
    setTd(newInputValues);
  };


  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setShowButtons(true);
  };

  const handleMouseEnter = () => {
    imageURL ? setShowButtons(false) : setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  const handleDeleteField = (indexToDelete) => {
    const updatedFormData = formData.filter(
      (_, index) => index !== indexToDelete
    );
    setFormData(updatedFormData);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const draggedIndex = event.dataTransfer.getData("index");
    const droppedElement = formElement[draggedIndex];
    setFormData([...formData, droppedElement?.element]);
    setDraggedItem(null);
  };

  const generateValidationSchema = (fields) => {
    const schema = {};
    fields.forEach((field) => {
      switch (field.type) {
        case "text":
        case "email":
          schema[field.name] = Yup.string().required(
            `${field.label} is required`
          );
          if (field.type === "email") {
            schema[field.name] = schema[field.name].email("Invalid email");
          }
          break;
        case "password":
          schema[field.name] = Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required(`${field.label} is required`);
          break;
        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const dynamicFields = [
    { name: "name", type: "text", label: "Name" },
    { name: "email", type: "email", label: "Email" },
    { name: "password", type: "password", label: "Password" },
  ];

  const validationSchema = generateValidationSchema(dynamicFields);

  const handleSubmit = async (formData) => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      // console.log("Form is valid. Submitting...");
    } catch (validationErrors) {
      console.error("Validation Errors:", validationErrors.errors);
    }
  };

  const replaceSpacesWithUnderscores = (str) => {
    return str.replace(/\s+/g, "_");
  };

  const handleDragStart = (e, index) => {
    setDraggedItem(index);
    setIsDragging(true);
    setDraggedIndex(index);
  };

  const handleDragOverr = (e, index) => {
    e.preventDefault();
    const draggedItem = formData[draggedIndex];
    const newFormData = formData.filter((item, idx) => idx !== draggedIndex);
    newFormData.splice(index, 0, draggedItem);
    const dataWithoutNull = newFormData.filter((item) => item !== undefined);
    setFormData(dataWithoutNull);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setDraggedIndex(null);
  };
  // Function to render table rows
  const renderRows = (rows, isBeingEdited) => {
    return rows.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td
            style={{
              backgroundColor: cellIndex === 0 ? "#E5EAF4" : "",
              border: `1px solid ${themeObject?.color}`,
              padding: cellIndex === 0 ? "8px 16px" : 0,
            }}
            className="cad-text-small cad-fw-600"
            key={cellIndex}
          >
            {cell}
            {/* {isBeingEdited ? (
              cellIndex !== 0 ? (
                ""
              ) : (
                <CloseIcon className="action-hover rounded-2 pointer ms-1" />
              )
            ) : (
              ""
            )} */}
          </td>
        ))}
      </tr>
    ));
  };






  


  return (
    <div style={{
      minHeight:"100vh"
    }} className="col-9  overflow-auto pt-2 pb-3 pt-4  ps-5 pe-5 pt-0 pb-0">
      <form
        onSubmit={handleSubmit}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
          // backgroundColor: themeObject?.formColor,
          color: themeObject?.color,
          minHeight: "80vh",
          border: "1px solid",
          padding: "10px",
          backgroundColor: isDragging ? "lightblue" : "white" || themeObject?.formColor,
          cursor: "pointer", // Change cursor to indicate draggable
          position:"relative",
          overflow:"scroll"
        }}
        className=" form-temp  w-100  cad-shadow rounded-1 "
      >
        <span className="d-flex" style={{
          position:"absolute",
          top:"40%",
          left:"-5%",
          opacity:0.1,
          transform:"rotate(-38deg)"
        }}>
        <span
              className="cad-fw-800  d-flex align-items-end"
              style={{
                color: "#4CCD8D",
                fontSize: "150px",
                // opacity: open ? 0 : 1,
                transform:  "translateX(0px)",
                transition: "0.5s",
              }}
            >
              Cloudify
            </span>
            <span
              className=" d-flex text-primary align-items-end cad-fw-500"
              style={{
                fontSize: "152px",
                // opacity: open ? 0 : 1,
                transform: "translateX(0px)" ,
                transition: "0.8s",
              }}
            >
              Docs
            </span>
        </span>


        <div className="p-4  d-flex w-100 gap-3 justify-content-center flex-column align-items-start">
          <div className="d-flex w-100 flex-wrap form-canva">
            {formData?.map((field, index) => {
              const isBeingEdited = editableIndex === index;
              switch (field?.type) {



                case "details":
                  return (
                    <div
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative  p-2  d-flex flex-column"
                        >
                          <div className="d-flex flex-wrap w-100">
                            <label className="col-12 postion-relative mb-1 mt-2 cad-fw-600">
                              {field?.detailsTitle}
                            </label>

                            {field?.inputsArray.map((input, index) => (
                              <div
                                key={index}
                                className="col-3  flex-column d-flex justify-content-start mb-2"
                              >
                                <input
                                  type="text"
                                  style={{
                                    borderColor: themeObject.color,
                                  }}
                                  className="form-control cad-text-small m-1 rounded-0 "
                                  placeholder={input?.fieldInput}
                                  value={td[index]}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                <span className="cad-text-small ps-1">
                                  {input?.fieldInput}
                                </span>
                              </div>
                            ))}

                            {/* <span
                              style={{
                                backgroundColor: themeObject.color,
                                position: "absolute",
                                right: -24,
                                top: 40,
                              }}
                              // onClick={handleAddRow}
                              className="text-white float-end mt-2 action-hover pointer rounded-2"
                            >
                              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
                            </span> */}
                          </div>


                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field?.id}
                          // // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <div className="d-flex flex-wrap w-100">
                            <label className="col-12 postion-relative mb-1 mt-2 cad-fw-600">
                              {field?.detailsTitle}
                            </label>

                            {field?.inputsArray?.map((input, index) => (
                              <div
                                key={index}
                                className="col-3  flex-column d-flex justify-content-start mb-2"
                              >
                                <input
                                  type="text"
                                  style={{
                                    borderColor: themeObject.color,
                                  }}
                                  className="form-control cad-text-small m-1 rounded-0 "
                                  placeholder={input?.fieldInput}
                                  value={td[index]}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                <span className="cad-text-small ps-1">
                                  {input?.fieldInput}
                                </span>
                              </div>
                            ))}

                            {/* <span
                              style={{
                                backgroundColor: themeObject.color,
                                position: "absolute",
                                right: -24,
                                top: 40,
                              }}
                              // onClick={handleAddRow}
                              className="text-white float-end mt-2 action-hover pointer rounded-2"
                            >
                              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
                            </span> */}
                          </div>

                          {/* <table >
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "#E5EAF4",
                                  color:themeObject.color,
                                  
                                  border:`1px solid ${themeObject?.color}`,
                                }}
                                className="cad-text-medium"
                              >
                                {field?.inputsArray.map((col, index) => (
                                  <th
                                    scope="col "
                                    className="p-2 cad-text-medium"
                                    
                                  >
                                    {col.fieldInput}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((rowData, rowIndex) => (
                                <tr 
                                style={{
                                  color: themeObject?.color,
                                  
                                  border:`1px solid ${themeObject?.color}`,
                                }}
                                className="cad-text-medium" key={rowIndex}>
                                  {rowData.map((value, columnIndex) => (
                                    <td 
                                    style={{
                                      borderLeft:`1px dotted ${themeObject?.color}`,
                                      borderRight:`1px dotted ${themeObject?.color}`}}
                                    scope="col "
                                    className="p-2 cad-text-small" key={columnIndex}>{value}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table> */}
                        </div>
                      )}
                    </div>
                  );

                case "header":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="w-100 mb-3"
                      style={{
                        cursor: "grab",
                        position:"relative"
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          // element-edit
                          onClick={() => setEditableIndex(null)}style={{
                            // borderBottom: `1px solid ${themeObject.color}`,
                            // paddingLeft: "220px",
                            width:"100%"
                          }}
                          className={` position-relative w-100 d-flex align-items-end element-edit p-4 pb-2`}
                        >
                          {selectedImage && <img style={{
                            // position:"absolute",
                            top:0,
                            left:0,
                            width:"20%",
                            margin:14,
                            objectFit:"cover"
                          }} src={selectedImage} />}

<span style={{ width:"80%"}} className={`${field.alignment}   p-2 pt-2 pb-0    w-100 d-flex justify-content-center  flex-column`}>
                          <h3 className={`${field.className} cad-fw-700`}>
                            {field.label}
                          </h3>
                          <p className={field.subTitleClassName}>
                            {field.subTitle}
                          </p>
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />

                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          // element-edit
                          onDoubleClick={() => setEditableIndex(index)}
                          style={{
                            borderBottom: `1px solid ${themeObject.color}`,
                            // paddingLeft: "220px",
                            width:"100%"
                          }}
                          className={` position-relative w-100 d-flex align-items-end `}
                        >
                          {selectedImage && <img style={{
                            // position:"absolute",
                            top:0,
                            left:0,
                            width:"20%",
                            margin:14,
                            objectFit:"cover"
                          }} src={selectedImage} />}

<span style={{ width:"80%"}} className={`${field.alignment}   p-2 pt-2 pb-0    w-100 d-flex justify-content-center  flex-column`}>
                          <h3 className={`${field.className} cad-fw-700`}>
                            {field.label}
                          </h3>
                          <p className={field.subTitleClassName}>
                            {field.subTitle}
                          </p>
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "text":
                  return (
                    <div
                      className="col-6 mb-3"
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          // element-edit
                          onClick={() => setEditableIndex(null)}
                          className={`element-edit position-relative   p-2 gap-2 d-flex flex-column`}
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type={field.type}
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />

                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className={` position-relative p-2 gap-2 d-flex flex-column`}
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type={field.type}
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "tel":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      style={{
                        cursor: "grab",
                      }}
                      className="col-6"
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type="number"
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>

                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />

                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative  p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type="number"
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "date":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-6"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type={field.type}
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type={field.type}
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "email":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-6"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative   p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type={field.type}
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            required={field.required}
                            type={field.type}
                            className={field.className}
                            placeholder={field.placeHolder}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "textarea":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <textarea
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control rounded-0"
                            placeholder={field.placeHolder}
                            id="floatingTextarea"
                          ></textarea>
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <textarea
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control rounded-0"
                            placeholder={field.placeHolder}
                            id="floatingTextarea"
                          ></textarea>
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "submit":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className={`${field.alignment} element-edit position-relative p-4 pb-3 gap-2 d-flex`}
                        >
                          <button
                            style={{
                              backgroundColor: themeObject.color,
                              color: themeObject.formColor,
                            }}
                            type={field.type}
                            className="btn"
                          >
                            {field.label}
                          </button>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className={`${field.alignment} position-relative p-4 pb-3 gap-2 d-flex `}
                        >
                          <button
                            style={{
                              backgroundColor: themeObject.color,
                              color: themeObject.formColor,
                            }}
                            type={field.type}
                            className="btn"
                          >
                            {field.label}
                          </button>
                        </div>
                      )}
                    </div>
                  );

                case "dropdown":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      style={{
                        minWidth: "50%",
                        cursor: "grab",
                      }}
                    // className="col-6"
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className=" element-edit position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <select
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className={field.className}
                            aria-label="Default select example"
                          >
                            <option selected>Open this select menu</option>
                            {field.options.map(({ option }, index) => (
                              <option key={index} value={index + 1}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className=" position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <select
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className={field.className}
                            aria-label="Default select example"
                          >
                            <option selected>Open this select menu</option>
                            {field.options.map(({ option }, index) => (
                              <option key={index} value={index + 1}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "paragraph":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <p className={`${field.className}  cad-fw-500`}>
                            {field.label}
                          </p>

                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className={`${field.alignment} position-relative p-2 gap-2 d-flex flex-column`}
                        >
                          <p className={`${field.className}  cad-fw-500`}>
                            {field.label}
                          </p>
                        </div>
                      )}
                    </div>
                  );

                case "file":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label
                            htmlFor="formFile"
                            className="cad-text-medium cad-fw-500"
                          >
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className={field.className}
                            type={field.type}
                            id="formFile"
                            multiple={field.multiple}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label htmlFor="formFile" className="form-label">
                            {field.label}
                          </label>
                          <input
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className={field.className}
                            type={field.type}
                            id="formFile"
                            multiple={field.multiple}
                          />
                          <span className="cad-text-small ">
                            {field?.subLabel}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "hr":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12 mt-3"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative p-2 gap-2 d-flex flex-column"
                        >
                          {/* <hr color={themeObject.color} /> */}
                          <span
                            className="mt-2 mb-2"
                            style={{
                              borderTop: `0.5px solid ${themeObject.color}`,
                            }}
                          />

                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          {/* <hr color={themeObject.color} /> */}
                          <span
                            className="mt-2 mb-2"
                            style={{
                              borderTop: `0.5px solid ${themeObject.color}`,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );

                case "checkbox":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-6"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className=" element-edit position-relative p-2 gap-2"
                        >
                          <label className="cad-text-medium cad-fw-500 mb-2">
                            {field.label}
                          </label>
                          {field.options.map(({ option }, index) => (
                            <div
                              key={index}
                              className="form-check d-flex justify-content-start w-100 gap-2"
                            >
                              <input
                                className="form-check-input cad-text-medium"
                                type={field.type}
                                value=""
                                id="flexCheckIndeterminate"
                              />
                              <label
                                className="form-check-label col-12 cad-text-small"
                                htmlFor="flexCheckIndeterminate"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className=" position-relative p-2"
                        >
                          <label className="cad-text-medium cad-fw-500 mb-2">
                            {field.label}
                          </label>
                          {field.options.map(({ option }, index) => (
                            <div
                              key={index}
                              className="form-check d-flex justify-content-start w-100 gap-2"
                            >
                              <input
                                className="form-check-input cad-text-medium"
                                type={field.type}
                                value=""
                                id="flexCheckIndeterminate"
                              />
                              <label
                                className="form-check-label col-12 cad-text-small"
                                htmlFor="flexCheckIndeterminate"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );

                case "radio":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      style={{
                        minWidth: "50%",
                        cursor: "grab",
                      }}
                    // className="col-6"
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className=" element-edit position-relative p-2 gap-2"
                        >
                          <label className="cad-text-medium cad-fw-500 mb-2">
                            {field.label}
                          </label>
                          {field.options.map(({ option }, index) => (
                            <div
                              key={index}
                              className="form-check d-flex justify-content-start w-100 gap-2"
                            >
                              <input
                                name={replaceSpacesWithUnderscores(field.label)}
                                className="form-check-input cad-text-medium"
                                type={field.type}
                                value=""
                                id="flexCheckIndeterminate"
                              />
                              <label
                                className="form-check-label col-12 cad-text-small"
                                htmlFor="flexCheckIndeterminate"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}

                          onDoubleClick={() => setEditableIndex(index)}
                          className=" position-relative p-2"
                        >
                          <label className="cad-text-medium cad-fw-500 mb-2">
                            {field.label}
                          </label>
                          {field.options.map(({ option }, index) => (
                            <div
                              key={index}
                              className="form-check d-flex justify-content-start w-100 gap-2"
                            >
                              <input
                                name={replaceSpacesWithUnderscores(field.label)}
                                className="form-check-input cad-text-medium"
                                type={field.type}
                                value=""
                                id="flexCheckIndeterminate"
                              />
                              <label
                                className="form-check-label col-12 cad-text-small"
                                htmlFor="flexCheckIndeterminate"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );

                case "address":
                  return (
                    <div
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative  p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>

                          <div className="d-flex flex-wrap w-100">
                            <div className="w-100 d-flex justify-content-start flex-column mb-2">
                              <textarea
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control w-100 cad-text-medium   m-1"
                                placeholder={field?.street}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.street}
                              </span>
                            </div>
                            <div className="col-6 flex-column d-flex justify-content-start mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0"
                                placeholder={field?.city}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.city}
                              </span>
                            </div>

                            <div className="col-6 flex-column d-flex justify-content-end mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0"
                                placeholder={field?.state}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.state}
                              </span>
                            </div>

                            <div className="col-6 flex-column d-flex justify-content-end mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0 "
                                placeholder={field?.country}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.country}
                              </span>
                            </div>

                            <div className="col-6 flex-column d-flex justify-content-end mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0 "
                                placeholder={field?.zipCode}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.zipCode}
                              </span>
                            </div>
                          </div>

                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-medium cad-fw-500">
                            {field.label}
                          </label>
                          <div className="d-flex flex-wrap w-100">
                            <div className="w-100 d-flex justify-content-start flex-column mb-2">
                              <textarea
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                type="text"
                                className="form-control w-100 cad-text-medium m-1 rounded-0"
                                placeholder={field?.street}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.street}
                              </span>
                            </div>
                            <div className="col-6 flex-column d-flex justify-content-start mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0 "
                                placeholder={field?.city}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.city}
                              </span>
                            </div>

                            <div className="col-6 flex-column d-flex justify-content-end mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0 "
                                placeholder={field?.state}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.state}
                              </span>
                            </div>

                            <div className="col-6 flex-column d-flex justify-content-end mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0 "
                                placeholder={field?.country}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.country}
                              </span>
                            </div>

                            <div className="col-6 flex-column d-flex justify-content-end mb-2">
                              <input
                                type="text"
                                style={{
                                  borderColor: themeObject.color,
                                }}
                                className="form-control cad-text-medium m-1 rounded-0 "
                                placeholder={field?.zipCode}
                              />
                              <span className="cad-text-small ps-1">
                                {field?.zipCode}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );

                case "footer":
                  return (
                    <div
                      // onDragOver={(e)=>handleElementDragOver(e, index)}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="w-100 mt-3"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          // element-edit
                          onClick={() => setEditableIndex(null)}
                          style={{
                            border: "1px solid red",
                          }}
                          className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0  rounded-3 w-100 d-flex justify-content-between`}
                        >
                          <span className={`${field.className} cad-fw-700`}>
                            {field.label}
                          </span>
                          <span className={field.subTitleClassName}>
                            {field.subTitle}
                          </span>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />

                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          // element-edit
                          onDoubleClick={() => setEditableIndex(index)}
                          style={{
                            borderTop: `1px solid ${themeObject.color}`,
                          }}
                          className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0 w-100 d-flex justify-content-between `}
                        >
                          <span className={`${field.className} cad-fw-700`}>
                            {field.label}
                          </span>
                          <span className={field.subTitleClassName}>
                            {field.subTitle}
                          </span>
                        </div>
                      )}
                    </div>
                  );

                case "details-heading":
                  return (
                    <div
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12"
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          onClick={() => setEditableIndex(null)}
                          className="element-edit position-relative  p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-large cad-fw-700">
                            {field.label}
                          </label>
                          <label className="cad-text-medium cad-fw-500">
                            {field.subTitle}
                          </label>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />
                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                        </div>
                      ) : (
                        <div
                          key={field.id}
                          // // dragable
                          // onDragStart={(e) => handleElementDragStart(e, index)}
                          // onDragOver={(e) => handleElementDragOver(e, index)}
                          onDoubleClick={() => setEditableIndex(index)}
                          className="position-relative p-2 gap-2 d-flex flex-column"
                        >
                          <label className="cad-text-large cad-fw-700">
                            {field.label}
                          </label>
                          <label className="cad-text-medium cad-fw-500">
                            {field.subTitle}
                          </label>
                        </div>
                      )}
                    </div>
                  );

                case "signeture":
                  return (
                    <div className="col-12 mt-5 pt-5">
                      <div
                        onDoubleClick={() => setEditableIndex(index)}
                        className="col-12 d-flex flex-column justify-content-end align-items-end pe-2"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {showButtons && showButtons ? (
                          <>
                            <SignaturePad
                              ref={sigCanvas}
                              penColor={themeObject.color}
                              canvasProps={{
                                className: " bg-white",
                                style: {
                                  borderBlockColor: themeObject.color,
                                  width: "35%",
                                  height: "120px",
                                  border: "1px solid",
                                  borderColor: themeObject.color,
                                },
                              }}
                            />
                            {/* Button to trigger save canvas image */}
                            <div className="w-25 position-relative d-flex justify-content-end cad-fw-600 cad-text-large pt-2">
                              <span>Signature</span>
                              {showButtons && (
                                <div
                                  style={{
                                    left: -80,
                                    top: 8,
                                  }}
                                  className="position-absolute "
                                >
                                  <span
                                    onClick={save}
                                    className="pointer me-1 cad-text-small bg-success ps-2 pe-2 rounded-3 text-white p-1"
                                  >
                                    Save
                                    {/* <CheckIcon onClick={save} /> */}
                                  </span>
                                  <span
                                    onClick={clear}
                                    className="pointer me-1 cad-text-small bg-danger ps-2 pe-2 rounded-3 text-white p-1"
                                  >
                                    Clear
                                    {/* <CloseIcon onClick={clear} /> */}
                                  </span>
                                  <span
                                    onClick={() => handleDeleteField(index)}
                                    className="pointer me-3 cad-text-small bg-danger ps-2 pe-2 rounded-3 text-white p-1"
                                  >
                                    Delete
                                    {/* <DeleteOutlineIcon onClick={() => handleDeleteField(index)} /> */}
                                  </span>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={imageURL}
                              alt=""
                              style={{
                                // borderBlockColor: themeObject.color,
                                width: "35%",
                                height: "120px",
                                border: imageURL ? "" : "1px solid",
                                padding: "26px",
                                paddingRight: "0px",
                                borderColor: themeObject.color,
                                objectFit: "contain",
                                // backgroundColor:"#fff"
                              }}
                            />

                            <div className="w-25 position-relative d-flex justify-content-end cad-fw-600 cad-text-large pt-2">
                              <span>Signature</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );

                case "table":
                  return (
                    <div
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOverr(e, index)}
                      onDragEnd={handleDragEnd}
                      className="col-12 "
                      style={{
                        cursor: "grab",
                      }}
                    >
                      {isBeingEdited ? (
                        <div
                          // element-edit
                          onClick={() => setEditableIndex(null)}
                          className={`element-edit position-relative   p-2 gap-2 d-flex flex-column`}
                        >
                          <table
                            key={field.id}
                            className="w-100 position-relation"
                          >
                            <thead>
                              <tr>
                                {field.columns.map((column, index) => (
                                  <th
                                    style={{
                                      backgroundColor:
                                        index === 0 ? "" : "#E5EAF4",
                                      border:
                                        index === 0
                                          ? ""
                                          : `1px solid ${themeObject?.color}`,
                                    }}
                                    className={`p-3 pt-2 pb-2  cad-fw-600 cad-text-small position-relation`}
                                    key={index}
                                  >
                                    {column.heading}
                                    {/* {index === 0 ? (
                                      ""
                                    ) : (
                                      <CloseIcon className="action-hover cad-text-large rounded-2 pointer ms-1" />
                                    )} */}
                                  </th>
                                ))}
                                {/* <span
                                  style={{
                                    display: "inline-block", // Ensure the element is block-level to apply transformations
                                    transform: "rotate(90deg)",
                                    width: 110,
                                    top: "45%",
                                    right: -44,
                                  }}
                                  className="d-flex align-items-center pointer cad-text-small position-absolute"
                                >
                                  Add column +
                                </span> */}
                              </tr>
                            </thead>
                            <tbody>
                              {renderRows(field.rows, isBeingEdited)}
                            </tbody>
                          </table>
                          <DeleteOutlineIcon
                            onClick={() => handleDeleteField(index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-danger p-1 delete-element pointer"
                            }
                          />

                          <ModeEditIcon
                            onClick={() => handleOpenEditElement(field, index)}
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50%",
                            }}
                            className={
                              "text-white bg-info p-1 edit-element pointer"
                            }
                          />
                          {/* <span
                            style={{}}
                            className="pointer m-auto cad-text-small"
                          >
                            Add row +
                          </span> */}
                        </div>
                      ) : (
                        <div
                          // element-edit
                          onClick={() => setEditableIndex(null)}
                          key={field.id}
                          onDoubleClick={() => setEditableIndex(index)}
                          className={` position-relative p-2 gap-2 d-flex flex-column`}
                        >
                          <table
                            key={field.id}
                            className="w-100 position-relation"
                          >
                            <thead>
                              <tr>
                                {field.columns.map((column, index) => (
                                  <th
                                    style={{
                                      backgroundColor:
                                        index === 0 ? "" : "#E5EAF4",
                                      border:
                                        index === 0
                                          ? ""
                                          : `1px solid ${themeObject?.color}`,
                                    }}
                                    className={`p-3 pt-2 pb-2  cad-fw-600 cad-text-small position-relation`}
                                    key={index}
                                  >
                                    {column.heading}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {renderRows(field.rows, isBeingEdited)}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  );

                default:
                  return null;
              }
            })}
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormCanvas;
