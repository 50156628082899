import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toster } from "../Login/Login";
import { set, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Button, CircularProgress} from "@mui/material";
import * as Yup from 'yup'
import { generateNewPassword } from "../../service/auth.service";
import { forgotPasswordCodes } from "../../Utils/StatusCode/index"
import { notify } from "../../App";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  console.log(id);


  const token = searchParams.get("token");

  console.log(token);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false)
  const [ showPassword, setShowPassword ] = useState(false)
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)

  const passwordSchema = Yup.object().shape({

  password: Yup.string()
  .required('Password is required')
  .matches(/[A-Z]/, 'Password must contain atleast one uppercase')
  .matches(/[a-z]/, 'Password must contain atleast one lowercase')
  .matches(/[@#&]/, 'Password must contain special character @,#,&')
  .min(8, 'password must be at least 8 characters'),

  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .matches(/[A-Z]/, 'Password must contain atleast one uppercase')
    .oneOf([Yup.ref('password'), null], 'Password do not match')
    .matches(/[a-z]/, 'Password must contain atleast one lowercase')
    .matches(/[@#&]/, 'Password must contain special character @,#,&')
    .min(8, 'password must be at least 8 characters'),
})


const {
  register: passwordRegister,
  handleSubmit: handlePasswordSubmit,
  formState: { errors: passErrors }, // Changed from passwordFormState to formState
  reset: resetPassword,
} = useForm({ mode: 'all', resolver: yupResolver(passwordSchema) })


  const onConfirmPasswordSubmit = async (value) => {
    console.log(value);
    
    const {status} = await generateNewPassword(value, id, token)
    console.log(status);
    if(status === 200){
      // <Alert severity="success"></Alert>
      notify("Password updated Successfully")
      navigate("/login")
    } else if (status === 500){
      // =======[ this condition is not exicuting] ========
      notify("Please try again", "error")
    }

    resetPassword()
  }




  return (
    <div className="login  container-fluid">

      <div className="container d-flex align-items-center w-100  h-100">
        <div className="d-flex w-100 justify-content-center align-items-center  h-75 p-0">
          <div className="col-left pe-3 pag-2 d-flex flex-column  col-7 h-50 d-flex align-items-start justify-content-center">
          <h1 style={{ fontSize: "50px" }} className="cad-fw-800">
              Cloudify<span className="cad-fw-500 text-primary">Docs</span>
            </h1>
            <p style={{ color: "#1c1e21" }} className="fs-4 cad-fw-600">
              Empowering Visions, Engineering Solutions: Your Cloudify Docs Partner.
            </p>
          </div>

          <div className=" col-right col-4 h-auto  p-3">
            
    <form
    onSubmit={handlePasswordSubmit(onConfirmPasswordSubmit)}
    className={`d-flex flex-column  justify-content-start h-100`}
  >
     <div >
      <label
        htmlFor="createPassword1"
        className="form-label text-primary"
      >
       Create password
      </label>
      <div className="input-group">
          <input
          type={showPassword ? 'text' : 'password'}
            className="form-control p-4 ps-3 border-primary "
            id="createPassword1"
            placeholder="Enter password"
            maxLength={20} // Set the maximum length to 20 characters
            {...passwordRegister("password")}
          />
          <button
            className="btn btn-outline-primary "
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <VisibilityOffIcon className="cad-text-large" /> : <RemoveRedEyeIcon className="cad-text-large" />}
          </button>
    </div>
      <label
        htmlFor="exampleInputPassword1"
        className="cad-text-small cad-fw-600 text-danger"
      >
        {passErrors.password?.message}
      </label>
    </div> 


    <div >
      <label
        htmlFor="exampleInputPassword1"
        className="form-label text-primary"
      >
       Confirm password
      </label>
      <div className="input-group">
          <input
          type={showConfirmPassword ? 'text' : 'password'}
            className="form-control p-4 ps-3 border-primary "
            id="createPassword1"
            placeholder="Confirm password"
            maxLength={20} // Set the maximum length to 20 characters
            {...passwordRegister("confirmPassword")}
          />
          <button
            className="btn btn-outline-primary "
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? <VisibilityOffIcon className="cad-text-large" /> : <RemoveRedEyeIcon className="cad-text-large" />}
          </button>
    </div>
      <label
        htmlFor="exampleInputPassword1"
        className="cad-text-small cad-fw-600 text-danger"
      >
        {passErrors.confirmPassword?.message}
      </label>
    </div>
    <Button
      type="submit"
      className={`${isPasswordLoading ? "opacity-75" : ""} btn mt-2 pt-2 fs-5 cad-fw-700 pb-2 button-contained`}>
      {isPasswordLoading ? <CircularProgress size={36} color="inherit" /> :
      "Submit"}
    </Button>
  </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
