import AddIcon from "@mui/icons-material/Add";
import { Paper, Skeleton, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import GlobalStateContext from "../../../Contexts/GlobalStateContext";
import GridCard from "./GridCard";
import NoData from "../../../Components/NoData";
import PrimaryButton from "../../../Components/PrimaryButton";
import {
  fetchKanbanData,
  updateMappingKanbanData,
} from "../../../Redux/Slice/kanbanSlice";
import Loader from "../../../Components/Loader/Loader";
import { updateKanbanStatus, updateTask } from "../../../service/task.service";
import { fetchTaskData } from "../../../Redux/Slice/taskSlice";
import Refresher from "../../../Components/Refresher";
import LightTooltip from "../../../Components/Tooltip/LightTooltip";
import SearchInput from "../../../Components/SearchInput/SearchInput";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  //   padding: grid * 2,
  margin: `0 0 8px 0`,
  borderRadius: "6px",

  // change background colour if dragging
  background: isDragging ? "#4CCD8D90" : "#ffffff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({});

const TaskGridView = ({ 
  themeColorsObject, 
  theme, 
  setOpenAddTaskModal ,
  setOperationTaskId
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKanbanData());
  }, []);

  const { kanbanData, isLoading, mappingKanbanData } = useSelector(
    (state) => state?.kanban
  );

  const [searchTerm, setSearchTerm] = useState("");

  const filteredTaskArray = mappingKanbanData.map((task) => {
    const filteredTasks = task.tasks.filter(
      (subTask) =>
        subTask.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subTask.employees[0]?.first_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    return { ...task, tasks: filteredTasks };
  });

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    // // console.log(destination, source, draggableId);

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    // Create a new copy of the mappingKanbanData array
    const newMappingKanbanData = mappingKanbanData.map((column) => ({
      ...column,
      tasks: [...column.tasks], // Create a new copy of the tasks array for each column
    }));

    // Find the index of the source and destination columns based on their droppableId (item.id)
    const sourceIndex = newMappingKanbanData.findIndex(
      (item) => item.id === source.droppableId
    );
    const destinationIndex = newMappingKanbanData.findIndex(
      (item) => item.id === destination.droppableId
    );

    // Find the source and destination columns
    const sourceColumn = newMappingKanbanData[sourceIndex];
    const destinationColumn = newMappingKanbanData[destinationIndex];

    // Remove the dragged item from the source column and insert it into the destination column
    const [removed] = sourceColumn.tasks.splice(source.index, 1);
    destinationColumn.tasks.splice(destination.index, 0, removed);

    // Dispatch an action to update the mappingKanbanData in the Redux store
    dispatch(updateMappingKanbanData(newMappingKanbanData));

    // ===================[ api call]=================
    const updatedTask = {
      status_xid: destination.droppableId,
    };

    try {
      const response = await updateKanbanStatus(draggableId, updatedTask);
      // console.log(response);

      dispatch(fetchTaskData());
    } catch (error) {
      console.error("Error Updateing ta:", error);
    }
  };

  // Calculate total number of tasks
  const totalTasks = filteredTaskArray.reduce(
    (total, category) => total + category.tasks.length,
    0
  );

  return (
    <div
      style={{
        height: "80vh",
      }}
      className="d-flex gap-3 flex-column"
    >
      <div className="d-flex p-3  w-100 justify-content-between">
        <div className="col-md-3 mb-md-0 col-12 d-flex align-itmes-center">
          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="d-flex w-100 align-items-center gap-2 justify-content-end">
          <div className="d-flex gap-3 align-items-center">
            <div
              className="task-progressbar pointer d-flex"
              style={{
                backgroundColor: "#E0E0E0",
                height: 20,
                borderRadius: 4,
                overflow: "hidden",
              }}
            >
              <LightTooltip
                color="#e74c3c"
                title={`${filteredTaskArray[0]?.title} tasks ${filteredTaskArray[0]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${
                      (filteredTaskArray[0]?.tasks?.length / totalTasks) * 100
                    }%`,
                    backgroundColor: "#e74c3c",

                    height: "100%",
                    transition: "0.5s",
                    // borderRadius: "4px 0px 0px 4px",
                  }}
                />
              </LightTooltip>

              <LightTooltip
                color="#E39C009d"
                title={`${filteredTaskArray[1]?.title} tasks ${filteredTaskArray[1]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${
                      (filteredTaskArray[1]?.tasks?.length / totalTasks) * 100
                    }%`,
                    backgroundColor: "#E39C009d",
                    height: "100%",
                    transition: "0.5s",
                    // borderRadius: 0,
                  }}
                />
              </LightTooltip>

              <LightTooltip
                color="#548CFF9d"
                title={`${filteredTaskArray[2]?.title} tasks ${filteredTaskArray[2]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${
                      (filteredTaskArray[2]?.tasks?.length / totalTasks) * 100
                    }%`,
                    backgroundColor: "#548CFF9d",
                    height: "100%",
                    transition: "0.5s",
                    // borderRadius: 0,
                  }}
                />
              </LightTooltip>

              <LightTooltip
                color="#34A8539d"
                title={`${filteredTaskArray[3]?.title} tasks ${filteredTaskArray[3]?.tasks?.length} / ${totalTasks}`}
              >
                <div
                  style={{
                    width: `${
                      (filteredTaskArray[3]?.tasks?.length / totalTasks) * 100
                    }%`,
                    backgroundColor: "#34A8539d",
                    height: "100%",
                    transition: "0.5s",
                    cursor: "pointer",
                    ":hover": {
                      scale: 1.5,
                    },
                    // borderRadius: "0px 4px 4px 0",
                  }}
                />
              </LightTooltip>
            </div>
          </div>

          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(fetchKanbanData())}
          />
          <PrimaryButton
            variant="contained"
            title="Add New Task"
            padding="0.3rem 0.6rem"
            icon={<AddIcon size={13} />}
            onClick={() => setOpenAddTaskModal(true)}
            fontSize="14px"
            width="200px"
          />
        </div>
      </div>

      <div
        className={`${
          false ? "align-items-center" : ""
        } pt-1  mb-2 me-2 w-100 h-100 justify-content-center d-flex `}
      >
        {false ? (
          <Loader />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            {filteredTaskArray?.map((item, ind) => (
              <Droppable key={item.id} droppableId={`${item.id}`}>
                {(provided, snapshot) => (
                  <div
                    className="col-md-3 col-12  d-flex  flex-column gap-3 p-2"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    // style={{
                    //   backgroundColor:
                    //     themeColorsObject.megaCardBackgroundColor,
                    //   color: themeColorsObject.titleText,
                    // }}
                    {...provided.droppableProps}
                  >
                    <div
                      style={{
                        backgroundColor:
                          item.title === "On Hold"
                            ? "#548CFF2d"
                            : item.title === "In Progress"
                            ? "#FCC23D2d"
                            : item.title === "Open"
                            ? "#FF39492d"
                            : "#4CA8032d",
                        fontWeight: "bold",
                        color: "#000",
                      }}
                      className="h-auto   rounded-3 ps-3 pe-3 p-2 "
                    >
                      <div className="d-flex  gap-3 mb-3  align-items-center">
                        <span
                          style={{
                            color: theme === "light" ? "#000" : "#fff",
                          }}
                          className="cad-fw-700 cad-text-large"
                        >
                          {item.title.toUpperCase()}
                        </span>
                        <Paper
                          className="ps-2 pe-2"
                          style={{
                            backgroundColor:
                              item.title === "On Hold"
                                ? "#548CFF"
                                : item.title === "In Progress"
                                ? "#E39C00"
                                : item.title === "Open"
                                ? "#FD394A"
                                : "#34A853",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          {item.tasks.length}
                        </Paper>
                      </div>

                      {/* Progress bar */}
                      <div
                        className="mb-3"
                        style={{
                          backgroundColor: "#E0E0E0",
                          height: 8,
                          borderRadius: 4,
                        }}
                      >
                        <div
                          style={{
                            width: `${(item.tasks.length / totalTasks) * 100}%`,
                            backgroundColor:
                              item.title === "On Hold"
                                ? "#548CFF"
                                : item.title === "In Progress"
                                ? "#E39C00"
                                : item.title === "Open"
                                ? "#e74c3c"
                                : "#34A853",
                            height: "100%",
                            borderRadius: 4,
                            transition: "0.5s",
                          }}
                        />
                      </div>

                      {item.tasks.length === 0 ? (
                        <></>
                      ) : (
                        item.tasks.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <GridCard
                                setOperationTaskId={setOperationTaskId}
                                  theme={theme}
                                  themeColorsObject={themeColorsObject}
                                  key={item.id}
                                  id={item.id}
                                  title={item.title}
                                  disc={
                                    item.description === null
                                      ? "NA"
                                      : item.description
                                  }
                                  tags={
                                    item?.tags.length === 0
                                      ? [{ title: "NA" }]
                                      : item.tags
                                  }
                                  assignee={item?.employees}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                      )}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default TaskGridView;
