import React, { useEffect } from "react";
import "./FAQ.css";
import FAQAccordian from "../FAQAccordian/FAQAccordian";
import faqImage from "../../Images/freqAskQuestion.png";
import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchFaqData } from "../../Redux/Slice/faqSlice";
import { useSelector } from "react-redux";

const Skelton = () => {
  return Array.from({ length: 3 }).map((_, index) => (
    <div key={index} className="col-12 col-md-6 col-lg-4 mt-4 mt-md-0 p-2 ">
      <div className="d-flex ms-2 justify-content-start gap-2 align-items-center  pt-2">
      <Skeleton className="w-50" variant="text" sx={{ fontSize: '1.8rem' }} />
      </div>
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={index} className="mt-3 ms-2 rounded-2 overflow-hidden ">
          <Skeleton variant="rounded"  height={45} />
        </div>
      ))}
    </div>
  ));
};

const FAQ = () => {

  const { faq, isLoading } = useSelector((state) => state.faq);


  return (
    <div className=" bg-white h-100">
      <div
        style={{
          width: "100%",
          height: "180px",
          position: "relative",
          backgroundImage: `url(${faqImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="pt-5 ps-4"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
          }}
        >
          <h2 className="fw-bold text-white">
            Frequently asked
            <br />
            questions
          </h2>
        </div>
      </div>

      <div className="d-flex  h-auto w-100 p-3">
        {isLoading ? <Skelton /> : <FAQAccordian accordionData={faq} />}
      </div>
    </div>
  );
};

export default FAQ;
