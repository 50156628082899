import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { comapanyBranding } from "../../service/company.branding.service";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { notify } from "../../App";
import { useDispatch } from "react-redux";
import { fetchBusinessDetails, fetchBusinessStorage, setBusinessLogo } from "../../Redux/Slice/business.slice";
import { AuthMe } from "../../Redux/Slice/auth/auth.slice";
import fetchAuthMe from "../../Redux/Slice/fetchAuthMe";

const CompanyBranding = () => {
  const dispatch = useDispatch();
  const { bussiness_logo, deatils } = useSelector((state) => state?.business);
  console.log(bussiness_logo);

  const { themeColorsObject } = useSelector((state) => state.theme);
  const [fileImage, setFileImage] = useState(null);
  const [discription, setDiscription] = useState(deatils?.description);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(deatils?.description);

  useEffect(() => {
    dispatch(fetchBusinessStorage());
    dispatch(fetchBusinessDetails())
    setDiscription(deatils?.description);
    setBusinessLogo(bussiness_logo)
    setSelectedImage(
      bussiness_logo
        ? process.env.REACT_APP_DOWLOAD_URL + "/" + bussiness_logo
        : null
    );
  }, [deatils?.description, dispatch, bussiness_logo]);

  const handleFileDrop = (files) => {
    setFileImage(files[0].name);
    // console.log('Dropped files:',fileImage);
    // // console.log('Dropped files:', files[0].name);
    // // console.log("entered");
  };

  // console.log(fileImage);

  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    // Handle the dropped files here
    setFileImage(files[0]);
    console.log(fileImage);
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setSmallImageData(file);
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setSelectedImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const onSubmit = async (e) => {
    setIsLoading(true);
    // console.log(fileImage);
    e.preventDefault();
    const formData = new FormData();
    formData.append("description", discription);

    if (fileImage) {
      formData.append("business_logo", fileImage);
    }

    try {
      const res = await comapanyBranding(formData);
      if (res.status === 202) {
        notify(res?.data?.message);
        setIsLoading(false);
        setDiscription("");
        dispatch(AuthMe());
        dispatch(fetchAuthMe());
        setSelectedImage(
          process.env.REACT_APP_DOWLOAD_URL + "/" + bussiness_logo
        );
      } else if (res.status === 500) {
        setIsLoading(false);
        console.log("error");
      }
    } catch (error) {
      setIsLoading(false);
      notify(error?.data?.error?.message, "error");
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.titleText,
      }}
      className="mt-1 rounded-3 pb-3 cad-shadow overflow-auto mb-4"
    >
      <div className=" p-3">
        <h4 className="cad-text-medium">Upload Your Logo</h4>
        <p className="cad-text-small">
          Showcase your brand's unique identity by uploading your logo.
          Personalize your experience and stand out from the crowd.
        </p>

        {/* <Company className="mt-3 mb-0 mx-4" onFileDrop={handleFileDrop} /> */}

        <div
          style={{
            height: 180,
          }}
          className="w-100 p-3 "
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div
            className="h-100 rounded-3 d-flex justify-content-center align-items-center"
            style={{
              border: "2px dashed grey",
            }}
          >
            <input
              type="file"
              id="fileInput"
              style={{
                display: "none",
              }}
              onChange={(e) => handleFiles(e.target.files)}
            />
            <label htmlFor="fileInput" className="pointer">
              {dragging ? "Drop the files here" : "Drag and drop files here"}
            </label>
          </div>
        </div>
        <p className="cad-text-small">
          Please upload image of resolution 40x150 px
        </p>
      </div>
      <div
        style={{ position: "relative" }}
        className="d-flex justify-content-start align-items-start ps-3  flex-column"
      >
        {selectedImage && (
          <img
            alt={fileImage?.name}
            width={"270px"}
            height={"70px"}
            src={selectedImage}
          />
        )}
        {selectedImage && fileImage && (
          <span className="cad-text-small mt-2 fw-bold text-secondary">
            file name: {fileImage?.name}
          </span>
        )}
        {selectedImage && fileImage && (
          <span className="cad-text-small fw-bold text-secondary">
            file size: {(fileImage?.size / (1024 * 1024)).toFixed(2)} mb
          </span>
        )}
        {selectedImage && (
          <DeleteForeverOutlinedIcon
            onClick={() => {
              setSelectedImage(null);
              setFileImage(null);
            }}
            style={{ position: "absolute", top: 0, left: 300 }}
            className=" fs-5 text-danger action-hover rounded-2 pointer"
          />
        )}
      </div>
      <hr className="my-3" />
      {/* <div className=" p-3">
        <h4 className="cad-text-medium">Description</h4>
        <textarea
          onChange={(e) => setDiscription(e.target.value)}
          value={discription === null ? "Not metioned" : discription}
          rows={4}
          className="cad-text-small w-100 rounded-3 form-control"
        />
      </div> */}
      <div className=" d-flex justify-content-center">
        <Button variant="contained" className="col-2" type="submit">
          {isLoading ? "Loading..." : "Save Details"}
        </Button>
      </div>
    </form>
  );
};

export default CompanyBranding;
