import api from "./api.service";
let formDataConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

let headers = {
  "Content-Type": "multipart/form-data",
};

export const createOrGetOneOnOneChat = async (reciverId, signal) => {
  // console.log(reciverId);
  try {
    return await api.post(`/chat/c/${reciverId}`, { signal: signal });
  } catch (error) {
    throw error;
  }
};

export const getContacts = async () => {
  try {
    return await api.get(`/chat/`);
  } catch (error) {
    throw error;
  }
};

export const getContactsWithoutSort = async () => {
  try {
    return await api.get(`/chat/`);
  } catch (error) {
    throw error;
  }
};

export const createMessage = async (chatId, data, signal) => {
  // console.log(chatId);
  try {
    return await api.post(`/chat/message/${chatId}`, data, { signal: signal });
  } catch (error) {
    throw error;
  }
};

export const createNewGroup = async (groupDetails) => {
  for (const pair of groupDetails.entries()) {
    // console.log(pair[0] + ": " + pair[1]);
  }
  try {
    const res = await api.post(`/chat/group`, groupDetails, {
      headers,
    });
    // console.log(res);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getChatGroups = async (id) => {
  try {
    return await api.get(`/chat/group/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getGroupInfo = async (chatId) => {
  // console.log(chatId);
  try {
    return await api.get(`/chat/group/${chatId}`);
  } catch (error) {
    throw error;
  }
};
export const getMessage = async (chatId, signal) => {
  // console.log(chatId);
  try {
    return await api.get(`/chat/message/${chatId}`, { signal });
  } catch (error) {
    throw error;
  }
};
export const getChatById = async (chatId, signal) => {
  try {
    return await api.get(`/chat/${chatId}`, { signal });
  } catch (error) {
    throw error;
  }
};
