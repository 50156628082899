import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getArchiveClientData } from "../../service/client.service";



export const getArchiveClient = createAsyncThunk("getArchiveClient", async (params) => {
    try {
        const res = await getArchiveClientData(params);
        return res.data;
    } catch (err) {
        throw new Error(`Error fetching data: ${err.message}`);
    }
});

const getArchiveClientSlice = createSlice({
    name: "archiveClientData",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        clients: [], // Initialize rows as an empty array
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getArchiveClient.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(getArchiveClient.fulfilled, (state, action) => {
                state.isLoading = false;
                state.clients = action.payload;
            })
            .addCase(getArchiveClient.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message; // Store the error message
            });
    },
})


export default getArchiveClientSlice.reducer;

