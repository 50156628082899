import noData from "../Images/no-data.png"

const NoData = ({message, height, width, fontSize }) => {
  return (
  <div
    className="d-flex gap-3 m-auto  justify-content-center flex-column align-items-center"
    style={{ height: height? height : "65vh" }}
  >
    <img width={width? width : 100} src={noData} alt={message}/>
    <span className={`${fontSize ? fontSize : "cad-fw-600" } text-primary`}>{message}</span>
  </div>
  )
}

export default NoData