import React, { useEffect, useState } from "react";
import { Avatar, Paper, Button } from "@mui/material";
import { Box } from "@mui/system";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import PrimaryButton from "../../../Components/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { BsCaretLeft } from "react-icons/bs";
import { AiOutlineLeft } from "react-icons/ai";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCompany } from "../../../service/company.service";
import Select from "../../../Components/Select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchGlobalClient } from "../../../Redux/Slice/globalClientSlice";
import { fetchTag } from "../../../Redux/Slice/tagsSlice";
import { toast, ToastContainer } from "react-toastify";
import { fetchGlobalTags } from "../../../Redux/Slice/globlalTags";
import { fetchGlobalCompany } from "../../../Redux/Slice/globalCompanySlice";
import { getCompanyData } from "../../../Redux/Slice/company.service.slice";

const AddCompany = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGlobalClient());
    dispatch(fetchGlobalTags());
  }, []);

  const clientData = useSelector((state) => state?.globalClient?.clients);
  const tags = useSelector((state) => state?.globalTags?.tags);
  // console.log("global tags", tags);

  const [selectClient, setSelectClient] = useState([]);
  const [selectTags, setSelectTags] = useState([]);

  const selectOption = (selectedOptions) => {
    // console.log("working");
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectClient(selectedValues);
  };

  const selectTagsOption = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectTags(selectedValues);
  };

  const contentData = [
    {
      title: "Company Information",
      fields: [
        {
          label: "Registered name",
          type: "text",
          required: true,
          name: "company_name",
          error_msg: true,
        },
        {
          label: "Email Address",
          type: "email",
          required: true,
          name: "email_address",
          error_msg: true,
        },
        {
          label: "Web Address",
          type: "text",
          required: false,
          name: "web_address",
          error_msg: true,
        },
        {
          label: "Industry",
          type: "text",
          name: "industry",
          error_msg: true,
        },
        {
          label: "Phone Number",
          type: "text",
          name: "phone_number",
          error_msg: true,
        },
      ],
    },
    {
      title: "Address Details",
      fields: [
        {
          label: "Country",
          type: "text",
          name: "country",
          error_msg: true,
        },
        {
          label: "City",
          type: "text",
          name: "city",
          error_msg: true,
        },
        {
          label: "Pincode",
          type: "text",
          name: "pincode",
          error_msg: true,
        },
      ],
    },
    {
      title: "Other Information",
      fields: [
        {
          label: "Add Client",
          type: "multiple-select",
          funtionToUse: selectOption,
          options: Array.isArray(clientData)
            ? clientData // Filter out elements with details.length <= 0
              .map((ele) => ({
                label: ele.last_name
                  ? ele.first_name + " " + ele.last_name
                  : ele.first_name,
                value: ele.id,
                color: "blue",
              }))
            : [],
        },
        {
          label: "Tags",
          type: "multiple-select",
          funtionToUse: selectTagsOption,
          options:
            tags?.length > 0
              ? tags?.map((ele) => ({
                label: ele.title,
                value: ele.id,
                color: "blue",
              }))
              : [],
        },
      ],
    },
  ];

  const [previewImage, setPreviewImage] = useState("");

  const userSchema = Yup.object().shape({
    company_name: Yup.string()
      .required("First Name is required")
      .matches(/^\S*$/, "First Name cannot contain spaces"),

    web_address: Yup.string().required("Web address is required"),

    phone_number: Yup.string()
      .notRequired() // Mark the field as optional
      .matches(/^[0-9]*$/, "Phone Number must contain only numbers")
      .test(
        "is-min-10-characters",
        "Phone Number must be at least 10 characters",
        (value) => {
          if (!value) {
            return true; // Pass the validation if the field is empty
          }
          return value.length >= 10;
        }
      ),

    pincode: Yup.string(),

    profile_photo: Yup.mixed().test(
      "fileType",
      "Invalid file type",
      (value) => {
        if (!value || value.length === 0) {
          setPreviewImage(""); // Clear the preview when no file is selected
          return true; // Allow empty value (no file selected)
        }

        if (value[0].type.startsWith("image/")) {
          // Set the previewImage state with the selected image
          setPreviewImage(URL.createObjectURL(value[0]));
          return true; // Valid image file
        } else {
          setPreviewImage(""); // Clear the preview when an invalid file is selected
          return false; // Invalid file type
        }
      }
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(userSchema) });

  // console.log(errors);

  const onSubmit = async (values) => {
    // reset()
    // return // console.log(formData);
    // console.log("Form submit event triggered");
    console.log(values);

    // Create a FormData object
    const formDataToSend = new FormData();

    // Append the data from each field
    values.profile_photo[0] &&
      formDataToSend.append("profile_photo", values.profile_photo[0]);
    formDataToSend.append("company_name", values.company_name);
    formDataToSend.append("web_address", values.web_address);
    formDataToSend.append("email_address", values.email_address);
    values.phone_number &&
      formDataToSend.append("phone_number", values.phone_number);
    values.country && formDataToSend.append("country", values.country);
    values.city && formDataToSend.append("city", values.city);
    values.pincode && formDataToSend.append("postal_code", values.pincode);
    values.industry && formDataToSend.append("industry", values.industry);

    if (selectClient.length > 0) {
      selectClient.forEach((contacts, index) => {
        formDataToSend.append(`contacts[${index}]`, contacts);
      });
    }

    if (selectTags.length > 0) {
      selectTags.forEach((tags, index) => {
        formDataToSend.append(`tags[${index}]`, tags);
      });
    }

    // // console.log(formDataToSend);



    try {
      const response = await createCompany(formDataToSend);
      // console.log(response);
      dispatch(fetchGlobalCompany());
      dispatch(getCompanyData());
      navigate("/contacts");
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "#f1f2f6",
      }}
      className=" h-100 w-100 p-3 d-flex overflow-hidden flex-column justify-content-start align-items-start "
    >
      <Box
        className="border-bottom rounded-top-3"
        sx={{
          borderBottom: 0,
          padding: 0,
          backgroundColor: "#fff",
          width: "100%",
          overflow: "scroll",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-3">
            <Link
              to="/contacts"
              className="mb-4 d-flex align-items-center gap-1 text-decoration-none"
            >
              <AiOutlineLeft className="pointer" size={18} />
              <label className="pointer">back to contacts</label>
            </Link>
            <h4 className="cad-text-dark cad-fw-600">Add Company</h4>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <div
                style={{
                  position: "relative",
                }}
              >
                <Avatar
                  className="m-1"
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "4px solid #E9E9E9",
                  }}
                  alt="Remy Sharp"
                  src={previewImage}
                />

                <input
                  type="file"
                  id="profile-image-input"
                  accept="image/*"
                  style={{
                    display: "none",
                  }}
                  {...register("profile_photo")}
                />
                {errors.profile_photo && (
                  <div className="text-danger">
                    {errors.profile_photo.message}
                  </div>
                )}

                <label
                  style={{
                    position: "absolute",
                    bottom: "0.9rem",
                    left: "4.9rem",
                    height: "30px",
                    width: "30px",
                    backgroundColor: "#E9E9E9",
                    padding: "4px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  htmlFor="profile-image-input"
                >
                  <CameraAltTwoToneIcon
                    sx={{
                      position: "absolute",
                      bottom: "0rem",
                      right: "0rem",
                      height: "30px",
                      width: "30px",
                      backgroundColor: "#E9E9E9",
                      padding: "4px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </label>
              </div>
              <label className="cad-text-medium mb-1 cad-text-light-grey cad-fw-500">
                The maximum size of profile picture is 2MB.
              </label>
            </div>
          </div>
          {contentData.map((section, index) => (
            <div key={index}>
              <hr className="m-0 hr-border" />
              <div className="p-3">
                <h5 className="cad-text-grey mb-3 cad-text-large">
                  {section.title}
                </h5>
                <div className="row">
                  {section.fields.map((field, fieldIndex) => (
                    <div
                      className={
                        field.type === "checkboxGroup"
                          ? "col-4 mb-2"
                          : "col-4 mb-2"
                      }
                      key={fieldIndex}
                    >
                      <label
                        className={`cad-text-medium mb-1 cad-text-light-grey cad-fw-500 ${field.required ? "text-danger" : ""
                          }`}
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-danger"> *</span>
                        )}
                      </label>
                      {field.type === "select" ? (
                        <select
                          defaultValue={"none"}
                          className={`form-control w-100  ${field.required ? "required" : ""
                            }`}
                          {...register(field.name)}
                        >
                          <option value="">none</option>
                          {field.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "multiple-select" ? (
                        <Select
                          isMulti={true}
                          options={field.options}
                          setSelected={field.funtionToUse}
                        />
                      ) : (
                        <input
                          className={`form-control w-100 ${field.required ? "required" : ""
                            }`}
                          type={field.type}
                          {...register(field.name)}
                        />
                      )}
                      {field.error_msg && (
                        <div
                          htmlFor={field.name}
                          className="form-label text-primary form-text text-danger"
                        >
                          {errors[field.name]?.message}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-center py-3 m-auto w-25">
            <Button className="button-contained" type="submit">
              Add
            </Button>
          </div>
        </form>
      </Box>
    </Paper>
  );
};

export default AddCompany;
