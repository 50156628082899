import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import paid from "../../Images/paid.png"
import notpaid from "../../Images/notpaid.png"

function formatUTCDate(utcDateString) {
  const date = new Date(utcDateString);

  const options = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };

  return date.toLocaleDateString("en-GB", options);
}

function getCurrentDateUTC() {
  const now = new Date();
  return formatUTCDate(now.toISOString());
}

function createData(Package, Recurring) {
  return { Package, Recurring };
}

const Invoice = () => {
  const pdfRef = useRef();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { totalPages, payments } = useSelector(
    (state) => state?.paymentHistory
  );

  // Filter payments with the matching id
  const filteredPayments = payments.find((payment) => payment.id === id);

  const rows = [
    createData(
      "PLAN ",
      filteredPayments?.checkout_plans?.package?.title,
      // filteredPayments?.payment_method_types[0],
      // filteredPayments?.payment_status
    ),
    createData(
      "START DATE ",
      formatUTCDate(filteredPayments?.transactions?.effective_from),
      // filteredPayments?.payment_method_types[0],
      // filteredPayments?.payment_status
    ),
    createData(
      "END DATE",
      formatUTCDate(filteredPayments?.transactions?.effective_to),
      // filteredPayments?.payment_method_types[0],
      // filteredPayments?.payment_status
    ),
    createData(
      "TRANSACTION ID",
      filteredPayments?.transactions?.transaction_xid,
      // filteredPayments?.payment_method_types[0],
      // filteredPayments?.payment_status
    ),
    createData(
      "PAYMENT METHOD",
      typeof filteredPayments?.payment_method_types ==="string"? JSON.parse(filteredPayments?.payment_method_types)[0] === "card" && "Card" : filteredPayments?.payment_method_types[0] ,
      // filteredPayments?.payment_method_types[0],
      // filteredPayments?.payment_status
    ),
  ];
  // Optionally set loading to true while filtering
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      console.log(filteredPayments);
      setIsLoading(false);
    }, 500);
  }, [id]);

  const createGradientCanvas = (width, height) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    // Create gradient
    const gradient = ctx.createLinearGradient(0, 0, width, height);
    gradient.addColorStop(0, "hsla(203, 69%, 84%, 0.5)");
    gradient.addColorStop(1, "hsla(18, 76%, 85%, 0.5)");

    // Fill with gradient
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);

    return canvas;
  };

  const downloadPdf = () => {
    setLoading(true); // Set loading state to true
    const input = pdfRef.current;
    const pdf = new jsPDF("p", "pt", "a4", true); // 'p' for portrait
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Create gradient canvas
    const gradientCanvas = createGradientCanvas(pdfWidth, pdfHeight);
    const gradientData = gradientCanvas.toDataURL("image/png");

    html2canvas(input, { scale: 5 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Define padding
      const padding = 10;


      // Add gradient background
      pdf.addImage(gradientData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Calculate the scaling factor to fit the image in the PDF with padding
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratioX = (pdfWidth - 2 * padding) / imgWidth;
      const ratioY = (pdfHeight - 2 * padding) / imgHeight;
      const ratio = Math.min(ratioX, ratioY);
      const imgX = padding; // Add left padding
      const imgY = padding; // Add top padding

      // Add the content image
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );

      pdf.save("invoice.pdf");
      setLoading(false); // Set loading state to false after download is complete
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
        overflow:"auto",
        padding:20,
        background:
          "linear-gradient(90deg, hsla(203, 69%, 84%, 0.5) 0%, hsla(18, 76%, 85%, 0.5) 100%)",
      }}
    >
      {isLoading ? (
        <span className="cad-fw-600">Generating invoice please wait...</span>
      ) : (
        <>
          <div
            style={{
              background: "#fff",
              width: 700,
              padding: "20px 20px 20px 20px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              position:"relative"

            }}
            ref={pdfRef}
            className="container invoice rounded-1"
          >
            <h1
                  style={{ fontSize: "105px", color: "#4ccd8d", position:"absolute", top:250, left:-0, opacity:0.1, transform: "rotate(-30deg)" }}
                  className="cad-fw-800"
                >
                  Cloudify<span className="cad-fw-500 text-primary">Docs</span>
                </h1>

            <div
              style={{ borderBottom: "1px solid #ddd" }}
              className="d-flex justify-content-between pb-3"
            >
              <div className="d-flex flex-column">
                <h1
                  style={{ fontSize: "25px", color: "#4ccd8d" }}
                  className="cad-fw-800"
                >
                  Cloudify<span className="cad-fw-500 text-primary">Docs</span>
                </h1>
                <span className="cad-text-small invoice cad-fw-600 text-secondary">
                  Robert Speck Parkway
                </span>
                <span className="cad-text-small invoice cad-fw-600 text-secondary">
                  Mississauga, ON, L4Z 3GI
                </span>
                {/* <span className="cad-text-small cad-fw-600 text-secondary">
              Email: info@caditsolutions.com
            </span> */}
              </div>

              <div className="d-flex flex-column justify-content-around">
                <h2 className="cad-text-small cad-fw-600 text-dark text-end invoice">
                  INVOICE
                </h2>
                <span className="cad-text-small invoice cad-fw-600 text-secondary text-end">
                  {formatUTCDate(filteredPayments?.transactions?.createdAt)}
                </span>
                <span className="cad-text-small invoice cad-fw-600 text-secondary text-end">
                  Invoice No: 
                  {filteredPayments?.stripe_checkout_object?.invoice}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column pt-3 col-6 ">
                <span className="cad-text-small cad-fw-700 invoice ">Bill to,</span>
                <span className="cad-text-small cad-fw-700 invoice">
                  {filteredPayments?.principal[0]?.first_name}{" "}
                  {filteredPayments?.principal[0]?.last_name}
                </span>
                <span className="cad-text-xsmall text-secondary cad-fw-500 invoice">
                  {filteredPayments?.principal[0]?.email_address}
                </span>
              </div>

              <div className="d-flex flex-column pt-3 col-6 justify-content-center">
                <span className="cad-text-small text-secondary cad-fw-700 invoice">
                  Amount paid
                </span>
                <span className="cad-text-large cad-fw-700 invoice">
                  {filteredPayments?.checkout_plans?.currency === "INR" ? "₹" : filteredPayments?.checkout_plans?.currency}{" "}
                  {filteredPayments?.checkout_plans?.unit_amount}
                </span>
              </div>
            </div>
<hr/>
            <TableContainer className="mt-2 mb-3">
              <Table sx={{ minWidth: 650 }}>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                      className="cad-text-xsmall  invoice p-1"
                        sx={{ borderBottom: "none" }}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {row.Package}
                      </TableCell>
                      <TableCell
                      className="cad-text-small cad-fw-500 invoice p-1" sx={{ borderBottom: "none" }} align="left">
                        {row.Recurring}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <hr/>
            
            <p className="cad-text-xsmall cad-fw-500 text-dark invoice">We appreciate your business and are committed to providing you with the highest level of service. If you have any questions or need further assistance, please do not hesitate to get in touch with us.<br />If you're unsure which plan best suits your needs, please reach out to us for personalized assistance and guidance. Our team is here to help you make the most informed decision. We value your satisfaction and are ready to provide any support you require.</p>

            
            


            <div className="d-flex flex-column justify-content-between pt-2 position-relative">
              <span className="cad-text-xsmall cad-fw-500 text-dark invoice">
                8546921576
              </span>
              <span className="cad-text-xsmall cad-fw-500 text-dark invoice">
                info@caditsolutions.com
              </span>
              <span className="cad-text-xsmall cad-fw-500 text-dark invoice">
                www.cloudifydocs.com
              </span>
              <span className="cad-text-small cad-fw-700 text-primary invoice">
                Thank you!
              </span>

              <img style={{position:"absolute", right:50, bottom:10}} src={filteredPayments?.payment_status === "paid" ? paid : notpaid} width={80} />


            </div>

            {/* Footer */}
            <div
              style={{
                marginTop: "20px",
                borderTop: "1px solid #ddd",
                paddingTop: "10px",
                textAlign: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span className="cad-text-xsmall cad-fw-500 text-secondary invoice">
                Note : This is a computer-generated invoice and does not require
                a signature.
              </span>
              <span className="cad-text-xsmall cad-fw-500 text-secondary invoice">
                © 2024 CloudifyDocs.
              </span>
            </div>
            {/* Add your invoice content here */}
          </div>
          <Button
            onClick={downloadPdf}
            className="cad-text-small mt-3"
            variant="contained"
            color="primary"
          >
            {loading ? "Loading..." : "Download"}
          </Button>
        </>
      )}
    </div>
  );
};

export default Invoice;
