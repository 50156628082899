import React, { useState } from "react";
import * as Yup from "yup";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, CircularProgress } from "@mui/material";
import { ReportGmailerrorred } from "@mui/icons-material";
import Swal from "sweetalert2";
import { forgotPassword } from "../../service/auth.service";
import { Slide, ToastContainer, Zoom, toast } from "react-toastify";
import { notify } from "../../App";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EmailField = ({
  setForgotPasswordEmailId,
  setAuthorized,
  setForgotPassword,
  handleClickOpen,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const emailSchema = Yup.object().shape({
    email_address: Yup.string()
      .required("Email is required")
      .email("Please enter a valid email address")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      ),
  });

  const {
    register: emailRegister,
    handleSubmit: handleEmailSubmit,
    formState: { errors: emailErrors }, // Changed from emailFormState to formState
    reset: resetEmail,
  } = useForm({ mode: "all", resolver: yupResolver(emailSchema) });

  const onSubmit = async (value) => {
    setForgotPasswordEmailId(value.email_address);
try {
  const { status, data } = await forgotPassword(value);
console.log(status);

if (status === 200) {
  setAuthorized(true);
  handleClickOpen(status);
} else {
  notify("Please check your email");
  // Swal.fire({
  //   title: 'Unauthorized User',
  //   customClass: {
  //     title: 'cad-text-dark',
  //     confirmButton:"bg-danger" // Define a custom class for the title
  //   },
  //   confirmButtonWidth:"w-50"

  //   // confirmButtonColor: '#4CCD8D',
  //   // You can customize buttons, add HTML content, and more with SweetAlert options
  // });
}

  
} catch (error) {

  notify(error?.data?.message, "error")
  
}
    
    setAuthorized(false);
    setForgotPassword(false);
    // console.log(value);
    resetEmail();
  };

  return (
    <form
      onSubmit={handleEmailSubmit(onSubmit)}
      className={`d-flex flex-column  justify-content-start h-100`}
    >
      <ToastContainer
        transition={Zoom}
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="mb-3 ">
        <label htmlFor="exampleInputEmail1" className="form-label text-primary">
          Email address
        </label>
        <input
          type="text"
          style={{
            padding: "12px",
          }}
          className="form-control ps-3  p-4 border-primary"
          id="exampleInputEmail1"
          placeholder="Email address"
          aria-describedby="emailHelp"
          {...emailRegister("email_address")}
        />

        <div
          htmlFor="exampleInputEmail1"
          className="form-label text-primary form-text text-danger  cad-fw-600"
        >
          {emailErrors.email_address?.message}
        </div>
      </div>
      <Button
        type="submit"
        className={`${
          isLoading ? "opacity-75" : ""
        } btn mt-2 pt-2 fs-5 cad-fw-700 pb-2 button-contained`}
      >
        {isLoading ? <CircularProgress size={36} color="inherit" /> : "Next"}
      </Button>

      <Link
        onClick={() => {
          setAuthorized(false);
          setForgotPassword(false);
          navigate("/");
        }}
        className="cad-text-small cad-fw-600 text-primary text-center mt-3 action-hover p-1 rounded-1"
      >
        Back to login
      </Link>
    </form>
  );
};

export default EmailField;
