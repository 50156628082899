import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { updateForm, updateFormData } from "../../../Redux/Slice/getFormsSlice";
import { Switch } from "@mui/material";

const inputProperties = Object.freeze({
  LABEL: 'label',
  PLACEHOLDER: 'placeHolder',
  DETAILSTITLE: 'detailsTitle',
  FIELDONE: 'fieldOne',
  FIELDTWO: 'fieldTwo',
  FIELDTHREE: 'fieldThree',
  FIELDFOUR: 'fieldFour',
  FIELDFIVE: 'fieldFive',
  FIELDSIX: 'fieldSix',
  SUBLABEL: 'subLabel'
});

const EditBar = ({
  editableObject,
  formData,
  setFormData,
  setEditableObject,
  setThemeObject,
  editableIndex,
  handleKeyUp
}) => {
  // const [editedObject, setEditedObject] = useState({ ...editableObject });
  // const [selectedHeadingSize, setSelectedHeadingSize] = useState("h3"); // State to manage the selected
  // const [selectedAlign, setSelectedAlign] = useState("align-items-center"); // State to manage the selected size
  // const [option, setOption] = useState(""); // State to manage the selected size



  const dispatch = useDispatch();
  function replaceSpacesWithUnderscores(str) {
    return str.replace(/\s+/g, "_");
  }

  const handleSizeChange = (e) => {
    setEditableObject({
      ...editableObject,
      className: e.target.value,
      subTitleClassName:
        e.target.value === "h3"
          ? "cad-text-medium"
          : e.target.value === "h4"
            ? "cad-text-small"
            : "cad-text-large",
    });

    handleDataUpdate({
      ...editableObject,
      className: e.target.value,
      subTitleClassName:
        e.target.value === "h3"
          ? "cad-text-medium"
          : e.target.value === "h4"
            ? "cad-text-small"
            : "cad-text-large",
    });
  };

  const handleAlignChange = (e) => {
    setEditableObject({
      ...editableObject,
      alignment: e.target.value,
    });
    handleDataUpdate({ ...editableObject, alignment: e.target.value });
  };

  const handleTitleChange = (e) => {
    setEditableObject({
      ...editableObject,
      label: e.target.value,
    });
    handleDataUpdate({ ...editableObject, label: e.target.value });
  };

  const handleSubtitleChange = (e) => {
    setEditableObject({
      ...editableObject,
      subTitle: e.target.value,
    });
    handleDataUpdate({ ...editableObject, subTitle: e.target.value });
  };

  const handleLabelChange = (e, titleToBeChanged) => {
    setEditableObject({
      ...editableObject,
      [titleToBeChanged]: e.target.value,
      name: replaceSpacesWithUnderscores(e.target.value),
    });

    const updatedObject = {
      ...editableObject,
      [titleToBeChanged]: e.target.value,
      name: replaceSpacesWithUnderscores(e.target.value),
    };
    handleDataUpdate(updatedObject);
  };

  const handlePlacehoderChange = (e) => {
    setEditableObject({
      ...editableObject,
      placeHolder: e.target.value,
    });

    const updatedObject = {
      ...editableObject,
      placeHolder: e.target.value,
    };
    handleDataUpdate(updatedObject);
  };

  const handleAddressSubtitleChange = (e, subtitle) => {
    let key =
      subtitle === editableObject?.street
        ? "street"
        : subtitle === editableObject?.city
          ? "city"
          : subtitle === editableObject?.state
            ? "state"
            : subtitle === editableObject?.country
              ? "country"
              : "zipCode";

    let keyName =
      subtitle === editableObject?.street
        ? "Street"
        : subtitle === editableObject?.city
          ? "City"
          : subtitle === editableObject?.state
            ? "State"
            : subtitle === editableObject?.country
              ? "Country"
              : "Zip Code";

    setEditableObject({
      ...editableObject,
      [key]: e.target.value, // Using computed property names to set the key
      // [keyName]: replaceSpacesWithUnderscores(e.target.value)
    });

    const updatedObject = {
      ...editableObject,
      [key]: e.target.value,
      // [keyName]: replaceSpacesWithUnderscores(e.target.value)
    };
    handleDataUpdate(updatedObject);
  };

  const addOptions = () => {
    setEditableObject({
      ...editableObject,
      options: [...editableObject.options, { option: "option" }],
    });

    const updatedObject = {
      ...editableObject,
      options: [...editableObject.options, { option: "option" }],
    };
    handleDataUpdate(updatedObject);
  };

  const deleteOption = (index) => {
    const updatedOptions = [...editableObject.options];
    updatedOptions.splice(index, 1); // Remove the option at the specified index

    setEditableObject({
      ...editableObject,
      options: updatedOptions,
    });

    const updatedObject = {
      ...editableObject,
      options: updatedOptions,
    };
    handleDataUpdate(updatedObject);
  };

  const addColumn = () => {
    const updatedRows = editableObject.rows.map((row) => [...row, ""]);

    setEditableObject({
      ...editableObject,
      columns: [...editableObject.columns, { heading: "New column" }],
      rows: updatedRows,
    });

    const updatedObject = {
      ...editableObject,
      columns: [...editableObject.columns, { heading: "New column" }],
      rows: updatedRows,
    };
    handleDataUpdate(updatedObject);
  };


  const addInputColumns = () => {

    setEditableObject({
      ...editableObject,
      inputsArray: [...editableObject.inputsArray, { fieldInput: "New column" }],
    });

    const updatedObject = {
      ...editableObject,
      inputsArray: [...editableObject.inputsArray, { fieldInput: "New column" }],
    };
    handleDataUpdate(updatedObject);
  };

  const addRow = () => {
    // Create a new row with the first cell initialized as "New Row"
    const newRow = [
      "New Row",
      ...editableObject.columns.slice(1).map(() => ""),
    ]; // Initialize other cells as empty strings

    // Append the new row to the existing rows array
    const updatedRows = [...editableObject.rows, newRow];

    // Update the editableObject state with the new rows
    setEditableObject({
      ...editableObject,
      rows: updatedRows,
    });

    // Pass the updated object to the handleDataUpdate function
    const updatedObject = {
      ...editableObject,
      rows: updatedRows,
    };
    handleDataUpdate(updatedObject);
  };

  const deleteColumn = (index) => {
    const updatedColumns = [...editableObject.columns];
    updatedColumns.splice(index, 1); // Remove the column at the specified index

    const updatedRows = editableObject.rows.map((row) => {
      const updatedRow = [...row];
      updatedRow.splice(index, 1); // Remove the cell corresponding to the deleted column
      return updatedRow;
    });

    setEditableObject({
      ...editableObject,
      columns: updatedColumns,
      rows: updatedRows,
    });

    const updatedObject = {
      ...editableObject,
      columns: updatedColumns,
      rows: updatedRows,
    };
    handleDataUpdate(updatedObject);
  };


  const deleteInputs = (index) => {
    const updatedinputsArray = [...editableObject.inputsArray];
    updatedinputsArray.splice(index, 1); // Remove the column at the specified index



    setEditableObject({
      ...editableObject,
      inputsArray: updatedinputsArray,
    });

    const updatedObject = {
      ...editableObject,
      inputsArray: updatedinputsArray,
    };
    handleDataUpdate(updatedObject);
  };

  // Function to delete a row by index
  const deleteRow = (index) => {
    const updatedRow = [...editableObject.rows];
    updatedRow.splice(index, 1); // Remove the option at the specified index
    setEditableObject({
      ...editableObject,
      rows: updatedRow,
    });

    const updatedObject = {
      ...editableObject,
      rows: updatedRow,
    };
    handleDataUpdate(updatedObject);
  };

  const handleOptionChange = (e, index) => {
    const updatedOptions = [...editableObject.options];
    updatedOptions[index - 1] = { option: e.target.value };

    const updatedEditableObject = {
      ...editableObject,
      options: updatedOptions,
    };

    setEditableObject(updatedEditableObject);
    handleDataUpdate(updatedEditableObject);
  };

  const handleColumnChange = (e, index) => {
    // console.log(editableObject.columns, e.target.value);
    const updatedColumns = [...editableObject.columns];
    updatedColumns[index - 1] = { heading: e.target.value };

    const updatedEditableObject = {
      ...editableObject,
      columns: updatedColumns,
    };

    // console.log(updatedEditableObject);

    setEditableObject(updatedEditableObject);
    handleDataUpdate(updatedEditableObject);
  };


  const handleInputsChange = (e, index) => {
    const updatedinputsColumn = [...editableObject.inputsArray];
    updatedinputsColumn[index] = { fieldInput: e.target.value };

    const updatedEditableObject = {
      ...editableObject,
      inputsArray: updatedinputsColumn,
    };

    setEditableObject(updatedEditableObject);
    handleDataUpdate(updatedEditableObject);
  };


  const handleRowChange = (e, rowIndex, cellIndex) => {
    const updatedRows = [...editableObject.rows];
    updatedRows[rowIndex][cellIndex] = e.target.value;

    const updatedEditableObject = {
      ...editableObject,
      rows: updatedRows,
    };

    setEditableObject(updatedEditableObject);
    handleDataUpdate(updatedEditableObject);
  };

  const handleDataUpdate = (updatedData) => {
    const updatedArray = formData?.map((item, index) => {
      if (item?.type === updatedData?.type && index === editableIndex) {
        return { ...item, ...updatedData };
      }
      return item;
    });

    setFormData(updatedArray);
    // dispatch(updateFormData(updatedArray))
    // console.log(formData);
  };

  const handleThemeChange = (theme) => {
    const updatedThemeArray = [...formData]; // Create a copy of the theme array
    updatedThemeArray[0] = theme;
    setFormData(updatedThemeArray);
  };

  const handleMultiselectSwitchChange = (newValue) => {
    const updatedEditableObject = {
      ...editableObject,
      multiple: newValue,
    };

    // console.log(updatedEditableObject);

    setEditableObject(updatedEditableObject);
    handleDataUpdate(updatedEditableObject);
    // Optionally, perform any additional actions here, such as updating data
  };

  switch (editableObject?.type) {
    case "header":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Heading Text
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleTitleChange}
            />
          </div>

          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Subheading Text
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subTitle}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleSubtitleChange}
            />
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600  cad-text-medium text-white">
              Heading Size
            </h1>

            <div
              className="btn-group mt-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnradio"
                id="default"
                value="h3"
                autoComplete="off"
                checked={editableObject?.className === "h3"}
                onChange={handleSizeChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="default"
              >
                DEFAULT
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnradio"
                id="large"
                value="h1"
                autoComplete="off"
                checked={editableObject?.className === "h1"}
                onChange={handleSizeChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="large"
              >
                LARGE
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnradio"
                id="small"
                value="h4"
                autoComplete="off"
                checked={editableObject?.className === "h4"}
                onChange={handleSizeChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="small"
              >
                SMALL
              </label>
            </div>
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600 cad-text-medium text-white">
              Text Align
            </h1>

            <div
              className="btn-group mt-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="align-items-start"
                autoComplete="off"
                value="align-items-start"
                checked={editableObject.alignment === "align-items-start"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="align-items-start"
              >
                LEFT
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="align-items-center"
                autoComplete="off"
                value="align-items-center"
                checked={editableObject.alignment === "align-items-center"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="align-items-center"
              >
                CENTER
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="align-items-end"
                autoComplete="off"
                value="align-items-end"
                checked={editableObject.alignment === "align-items-end"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="align-items-end"
              >
                RIGHT
              </label>
            </div>
          </div>

          <hr />
        </>
      );

    case "text":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Place holder Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.placeHolder}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) =>
                handleLabelChange(e, inputProperties.PLACEHOLDER)
              }
            />
          </div>
          <hr />
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              SubLabel
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subLabel}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.SUBLABEL)}
            />
          </div>

          <hr />
        </>
      );

    case "tel":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Place holder Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.placeHolder}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) =>
                handleLabelChange(e, inputProperties.PLACEHOLDER)
              }
            />
          </div>

          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              SubLabel
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subLabel}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.SUBLABEL)}
            />
          </div>
        </>
      );

    case "textarea":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Place holder Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.placeHolder}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) =>
                handleLabelChange(e, inputProperties.PLACEHOLDER)
              }
            />
          </div>

          <hr />
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              SubLabel
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subLabel}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.SUBLABEL)}
            />
          </div>
        </>
      );

    case "email":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Place holder Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.placeHolder}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) =>
                handleLabelChange(e, inputProperties.PLACEHOLDER)
              }
            />
          </div>

          <hr />
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              SubLabel
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subLabel}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.SUBLABEL)}
            />
          </div>
        </>
      );

    case "paragraph":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Paragraph Text
            </span>
            <textarea
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0 cad-text-medium"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleTitleChange}
              rows={5}
            />
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600  cad-text-medium text-white">
              Heading Size
            </h1>

            <div
              className="btn-group mt-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnradio"
                id="default"
                value="cad-text-medium"
                autoComplete="off"
                checked={editableObject?.className === "cad-text-medium"}
                onChange={handleSizeChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="default"
              >
                DEFAULT
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnradio"
                id="large"
                value="cad-text-large"
                autoComplete="off"
                checked={editableObject?.className === "cad-text-large"}
                onChange={handleSizeChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="large"
              >
                LARGE
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnradio"
                id="small"
                value="cad-text-small"
                autoComplete="off"
                checked={editableObject?.className === "cad-text-small"}
                onChange={handleSizeChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="small"
              >
                SMALL
              </label>
            </div>
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600 cad-text-medium text-white">
              Text Align
            </h1>

            <div
              className="btn-group mt-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="text-start"
                autoComplete="off"
                value="text-start"
                checked={editableObject.alignment === "text-start"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="text-start"
              >
                LEFT
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="text-center"
                autoComplete="off"
                value="text-center"
                checked={editableObject.alignment === "text-center"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="text-center"
              >
                CENTER
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="text-end"
                autoComplete="off"
                value="text-end"
                checked={editableObject.alignment === "text-end"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="text-end"
              >
                RIGHT
              </label>
            </div>
          </div>

          <hr />
        </>
      );

    case "address":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0 cad-text-medium"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleTitleChange}
            />
          </div>
          <hr />
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Place Holder
            </span>

            <div className="d-flex align-items-center gap-3  bg-secondary mt-3">
              <span className="d-flex  align-items-center cad-text-medium cad-fw-600 col-5 ps-3 text-white">
                Street
              </span>
              <input onKeyUp={handleKeyUp}
                type="text"
                value={editableObject?.street}
                className="form-control rounded-0 cad-text-medium"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) =>
                  handleAddressSubtitleChange(e, editableObject?.street)
                }
              />
            </div>
            <div className="d-flex align-items-center gap-3  bg-secondary mt-1">
              <span className="d-flex  align-items-center cad-text-medium cad-fw-600 col-5 ps-3 text-white">
                City
              </span>
              <input onKeyUp={handleKeyUp}
                type="text"
                value={editableObject?.city}
                className="form-control rounded-0 cad-text-medium"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) =>
                  handleAddressSubtitleChange(e, editableObject?.city)
                }
              />
            </div>
            <div className="d-flex align-items-center gap-3 bg-secondary mt-1">
              <span className="d-flex align-items-center cad-text-medium cad-fw-600 col-5 ps-3 text-white">
                State
              </span>
              <input onKeyUp={handleKeyUp}
                type="text"
                value={editableObject?.state}
                className="form-control rounded-0 cad-text-medium"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) =>
                  handleAddressSubtitleChange(e, editableObject?.state)
                }
              />
            </div>
            <div className="d-flex align-items-center gap-3 bg-secondary mt-1">
              <span className="d-flex align-items-center cad-text-medium cad-fw-600 col-5 ps-3 text-white">
                Country
              </span>
              <input onKeyUp={handleKeyUp}
                type="text"
                className="form-control rounded-0 cad-text-medium"
                id="exampleFormControlInput1"
                value={editableObject?.country}
                onChange={(e) =>
                  handleAddressSubtitleChange(e, editableObject?.country)
                }
              />
            </div>
            <div className="d-flex align-items-center gap-3 bg-secondary mt-1">
              <span className="d-flex align-items-center cad-text-medium cad-fw-600  col-5 ps-3 text-white">
                Zip code
              </span>
              <input onKeyUp={handleKeyUp}
                type="text"
                value={editableObject?.zipCode}
                className="form-control rounded-0 cad-text-medium"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) =>
                  handleAddressSubtitleChange(e, editableObject?.zipCode)
                }
              />
            </div>
          </div>

          <hr />
        </>
      );

    case "date":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Sublabel
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subLabel}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.SUBLABEL)}
            />
          </div>

          <hr />
        </>
      );

    case "dropdown":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600  cad-text-medium text-white mb-2">
              Options
            </h1>

            {editableObject?.options.map(({ option }, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-around bg-secondary mt-1"
              >
                <span className="d-flex  align-items-center cad-text-medium cad-fw-600 col-4 ps-3 text-white">
                  Option {index++ + 1}
                </span>
                <input onKeyUp={handleKeyUp}
                  type="text"
                  value={option}
                  className="form-control rounded-0 cad-text-medium"
                  id="exampleFormControlInput1"
                  placeholder=""
                  onChange={(e) => handleOptionChange(e, index)}
                />
                <span
                  onClick={() => deleteOption(index - 1)}
                  className="p-1 form-editbar-delete-button text-white ps-2 pe-2 pointer"
                >
                  <ClearIcon />
                </span>
              </div>
            ))}
            <span className="text-white float-end mt-2" onClick={addOptions}>
              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
            </span>
          </div>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              SubLabel
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subLabel}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.SUBLABEL)}
            />
          </div>
        </>
      );

    case "footer":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Heading Text
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleTitleChange}
            />
          </div>

          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Subheading Text
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subTitle}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleSubtitleChange}
            />
          </div>
          <hr />

          <hr />
        </>
      );

    case "radio":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600  cad-text-medium text-white mb-2">
              Options
            </h1>

            {editableObject?.options.map(({ option }, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-around bg-secondary mt-1"
              >
                <span className="d-flex  align-items-center cad-text-medium cad-fw-600 col-4 ps-3 text-white">
                  Option {index++ + 1}
                </span>
                <input onKeyUp={handleKeyUp}
                  type="text"
                  value={option}
                  className="form-control rounded-0 cad-text-medium"
                  id="exampleFormControlInput1"
                  placeholder=""
                  onChange={(e) => handleOptionChange(e, index)}
                />
                <span
                  onClick={() => deleteOption(index - 1)}
                  className="p-1 form-editbar-delete-button text-white ps-2 pe-2 pointer"
                >
                  <ClearIcon />
                </span>
              </div>
            ))}
            <span className="text-white float-end mt-2" onClick={addOptions}>
              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
            </span>
          </div>
        </>
      );

    case "checkbox":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600  cad-text-medium text-white mb-2">
              Options
            </h1>
            {editableObject?.options.map(({ option }, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-around bg-secondary mt-1"
              >
                <span className="d-flex  align-items-center cad-text-medium cad-fw-600 col-4 ps-3 text-white">
                  Option {index++ + 1}
                </span>
                <input onKeyUp={handleKeyUp}
                  type="text"
                  value={option}
                  className="form-control rounded-0 cad-text-medium"
                  id="exampleFormControlInput1"
                  placeholder=""
                  onChange={(e) => handleOptionChange(e, index)}
                />
                <span
                  onClick={() => deleteOption(index - 1)}
                  className="p-1 form-editbar-delete-button text-white ps-2 pe-2 pointer"
                >
                  <ClearIcon />
                </span>
              </div>
            ))}
            <span className="text-white float-end mt-2" onClick={addOptions}>
              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
            </span>
          </div>
        </>
      );

    case "submit":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Heading Text
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleTitleChange}
            />
          </div>

          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600 cad-text-medium text-white">
              Text Align
            </h1>

            <div
              className="btn-group mt-3"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="justify-content-start"
                autoComplete="off"
                value="justify-content-start"
                checked={editableObject.alignment === "justify-content-start"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="justify-content-start"
              >
                LEFT
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="justify-content-center"
                autoComplete="off"
                value="justify-content-center"
                checked={editableObject.alignment === "justify-content-center"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="justify-content-center"
              >
                CENTER
              </label>

              <input onKeyUp={handleKeyUp}
                type="radio"
                className="btn-check"
                name="btnAlign"
                id="justify-content-end"
                autoComplete="off"
                value="justify-content-end"
                checked={editableObject.alignment === "justify-content-end"}
                onChange={handleAlignChange}
              />
              <label
                className="btn btn-outline-primary cad-text-small text-white rounded-0"
                htmlFor="justify-content-end"
              >
                RIGHT
              </label>
            </div>
          </div>

          <hr />
        </>
      );

    case "details-heading":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Heading Text
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleTitleChange}
            />
          </div>

          <hr />

          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Subheading Text
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subTitle}
              className="form-control mt-3 rounded-0"
              id="exampleFormControlInput1"
              placeholder=""
              onChange={handleSubtitleChange}
            />
          </div>
          <hr />
        </>
      );

    case "details":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.detailsTitle}
              className="form-control mt-3 rounded-0 cad-text-medium"
              id="exampleFormControlInput1"
              placeholder=""
              // onChange={handleTitleChange}
              onChange={(e) =>
                handleLabelChange(e, inputProperties.DETAILSTITLE)
              }
            />
          </div>
          <hr />
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Fields
            </span>
            {editableObject.inputsArray.map((input, index) => (<div className="d-flex align-items-center  bg-secondary mt-3">
              <span className="d-flex align-items-center cad-text-medium cad-fw-600 col-4 ps-2 text-white">
                Column {index + 1}
              </span>


              <input onKeyUp={handleKeyUp}
                type="text"
                value={input?.fieldInput}
                className="form-control rounded-0 cad-text-small"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) => handleInputsChange(e, index)}
              />

              <span
                onClick={() => deleteInputs(index)}
                className="p-1 form-editbar-delete-button text-white ps-2 pe-2 pointer"
              >
                <ClearIcon />
              </span>
            </div>))}
            <span
              className="text-white float-end mt-2 action-hover pointer rounded-2"
              onClick={addInputColumns}
            >
              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
            </span>





          </div>

          <hr />
        </>
      );

    case "file":
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600 d-flex justify-content-between align-items-center cad-text-medium text-white">
              Multiselect{" "}
              <Switch
                onChange={(e) =>
                  handleMultiselectSwitchChange(e.target.checked)
                }
                value={editableObject?.multiple}
                size="small"
              />
            </span>
          </div>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.label}
              className="form-control mt-3 rounded-0 cad-text-medium"
              id="exampleFormControlInput1"
              placeholder=""
              // onChange={handleTitleChange}
              onChange={(e) => handleLabelChange(e, inputProperties.LABEL)}
            />
          </div>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Field Label
            </span>
            <input onKeyUp={handleKeyUp}
              type="text"
              value={editableObject?.subLabel}
              className="form-control mt-3 rounded-0 cad-text-medium"
              id="exampleFormControlInput1"
              placeholder=""
              // onChange={handleTitleChange}
              onChange={(e) => handleLabelChange(e, inputProperties.SUBLABEL)}
            />
          </div>
          <hr />
        </>
      );

    case "table":
      return (
        <>
          <div className="p-3">
            <h1 className="cad-fw-600  cad-text-medium text-white mb-2">
              Column
            </h1>

            {editableObject?.columns.map(({ heading }, index) =>
              index === 0 ? (
                ""
              ) : (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-around bg-secondary mt-1"
                >
                  <span className="d-flex  align-items-center cad-text-medium cad-fw-600 col-4 ps-3 text-white">
                    Column {index++}
                  </span>
                  <input onKeyUp={handleKeyUp}
                    type="text"
                    value={heading}
                    className="form-control rounded-0 cad-text-medium"
                    id="exampleFormControlInput1"
                    placeholder=""
                    onChange={(e) => handleColumnChange(e, index)}
                  />
                  <span
                    onClick={() => deleteColumn(index - 1)}
                    className="p-1 form-editbar-delete-button text-white ps-2 pe-2 pointer"
                  >
                    <ClearIcon />
                  </span>
                </div>
              )
            )}
            <span
              className="text-white float-end mt-2 action-hover pointer rounded-2"
              onClick={addColumn}
            >
              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
            </span>
          </div>
          <hr />

          <div className="p-3">
            <h1 className="cad-fw-600  cad-text-medium text-white mb-2">
              Rows
            </h1>

            {editableObject.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) =>
                  cellIndex === 0 ? (
                    <td
                      className="d-flex align-items-center justify-content-around bg-secondary mt-1"
                      key={cellIndex}
                    >
                      <span className="d-flex  align-items-center cad-text-medium cad-fw-600 col-4 ps-3 text-white">
                        Row {rowIndex}
                      </span>
                      <input onKeyUp={handleKeyUp}
                        type="text"
                        className="form-control rounded-0 cad-text-medium"
                        value={cell}
                        onChange={(e) =>
                          handleRowChange(e, rowIndex, cellIndex)
                        }
                      />
                      <span
                        onClick={() => deleteRow(rowIndex)}
                        className="p-1 form-editbar-delete-button text-white ps-2 pe-2 pointer"
                      >
                        <ClearIcon />
                      </span>
                    </td>
                  ) : (
                    ""
                  )
                )}
              </tr>
            ))}
            <span
              className="text-white float-end mt-2 action-hover pointer rounded-2"
              onClick={addRow}
            >
              <AddBoxIcon style={{ fontSize: "2.2rem" }} />
            </span>
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="p-3">
            <span className="cad-fw-600  cad-text-medium text-white">
              Color Scheme
            </span>
            <div className="d-flex mt-3 gap-2 justify-content-start flex-wrap">
              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#FFFFFF",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #F3F3FE",
                  color: "#2C3345",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#F3F3FE",
                    formColor: "#FFFFFF",
                    color: "#2C3345",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#FFF9BD",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #F06233",
                  color: "#6A1B00",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#F06233",
                    formColor: "#FFF9BD",
                    color: "#6A1B00",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer  justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#F5FDFF",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #367D96",
                  color: "#005774",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#367D96",
                    formColor: "#F5FDFF",
                    color: "#005774",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#5B6276",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #11111B",
                  color: "#11111B",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#11111B",
                    formColor: "#5B6276",
                    color: "#11111B",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#F4FFDF",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #7C9E3D",
                  color: "#455429",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#7C9E3D",
                    formColor: "#F4FFDF",
                    color: "#455429",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#E4EFFF",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #2462B9",
                  color: "#12458D",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#2462B9",
                    formColor: "#E4EFFF",
                    color: "#12458D",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#F7F2FF",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #7C56BD",
                  color: "#412A68",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#7C56BD",
                    formColor: "#F7F2FF",
                    color: "#412A68",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#f4f1bb",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #9bc1bc",
                  color: "#26547c",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#9bc1bc",
                    formColor: "#f4f1bb",
                    color: "#26547c",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#f8f7ff",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #b8b8ff",
                  color: "#3a506b",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#b8b8ff",
                    formColor: "#f8f7ff",
                    color: "#3a506b",
                  })
                }
              >
                A
              </span>

              <span
                className="d-flex pointer justify-content-center align-items-center cad-fw-500"
                style={{
                  background: "#ffffff",
                  width: "40px",
                  height: "40px",
                  fontSize: "22px",
                  border: "4px solid #4c5454",
                  color: "#ff715b",
                }}
                onClick={() =>
                  handleThemeChange({
                    backgroundColor: "#4c5454",
                    formColor: "#ffffff",
                    color: "#ff715b",
                  })
                }
              >
                A
              </span>
            </div>
          </div>
          <hr />
        </>
      );
  }
};

export default EditBar;
