import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

export const updateAgent = async (data) => {
    try {
        return await api.put(`/agent/`, data, formDataConfig)
    } catch (error) {
        throw error
    }
}



export const getExportContactsZip = async () => {
    try {
        return await api.get(`/agent/getzip`, { responseType: 'blob' })
    } catch (error) {
        throw error
    }
}


export const getExportContacts = async () => {
    try {
        return await api.get(`/contact/agent/client/download`)
    } catch (error) {
        throw error
    }
}

export const ClosedAccount = async (data) => {
    try {
        return await api.delete(`/agent/delete`, { data })
    } catch (error) {
        throw error
    }
}