// GlobalStateContext.js
import React, { useState } from "react";
import GlobalStateContext from "./GlobalStateContext";
import companyLogo from "../Images/logo.png";
import wd from "../Images/word.png";
import wordFile from "../Images/file-sample_100kB.doc";

const GlobalStateProvider = ({ children }) => {
  // =================[ chat variable ]=====================
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [activeChatIndex, setActiveChatIndex] = useState(null);
  const [chatContacts, setChatContacts] = useState([]);
  const [chatActiveId, setChatActiveId] = useState(null);
  const [profileSrc, setProfileSrc] = useState("");
  const [openNewChat, setOpenNewChat] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [activeChatProfile, setActiveChatProfile] = useState(false);
  return (
    <GlobalStateContext.Provider
      value={{
        socket,
        setSocket,
        message,
        setMessage,
        receivedMessages,
        setReceivedMessages,
        contacts,
        setContacts,
        activeChatIndex,
        setActiveChatIndex,
        chatContacts,
        setChatContacts,
        chatActiveId,
        setChatActiveId,
        profileSrc,
        setProfileSrc,
        openNewChat,
        setOpenNewChat,
        isChatLoading,
        setIsChatLoading,
        activeChatProfile,
        setActiveChatProfile,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
export default GlobalStateProvider;
