import React, { useEffect, useState } from "react";
import "./Tags.css";
import Table from "../../Tables/TagsTable/Table";
import { Button, Switch, Typography } from "@mui/material";
import AddTag from "../../Components/AddTag/AddTag";
import { fetchTag } from "../../Redux/Slice/tagsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PrimaryButton from "../../Components/PrimaryButton";
import * as themeColors from "../../Utils/Theme/Colors";
import { AiOutlineDelete } from "react-icons/ai";
import AddIcon from "@mui/icons-material/Add";
import TableData from "../../Components/TableData";
import { deleteTag, updateTag } from "../../service/tags.service";
import FormatDate from "../../Components/FormatDate/FormatDate";
import { fetchGlobalTags } from "../../Redux/Slice/globlalTags";
import AlertModal from "../../Components/AlertModal";
import Refresher from "../../Components/Refresher";
import AdvanceFilter from "../../Components/AdvanceFilter/AdvanceFilter";

const Tags = () => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const [mouseEntered, setMouseEntered] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const { rows, isLoading } = useSelector((state) => state.tag);
  const [ filteredCellConfig, setFilteredCellConfigg ] = useState([])
  const [ filteredTableHeadRowCell, setFilteredTableHeadRowCelll ] = useState([])
  const tags = useSelector((state) => state.globalTags);

  const updateTagStatus = async (tagId) => {
    const tagIdNeedsToBeUpdated = rows.find((item) => item.id === tagId);

    try {
      const response = await updateTag(tagId, {
        ...tagIdNeedsToBeUpdated,
        active: !tagIdNeedsToBeUpdated.active,
      });
      // console.log("Tag updated successfully:", response);
    } catch (error) {
      console.error("Error updating tag:", error);
    }

    dispatch(fetchTag());
    dispatch(fetchGlobalTags());
  };

  const handleDelete = async () => {
    try {
      const response = deleteTag(mouseEnteredId);

      dispatch(fetchTag());
      dispatch(fetchGlobalTags());
      setOpenDeleteModal(false);
      return response; // Assuming the deleted data is returned from the server
    } catch (error) {
      return error.response;
    }
  };

  const extractedDataArray = rows?.map((user) => {
    const extractedData = {
      id: user.id,
      name: user.title,
      description: user.description,
      createdAt: <FormatDate dateString={user.createdAt} />,
      status: (
        <Switch
          onChange={() => updateTagStatus(user.id)}
          size="small"
          checked={user.active}
          name={`customSwitch${user.index}`}
          color="primary"
        />
      ),
      Action: (
        <span
          style={{ borderRadius: "18%" }}
          className="p-2 action-hover pointer text-danger"
          onClick={() => setOpenDeleteModal(!openDeleteModal)}
        >
          <AiOutlineDelete style={{ fontSize: "20px" }} />
        </span>
      ),
    };

    return extractedData;
  });

  const [checkboxValues, setCheckboxValues] = useState({
    Name: true,
    "Description": true,
    "Created At": true,
    Status: true,
    // Action: true,
  });

  const cellConfig = [
    { dataKey: "name", align: "", width: "180px" },
    { dataKey: "description", align: "left", width: "" },
    { dataKey: "createdAt", align: "center", width: "120px" },
    { dataKey: "status", align: "center", width: "100px" },
    { dataKey: "Action", align: "center", width: "100px" },
  ];

  const tableHeadRowCell = [
    {
      title: "Name",
      width: "180px",
      align: "",
    },
    {
      title: "Description",
      width: "",
      align: "left",
    },
    {
      title: "Created At",
      width: "120px",
      align: "center",
    },
    {
      title: "Status",
      width: "100px",
      align: "center",
    },
    {
      title: "Action",
      width: "100px",
      align: "center",
    },
  ];

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        // transition:"0.3s"
      }}
      className="mt-1 mb-4 cad-shadow overflow-auto rounded-3"
    >
      <AddTag open={open} setOpen={setOpen} />
      <div className="p-0 w-100 p-3">
        <div className=" mb-3   d-flex justify-content-between align-items-center w-100 ">
          <Typography variant="h6">Tags List</Typography>

          <div className="d-flex gap-2">
            <Refresher
              isLoading={isLoading}
              handleRefresh={() => dispatch(fetchTag())}
            />
            <PrimaryButton
              onClick={handleOpen}
              variant="contained"
              title="Add New Tag"
              padding="0.3rem 0.6rem"
              icon={<AddIcon size={13} />}
              fontSize="14px"
              width="200px"
            />

            <AdvanceFilter
              bottom="-140px"
              left="-51px"
              cellConfig={cellConfig}
              tableHeadRowCell={tableHeadRowCell}
              setFilteredCellConfig={setFilteredCellConfigg}
              setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
              checkboxValues={checkboxValues}
              setCheckboxValues={setCheckboxValues}
            />
          </div>
        </div>
      </div>
      <TableData
        tableHeadRowCell={filteredTableHeadRowCell} 
        extractedDataArray={extractedDataArray}
        cellConfig={filteredCellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        handleViewOpen={()=>{}}
        padding="p-3"
      />

      <AlertModal
        message={"Are you sure you want to delete this tag?"}
        handler={handleDelete}
        open={openDeleteModal}
        closeModal={() => setOpenDeleteModal(false)}
      />
    </div>
  );
};

export default Tags;
