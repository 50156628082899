import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { useSelector } from "react-redux";


const FormViewModal = ({ open, handleClose, id }) => {

  // console.log("hit");
  const { formArray } = useSelector((state) => state?.getForms);

  const filteredForm = formArray?.find((obj) => obj?.id === id);
  let themeObject, theme;
  try {
    const jsonArray = filteredForm?.formData;
    if (typeof jsonArray === 'string') {
      themeObject = JSON.parse(jsonArray);
    } else {
      themeObject = jsonArray;
    }
    theme = jsonArray[0];
  } catch (error) {
    console.error("Error parsing JSON:");
  }

  // console.log(id);
  // console.log(filteredForm);

  // Function to render table rows
  const renderRows = (rows, isBeingEdited) => {
    return rows.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td
            style={{
              backgroundColor: cellIndex === 0 ? "#E5EAF4" : "",
              border: `1px solid ${themeObject?.color}`,
              padding: cellIndex === 0 ? "8px 16px" : 0,
            }}
            className="cad-text-small cad-fw-600"
            key={cellIndex}
          >
            {cell}
            {/* {isBeingEdited ? (
              cellIndex !== 0 ? (
                ""
              ) : (
                <CloseIcon className="action-hover rounded-2 pointer ms-1" />
              )
            ) : (
              ""
            )} */}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur p-3"
    >
      <Box
        sx={{
          height: "95%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <div className="d-flex w-100 cad-shadow justify-content-between  p-3   gap-1">
          <span
            style={{ fontSize: "30px" }}
            className=" cad-fw-700 cad-text-dark"
          >
            {filteredForm?.title}
          </span>

          <Link
            className="d-flex align-items-center"
            to={filteredForm?.isPersonal ? `/template-preview/${id}` : `/template-forms/${id}`}
          >
            <Button variant="outlined" className="button-contained ">
              Use Template
            </Button>
          </Link>
        </div>

        <div
          style={{
            padding: "0px 26px 100px 26px",
            backgroundColor: theme?.formColor,
            color: theme?.color,
          }}
          className="d-flex overflow-auto h-100 w-100 flex-wrap"
        >
          {themeObject?.map((field, index) => {
            switch (field.type) {
              case "header":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="w-100 mb-3"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}
                      // element-edit

                      style={{
                        borderBottom: `1px solid ${themeObject.color}`,
                      }}
                      className={`${field.alignment} position-relative  p-4 pb-2   w-100 d-flex justify-content-center flex-column`}
                    >
                      <h3 className={`${field.className} cad-fw-700`}>
                        {field.label}
                      </h3>
                      <p className={field.subTitleClassName}>
                        {field.subTitle}
                      </p>
                    </div>
                  </div>
                );

              case "text":
                return (
                  <div
                    className="col-6"
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className={` position-relative p-2 gap-2 d-flex flex-column`}
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type={field.type}
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "tel":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative  p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type="number"
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "date":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type={field.type}
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "email":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type={field.type}
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "textarea":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <textarea
                        style={{
                          borderColor: themeObject.color,
                        }}
                        className="form-control"
                        placeholder={field.placeHolder}
                        id="floatingTextarea"
                      ></textarea>
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "submit":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative  p-4 pb-3 gap-2 d-flex justify-content-center"
                    >
                      <button
                        style={{
                          backgroundColor: themeObject.color,
                          color: themeObject.formColor,
                        }}
                        type={field.type}
                        className="btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                );

              case "dropdown":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className=" position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <select
                        style={{
                          borderColor: themeObject.color,
                        }}
                        className={field.className}
                        aria-label="Default select example"
                      >
                        <option selected>Open this select menu</option>
                        {field.options.map(({ option }, index) => (
                          <option key={index} value={index + 1}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "paragraph":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className={`${field.alignment} position-relative p-2 gap-2 d-flex flex-column`}
                    >
                      <p className={`${field.className}  cad-fw-500`}>
                        {field.label}
                      </p>
                    </div>
                  </div>
                );

              case "file":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label htmlFor="formFile" className="form-label">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        className={field.className}
                        type={field.type}
                        id="formFile"
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "hr":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      {/* <hr color={themeObject.color} /> */}
                      <span
                        className="mt-2 mb-2"
                        style={{
                          borderTop: `0.5px solid ${themeObject.color}`,
                        }}
                      />
                    </div>
                  </div>
                );

              case "checkbox":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className=" position-relative p-2"
                    >
                      <label className="cad-text-medium cad-fw-500 mb-2">
                        {field.label}
                      </label>
                      {field.options.map(({ option }, index) => (
                        <div
                          key={index}
                          className="form-check d-flex justify-content-start w-100 gap-2"
                        >
                          <input
                            className="form-check-input cad-text-medium"
                            type={field.type}
                            value=""
                            id="flexCheckIndeterminate"
                          />
                          <label
                            className="form-check-label col-12 cad-text-small"
                            htmlFor="flexCheckIndeterminate"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                );

              case "radio":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className=" position-relative p-2"
                    >
                      <label className="cad-text-medium cad-fw-500 mb-2">
                        {field.label}
                      </label>
                      {field.options.map(({ option }, index) => (
                        <div
                          key={index}
                          className="form-check d-flex justify-content-start w-100 gap-2"
                        >
                          <input
                            name="exampleRadios"
                            className="form-check-input cad-text-medium"
                            type={field.type}
                            value=""
                            id="flexCheckIndeterminate"
                          />
                          <label
                            className="form-check-label col-12 cad-text-small"
                            htmlFor="flexCheckIndeterminate"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                );

              case "address":
                return (
                  <div className="col-12">
                    <div
                      key={field.id}
                      // // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <div className="d-flex flex-wrap w-100">
                        <div className="w-100 d-flex justify-content-start flex-column mb-2">
                          <textarea
                            style={{
                              borderColor: themeObject.color,
                            }}
                            type="text"
                            className="form-control w-100 cad-text-medium   m-1"
                            placeholder={field?.street}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.streetSubLabel}
                          </span>
                        </div>
                        <div className="col-6 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.city}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.citySubLabel}
                          </span>
                        </div>

                        <div className="col-6 flex-column d-flex justify-content-end mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.state}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.stateSubLabel}
                          </span>
                        </div>

                        <div className="col-6 flex-column d-flex justify-content-end mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.country}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.country}
                          </span>
                        </div>

                        <div className="col-6 flex-column d-flex justify-content-end mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.zipCode}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.zipCode}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "footer":
                return (
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    // element-edit
                    // onDoubleClick={() => setEditableIndex(index)}
                    style={{
                      borderTop: `1px solid ${themeObject.color}`,
                    }}
                    className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0 w-100 d-flex justify-content-between `}
                  >
                    <span className={`${field.className} cad-fw-700`}>
                      {field.label}
                    </span>
                    <span className={field.subTitleClassName}>
                      {field.subTitle}
                    </span>
                  </div>
                );

              case "table":
                return (
                  <div
                    // element-edit
                    // onClick={() => setEditableIndex(null)}
                    key={field.id}
                    // onDoubleClick={() => setEditableIndex(index)}
                    className={` position-relative p-2 w-100 gap-2 d-flex flex-column`}
                  >
                    <table
                      key={field.id}
                      className="w-100 position-relation"
                    >
                      <thead>
                        <tr>
                          {field.columns.map((column, index) => (
                            <th
                              style={{
                                backgroundColor:
                                  index === 0 ? "" : "#E5EAF4",
                                border:
                                  index === 0
                                    ? ""
                                    : `1px solid ${themeObject?.color}`,
                              }}
                              className={`p-3 pt-2 pb-2  cad-fw-600 cad-text-small position-relation`}
                              key={index}
                            >
                              {column.heading}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {renderRows(field.rows)}
                      </tbody>
                    </table>
                  </div>
                );

              case "details":
                return (
                  <div className="col-12">
                    <div
                      key={field.id}
                      // // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}
                      // onDoubleClick={() => setEditableIndex(index)}
                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <div className="d-flex flex-wrap w-100">
                        <label className="col-12  mb-1 mt-2 cad-fw-600">
                          {field.detailsTitle}
                        </label>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldOne}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldOne}
                          </span>
                        </div>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldTwo}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldTwo}
                          </span>
                        </div>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldThree}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldThree}
                          </span>
                        </div>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldFour}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldFour}
                          </span>
                        </div>
                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldFive}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldFive}
                          </span>
                        </div>
                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldSix}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldSix}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "details-heading":
                return (
                  <div className="col-12">
                    <div
                      key={field.id}
                      // // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}
                      // onDoubleClick={() => setEditableIndex(index)}
                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-large cad-fw-700">
                        {field.label}
                      </label>
                      <label className="cad-text-medium cad-fw-500">
                        {field.subTitle}
                      </label>
                    </div>
                  </div>
                );

              case "signeture":
                return (
                  <div className="col-12 mt-5 pt-5">
                    <div
                      // onDoubleClick={() => setEditableIndex(index)}
                      className="col-12 d-flex flex-column justify-content-end align-items-end pe-2"
                    >
                      <SignaturePad
                        // ref={sigCanvas}
                        penColor={themeObject.color}
                        canvasProps={{
                          className: " bg-white",
                          style: {
                            borderBlockColor: themeObject.color,
                            width: "35%",
                            height: "120px",
                            border: "1px solid",
                            borderColor: themeObject.color,
                          },
                        }}
                      />
                      {/* Button to trigger save canvas image */}
                      <div className="w-25 d-flex justify-content-end cad-fw-600 cad-text-large pt-2 ">
                        <span>Signature</span>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
      </Box>
    </Modal>
  );
};

export default FormViewModal;
