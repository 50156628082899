import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { Avatar, Chip, Tab, Tabs, Tooltip } from "@mui/material";
import GlobalStateContext from '../../Contexts/GlobalStateContext';
import Tag from '../../Components/Tag/Tag';
import { Box } from '@mui/system';
import Footer from '../../Components/Footer/Footer';
import SelectChip from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector } from 'react-redux';

const animatedComponents = makeAnimated();



function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = "#";
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }






const EditTask = () => {

    
  const { tasks } = useSelector((state) => state?.tasks);


  const { id } = useParams();

  const foundObject = tasks.find((obj) => obj.id === id);


  // console.log(foundObject);

  


    
  return (
    <div className="overflow-auto m-2  h-100">
      <div className="d-flex m-2 rounded-3 overflow-auto h-auto  bg-white ps-5 pe-5 p-4  cad-shadow pb-5 flex-column gap-4">
        <div className="d-flex align-items-center justify-content-end position-relative">
          <Link
            to="/task"
            style={{ left: -24 }}
            className="text-primary h6 text-decoration-none d-flex align-items-center gap-3 pointer top-0 position-absolute"
          >
            <ArrowBackRoundedIcon /> Edit Task
          </Link>
          
          <span
            style={{
              right: -24,
              backgroundColor:
                foundObject?.mode?.title === "public" ? "#34A853" : "#FD394A",
              height: "100%",
              width: "90px",
            }}
            className="text-white rounded-2 ms-5 p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
          >
            {foundObject?.mode?.title}
            <LocalOfferOutlinedIcon className="cad-text-large" />
          </span>
        </div>

        

        <div className="w-100">
          <Box
            className=" border-bottom "
            sx={{
              borderBottom: 0,
              padding: 0,
              width: "100%",
            }}
          >
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EditTask