import React, { useState } from 'react'
import GlobalTable from '../../../../Components/GlobalTable/GlobalTable'
import UserProfile from '../../../../Components/GlobalTable/UserProfile';
import CustomStatusCell from '../../../../Components/GlobalTable/CustomStatusCell';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ClientDetailsTasks = () => {
    // Sample data for the data table
    const data = [
        {
            id: 1,
            task_name: 'Fill personal details',
            priority: 'High',
            email: 'Ameliajones@gmail.com',
            status: 'Completed',
            due_date: 'Aug 23,2023',
            assigned_date: 'Jan 23,2023',
            name: 'Amelia Jones',
            designation: 'UIUX Designer',
            reporting: 'Prajakta',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 2,
            task_name: 'Mail verification',
            priority: 'Low',
            name: 'Emma Smith',
            assigned_date: 'Jan 23,2023',
            due_date: 'Feb 12,2023',
            status: 'Pending',
            email: 'Emmasmith@gmail.com',
            designation: 'Backend Developer',
            reporting: 'Shweta',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 3,
            task_name: 'Client meeting',
            priority: 'Medium',
            assigned_date: 'Jan 23,2023',
            due_date: 'Aug 18,2023',
            status: 'On Going',
            name: 'Charlotte Jones',
            email: 'Charlottejones@gmail.com',
            designation: 'Frontend Developer',
            reporting: 'Priyanka Joshi',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 4,
            task_name: 'Mail verification',
            name: 'Olivia Jones',
            assigned_date: 'Jan 23,2023',
            due_date: 'Jan 5,2023',
            status: 'On Going',
            priority: 'Medium',
            email: 'Oliviajones@gmail.com',
            designation: 'Tester',
            reporting: 'Dipti',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 5,
            task_name: 'Mail verification',
            name: 'Olivia Williams',
            priority: 'High',
            assigned_date: 'Jan 23,2023',
            due_date: 'Dec 9,2023',
            status: 'Pending',
            email: 'Oliviajones@gmail.com',
            designation: 'SEO',
            reporting: 'Pooja',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 6,
            task_name: 'Mail verification',
            name: 'Olivia Williams',
            priority: 'Medium',
            assigned_date: 'Jan 23,2023',
            due_date: 'Aug 2,2023',
            status: 'Completed',
            email: 'Oliviajones@gmail.com',
            designation: 'SEO',
            reporting: 'Pooja',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 7,
            task_name: 'Mail verification',
            name: 'Olivia Williams',
            email: 'Oliviajones@gmail.com',
            designation: 'SEO',
            assigned_date: 'Jan 23,2023',
            due_date: 'Aug 2,2023',
            status: 'Pending',
            priority: 'Low',
            reporting: 'Pooja',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 8,
            task_name: 'Mail verification',
            name: 'Olivia Williams',
            priority: 'High',
            assigned_date: 'Jan 23,2023',
            due_date: 'Aug 2,2023',
            status: 'On Going',
            email: 'Oliviajones@gmail.com',
            designation: 'SEO',
            reporting: 'Pooja',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 9,
            task_name: 'Mail verification',
            name: 'Olivia Williams',
            priority: 'High',
            email: 'Oliviajones@gmail.com',
            designation: 'SEO',
            assigned_date: 'Jan 23,2023',
            due_date: 'Aug 2,2023',
            status: 'On Going',
            reporting: 'Pooja',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 10,
            task_name: 'Mail verification',
            name: 'Olivia Williams',
            priority: 'Low',
            email: 'Oliviajones@gmail.com',
            designation: 'SEO',
            assigned_date: 'Jan 23,2023',
            due_date: 'Aug 2,2023',
            status: 'Pending',
            reporting: 'Pooja',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
        {
            id: 11,
            task_name: 'Mail verification',
            name: 'Olivia Williams',
            priority: 'Medium',
            assigned_date: 'Jan 23,2023',
            due_date: 'Aug 2,2023',
            status: 'Pending',
            email: 'Oliviajones@gmail.com',
            designation: 'SEO',
            reporting: 'Pooja',
            assign: 'Priyanka Joshi',
            profilePic: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        },
    ]

    // Define columns for the data table
    const columns = [
        {
            name: 'Task Name',
            selector: (row) => row.task_name,
            sortable: true,
            width: '16%'
        },
        {
            name: 'Assigned By',
            cell: (row) => <UserProfile data={row} />,
            sortable: true,
            width: '19%'
        },
        {
            name: 'Priority',
            selector: (row) => row.priority,
            sortable: true,
            width: '16%',
            cell: row => <CustomStatusCell value={row.priority} />
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            width: '14%',
            cell: row => <CustomStatusCell value={row.status} />
        }, 
        {
            name: 'Assigned Date',
            selector: (row) => row.assigned_date,
            sortable: true,
            width: '17%'
        },
        {
           name: 'Due Date',
            selector: (row) => row.due_date,
            sortable: true,
            width: '17%'
        }
    ];

    // state for data filtering
    const [searchText, setSearchText] = useState('')

  return (
    <div className='p-3'>
        <input
            type="text"
            placeholder="Search..."
            className="form-control cad-text-medium w-25 mb-1"
            onChange={(e) => setSearchText(e.target.value)}
        />
        <GlobalTable
            data={data}
            searchText={searchText}
            selectableRows={false}
            columns={columns}
        />
    </div>
  )
}

export default ClientDetailsTasks