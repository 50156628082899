import api from './api.service'

export const getNotification = async (params) => {
    try {
        return await api.get(`/notification/agent`, {
            params: params,
        })
    } catch (error) {
        throw error
    }
}
export const acknowledgedNotification = async (data) => {
    try {
        return await api.post(`/notification/agent`, { ids: data })
    } catch (error) {
        throw error
    }
}


