import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTags } from "../../service/tags.service";

export const fetchTag = createAsyncThunk("fetchTags", async () => {
  try {
    const response = await getTags();

    return response.data
  } catch (error) {
    // Handle network or other errors
    throw new Error(`Error fetching data: ${error.message}`);
  }
});

const tagSlice = createSlice({
  name: "tags",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    rows:[]
  },
  reducers: {
    // Define your additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTag.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchTag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rows = action.payload?.data?.rows || [];
      })
      .addCase(fetchTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default tagSlice.reducer;
