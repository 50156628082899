import {
  Button,
  Chip,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../PrimaryButton";
import { ImTicket } from "react-icons/im";
import CheckTicketModal from "../CheckTicketModal/CheckTicketModal";
import GenerateNewTickets from "../GenerateNewTickets/GenerateNewTickets";
import GenerateTicketsTableComponent from "../GenerateTicketsTableComponent/GenerateTicketsTableComponent";
import { Box } from "@mui/system";
import GlobalStateContext from "../../Contexts/GlobalStateContext";
import { getSupportTicket } from "../../service/support.service";
import { fetchSupportTicket } from "../../Redux/Slice/supportSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import noData from "../../Images/no-data.png";
import NoData from "../NoData";
import TableData from "../TableData";
import { Link } from "react-router-dom";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import SearchInput from "../SearchInput/SearchInput";
import Refresher from "../Refresher";
import AdvanceFilter from "../AdvanceFilter/AdvanceFilter";
import { formatDate } from "../DateFormatter";

const SupportComponent = ({ themeColorsObject }) => {
  const dispatch = useDispatch();
  const { isLoading, rows } = useSelector((state) => state.support);

  const [filteredCellConfig, setFilteredCellConfigg] = useState([]);
  const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([]);

  useEffect(() => {
    dispatch(fetchSupportTicket());
  }, []);

  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredTableData = rows.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const extractedDataArray = filteredTableData.map(
    ({ title, id, status, description, createdAt }, i) => {
      const extractedData = {
        id: id,
        srNo: i + 1,
        subject: title,
        description: description,
        status: (
          <Chip
            style={{ fontWeight: "400" }}
            size="small"
            className={`${status.title === "On Hold"
                ? "chip-onhold"
                : status.title === "Open"
                  ? "chip-high"
                  : status.title === "In Progress"
                    ? "chip-med"
                    : "chip-low"
              }`}
            label={
              status.title === "Open"
                ? "Open"
                : status.title === "In Progress"
                  ? "In Progress"
                  : status.title === "On Hold"
                    ? "On Hold"
                    : "Completed"
            }
          />
        ),
        action:  mouseEntered && mouseEnteredId === id ? (
          <div className="d-flex justify-content-evenly">
            <Link to={`/support/generated-tickets/${id}`}>
              <span
                style={{ borderRadius: "18%" }}
                className="p-2 action-hover"
              >
                <IoChatboxEllipsesOutline style={{ fontSize: "19px" }} />
              </span>
            </Link>
          </div>
        ) : formatDate(createdAt),
      };

      return extractedData;
    }
  );

  // console.log(extractedDataArray);




  const [checkboxValues, setCheckboxValues] = useState({
    "Sr No.": true,
    "Subject": true,
    "Description": true,
    "Status": true,
    // "Action": true,
  });

  const cellConfig = [
    { dataKey: "srNo", align: "center", width: 40 },
    { dataKey: "subject", align: "left", width: 200 },
    { dataKey: "description", align: "center", width: 100 },
    { dataKey: "status", align: "center", width: 100 },
    { dataKey: "action", align: "center", width: 100 },
  ];

  const tableHeadRowCell = [
    {
      title: "Sr No.",
      width: 40,
      align: "center",
    },
    {
      title: "Subject",
      width: 200,
      align: "left",
    },
    {
      title: "Description",
      width: 100,
      align: "center",
    },
    {
      title: "Status",
      width: 100,
      align: "center",
    },
    {
      title: "Action",
      width: 100,
      align: "center",
    },
  ];

  return (
    <div className=" overflow-auto border-top  d-flex w-100 flex-column h-100">
      <div className="d-flex p-3 justify-content-between align-items-center">
        <div className="col-11 col-md-3 text-small">
          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="col-11 col-md-6 d-flex justify-content-end gap-2">
          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(fetchSupportTicket())}
          />
          <PrimaryButton
            onClick={handleOpen}
            variant="contained"
            icon={<ImTicket />}
            title="Generate New Tickets"
            path="/support"
            padding="0.3rem 2rem"
          />

          <AdvanceFilter
            bottom="-140px"
            left="-50px"
            cellConfig={cellConfig}
            tableHeadRowCell={tableHeadRowCell}
            setFilteredCellConfig={setFilteredCellConfigg}
            setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
          />

        </div>
      </div>

      <div className={`col-12`}>
        <TableData
          handleViewOpen={() => { return }}
          cellConfig={filteredCellConfig}
          extractedDataArray={extractedDataArray}
          tableHeadRowCell={filteredTableHeadRowCell}
          setMouseEntered={setMouseEntered}
          setMouseEnteredId={setMouseEnteredId}
          padding="p-3"
        />
      </div>
      <CheckTicketModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default SupportComponent;
