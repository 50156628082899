import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const formatDateStringMonth = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString);
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}`;
};

function formatTime12Hour(dateString) {
  const date = new Date(dateString);

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
}
const ActivityBoxComponent = ({ data }) => {
  return (
    <div>
      {Array.isArray(data) &&
        data.length > 0 &&
        data?.map(({ id, createdAt, title, subtitle }) => (
          <div key={id} className="d-flex flex-column gap-2 mt-3">
            <div className="cad-text-large cad-fw-600">
              {formatDateStringMonth(createdAt)}
            </div>
            <div className="d-flex gap-3 flex-start align-items-center">
              <span
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#B8CFFF",
                }}
                className="text-white d-flex justify-content-center align-items-center rounded-5"
              >
                <EmailOutlinedIcon />
              </span>
              <span className="d-flex flex-column">
                <span className="cad-text-medium cad-fw-500">{title}</span>
                <span className="cad-text-small cad-fw-600 text-secondary">
                  {formatTime12Hour(createdAt)}
                </span>
              </span>
            </div>

            <span className="border-top mt-3" />
          </div>
        ))}
    </div>
  );
};

export default ActivityBoxComponent;
