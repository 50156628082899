import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReminders } from "../../service/global.service";

export const fetchReminderData = createAsyncThunk("fetchReminderData", async () => {
  try {
    const res = await getReminders(); // Assuming you have a function getReminders() to fetch reminder data
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching data: ${err.message}`);
  }
});

const reminderSlice = createSlice({
  name: "reminder",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    remindersData : [], // Property to store the fetched reminder data
  },
  reducers: {
    // You can add additional reducers specific to reminders if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReminderData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchReminderData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.remindersData = action.payload.data; // Update reminders property with fetched data
      })
      .addCase(fetchReminderData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});

export default reminderSlice.reducer;
