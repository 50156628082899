import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { helpAndGuide } from "../../service/help.guide.service";

export const fetchHelpAndGuide = createAsyncThunk(
  "fetchHelpAndGuide",
  async () => {
    try {
      const res = await helpAndGuide();
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching data: ${err.message}`);
    }
  }
);

const helpAndGuideSlice = createSlice({
  name: "helpAndGuideSlice",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    helpData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHelpAndGuide.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchHelpAndGuide.fulfilled, (state, action) => {
        state.isLoading = false;
        state.helpData = action.payload.data;
      })
      .addCase(fetchHelpAndGuide.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default helpAndGuideSlice.reducer;
