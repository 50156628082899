import { notify } from "../App";
import api from "./api.service";
// let formDataConfig = {
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// };

let headers = {
  "Content-Type": "multipart/form-data",
};

export const createTask = async (taskData) => {
  try {
    const response = await api.post(`/task/agent/`, taskData);
    return response;
  } catch (error) {
    // Handle error or return the error response
    throw error;
  }
};

export const createFillFormTask = async (taskData) => {
  try {
    const response = await api.post(`/task/agent/type/formfill`, taskData);
    return response;
  } catch (error) {
    // Handle error or return the error response
    throw error;
  }
};



export const deleteTask = async (id) => {
  try {
    const response = await api.delete(`/task/agent/${id}/delete/`);
    return response.data; // Assuming the deleted data is returned from the server
  } catch (error) {
    return error.response;
  }
};

export const getTask = async (params) => {
  try {
    return await api.get(`/task/agent`, {
      // params: { size: size, page: page, search: search },
      params: params,
    });
  } catch (error) {
    return error.response;
  }
};




export const getKanban = async () => {
  try {
    return await api.get(`/task/agent/kanban`);
  } catch (error) {
    return error.response;
  }
};

export const updateTask = async (id, updatedData) => {
  try {
    return await api.put(`/task/agent/${id}`, updatedData);
  } catch (error) {
    throw error;
  }
};

export const updateKanbanStatus = async (id, updatedData) => {
  try {
    return await api.put(`/task/agent/kanban/${id}`, updatedData);
  } catch (error) {
    return error.response;
  }
};



export const getTaskById = async (id) => {
  try {
    return await api.get(`/task/agent/${id}`);
  } catch (error) {
    return error.response;
  }
};






export const sharedDocs = async (docData, id) => {
  try {
    const response = await api.post(
      `/task/agent/type/${id}/sharedDoc` ,
      docData,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    // Handle error or return the error response
    throw error.response;
  }
};


export const getSharedDocs = async (id) => {
  try {
    return await api.get(`/task/agent/type/${id}/sharedDoc`);
  } catch (error) {
    return error.response;
  }
};

export const deleteShareDocs = async (id) => {
  try {
    const response = await api.delete(`/task/agent/type/sharedDoc/${id}/delete/`);
    return response.data; // Assuming the deleted data is returned from the server
  } catch (error) {
    return error.response;
  }
};


export const getRequestDocs = async (id) => {
  // console.log(id);
  try {
    return await api.get(`/task/agent/type/${id}/receviedDoc/`);
  } catch (error) {
    return error.response;
  }
};

export const deleteRequestDocs = async (task_xid, id) => {
  // console.log(task_xid);
  try {
    const response = await api.delete(`/task/agent/type/${task_xid}/receviedDoc/${id}/delete/`);
    return response.data; // Assuming the deleted data is returned from the server
  } catch (error) {
    return error.response;
  }
};

export const createRequestDocs = async (docData, id) => {

  // console.log(docData);
  try {
    const response = await api.post(
      `/task/agent/type/${id}/receviedDoc/`,
      docData
    );
    return response;
  } catch (error) {
    // Handle error or return the error response
    throw error;
  }
};


