import { Avatar } from "@mui/material";
import React from "react";
import LightTooltip from "../Tooltip/LightTooltip";

function formatDate(dateStr) {
  const date = new Date(dateStr);
  date.setMinutes(0, 0, 0); // Round to the nearest hour

  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  }).format(date);
}
const NotificationAll = ({ data }) => {
  return (
    <>
      {data?.map((item, index) => (

        <LightTooltip
          key={index}
          color="#e74c3c"
          title={item?.title}
        >
          <div className="pt-3 position-relative pb-3 align-items-start gap-3 d-flex border-bottom">

            {/* <Avatar
              alt="Remy Sharp"
              src={item?.banner}
            /> */}
            <span className="col-8 pointer  d-flex flex-column justify-content-center">
              <span className="cad-text-small fw-bold text-truncate">{item?.title}</span>
              <span className="cad-text-small text-secondary">{item?.content}</span>
            </span>

            <span
              className="cad-text-small "
              style={{
                position: "absolute",
                right: 0,
                top: 4
              }}>{formatDate(item?.createdAt)}</span>
          </div></LightTooltip>
      ))}
    </>
  );
};

export default NotificationAll;
