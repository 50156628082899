import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Fragment } from "react";
import { Chip, Modal } from "@mui/material";
import { registrastion, ssoGoogleLogin } from "../../../service/auth.service";
import { useNavigate } from "react-router-dom";
import { notify } from "../../../App";
import { useSelector } from "react-redux";
import successGif from "../../../Images/success_gif.gif";
import { makePaymentAfterRegistartion } from "../../../service/billing.service";

const steps = ["Step_1", "Step_2", "Step_3"];

const StepperComp = ({
  regData,
  setRegData,
  token,
  email,
  setEmail,
  setToken,
  setCompanyDetails,
  googleLogin,
}) => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [open, setOpen] = useState(false);
  const [tokenExp, setTokenExp] = useState(false);
  const [chipsData, setChipData] = useState(null);

  const makePayment = async (plan_xid, principal_xid) => {
    try {
      const { data } = await makePaymentAfterRegistartion(plan_xid, principal_xid)
      console.log(data);
      const stripePaymentLink = data?.data;
      window.location.href = stripePaymentLink;
    } catch (error) {
      console.log(error);
    }
  }

  const handleChipClick = (selectedChip, step) => {
    setChipData(selectedChip);
    switch (step) {
      case 0:
        // setSelectedChipsStep1(selectedChip);
        setRegData({ ...regData, know_about_us: selectedChip });
        break;
      case 1:
        setRegData({ ...regData, business_type: selectedChip });
        break;
      case 2:
        setRegData({
          ...regData,
          employees_range: selectedChip,
          token: token,
          email_address: email,
        });
        break;
      default:
        break;
    }
  };
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleNext = async () => {
    if (chipsData === null) {
      return notify("Please select an option", "info");
    }
    setChipData(null);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);

    if (activeStep === 2) {
      setActiveStep(2);
      try {
        const res = googleLogin
          ? await ssoGoogleLogin(regData)
          : await registrastion(regData);
        // console.log(res);
        if (res.status === 201) {
          if (res?.data.plan_xid) {
            await makePayment(res?.data?.plan_xid, res?.data?.principal_xid)
          }
          setOpen(true);
          navigate(res?.data?.url)
        } else if (res.status === 500) {
        }
      } catch (error) {
        // console.log(error?.data?.message);
        googleLogin
          ? notify("Email Id must be unique", "error")
          : setTokenExp(true);
        notify(error?.data?.message, "error")
      }
    }
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <h3 className="text-center cad-fw-600 p-4">Get Started</h3>
      <h5
        style={{ color: "#3c3c3c" }}
        className="cad-fw-600 mb-3 cad-text-medium text-center"
      >
        Hello, {regData.first_name} {regData.last_name} , <br />
        let us get started. Please take a minute to answer a few questions
      </h5>

      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
            ></StepButton>
          </Step>
        ))}
      </Stepper>

      <div className="h-75">
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <Fragment>
            {activeStep === 0 ? (
              <div className="mt-4 pb-5 h-100  d-flex justify-content-around flex-column gap-4">
                <div>
                  <h5
                    style={{ color: "#3c3c3c" }}
                    className="cad-fw-600 text-center cad-text-large"
                  >
                    How did you get to know about us?
                  </h5>
                </div>

                <div className="d-flex justify-content-center gap-2  w-100 flex-wrap">
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Email marketing"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Email marketing", 0)}
                    sx={{
                      ...(regData.know_about_us === "Email marketing" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Social media (platform)"
                    color="primary"
                    variant="outlined"
                    onClick={() =>
                      handleChipClick("Social media (platform)", 0)
                    }
                    sx={{
                      ...(regData.know_about_us ===
                        "Social media (platform)" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Referral"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Referral", 0)}
                    sx={{
                      ...(regData.know_about_us === "Referral" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Advertisement"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Advertisement", 0)}
                    sx={{
                      ...(regData.know_about_us === "Advertisement" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Website"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Website", 0)}
                    sx={{
                      ...(regData.know_about_us === "Website" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Other"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Other", 0)}
                    sx={{
                      ...(regData.know_about_us === "Other" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                </div>

                <div className="w-100 mt-4 d-flex justify-content-center">
                  <div className="col-4">
                    <Button
                      onClick={handleNext}
                      variant="contained"
                      className="button-contained"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : activeStep === 1 ? (
              <div className="mt-4 pb-5 h-100 d-flex justify-content-around flex-column gap-4">
                {/* Default content or content for other steps */}
                <div>
                  <h5
                    style={{ color: "#3c3c3c" }}
                    className="cad-fw-600 text-center cad-text-large"
                  >
                    What's your primary role?
                  </h5>
                </div>

                <div className="d-flex justify-content-center gap-2  w-100 flex-wrap">
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Finance"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Finance", 1)}
                    sx={{
                      ...(regData.business_type === "Finance" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Director"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Director", 1)}
                    sx={{
                      ...(regData.business_type === "Director" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="HR"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("HR", 1)}
                    sx={{
                      ...(regData.business_type === "HR" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="IT"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("IT", 1)}
                    sx={{
                      ...(regData.business_type === "IT" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                </div>

                <div className="w-100 mt-4 d-flex justify-content-center">
                  <div className="col-4">
                    <Button
                      onClick={handleNext}
                      variant="contained"
                      className="button-contained"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4 pb-5 h-100 d-flex justify-content-around flex-column gap-4">
                {/* Default content or content for other steps */}
                <div>
                  <h5
                    style={{ color: "#3c3c3c" }}
                    className="cad-fw-600 text-center cad-text-large"
                  >
                    Number of employees?
                  </h5>
                </div>

                <div className="d-flex justify-content-center gap-2  w-100 flex-wrap">
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="1-4"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("1-4", 2)}
                    sx={{
                      ...(regData.employees_range === "1-4" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="5-9"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("5-9", 2)}
                    sx={{
                      ...(regData.employees_range === "5-9" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="10-49"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("10-49", 2)}
                    sx={{
                      ...(regData.employees_range === "10-49" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="50-99"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("50-99", 2)}
                    sx={{
                      ...(regData.employees_range === "50-99" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                  <Chip
                    className=" ps-3 pe-3 stepper-chip"
                    label="Larger"
                    color="primary"
                    variant="outlined"
                    onClick={() => handleChipClick("Larger", 2)}
                    sx={{
                      ...(regData.employees_range === "Larger" && {
                        backgroundColor: "#1976D2",
                        color: "white",
                      }),
                    }}
                  />
                </div>

                <div className="w-100 mt-4 d-flex justify-content-center">
                  <div className="col-4">
                    <Button
                      onClick={handleNext}
                      variant="contained"
                      className="button-contained"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="cad-modal-blur"
      >
        <div
          className="d-flex justify-content-evenly flex-column p-5 align-items-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            backgroundColor: themeColorsObject.backgroundColor,
            color: themeColorsObject.textColor,
            height: "auto",
            borderRadius: 8,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <img alt="success" src={successGif} width={200} />
          <h2
            style={{ color: "#283734" }}
            className="text-center cad-text-xlarge mb-3  cad-fw-700"
          >
            Registration successful{" "}
          </h2>
          <p
            style={{ color: "#283734" }}
            className="text-center cad-text-samll cad-fw-700"
          >
            Your account has been successfully created. Please click on the
            button to continue
          </p>
          <div className="w-100 d-flex justify-content-center">
            <div className="col-5">
              <Button
                onClick={() => navigate("/login")}
                className="btn button-contained "
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={tokenExp}
        onClose={() => setTokenExp(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="cad-modal-blur"
      >
        <div
          className="d-flex justify-content-evenly flex-column align-items-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            padding: 20,
            p: 2,
            pt: 3,
            backgroundColor: themeColorsObject.backgroundColor,
            color: themeColorsObject.textColor,
            height: 200,
            borderRadius: 8,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <h2 className="text-center text-danger cad-fw-700">
            Session Expired!
          </h2>
          <p className="text-center text-danger cad-fw-700">
            Please Register Again
          </p>
          <div className="w-100 d-flex justify-content-center">
            <div className="col-12 d-flex justify-content-center">
              <button
                onClick={() => {
                  setEmail(false);
                  setToken(false);
                  setCompanyDetails(false);
                  setTokenExp(false);
                  navigate("/registration");
                }}
                className="btn btn-danger "
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Box>
  );
};

export default StepperComp;
