import * as React from "react";
import {
  Avatar,
  Badge,
  Button,
  Fade,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import { Route, Routes } from "react-router";
import { RouteLink } from "../_routes";
import NotFound from "../Pages/NotFound/NotFound";
import { nav } from "../_nav";
import SlideBtn from "./SubComponents/SlideBtn/SlideBtn";
import Accordion from "./SubComponents/Accordion";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import NotificationAll from "./NotificationAll/NotificationAll";
import { BiBell } from "react-icons/bi";
import upgradeLogo from "../Images/upgradeLogo.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useDispatch } from "react-redux";
import CustomizedBreadcrumbs from "./CustomizedBreadcrumbs.js";
import SettingTab from "./SettingTab/SettingTab.js";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import AlertModal from "./AlertModal.js";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AuthMe, logoutUser } from "../Redux/Slice/auth/auth.slice.js";
import { logoutAgent } from "../Redux/Slice/fetchAuthMe.js";
import { useEffect } from "react";
import { getNotificationData } from "../Redux/Slice/notification.service.slice.js";
import { fetchBusinessDetails, setBusinessDetails, setBusinessLogo } from "../Redux/Slice/business.slice.js";
import { acknowledgedNotification } from "../service/notification.service.js";
import { stringAvatar } from "./GlobalTable/UserProfile.js";

const drawerWidth = 260;

function isDateLessThanCurrent(dateString) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();
  return givenDate < currentDate;
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function NewDrawerPersistace() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatarPopoverOpen, setAvatarPopoverOpen] = useState(false);
  const [helloPopperOpen, setHelloPopperOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const rolbase = useSelector((state) => state?.authMe);
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);
  const profileData = authInfo;
  // console.log(rolbase?.role);

  const { themeColorsObject } = useSelector((state) => state.theme);
  const { notification, notification_count } = useSelector((state) => state?.notification);
  // useEffect hook to call the fetchNotification function initially and then every 10 seconds
  const dispatch = useDispatch();
  // useEffect(() => {
  //   // Interval to call fetchNotification every 10 seconds
  //   const interval = setInterval(() => {
  //     dispatch(getNotificationData());
  //   }, 10000);

    // Clean up the interval on component unmount
  //   return () => clearInterval(interval);
  // }, [dispatch]);
  useEffect(() => {
    dispatch(AuthMe());
  }, [dispatch]);

  // Function to get the title based on the route
  const getTitle = () => {
    switch (path) {
      case "/task":
        return "Task";
      case "/contacts":
        return "Contacts";
      case "/form":
        return "Form";
      case "/chats":
        return "Chats";
      case "/settings":
        return "Settings";
      case "/support":
        return "Setting » Support";
      case "/users":
        return "User";
      case "/subscription":
        return "Setting » Subscription";
      case "/tags":
        return "Setting » Tags";
      case "/company-branding":
        return "Setting » Company Branding";
      case "/accounts-security":
        return "Setting » Accounts & Security";
      case "/notification":
        return "Setting » Notifications";
      case "/upgrade-plan":
        return "Upgrade Plan";
      case "/profile":
        return "Profile";
      case "/add-task":
        return "Task » Add task";
      case "/add-new-user":
        return "Setting » Users » Add User";
      default:
        return "Dashboard";
    }
  };

  const handleOpen = () => {
    setOpenLogOut(true);
    setAvatarPopoverOpen(false); // Close the other Popper
  };

  const filteredNav = nav
    .filter((navItem) => {
      if (navItem.title === "Chats" || navItem.title === "Dashboard") {
        // if (navItem.title === "Chats" ) {
        return true;
      }
      return rolbase?.resources?.some(
        (filterItem) => navItem.title === filterItem.title
      );
    })
    .map((navItem) => {
      if (navItem.title === "Chats") {
        return {
          ...navItem,
        };
      }
      return navItem;
    });
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAvatarPopoverOpen(!avatarPopoverOpen);
    setHelloPopperOpen(false); // Close the other Popper
  };

  const handleCloseAvatarPopover = () => {
    setAvatarPopoverOpen(false);
  };

  const handleBellClick = async (event) => {
    setAnchorEl(event.currentTarget);
    setHelloPopperOpen(!helloPopperOpen);
    setAvatarPopoverOpen(false); // Close the other Popper
    const ids = notification
      .filter(item => !item.isRead)
      .map(item => item.id) || [];
    if (ids.length > 0) {
      try {
        dispatch(getNotificationData());
        await acknowledgedNotification(ids);
      } catch (error) {
        console.error(error);
      }

    }
  };

  // console.log(authInfo?.business[0]?.subscribed_user ,  rolbase?.role === "Admin");

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}

      <AppBar
        className={`${themeColorsObject.app_bar_class} appbar-shadow `}
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
        }}
        position="fixed"
        open={open}
      >
        <span className="d-flex justify-content-between ps-4 pe-2 p-2">
          <div className="d-flex align-items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              className="rounded-2 action-hover p-1"
              sx={{ mr: 2, ...(open && { display: "" }) }}
            >
              {open ? (
                <MenuOpenIcon style={{ fontSize: 26 }} />
              ) : (
                <MenuIcon style={{ fontSize: 26 }} />
              )}
            </IconButton>

            <span
              style={{
                borderStartStartRadius: "20%",
                borderBottomLeftRadius: "20%",
              }}
              className="overflow-hidden d-flex"
            >
              <span
                className="cad-fw-800  d-flex align-items-end"
                style={{
                  fontSize: "24px",
                  color: "#4CCD8D",
                  // opacity: open ? 0 : 1,
                  transform: open ? "translateX(-160px)" : "translateX(0px)",
                  transition: "0.5s",
                }}
              >
                Cloudify
              </span>
              <span
                className="d-flex text-primary align-items-end cad-fw-500"
                style={{
                  fontSize: "26px",
                  // opacity: open ? 0 : 1,
                  transform: open ? "translateX(-220px)" : "translateX(0px)",
                  transition: "0.8s",
                }}
              >
                Docs
              </span>
            </span>
          </div>

          <div className="header-right d-flex align-items-center gap-3">
            <div className="pointer"
              onClick={handleAvatarClick} // Open popper when avatar is clicked
            >
              <Tooltip title="Profile Pic">
                <div className="d-flex gap-3 align-items-center">
                  <Avatar
                    alt={profileData?.first_name}
                    src={
                      process.env.REACT_APP_DOWLOAD_URL +
                      "/" +
                      profileData?.profile_photo
                    }
                    className="pointer" // Make the avatar clickable
                    {...stringAvatar(`${profileData?.first_name === undefined ? "N/A" : profileData?.first_name} `)}
                  />

                  <div className="d-flex flex-column me-2">
                    <span className="cad-fw-600 text- cad-text-medium">
                      Hello, {profileData?.first_name} {profileData?.last_name}
                    </span>
                    <span className="cad-text-700 text-primary cad-text-small ">
                      {profileData?.email_address}
                    </span>
                  </div>
                </div>
              </Tooltip>
              <Popper
                open={avatarPopoverOpen}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                sx={{ zIndex: 1200 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className="m-2"
                      style={{
                        width: "220px",
                      }}
                    >
                      <Link
                        onClick={handleCloseAvatarPopover}
                        to="/profile"
                        className="d-flex text-decoration-none p-3 pointer align-items-center gap-3"
                      >
                        <Avatar
                          alt={profileData?.first_name}
                          src={
                            process.env.REACT_APP_DOWLOAD_URL +
                            "/" +
                            profileData?.profile_photo
                          }
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                          {...stringAvatar(`${profileData?.first_name === undefined ? "N/A" : profileData?.first_name} `)}
                        />
                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#4A5057",
                          }}
                          variant="subtitle2"
                        >
                          Profile
                        </Typography>
                      </Link>

                      <Link className="d-flex text-decoration-none border-top  p-3 pointer align-items-center gap-3">
                        <LogoutTwoToneIcon
                          style={{
                            width: "26px",
                            height: "26px",
                          }}
                        />

                        <Typography
                          onClick={handleOpen}
                          sx={{
                            fontWeight: "400",
                            color: "#4A5057",
                          }}
                          variant="subtitle2"
                        >
                          Logout
                        </Typography>
                      </Link>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>

            <div>
              <Tooltip title="Notifcation">
                <Badge
                  onClick={handleBellClick}
                  className="pointer"
                  badgeContent={notification_count}
                  color="error"
                >
                  <BiBell size={22} className="bell cad-fw-800" />
                </Badge>
              </Tooltip>
              <Popper
                open={helloPopperOpen}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                sx={{ zIndex: 1200 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={0}>
                    <div
                      className="m-2"
                      style={{
                        width: "330px",
                      }}
                    >
                      <Paper
                        elevation={2}
                        style={{
                          backgroundColor: "#548CFF",
                        }}
                        className="row pt-3"
                      >
                        <div className="col-7 d-flex align-items-center">
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: "#ffffff",
                            }}
                            variant="h6"
                          >
                            Notifications
                          </Typography>
                        </div>

                        <div className="col-5 d-flex align-items-center justify-content-end">
                          <Paper
                            style={{
                              fontSize: "12px",
                            }}
                            className="fw-bold p-1 ps-2 pe-2"
                          >
                            {notification_count} New
                          </Paper>
                        </div>

                        <div className="mt-3 d-flex justify-content-start"></div>
                        <div
                          className="pb-3"
                          style={{
                            backgroundColor:
                              themeColorsObject.megaCardBackgroundColor,
                            borderRadius: "0px 0px 6px 6px",
                            color: themeColorsObject.textColor,
                            height: "60vh",
                            overflow: "scroll",
                          }}
                        >
                          <NotificationAll data={notification} />
                        </div>
                      </Paper>
                    </div>
                  </Fade>
                )}
              </Popper>
            </div>

            <div
              onClick={() => setIsSettingOpen(!isSettingOpen)}
              className="rounded-2 p-1 me-2 theme-button"
            >
              <Tooltip title="Setting">
                <SettingsRoundedIcon className="pointer text-primary sun cad-text-xlarge" />
              </Tooltip>
            </div>
          </div>
        </span>

        <AlertModal
          open={openLogOut}
          closeModal={() => setOpenLogOut(false)}
          handler={() => {
            localStorage.removeItem("user");
            Cookies.remove("userData"); // Remove the cookie notification
            dispatch(logoutUser());
            dispatch(logoutAgent());
            navigate("/");
          }}
          message={"Are your sure you want to logout?"}
        />

        {/* <LogoutModal open={openLogOut} setOpen={setOpenLogOut} /> */}

        <SettingTab
          isSettingOpen={isSettingOpen}
          setIsSettingOpen={setIsSettingOpen}
        />

        {/* {open ? <></>:  <MiniHorizontalNavbar  route={getTitle()} path={path} /> } */}
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="w-100 d-flex  justify-content-center gap-2">
          {/* <span
            className="cad-fw-700 d-flex align-items-end"
            style={{ color: "#4CCD8D", fontSize: "30px" }}
          >
            CAD
          </span>
          <span
            className="text-primary d-flex align-items-end cad-fw-500"
            style={{ fontSize: "28px" }}
          >
            ITSolution
          </span> */}

          <span
            style={{
              borderStartStartRadius: "20%",
              borderBottomLeftRadius: "20%",
            }}
            className="overflow-hidden d-flex"
          >
            <span
              className="cad-fw-800  d-flex align-items-end"
              style={{
                color: "#4CCD8D",
                fontSize: "24px",
                // opacity: open ? 0 : 1,
                transform: open ? "translateX(0px)" : "translateX(160px)",
                transition: "0.5s",
              }}
            >
              Cloudify
            </span>
            <span
              className=" d-flex text-primary align-items-end cad-fw-500"
              style={{
                fontSize: "26px",
                // opacity: open ? 0 : 1,
                transform: open ? "translateX(0px)" : "translateX(220px)",
                transition: "0.8s",
              }}
            >
              Docs
            </span>
          </span>
        </DrawerHeader>

        {/* <Divider /> */}

        <div className="d-flex flex-column h-100 p-2 pt-0 justify-content-between">
          {rolbase?.resources?.length === 0 ? (
            <List>
              {nav.map(({ title, path, icon, items }, index) =>
                items ? (
                  <ListItem className=" mb-4" key={title} disablePadding>
                    <Accordion
                      themeColorsObject={themeColorsObject}
                      dropLinks={items}
                      title={title} // Corrected 'link.title'
                      size={23}
                      Icon={icon} // Corrected 'link.icon'
                    />
                  </ListItem>
                ) : (
                  <ListItem className=" mb-1" key={title} disablePadding>
                    <SlideBtn
                      themeColorsObject={themeColorsObject}
                      title={title}
                      path={path}
                      size={22}
                      Icon={icon}
                    />
                  </ListItem>
                )
              )}
            </List>
          ) : (
            <List>
              {filteredNav.map(({ title, path, icon, items }, index) =>
                items ? (
                  <ListItem className=" mb-4" key={title}>
                    <Accordion
                      themeColorsObject={themeColorsObject}
                      dropLinks={items}
                      title={title} // Corrected 'link.title'
                      size={22}
                      Icon={icon} // Corrected 'link.icon'
                    />
                  </ListItem>
                ) : (
                  <ListItem key={title} disablePadding>
                    <SlideBtn
                      themeColorsObject={themeColorsObject}
                      title={title}
                      path={path}
                      size={22}
                      Icon={icon}
                    />
                  </ListItem>
                )
              )}
            </List>
          )}

          {authInfo?.business[0]?.subscribed_user || rolbase?.role === "Admin" ? (
            ""
          ) : (
            <Paper
              style={{
                // backgroundColor: theme === "light" ? "#F1F6FF" : "#14272A",
                // color: theme === "light" ? "#000" : "#fff",

                backgroundColor: themeColorsObject.premiumCard,
                color: themeColorsObject.textColor,
              }}
              className="upgrade-card p-4  pt-3 pb-3 rounded-3  gap-2   mt-5 w-100 d-flex flex-column align-items-center "
            >
              <img src={upgradeLogo} width={40} alt="logo" />
              <span className="cad-text-medium cad-fw-600">Upgrade Experience</span>
              <span className="text-center cad-fw-600 cad-text-small">
                Loving the features so far? Want this seamless experience 24x7?
              </span>

              <Link to="/subscription">
                <Button
                  className={`bg-primary text-capitalize w-100 ps-4 pe-4 p-0 cad-text-medium`}
                  variant="contained"
                >
                  Subscribe Now
                </Button>
              </Link>
            </Paper>
          )}
        </div>

        {/* {open?  <></> : } */}
      </Drawer>

      <Main
        style={{
          backgroundColor: themeColorsObject.backgroundColor,
          height:"100vh",
          // backgroundColor: theme === "light" ? "#F1F2F6" : "#282828",
          padding:
            // path === "/template-forms/new-form/"
            false
              ? 0
              : true
                ? "62px 20px 0px 20px"
                : "86px 20px 0px 20px",
        }}
        open={open}
      >
        {true ? (
          <CustomizedBreadcrumbs route={getTitle()} path={path} />
        ) : (
          <></>
        )}

        <AppContent />

        {/* {path === "/template-forms/new-form" ? "" : <Footer />} */}
      </Main>
    </Box>
  );
}

const AppContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { authInfo, isAuthenticate } = useSelector((state) => state?.fetchAuthMe);
  const { subscribed_user } = useSelector((state) => state?.business);
  console.log(isAuthenticate);


  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);
    const endDate = isDateLessThanCurrent(authInfo?.business[0]?.trial_end);
    const SubscriptionEndDate = isDateLessThanCurrent(authInfo?.business[0]?.subscriptions[0]?.end_date);
    dispatch(fetchBusinessDetails())
    // dispatch(setBusinessLogo(authInfo?.business[0]?.business_logo))

    if (endDate) {
      if (subscribed_user && !SubscriptionEndDate) return
      return navigate("/initial-subsription");
    }

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [authInfo?.business, dispatch, isAuthenticate, navigate, subscribed_user]);

  return (
    <Routes>
      {RouteLink.map(({ path, component }, index) => (
        <Route key={index} path={path} element={component} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
