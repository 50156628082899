import React from "react";
import "./DashboardSmallCard.css";
import { GoPeople } from "react-icons/go";
import { BiBriefcaseAlt2 } from "react-icons/bi";
import { IoCallOutline } from "react-icons/io5";
import { Paper } from "@mui/material";
import CountUp from "react-countup";
import { useSelector } from "react-redux";

const DashboardSmallCard = ({ title, count, disc, iconName, subscribed }) => {
  const {  themeColorsObject } = useSelector((state) => state.theme);
  const iconComponents = {
    GoPeople: GoPeople,
    BiBriefcaseAlt2: BiBriefcaseAlt2,
    IoCallOutline: IoCallOutline,
  };

  // Get the corresponding icon component based on the iconName prop
  const IconComponent = iconComponents[iconName];

  if (!IconComponent) {
    // Handle the case where an invalid icon name is provided
    return <div>Invalid Icon</div>;
  }

  return (
    // <div draggable className="parrent col-lg col-12 p-2">
    <div style={{height:195}} draggable className={`"parrent ${subscribed ? "col-lg-4":"col-lg"} p-2 "`}>
      <Paper
        elevation={1}
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.titleText,
          height:"100%"
        }}
        className="dashboard-small-cards d-flex justify-content-around flex-column gap-1"
      >
        <div className="d-flex align-items-center justify-content-between ">
          <h6
            style={{
              color: themeColorsObject?.titleText,
            }}
            className="cad-fw-400"
          >
            {title}
          </h6>
          <IconComponent className="icon" size={40} color="#ffffff" />
        </div>

        <CountUp
          style={{
            color: themeColorsObject?.titleText,
          }}
          className="cad-fw-400"
          start={0}
          suffix={count === 50 ? "k" : ""}
          end={count}
        />

        <p
          style={{
            color: themeColorsObject?.titleText,
          }}
          className="cad-fw-500"
        >
          {disc}
        </p>
      </Paper>
    </div>
  );
};

export default DashboardSmallCard;
