import {
  Avatar,
  CircularProgress,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateUser } from "../../service/user.service";
import { getAllUsers } from "../../Redux/Slice/getAllUsers";
import MainModal from "../../Components/MainModal";
import MuiSelector from "../../Components/MuiSelector";
import { notify } from "../../App";
import GroupAvatars from "../../Components/Avatar/AvatarGroup";

const ViewUserDetails = ({
  open,
  handleClose,
  userId,
  handleOpenDpViewModal,
}) => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const [isEditActive, setIsEditActive] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const { isLoading, users } = useSelector((state) => state?.allUserData);
  const userData = users?.data?.rows.find((obj) => obj.id === userId);
  const { resources } = useSelector((state) => state?.getGlobalResource);
  const { role } = useSelector((state) => state?.authMe);

  const fileURL = (path) => {
    return new URL(process.env.REACT_APP_DOWLOAD_URL + "/" + path).toString();
  };

  const globlePermission = resources?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.title,
      color: "#0d6efd",
    };
  });

  const permissionOptions = userData?.resources?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.title,
      color: "#0d6efd",
    };
  });

  const [selectPermission, setSelectPermission] = useState(permissionOptions);

  const { clients } = useSelector((state) => state?.globalClient);
  const [selectedClients, setSelectedClients] = useState([]);

  const clientsOption = clients?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name + " " + item.last_name,
      color: "#0d6efd",
    };
  });

  const clientsDefault = userData?.clients?.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.first_name + " " + item.last_name,
      color: "#0d6efd",
    };
  });

  const userUpdateSchema = yup.object().shape({
    first_name: yup
      .string()
      .required("First Name is required")
      .min(4, "Designation must be at least 4 characters"),
    last_name: yup
      .string()
      .required("Last Name is required")
      .min(4, "Designation must be at least 4 characters"),
    email_address: yup
      .string()
      .required("Email address is required")
      .email("Email address is required")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email address is required"
      ),
    designation: yup
      .string()
      .required("Designation number is required")
      .matches(/^[^\d]*$/, "Designation cannot contain numbers")
      .min(4, "Designation must be at least 4 characters"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
    // gender: yup.string().required(),
    // city: yup.string().required(),
    // state: yup.string().required(),
    // country: yup.string().required(),
    // post_code: yup.string().required(),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userUpdateSchema),
    // mode: `all`,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setIsEditActive(false);
    if (userData) {
      setValue("first_name", userData?.first_name ?? "");
      setValue("last_name", userData?.last_name ?? "");
      setValue("email_address", userData?.email_address ?? "");
      setValue("designation", userData?.metadata?.designation ?? "");
      setValue("phone_number", userData?.phone_number ?? "");
      // setValue("gender", userData?.gender ?? "");
      // setValue("city", userData?.city ?? "");
      // setValue("state", userData?.state ?? "");
      // setValue("country", userData?.country ?? "");
      // setValue("post_code", userData?.post_code ?? "");
    }
  }, [userData, setValue]);

  const personalInformation = [
    {
      title: "First Name",
      data: userData?.first_name === null ? "N/A" : userData?.first_name,
    },
    {
      title: "Last Name",
      data: userData?.last_name === null ? "N/A" : userData?.last_name,
    },
    {
      title: "Email Address",
      data: userData?.email_address === null ? "N/A" : userData?.email_address,
    },
    {
      title: "Designation",
      data: userData?.metadata?.designation,
    },
    // {
    //   title: "Gender",
    //   data: userData?.gender === null ? "N/A" : userData?.gender,
    // },
    // {
    //   title: "City",
    //   data: userData?.city === null ? "N/A" : userData?.city,
    // },
    // {
    //   title: "State",
    //   data: userData?.state === null ? "N/A" : userData?.state,
    // },
    // {
    //   title: "Country",
    //   data: userData?.country === null ? "N/A" : userData?.country,
    // },
    // {
    //   title: "Post code",
    //   data: userData?.post_code === null ? "N/A" : userData?.post_code,
    // },
  ];

  const onSubmit = async (value) => {
    console.log(selectPermission?.length);
    setIsEditLoading(true);
    const updatedValue = {
      ...value,
      resources: selectPermission.map((item) => item.id),
      clients: selectedClients.map((item) => item.id),

    };

    if (selectPermission?.length === 0) {
      console.log("boom");
      setIsEditLoading(false);
      return notify("Please select permission", "warn");
    }

    try {
      await updateUser(updatedValue, userId);
      // console.log(res);
      setIsEditLoading(false);
      dispatch(getAllUsers());
      setIsEditActive(true);
      // setIsEditActive(false);
      notify(`${userData?.first_name} updated successfully`);
    } catch (error) {
      setIsEditLoading(false);
      console.log(error);
    }
  };

  return (
    <MainModal
      open={open}
      handleClose={handleClose}
      title="View user details"
      width={"60%"}
      height={"90%"}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="h-100">
        <div className="d-flex  flex-wrap position-relative">
          {isLoading ? (
            <div
              style={{ height: "450px" }}
              className="d-flex w-100 justify-content-center align-items-center "
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="col-12 border-bottom mb-3 pb-3 d-flex justify-content-between align-items-center">
                <span className="position-relative   d-flex justify-content-start gap-4 align-items-center">
                  <Avatar
                    onClick={() =>
                      handleOpenDpViewModal(userData?.profile_photo)
                    }
                    sx={{ width: 80, height: 80, cursor: "pointer" }}
                    src={
                      userData?.profile_photo === null
                        ? ""
                        : fileURL(userData?.profile_photo)
                    }
                  />
                  <div className="d-flex flex-column gap-1 ">
                    <span className="cad-text-large cad-fw-600  position-relative">
                      {userData?.first_name} {userData?.last_name}
                    </span>
                    {isEditActive ? (
                      <>
                        <input
                          className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
                          {...register("phone_number")}
                        />
                        {errors["phone_number"] && (
                          <span className="error-message cad-text-small text-danger">
                            {errors["phone_number"].message}
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="cad-text-medium cad-fw-600 cad-text-light-grey">
                        Mob: {userData?.phone_number}
                      </span>
                    )}

                    <span
                      style={{
                        right: "-36px",
                        bottom: "40px",
                      }}
                      className="position-absolute"
                    >
                      {/* {client?.principal[0]?.verfied_user ? ( */}
                      {true ? (
                        <VerifiedRoundedIcon style={{ color: "#548CFF" }} />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </span>

                {/* TODO write a logic last seen 
                  make a threshold mantanin getting a data on backend */}
                {/* <div className="h-100 d-flex align-items-end">
                  <span className="cad-text-small align-self-end cad-fw-600">
                    Last seen{" "}
                    {userData?.last_login_date === null ? (
                      "N/A"
                    ) : (
                      <LastAccess timestamp={userData?.last_login_date} />
                    )}
                  </span>
                </div> */}
              </div>

              <div className="col-12 mb-2 d-flex align-items-center justify-content-between pe-2 gap-3">
                <div className="cad-text-large cad-fw-600">
                  Personal Information
                </div>

                {isEditActive ? (
                  <Stack direction={"row"} spacing={2}>
                    <button
                      type="submit"
                      style={{
                        marginRight: "1rem",
                        borderRadius: "4px",
                        border: "none",
                        backgroundColor: "#4CCD8D",
                        display: role === "Admin" ? "none" : "block",
                      }}
                      className="p-1 ps-3 pe-3 pointer cad-text-small text-white cad-fw-500"
                    >
                      {isEditLoading ? "loading..." : "Save"}
                    </button>
                    <button
                      type="submit"
                      style={{
                        borderRadius: "4px",
                        border: "none",
                        display: role === "Admin" ? "none" : "block",
                      }}
                      className="p-1 ps-3 pe-3 pointer bg-primary cad-text-small text-white cad-fw-500"
                      onClick={() => setIsEditActive(false)}
                    >
                      Cancel
                    </button>
                  </Stack>
                ) : (
                  <span
                    style={{
                      borderRadius: "10%",
                      display: role === "Admin" ? "none" : "block",
                    }}
                    className="p-1 ps-3 text-white bg-primary pe-3 pointer cad-text-small cad-fw-500"
                    onClick={() => setIsEditActive(true)}
                  >
                    Edit
                  </span>
                )}
              </div>

              {isEditActive ? (
                <>
                  {personalInformation.map(({ title, data }, index) => (
                    <div
                      key={index}
                      className="col-6 mb-4 d-flex flex-column gap-2"
                    >
                      <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                        {title}
                      </div>

                      <div className="cad-text-medium w-100  cad-fw-600 ">
                        {/* <input className="cad-text-medium  form-control cad-fw-600" value={data}  /> */}
                        <input
                          className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
                          {...register(title.toLowerCase().replace(" ", "_"))}
                        />
                        {errors[title.toLowerCase().replace(" ", "_")] && (
                          <span className="error-message cad-text-small text-danger">
                            {
                              errors[title.toLowerCase().replace(" ", "_")]
                                .message
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  ))}

                  <div className="col-12 mb-4 d-flex flex-column gap-2">
                    <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                      Permissions
                    </div>
                    <div className=" w-100 d-flex cad-fw-400 cad-text-medium">
                      <MuiSelector
                        defaultValue={selectPermission}
                        isMulti={true}
                        options={globlePermission}
                        setSelected={setSelectPermission}
                      />
                    </div>
                  </div>

                  <div className="col-12 mb-4 d-flex flex-column gap-2 mb-2">
                    <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                      Clients
                    </div>
                    <div className=" w-100 d-flex cad-fw-400 cad-text-medium">
                      <MuiSelector
                        defaultValue={clientsDefault}
                        options={clientsOption}
                        isMulti={true}
                        setSelected={setSelectedClients}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {personalInformation.map(({ title, data }, index) => (
                    <div
                      key={index}
                      className="col-6 mb-4 d-flex flex-column gap-2"
                    >
                      <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                        {title}
                      </div>
                      <div className="cad-text-medium w-100 p-1   cad-fw-600 ">
                        {data}
                      </div>
                    </div>
                  ))}

                  <div className="col-12 mb-4 d-flex flex-column gap-2">
                    <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                      Permissions
                    </div>
                    <div className=" w-100 mt-2 d-flex gap-3  cad-fw-400 cad-text-medium">
                      {userData?.resources?.map(({ id, title }) => (
                        <span className="permissions" key={id}>
                          {title}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="col-12 mb-4 d-flex flex-column gap-2">
                    <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                      Clients
                    </div>
                    <div className=" w-100 d-flex cad-fw-400 cad-text-medium">
                      <GroupAvatars
                        data={userData?.clients}
                        max={userData?.clients?.length}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </form>
    </MainModal>
  );
};

export default ViewUserDetails;
