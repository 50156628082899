import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
} from "@mui/material";
import CountryCode from "../../Utils/COUNTRY_CODE.json";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAllUsers } from "../../Redux/Slice/getAllUsers";
import { useDispatch } from "react-redux";
import { addClient } from "../../service/client.service";
import { useSelector } from "react-redux";
import { getCompanyData } from "../../Redux/Slice/company.service.slice";
import { fetchClient } from "../../Redux/Slice/contactClientSlice";
import { fetchGlobalClient } from "../../Redux/Slice/globalClientSlice";
import MuiSelector from "../../Components/MuiSelector";
import MainModal from "../../Components/MainModal";
import darkPro from "../../Images/darkPro.jpg";
import { notify } from "../../App";


// {/* <PhoneInput
// country={"in"}
// value={phone}
// onChange={setPhone}
// inputProps={{
//   name: 'phone',
//   required: true,
// }}
// style={{ width: '300px' }} // Set the desired width here
// /> */}


const AddClientModal = ({
  openAddNewClientModal,
  setOpenAddNewClientModal,
  theme,
  themeColorsObject,
}) => {
  // const navigate = useNavigate();
  const tags = useSelector((state) => state?.globalTags);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyData());
    dispatch(getAllUsers());
  }, [dispatch]);

  const companyData = useSelector((state) => state?.company?.users?.data?.rows);
  const { globalUsers } = useSelector((state) => state?.globalUser);

  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const countryCodeOptions = CountryCode?.map((item) => {
    return {
      id: item.code,
      value: item.name,
      label: item.name,
      color: "#0d6efd",
    };
  });

  // console.log(selectedTags);

  const contentData = [
    {
      title: "Personal Information",
      fields: [
        {
          label: "First Name",
          type: "text",
          required: true,
          name: "first_name",
          error_msg: true,
        },
        {
          label: "Last Name",
          required: true,
          type: "text",
          name: "last_name",
          error_msg: true,
        },
        {
          label: "Phone Number",
          required: true,
          type: "text",
          name: "phone_number",
          error_msg: true,
        },
        {
          label: "Email Address",
          type: "email",
          required: true,
          name: "email_address",
          error_msg: true,
        },
      ],
    },
    {
      title: "Other Information",
      fields: [
        {
          label: "Tag",
          name: "tag",
          type: "multiple-select",
          options: tags?.rows.map((item) => {
            return {
              id: item.id,
              value: item.id,
              label: item.title,
              color: "#0d6efd",
            };
          }),
        },
        {
          label: "Company/Organization",
          required: false,
          name: "company",
          type: "select",
          options: companyData?.map((ele) => ({
            label: ele.company_name,
            value: ele.id,
          })),
        },
        {
          label: "Assign to",
          // required: true,
          name: "assign",
          type: "select",
          options: globalUsers?.map((ele) => ({
            label: ele.first_name,
            value: ele.id,
          })),
        },
      ],
    },
  ];

  const [previewImage, setPreviewImage] = useState(
    theme === "dark-black" ? darkPro : ""
  );

  const userSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z]+$/, "First Name can only contain letters"),

    email_address: Yup.string()
    .required("Email Address is required")
      .email("Please Enter a valid email address")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid email address"
      ),
    last_name: Yup.string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z]+$/, "First Name can only contain letters"),
    state: Yup.string()
      .notRequired(),
    gender: Yup.string().notRequired(),
    country: Yup.string()
      .notRequired(),
    phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]*$/, "Phone Number must contain only numbers")
    .test(
        "is-min-10-characters",
        "Phone Number must be at least 10 digits",
        (value) => {
            if (!value) {
                return true; // Pass the validation if the field is empty
            }
            return value.length >= 10;
        }
    )
    .max(16, "Phone Number must be at most 16 digits"),

    postal_code: Yup.string(),

    profile_photo: Yup.mixed()
      .test("required", "You need to provide a file", (files) => {
        // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
        if (files) return true;
        return false;
      })
      .test("fileSize", " The maximum size of profile picture is 2MB.", (files) => {
        //if u want to allow only certain file sizes
        try {
          if (files.length !== 0) {
            return files[0].size <= 2000000;
          }
          return true;
        } catch (error) {
          return false;
        }
      })
      .test("file_formate", "Image file has unsupported format.", (files) => {
        // // console.log(files[0].type)

        const SUPPORTED_FORMATS = [
          "image/jpeg",
          "image/png",
          // "image/gif",
          "image/tiff",
          "image/svg+xml",
        ];
        try {
          if (files.length !== 0) {
            setPreviewImage(URL.createObjectURL(files[0]));
            return files && SUPPORTED_FORMATS.includes(files[0].type);
          }
          return true;
        } catch (error) {
          return false;
        }
      })
      .optional(),

    tag: Yup.array(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(userSchema) });


  const onSubmit = async (values) => {
    console.log(values);
    setIsLoading(true);
    const formDataToSend = new FormData();
    values.profile_photo[0] &&
      formDataToSend.append("profile_photo", values.profile_photo[0]);
    formDataToSend.append("first_name", values.first_name);
    formDataToSend.append("email_address", values.email_address);
    values.last_name && formDataToSend.append("last_name", values.last_name);
    values.phone_number &&
      formDataToSend.append("phone_number", values.phone_number);
    values.country && formDataToSend.append("country", values.country);
    values.city && formDataToSend.append("city", values.city);
    // values.country && formDataToSend.append("state", values.state);
    // values.city && formDataToSend.append("gender", values.gender);
    values.postal_code &&
      formDataToSend.append("postal_code", values.postal_code);
    values.company && formDataToSend.append("company_id", values.company);
    values.assign && formDataToSend.append("employee_id", values.assign);

    if (selectedTags.length > 0) {
      selectedTags.forEach((tag, index) => {
        formDataToSend.append(`tags[${index}]`, tag.value);
      });
    }

    // console.log(formDataToSend);
    try {
      const response = await addClient(formDataToSend);
      // console.log("=============[client password]=============",response);
      if (response === undefined) {
        setIsLoading(false);
        return notify("Email should be unique", "error");
      } else {
        if (response.status === 400) {
          setIsLoading(false);
        } else if (response.status === 201) {
          notify("Client added successfully");
          setPreviewImage(theme === "dark-black" ? darkPro : "");
          dispatch(fetchGlobalClient());
          dispatch(
            fetchClient({
              size: 10,
              page: 1,
            })
          );
          reset();
          setIsLoading(false);
          setOpenAddNewClientModal(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      notify(error?.data?.error?.message, "error");
      
    }

    setPreviewImage(theme === "dark-black" ? darkPro : "");
  };


  const handleClose = () => {
    reset();
    setOpenAddNewClientModal(false);
    setPreviewImage(theme === "dark-black" ? darkPro : "");
    setPreviewImage(theme === "dark-black" ? darkPro : "");
  };

  return (
    <MainModal
      open={openAddNewClientModal}
      handleClose={handleClose}
      title="Add Client"
      width={"60%"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex  pt-0 justify-content-between flex-column align-items-center gap-2">
          <div
            style={{
              position: "relative",
            }}
          >
            <Avatar
              className="m-1"
              style={{
                width: "100px",
                height: "100px",
                border: `4px solid ${themeColorsObject.backgroundColor}`,
              }}
              alt="Remy Sharp"
              src={previewImage}
            />

            <input
              type="file"
              id="profile-image-input"
              accept="image/*"
              style={{
                display: "none",
              }}
              {...register("profile_photo")}
            />

            <label
              style={{
                position: "absolute",
                bottom: "0.9rem",
                left: "4.9rem",
                height: "30px",
                width: "30px",
                backgroundColor: themeColorsObject.backgroundColor,
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              htmlFor="profile-image-input"
            >
              <CameraAltTwoToneIcon
                sx={{
                  position: "absolute",
                  bottom: "0rem",
                  right: "0rem",
                  height: "30px",
                  width: "30px",
                  backgroundColor: themeColorsObject.backgroundColor,
                  color: themeColorsObject.textColor,
                  padding: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </label>
          </div>
          {errors?.profile_photo?.message && (
            <span className="cad-text-small mb-1  text-danger cad-fw-600">
              {errors?.profile_photo?.message}
            </span>
          )}
          
          <label className="cad-text-medium mb-1 cad-text-light-grey cad-fw-500">
              Upload Profile Picture
            </label>
          <span className=" cad-text-small text-secondary">Please image less than 2 MB</span>
          
        </div>

        {contentData.map((section, index) => (
          <div key={index}>
            <hr className="mt-3 hr-border" />
            <h5 className="mb-3 cad-text-large">{section.title}</h5>
            <div className="w-100 d-flex flex-wrap">
              {section.fields.map((field, fieldIndex) => (
                <div
                  className={
                    field.type === "multiple-select" || 
                    field.type === "select"
                      ? "col-12 mb-3"
                      : "col-4   pe-2  mb-3"
                  }
                  key={fieldIndex}
                >
                  <p
                    className={`cad-text-medium mb-1 cad-text-light-grey cad-fw-600 ${
                      field.required ? "text-danger" : ""
                    }`}
                  >
                    {field.label}
                    {field.required && <span className="text-danger"> *</span>}
                  </p>
                  {field.type === "select" ? (
                    <select
                      defaultValue={"none"}
                      className={`form-control form-select cad-text-medium w-100 ${
                        themeColorsObject.themed_input_class
                      } ${field.required ? "required" : ""}`}
                      {...register(field.name)}
                    >
                      <option value="">none</option>
                      {field.options?.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : field.type === "multiple-select" ? (
                    <MuiSelector
                      defaultValue={[]}
                      isMulti={true}
                      options={field.options}
                      setSelected={setSelectedTags}
                    />
                  ) : (
                    <input
                      className={`form-control cad-text-medium ${
                        themeColorsObject.themed_input_class
                      } ${field.required ? "required" : ""}`}
                      type={field.type}
                      {...register(field.name)}
                    />
                  )}
                  {field.error_msg && (
                    <div
                      htmlFor={field.name}
                      className=" cad-text-small cad-fw-600 text-primary  text-danger"
                    >
                      {errors[field.name]?.message}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="d-flex justify-content-center py-3 m-auto w-25">
          <Button className="button-contained" type="submit">
            {isLoading ? (
              "Loding..."
            ) : (
              "Add"
            )}
          </Button>
        </div>
      </form>
    </MainModal>
  );
};

export default AddClientModal;
