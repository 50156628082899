import { Avatar, Chip, CircularProgress, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LastAcces from "../../Components/LastAcces";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import LastAccess from "../../Components/LastAcces";
import { updateClients } from "../../service/client.service";
import { getClientById } from "../../Redux/Slice/getClientById";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { fetchClient } from "../../Redux/Slice/contactClientSlice";
import NoData from "../../Components/NoData";
import DateFormatter from "../../Components/DateFormatter";
import TableData from "../../Components/TableData";
import MainModal from "../../Components/MainModal";
import { notify } from "../../App";
import UserProfile, {
  stringAvatar,
} from "../../Components/GlobalTable/UserProfile";
import ActivityBoxComponent from "../../Components/Activity/ActivityBoxComponent";
import DocumentBoxComponents from "../../Components/Document/DocumentBoxComponents";
import { Stack } from "@mui/system";
import Tag from "../../Components/Tag/Tag";
import GroupAvatars from "../../Components/Avatar/AvatarGroup";
import MuiSelector from "../../Components/MuiSelector";
import AlertModal from "../../Components/AlertModal";

const ClientViewModal = ({
  idu,
  open,
  handleClose,
  handleOpenDpViewModal,
  themeColorsObject,
  theme,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [isEditActive, setIsEditActive] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const companyData = useSelector((state) => state?.company?.users?.data?.rows);
  const employee = useSelector((state) => state?.globalUser.globalUsers);
  const { isLoading, clientDetails } = useSelector(
    (state) => state?.getClientById
  );
  const client = clientDetails?.data;
  // console.log("==============================", client);
  const [selectedCompany, setSelectedCompany] = useState(client?.company_xid);

  const [selectedTag, setSelectedTag] = useState(client?.tags || []);

  const [selectedEmp, setSelectedEmp] = useState(client?.employees || []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fileURL = (path) => {
    return new URL(process.env.REACT_APP_DOWLOAD_URL + "/" + path).toString();
  };

  const personalInformation = [
    // {
    //   title: "Salutation",
    //   data: "Lorem",
    // },
    {
      title: "First Name",
      data: client?.first_name === null ? "N/A" : client?.first_name,
    },
    {
      title: "Last Name",
      data: client?.last_name === null ? "N/A" : client?.last_name,
    },
    {
      title: "Email address",
      data: client?.email_address === null ? "N/A" : client?.email_address,
    },
    // {
    //   title: "Phone Number",
    //   data: client?.phone_number === null ? "N/A" : client?.phone_number,
    // },
  ];

  const tags = useSelector((state) => state.globalTags);

  const otherInformation = [
    {
      title: "Tags",
      data: (
        <div className="cad-text-medium d-flex gap-2 cad-text-dark flex-wrap cad-fw-600">
          {client?.tags?.map((tag, i) => (
            <Tag key={i} title={tag?.title} />
          ))}
        </div>
      ),
      selected: selectedTag,
      defaultSelected: client?.tags?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.title,
          color: "#0d6efd",
        };
      }),
      setSelected: setSelectedTag,
      type: "multi-select",

      options: tags?.rows.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.title,
          color: "#0d6efd",
        };
      }),
    },
    {
      title: "Company",
      data: (
        <UserProfile
          proPic={client?.company?.profile_photo}
          companyName={client?.company?.company_name}
        />
      ),
      selected: selectedCompany,
      setSelected: setSelectedCompany,
      type: "select",
    },
    {
      title: "Assign to",
      data: <GroupAvatars data={client?.employees} />,

      defaultSelected: client?.employees?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.first_name,
          color: "#0d6efd",
          isFixed: false,
        };
      }),
      setSelected: setSelectedEmp,
      type: "multi-select",
      options: employee?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.first_name + " " + item?.last_name,
          color: "#0d6efd",
        };
      }),
    },
  ];

  // console.log(otherInformation[0]?.selected);

  const clientUpdateSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    // date_of_birth: yup.string().required("DOB is required"),
    // company: yup.string().required("Company name is required"),
    email_address: yup
      .string()
      .email()
      .required("Email is required")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email address is required"
      ),
    // gender: yup.string().required("Gender is required"),
    // city: yup.string().required("City name is required"),
    // state: yup.string().required("State name is required"),
    // country: yup.string().required("Country is required"),
    phone_number: yup
      .string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    // post_code: yup.string().required("Post code is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(clientUpdateSchema),
  });

  const dispatch = useDispatch();

  console.log(client?.details);

  useEffect(() => {
    setIsEditActive(false);
    if (client) {
      setValue("first_name", client?.first_name ?? "");
      setValue("last_name", client?.last_name ?? "");
      setValue("email_address", client?.email_address ?? "");
      // setValue("phone_number", client?.phone_number ?? "");
      // setValue("gender", client?.gender ?? "");
      // setValue("date_of_birth", client?.city ?? "");
      // setValue("state", client?.state ?? "");
      // setValue("country", client?.country ?? "");
      // setValue("company", client?.company ?? "");
      // setValue("post_code", client?.post_code ?? "");
    }
  }, [client, setValue]);

  const moreInformation = [
    // {
    //   title: "Passport Name",
    //   data: "N/A",
    // },
    // {
    //   title: "Passport Number",
    //   data:
    //     client?.details?.passport_number === null
    //       ? "N/A"
    //       : client?.details?.passport_number,
    // },
    {
      title: "Country",
      data: client?.details?.permanent_country === null ? "N/A" : client?.details?.permanent_country,
      //data: client?.details?.country === null ? "N/A" : client?.details?.country,
    },
    {
      title: "City ",
      data: client?.details?.permanent_city === null ? "N/A" : client?.details?.permanent_city,
      // data: client?.details?.city === null ? "N/A" : client?.details?.city,
    },
    // {
    //   title: "National ID",
    //   data:
    //     client?.details?.national_ID === null
    //       ? "N/A"
    //       : client?.details?.national_ID,
    // },
    {
      title: "Pincode",
      data: client?.permanent_postal_code === null ? "N/A" : client?.permanent_postal_code,
      // data: client?.post_code === null ? "N/A" : client?.post_code,
    },
    // {
    //   title: "Profession",
    //   data:
    //     client?.details?.job_title === null
    //       ? "N/A"
    //       : client?.details?.job_title,
    // },
    // {
    //   title: "Job title",
    //   data:
    //     client?.details?.job_title === null
    //       ? "N/A"
    //       : client?.details?.job_title,
    // },
    // {
    //   title: "Date Of Birth",
    //   data:
    //     client?.details?.date_of_birth === null ? (
    //       "N/A"
    //     ) : (
    //       <DateFormatter inputDate={client?.details?.date_of_birth} />
    //     ),
    // },
    {
      title: "Personal Mobile Number",
      data:
        client?.details?.mobile_number === null
          ? "N/A"
          : client?.details?.mobile_number,
    },
    {
      title: "Primary Email",
      data:
        client?.details?.primary_email_address === null
          ? "N/A"
          : client?.details?.primary_email_address,
    },
    {
      title: "Last engagement",
      data:
        true === null ? (
          "N/A"
        ) : (
          <LastAccess timestamp={client?.details?.updatedAt} />
          // <DateFormatter inputDate={client?.details?.updatedAt} />
        ),
    },
    {
      title: "Created At",
      data:
        true === null ? (
          "N/A"
        ) : (
          // <LastAccess timestamp={client?.details?.updatedAt} />
          <DateFormatter inputDate={client?.details?.updatedAt} />
        ),
    },
  ];

  const onSubmit = (data) => async () => {
    setIsEditLoading(true);
    const updatedValue = {
      ...data,
      company_xid: selectedCompany,
      employee: selectedEmp.map((item) => item.id),
      tags: selectedTag.map((item) => item?.id),
    };
    // setValue("date_of_birth", data?.date_of birth ?? "");
    const id = client?.id;
    try {
      const { status } = await updateClients(id, updatedValue);

      if (status === 200) {
        dispatch(getClientById(id));
        dispatch(fetchClient());
        setSelectedCompany("");
        setIsUpdateLoading(false);
        setIsEditLoading(false);
        setIsEditActive(true);
        setOpenAlert(false)
        setFormData(undefined)
        // handleClose();
        notify(`${client?.first_name} updated successfully`);
      } else if (status === 400) {
        setIsUpdateLoading(false);
        setIsEditActive(false);
      }
    } catch (error) {
      console.error("Error Updateing client:", error);
    }
  };

  const extractedDataArray = client?.clientTasks?.map((user) => {
    const extractedData = {
      id: user.id,
      taskName: user.title,
      priority: (
        <Chip
          style={{ fontWeight: "500" }}
          size="small"
          className={`${user.priority?.title === "Open"
            ? "chip-low"
            : user.priority?.title === "Low"
              ? "chip-low"
              : user.priority?.title === "Medium"
                ? "chip-med"
                : "chip-high"
            }`}
          label={user?.priority?.title}
        />
      ),
      assignedDate: <DateFormatter inputDate={user?.createdAt} />,
      taskType: user?.type.title,
      status: (
        <Chip
          style={{ fontWeight: "500" }}
          size="small"
          className={`${user.status.title === "Open"
            ? "chip-high"
            : user.status.title === "On Hold"
              ? "chip-high"
              : user.status.title === "In Progress"
                ? "chip-med"
                : "chip-low"
            }`}
          label={
            user.status.title === "Open"
              ? "Open"
              : user.status.title === "On Hold"
                ? "On Hold"
                : user.status.title === "In Progress"
                  ? "In Progress"
                  : "Completed"
          }
        />
      ),
      dueDate: <DateFormatter inputDate={user?.due_date} />,
      // mode: (
      //   <span
      //     style={{
      //       right: -24,
      //       backgroundColor:
      //         user?.mode.title === "public" ? "#34A853" : "#FD394A",
      //       width: "fit-content",
      //       height: "100%",
      //     }}
      //     className="text-white rounded-2 m-auto p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center gap-2 pointer cad-text-small"
      //   >
      //     <LocalOfferOutlinedIcon className="cad-text-large" />
      //   </span>
      // ),
    };

    return extractedData;
  });

  const cellConfig = [
    {
      dataKey: "taskName",
      width: "20%",
      align: "left",
    },
    {
      dataKey: "priority",
      width: "8%",
      align: "center",
    },
    {
      dataKey: "assignedDate",
      width: "12%",
      align: "center",
    },
    {
      dataKey: "taskType",
      width: "20%",
      align: "left",
    },
    {
      dataKey: "status",
      width: "8%",
      align: "center",
    },
    {
      dataKey: "dueDate",
      width: "10%",
      align: "center",
    },
    // {
    //   dataKey: "mode",
    //   width: "16%",
    //   align: "center",
    // },
  ];

  const tableHeadRowCell = [
    {
      title: "Task Name",
      width: "20%",
      align: "left",
    },
    {
      title: "Priority",
      width: "8%",
      align: "center",
    },
    {
      title: "Assigned Date",
      width: "16%",
      align: "center",
    },
    {
      title: "Task Type",
      width: "20%",
      align: "left",
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
    },
    {
      title: "Due Date",
      width: "10%",
      align: "center",
    },
    // {
    //   title: "Mode",
    //   width: "16%",
    //   align: "center",
    // },
  ];

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [formData, setFormData] = useState({} || undefined);
  const handleAlert = (data) => {
    setOpenAlert(true);
    setFormData(data);
  };

  return (
    <MainModal
      open={open}
      handleClose={() => {
        handleClose();
        setSelectedCompany("");
        setSelectedEmp([]);
        setSelectedTag([]);
      }}
      title="View Client"
      width={"60%"}
    >
      <AlertModal
        message="Are you sure you want to update this data?"
        handler={onSubmit(formData)}
        open={openAlert}
        closeModal={() => setOpenAlert(false)}
      />
      <form onSubmit={handleSubmit(handleAlert)} className="h-100">
        <div className="d-flex h-100  flex-wrap position-relative">
          {isLoading ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center ">
              <CircularProgress />
            </div>
          ) : (
            <>
              {/* ===========[ border ]=============== */}
              <div className="col-12 border-bottom pt-3 ps-3 pe-3 mb-3 pb-3 d-flex justify-content-between align-items-center">
                <span className="position-relative   d-flex justify-content-start gap-4 align-items-center">
                  <Avatar
                    onClick={() => handleOpenDpViewModal(client?.profile_photo)}
                    style={{ width: 80, height: 80, cursor: "pointer" }}
                    alt={client?.first_name}
                    src={
                      client?.profile_photo === null
                        ? ""
                        : fileURL(client?.profile_photo)
                    }
                    // src={fileURL(client?.profile_photo)}

                    {...stringAvatar(
                      `${client?.first_name === undefined
                        ? "N/A"
                        : client?.first_name
                      } `
                    )}
                  />
                  <div className="d-flex flex-column gap-1 position-relative">
                    <span className="cad-text-large cad-fw-600 cad-tex-dark">
                      {client?.first_name} {client?.last_name}
                    </span>

                    <span className="cad-text-medium cad-fw-600 cad-text-light-grey">
                      Mob: {client?.phone_number}
                    </span>

                    <span
                      style={{
                        right: "-36px",
                        bottom: "26px",
                      }}
                      className="position-absolute"
                    >
                      {/* {client?.verfied_user ? ( */}
                      {client?.verfied_user ? (
                        <VerifiedRoundedIcon style={{ color: "#548CFF" }} />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </span>

                <div className="h-100 d-flex align-items-end">
                  <span className="cad-text-small cad-fw-600">
                    Updated{" "}
                    {client?.updatedAt === null ? (
                      "N/A"
                    ) : (
                      <LastAcces timestamp={client?.updatedAt} />
                    )}
                  </span>
                </div>
              </div>

              <div className="col-12 mb-2 d-flex align-items-center justify-content-between pe-2 gap-3">
                <div className="cad-text-large cad-fw-600 ">
                  Personal Information
                </div>

                {isEditActive ? (
                  <Stack direction={"row"} spacing={2}>
                    <button
                      type="submit"
                      style={{
                        marginRight: "1rem",
                        borderRadius: "4px",
                        border: "none",
                        backgroundColor: "#4CCD8D",
                      }}
                      className="p-1 ps-3 pe-3 pointer cad-text-small text-white cad-fw-500"
                    >
                      {isEditLoading ? "loading..." : "Save"}
                    </button>
                    <button
                      type="submit"
                      style={{
                        borderRadius: "4px",
                        border: "none",
                      }}
                      className="p-1 ps-3 pe-3 pointer bg-primary cad-text-small text-white cad-fw-500"
                      onClick={() => setIsEditActive(false)}
                    >
                      Cancel
                    </button>
                  </Stack>
                ) : (
                  <span
                    style={{
                      background: "#EAEAEA",
                      borderRadius: "10%",
                    }}
                    className="p-1 bg-primary text-white ps-3 pe-3 pointer cad-text-small cad-fw-500"
                    onClick={() => setIsEditActive(true)}
                  >
                    Edit
                  </span>
                )}
              </div>

              {isEditActive
                ? personalInformation.map(({ title, data }, index) => (
                  <div
                    key={index}
                    className="col-4 mb-4 d-flex flex-column gap-2"
                  >
                    <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                      {title}
                    </div>

                    <div className="cad-text-medium w-100  cad-fw-600 ">
                      {/* <input className="cad-text-medium  form-control cad-fw-600" value={data}  /> */}
                      <input
                        type={title === "Date Of Birth" ? "date" : "text"}
                        className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
                        {...register(
                          title === "Date Of Birth"
                            ? "date_of_birth"
                            : title.toLowerCase().replace(" ", "_")
                        )}
                      />
                      {errors[title.toLowerCase().replace(" ", "_")] && (
                        <span className="error-message cad-text-small text-danger">
                          {
                            errors[title.toLowerCase().replace(" ", "_")]
                              .message
                          }
                        </span>
                      )}
                    </div>
                  </div>
                ))
                : personalInformation.map(({ title, data }, index) => (
                  <div
                    key={index}
                    className="col-4 mb-4 d-flex flex-column gap-2"
                  >
                    <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                      {title}
                    </div>
                    <div className="cad-text-medium w-100 p-1   cad-fw-600 ">
                      {data}
                    </div>
                  </div>
                ))}

              {/* ===========[ border ]=============== */}

              <div className="col-12 mb-2 d-flex align-items-center justify-content-between pe-2 gap-3 ">
                <div className="cad-text-large cad-fw-600 ">
                  Other Information
                </div>
              </div>

              {isEditActive
                ? otherInformation.map(
                  (
                    {
                      title,
                      type,
                      data,
                      options,
                      setSelected,
                      defaultSelected,
                    },
                    index
                  ) => (
                    <div
                      key={index}
                      className="col-12 mb-5 d-flex align-items-start flex-column gap-2"
                    >
                      <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                        {title}
                      </div>

                      {type === "select" ? (
                        <select
                          value={selectedCompany}
                          onChange={handleCompanyChange}
                          className={`form-control form-select cad-text-medium w-100 ${themeColorsObject.themed_input_class}`}
                        >
                          {companyData?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.company_name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <MuiSelector
                          defaultValue={defaultSelected}
                          isMulti={true}
                          options={options}
                          setSelected={setSelected}
                        />
                      )}
                    </div>
                  )
                )
                : otherInformation.map(({ title, data }, index) => (
                  <div
                    key={index}
                    className="col-12 mb-4 d-flex align-items-start flex-column gap-2"
                  >
                    <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                      {title}
                    </div>
                    <div className="cad-text-medium w-100 p-1 d-flex justify-content-start   cad-fw-600 ">
                      {data}
                    </div>
                  </div>
                ))}

              <div className="border-bottom col-12 mb-4"></div>

              {/* ============[ More Information ]================= */}

              <div className="col-12 mb-2 d-flex align-items-center gap-3">
                <div className="cad-text-large cad-fw-600 ">
                  More Information
                </div>
              </div>

              {/* ===========[ moreInformation ]=============== */}

              {moreInformation.map(({ title, data }, index) => (
                <div
                  key={index}
                  className="col-4 mb-4 d-flex flex-column gap-2"
                >
                  <div className="cad-text-medium w-100  cad-fw-500 cad-text-light-grey">
                    {title}
                  </div>
                  <div className="cad-text-medium w-100 cad-fw-600 ">
                    {data}
                  </div>
                </div>
              ))}
              {/* ===========[ border ]=============== */}
              {/* <div className="border-bottom col-12 mb-4"></div> */}
            </>
          )}
          {/* =====================[ tab ]================== */}
          <div className=" w-100 h-auto pt-2 border-top mt-4 h-100 d-flex flex-column ">
            <div
              className=""
              style={{
                width: "100%",
              }}
            >
              <Tabs value={tabValue} onChange={handleChange} left="true">
                <Tab
                  label="Tasks"
                  style={{
                    width: "8%",
                    textTransform: "none",
                    fontSize: "14px",
                    color: themeColorsObject.textColor,
                  }}
                />
                <Tab
                  label="Activity"
                  style={{
                    width: "8%",
                    textTransform: "none",
                    fontSize: "14px",
                    color: themeColorsObject.textColor,
                  }}
                />
                <Tab
                  label="Documents"
                  style={{
                    width: "20%",
                    textTransform: "none",
                    fontSize: "14px",
                    color: themeColorsObject.textColor,
                  }}
                />
              </Tabs>
            </div>
            {tabValue === 0 &&
              (extractedDataArray?.length === 0 ? (
                <span className="p-5">
                  <NoData height={"10vh"} />
                </span>
              ) : (
                <TableData
                  tableHeadRowCell={tableHeadRowCell}
                  extractedDataArray={extractedDataArray}
                  cellConfig={cellConfig}
                  setMouseEnteredId={setMouseEnteredId}
                  setMouseEntered={setMouseEntered}
                  padding="pt-2 pb-2"
                  handleViewOpen={() => { }}
                />
              ))}
            {/* Render SupportComponent when the first tab is selected */}
            {tabValue === 1 &&
              (Array.isArray(client?.principal_activities) &&
                client?.principal_activities?.length > 0 ? (
                <ActivityBoxComponent data={client?.principal_activities} />
              ) : (
                <span className="p-5">
                  <NoData height={"10vh"} />
                </span>
              ))}
            {/* Render FAQsComponent when the second tab is selected */}
            {tabValue === 2 &&
              (Array.isArray(client?.documents) &&
                client?.documents.length > 0 ? (
                <DocumentBoxComponents data={client?.documents} />
              ) : (
                <span className="p-5">
                  <NoData height={"10vh"} />
                </span>
              ))}
          </div>
        </div>
      </form>
    </MainModal>
  );
};

export default ClientViewModal;
