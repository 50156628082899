import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  FormControlLabel,
  Checkbox,
  Paper,
  Avatar,
  Button,
  Modal,
  CircularProgress,
} from "@mui/material";
import "./User.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { createUser, resource } from "../../service/user.service";
import { userReporting } from "../../service/user.service";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { getAllUsers } from "../../Redux/Slice/getAllUsers";
import { fetchGlobalUser } from "../../Redux/Slice/globalUserSlice";
import { useDispatch } from "react-redux";
import Selector from "../../Components/Select";
import MainModal from "../../Components/MainModal";
import { useSelector } from "react-redux";
import MuiSelector from "../../Components/MuiSelector";
import darkPro from "../../Images/darkPro.jpg";
import dummyLogo from "../../Images/dummyLogo.png";
import { notify } from "../../App";

const AddNewUserModal = ({ openAddNewUserModal, setOpenAddNewUserModal }) => {
  const navigate = useNavigate();
  const { theme, themeColorsObject } = useSelector((state) => state.theme);

  const [userResource, setUserResource] = useState([]);
  const [reporting, setReporting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPermission, setSlectedPermission] = useState(false);
  const [selectReporting, setSelectReporting] = useState(false);
  const [onSubmitted, setSubmitted] = useState(false);

  const { globalUsers } = useSelector((state) => state?.globalUser);
  // console.log(globalUsers);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchPermissionData() {
      try {
        const response = await resource();
        // // console.log(response.data)
        if (response.status === 204) {
          // Handle the case when the API returns a 204 status code (no content).
          // // console.log('No data available');
        } else if (response.status === 200) {
          // If the API call is successful (status code 200), set the data
          setUserResource(response.data.data);
          // // console.log(response.data.data);
        } else {
          // Handle other cases, e.g., error or non-200 response
          // console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        // console.error('API request error', error);
      }
    }

    async function fetchReportingData() {
      try {
        const response = await userReporting();
        // // console.log(response.data)
        if (response.status === 204) {
          // Handle the case when the API returns a 204 status code (no content).
          // // console.log('No data available');
        } else if (response.status === 200) {
          // If the API call is successful (status code 200), set the data
          setReporting(response.data.data);
        } else {
          // Handle other cases, e.g., error or non-200 response
          // console.error('API request failed');
        }
      } catch (error) {
        // Handle network or other errors
        // console.error('API request error', error);
      }
    }

    // Fetch data for both permissions and reporting options
    Promise.all([fetchPermissionData(), fetchReportingData()])
      .then(() => {
        setLoading(true);
      })
      .catch((error) => {
        // console.error('Error in fetching data', error);
        setLoading(false);
      });
  }, []);

  const contentData = [
    {
      title: "Personal Information",
      fields: [
        {
          label: "First Name",
          type: "text",
          required: true,
          name: "first_name",
          error_msg: true,
        },
        {
          label: "Last Name",
          type: "text",
          required: true,
          name: "last_name",
          error_msg: true,
        },
        {
          label: "Phone Number",
          type: "text",
          required: true,
          name: "phone_number",
          error_msg: true,
        },
        {
          label: "Email Address",
          type: "email",
          required: true,
          name: "email_address",
          error_msg: true,
        },
        {
          label: "Designation",
          type: "text",
          required: true,
          name: "designation",
          error_msg: true,
        },
        {
          label: "Reporting Manager",
          name: "manager_id",
          required: true,
          type: "select",
          options: reporting.map((item) => ({
            id: item.id,
            value: item.id,
            label: item.first_name,
            color: "#0d6efd",
          })),
        },
      ],
    },
    {
      title: "Other Information",
      fields: [
        {
          label: "Permission",
          required: true,
          type: "checkboxGroup",
          fields: userResource.map((item) => ({
            id: item.id,
            value: item.id,
            label: item.title,
            color: "#0d6efd",
          })),
          error_msg: true,
        },
      ],
    },
  ];
  const [previewImage, setPreviewImage] = useState(
    theme === "dark-black" ? darkPro : ""
  );
  const [isLoading, setIsLoading] = useState(false);

  const userSchema = Yup.object().shape({
    first_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "First Name can only contain letters")
      .matches(/^\S*$/, "First Name cannot contain spaces"),

    email_address: Yup.string()
      .email("Email address must be a valid email")
      .required("Email is required")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email address must be a valid email"
      ),

    last_name: Yup.string()
    .matches(/^[a-zA-Z]+$/, "Last Name can only contain letters")
      .required("Last Name is required"), // Mark the field as optional

    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]*$/, "Phone Number must contain only numbers")
      .test(
        "is-min-10-characters",
        "Phone Number must be at least 10 digits",
        (value) => {
          if (!value) {
            return true; // Pass the validation if the field is empty
          }
          return value.length >= 10;
        }
      )
      .max(16, "Phone Number must be at most 16 digits"),

    designation: Yup.string()
      // .matches(/^[^\d]*$/, "Designation cannot contain numbers")
      .required("Designation is required"),

    profile_photo: Yup.mixed()
      .test("required", "You need to provide a file", (files) => {
        // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
        if (files) return true;
        return false;
      })
      .test(
        "fileSize",
        " The maximum size of profile picture is 2MB.",
        (files) => {
          //if u want to allow only certain file sizes
          try {
            if (files.length !== 0) {
              return files[0].size <= 2000000;
            }
            return true;
          } catch (error) {
            return false;
          }
        }
      )
      .test("file_formate", "Image file has unsupported format.", (files) => {
        // // console.log(files[0].type)

        const SUPPORTED_FORMATS = [
          "image/jpeg",
          "image/png",
          // "image/gif",
          "image/tiff",
          "image/svg+xml",
        ];
        try {
          if (files.length !== 0) {
            setPreviewImage(URL.createObjectURL(files[0]));
            return files && SUPPORTED_FORMATS.includes(files[0].type);
          }
          return true;
        } catch (error) {
          return false;
        }
      })
      .optional(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // getValues,
    // getFieldState,
  } = useForm({ mode: "all", resolver: yupResolver(userSchema) });


  const onSubmit = async (values) => {
    setIsLoading(true);
    setSubmitted(true);
    // return // console.log(formData);

    // Create a FormData object
    const formDataToSend = new FormData();

    // Append the data from each field
    formDataToSend.append("first_name", values.first_name);
    formDataToSend.append("email_address", values.email_address);
    // formDataToSend.append('resources', formData.resources);
    values.last_name && formDataToSend.append("last_name", values.last_name);
    values.phone_number &&
      formDataToSend.append("phone_number", values.phone_number);
    selectReporting && formDataToSend.append("manager_id", selectReporting);
    values.profile_photo[0] &&
      formDataToSend.append("profile_photo", values.profile_photo[0]);
    values.designation &&
      formDataToSend.append("designation", values.designation);

    // Append the selected resources (checkbox values)
    if (selectedPermission.length > 0) {
      selectedPermission.forEach((resource) => {
        formDataToSend.append("resources[]", resource.id);
      });
    }

    try {
      const res = await createUser(formDataToSend);
      console.log(res?.data);
      if (res?.status === 201) {
        notify("User created successfully");
        setIsLoading(false);
        setOpenAddNewUserModal(false);
        dispatch(fetchGlobalUser());
        dispatch(getAllUsers());
        reset();
      } else {
        notify("Email id should be unique", "error");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      return notify(error?.data?.message, "error");
    }

    setPreviewImage(theme === "dark-black" ? darkPro : "");
  };

  const handleClose = () => {
    setOpenAddNewUserModal(false);
    setPreviewImage(theme === "dark-black" ? darkPro : "");
    reset();
  };

  return (
    <MainModal
      open={openAddNewUserModal}
      handleClose={handleClose}
      title="Add New User"
      width={"60%"}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="h-100">
        <div className="d-flex flex-column h-100 position-relative">
          <div className="d-flex justify-content-start align-items-center flex-column gap-2">
            <div
              style={{
                position: "relative",
              }}
            >
              <Avatar
                className="m-1"
                style={{
                  width: "100px",
                  height: "100px",
                  border: `4px solid ${themeColorsObject.backgroundColor}`,
                }}
                alt="Remy Sharp"
                src={previewImage}
              />

              <input
                type="file"
                id="profile-image-input"
                accept="image/*"
                style={{
                  display: "none",
                }}
                {...register("profile_photo")}
              />

              <label
                style={{
                  position: "absolute",
                  bottom: "0.9rem",
                  left: "4.9rem",
                  height: "30px",
                  width: "30px",
                  backgroundColor: themeColorsObject.backgroundColor,
                  padding: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                htmlFor="profile-image-input"
              >
                <CameraAltTwoToneIcon
                  sx={{
                    position: "absolute",
                    bottom: "0rem",
                    right: "0rem",
                    height: "30px",
                    width: "30px",
                    backgroundColor: themeColorsObject.backgroundColor,
                    color: themeColorsObject.textColor,
                    padding: "4px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </label>
            </div>
            {errors?.profile_photo && (
              <span className="text-danger cad-text-small cad-fw-600">
                {errors?.profile_photo?.message}
              </span>
            )}
            <label className="cad-text-medium mb-1 cad-text-light-grey cad-fw-500">
              Upload Profile Picture
            </label>
            <span className=" cad-text-small text-secondary">
              Please image less than 2 MB
            </span>
          </div>

          {contentData.map((section, index) => (
            <div key={index}>
              <hr className="mt-3 hr-border" />
              <div>
                <h5 className="cad-text-grey mb-3 cad-text-large">
                  {section.title}
                </h5>

                <div className="d-flex justify-content-start  gap-2 flex-wrap ">
                  {section.fields.map((field, fieldIndex) => (
                    <div
                      style={{
                        width:
                          field.type === "checkboxGroup" ? "100%" : "32.5%",
                      }}
                      className={
                        field.type === "checkboxGroup" ? " mb-3 " : " mb-3"
                      }
                      key={fieldIndex}
                    >
                      <p
                        className={`cad-text-medium mb-1 cad-text-light-grey cad-fw-600 ${
                          field.required ? "text-danger" : ""
                        }`}
                      >
                        {field.label}
                        {field.required && (
                          <span className="text-danger cad-text-small cad-fw-600">
                            {" "}
                            *
                          </span>
                        )}
                      </p>
                      {field.type === "select" ? (
                        // <Selector
                        //   defaultValue={[]}
                        //   isMulti={false}
                        //   options={field?.options}
                        //   setSelected={setSelectReporting}
                        // />

                        <select
                          className={`form-select cad-text-medium ${themeColorsObject.themed_input_class}`}
                          aria-label="Default select example"
                          onChange={(e) => setSelectReporting(e.target.value)}
                        >
                          {field?.options.map(({ id, value, label }) => (
                            <option key={id} value={value} selected>
                              {label}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "checkboxGroup" ? (
                        <>
                          <MuiSelector
                            defaultValue={[]}
                            isMulti={true}
                            options={field?.fields}
                            setSelected={setSlectedPermission}
                          />
                          {selectedPermission.length === 0 ? (
                            <div
                              htmlFor={field.name}
                              className="form-label text-primary form-text text-danger text-danger cad-text-small cad-fw-600"
                            >
                              Permission is required
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <input
                          className={`form-control cad-text-medium ${
                            themeColorsObject.themed_input_class
                          } ${field.required ? "required" : ""}`}
                          type={field.type}
                          {...register(field.name)}
                          // {...(field.error_msg ? register(field.name) : {})}
                          // onChange={handleInputChange}
                        />
                      )}
                      {field.error_msg && (
                        <div
                          htmlFor={field.name}
                          className="form-label text-primary form-text text-danger text-danger cad-text-small cad-fw-600"
                        >
                          {errors[field.name]?.message}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-center py-3 pb-5 m-auto w-25">
            <Button className="button-contained" type="submit">
              {isLoading ? (
                <CircularProgress
                  style={{ height: 24, width: 24 }}
                  color="inherit"
                />
              ) : (
                "Add"
              )}
            </Button>
          </div>
        </div>
      </form>
    </MainModal>
  );
};

export default AddNewUserModal;
