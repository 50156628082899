import React, { useEffect, useState } from "react";
import { Paper, Tab, Tabs, Typography, Tooltip } from "@mui/material";
import "./Contact.css"; // Importing styles
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ViewCarouselTwoToneIcon from "@mui/icons-material/ViewCarouselTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import LightTooltip from "../../Components/Tooltip/LightTooltip"; // Importing Tooltip component
import "react-datepicker/dist/react-datepicker.css";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import BusinessIcon from "@mui/icons-material/Business";
import ArchiveIcon from "@mui/icons-material/Archive";
import Company from "./Company/Company";
import ArchivedClients from "./ArchivedClients/ArchivedClients";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import CompanyNew from "./Company/CompanyNew";
import { useSelector } from "react-redux";
import * as themeColors from "../../Utils/Theme/Colors";
import Client from "./Client";
import { AuthMe } from "../../Redux/Slice/auth/auth.slice";
// import { fetchClient } from "../../Redux/Slice/contactClientSlice";

// Custom Tab Panel Component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Contact = () => {
  const dispatch = useDispatch();
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(AuthMe());
  }, []);

  const { role, actions } = useSelector((state) => state?.authMe);

  return (
    <Paper
      elevation={0}
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.titleText,
      }}
      className=" w-100 mt-1 text-danger mb-4 rounded-3 cad-shadow d-flex overflow-hidden flex-column justify-content-start align-items-start "
    >
      <Box
        className="custom-panel  border-bottom  "
        sx={{
          borderBottom: 0,
          padding: 0,
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tags"
          style={{
            height: "14px",
          }}
        >
          <Tab
            style={{
              textTransform: "none",
              display: "flex",
              color: themeColorsObject.titleText,
            }}
            label="Client"
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              display: "flex",
              color: themeColorsObject.titleText,
            }}
            label="Company"
            {...a11yProps(1)}
          />

          {role === "Admin" ? (
            ""
          ) : (
            <Tab
              style={{
                display: "flex",
                textTransform: "none",
                color: themeColorsObject.titleText,
              }}
              label="Archived clients"
              {...a11yProps(2)}
            />
          )}
        </Tabs>
      </Box>

      <CustomTabPanel
        className="w-100 rounded-bottom-3 custom-panel  h-100 overflow-auto"
        value={value}
        index={0}
      >
        <Client themeColorsObject={themeColorsObject} theme={theme} />
      </CustomTabPanel>
      <CustomTabPanel
        className="w-100  h-100 p-0 custom-panel rounded-bottom-3 overflow-auto"
        value={value}
        index={1}
      >
        <CompanyNew themeColorsObject={themeColorsObject} />
      </CustomTabPanel>
      <CustomTabPanel
        className="w-100 custom-panel rounded-bottom-3 overflow-auto  h-100"
        value={value}
        index={2}
      >
        <ArchivedClients themeColorsObject={themeColorsObject} />
      </CustomTabPanel>
    </Paper>
  );
};

export default Contact;
